import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { EmployeeBorrowerBanksAccountsActionTypes, EmployeeBorrowerBanksAccountsState } from './types';

export const initialState: EmployeeBorrowerBanksAccountsState = {
    status: HttpRequestStatus.NOOP,
    banksAccounts: undefined,
    error: undefined
};

const reducer: Reducer<EmployeeBorrowerBanksAccountsState> = (state = initialState, action): EmployeeBorrowerBanksAccountsState => {
    switch (action.type) {
        case EmployeeBorrowerBanksAccountsActionTypes.EMPLOYEE_BORROWER_BANKS_ACCOUNTS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeBorrowerBanksAccountsActionTypes.EMPLOYEE_BORROWER_BANKS_ACCOUNTS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, banksAccounts: action.payload, error: undefined };
        }
        case EmployeeBorrowerBanksAccountsActionTypes.EMPLOYEE_BORROWER_BANKS_ACCOUNTS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeBorrowerBanksAccountsActionTypes.EMPLOYEE_BORROWER_BANKS_ACCOUNTS_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeBorrowerBanksAccountsReducer };
