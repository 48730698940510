import { AxiosResponse } from 'axios';
import { ProposeEmployeeManagerInfo } from 'model/employee-borrower/propose/manager.model';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import managerInfoApi from 'services/api/employee-borrower/managerInfoApi';
import HttpStatus from '../../../model/enums/httpStatus';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import {  managerInfoError, managerInfoSuccess } from './actions';
import { ManagerInfoActionTypes } from './types';

function* handleManagerInfo() {
    try {
        const result: AxiosResponse<ProposeEmployeeManagerInfo> = yield call(managerInfoApi.getManagerInfo);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(managerInfoError(mapped));
            return;
        }
        yield put(managerInfoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(managerInfoError(mapped));
    }
}

function* watchManagerInfo() {
    yield takeEvery(ManagerInfoActionTypes.MANAGER_INFO_REQUEST, handleManagerInfo);
}

function* managerInfoSaga() {
    yield all([fork(watchManagerInfo)]);
}

export default managerInfoSaga;
