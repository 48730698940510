import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import React from 'react';
import MaskedInput from 'react-text-mask';

const MONTH_MASK = (value?: string) => {
    if (value?.startsWith('0')) {
        return [/0/, /[1-9]/];
    } else if (value?.startsWith('1')) {
        return [/1/, /[0-2]/];
    }
    return [/[01]/, /\d/];
};

export const MonthMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={MONTH_MASK} guide={false} />;
};

export default MonthMask;
