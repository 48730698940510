import { FilledInputProps } from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Check from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyCodeUtils } from 'shared/util/keypress-utils';
import { ValidationResult } from 'shared/util/validation-utils';
import './currency-field.scss';

type PartialTextFieldProps = Pick<
    TextFieldProps,
    'className' | 'margin' | 'autoFocus' | 'onFocus' | 'onBlur' | 'autoComplete' | 'type' | 'InputProps' | 'InputLabelProps'
>;

export interface CurrencyFieldProps extends PartialTextFieldProps {
    label?: string;
    defaultValue?: number;
    value?: number;
    onChange?: (value: number) => void;
    validate?: (value: number) => ValidationResult;
    showValidation?: boolean;
    readOnly?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    onEnter?: (value: number) => void;
    helpText?: string;
    // for situations where the input is externally updated
    // should be the exception
    // avoid using unless necessary to avoid needless rerenders
    externalUpdate?: boolean;
}

export const CurrencyField = (props: CurrencyFieldProps) => {
    const { value: propValue, onChange, validate, readOnly, onEnter, showValidation, helpText, InputProps, externalUpdate, ...rest } = props;
    const { t } = useTranslation();

    const [value, setValue] = React.useState<number>(props.value ?? props.defaultValue ?? 0);
    const [isFocused, setFocused] = React.useState<boolean>(false);

    const [validation, setValidation] = React.useState<ValidationResult>(validate && props.value ? validate(props.value) : { isValid: true });

    React.useEffect(() => {
        if (externalUpdate) {
            setValue(ps => (ps === propValue ? ps : propValue ?? 0));
        }
    }, [externalUpdate, propValue]);

    React.useEffect(() => {
        if (showValidation && validate) {
            const _validation = validate(value);
            setValidation(_validation);
        }
    }, [validate, value, showValidation]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _value = Number(event.target?.value?.replace(/[^0-9\\-]+/g, '') ?? 1);
        if (isNaN(_value) || _value === value) {
            return;
        }

        if (onChange) {
            onChange(_value);
        }

        setValue(_value);
    };

    
    const valueDisplay = () => {
        return Number(value).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (KeyCodeUtils.isEnterKey(event)) {
            event.preventDefault();
            if (onEnter) {
                onEnter(value);
            }
        }
    };

    const handleFocus = () => setFocused(true);
    const handleBlur = () => setFocused(false);

    const validAdornment = (
        <InputAdornment className="text__field__currency--valid-adornment" position="end">
            <Check />
        </InputAdornment>
    );
    const adornment: Pick<FilledInputProps, 'endAdornment'> | undefined = showValidation && validation.isValid ? { endAdornment: validAdornment } : undefined;

    const className = [
        'text__field__currency',
        props.className,
        value && 'text__has-value',
        isFocused && 'text__field__currency--focused',
        showValidation && validation.isValid && 'text__field__currency--valid',
        showValidation && !validation.isValid && 'text__field__currency--has-error',
    ]
        .filter(it => !!it)
        .join(' ');

    return (
        <TextField
            fullWidth
            {...rest}
            variant="outlined"
            InputProps={{ readOnly: props.readOnly, ...adornment, ...InputProps }}
            onChange={handleChange}
            className={className}
            value={valueDisplay()}
            autoComplete={props.autoComplete}
            error={showValidation && !!validate && !validation.isValid}
            helperText={
                showValidation && !!validate && !validation.isValid ? (
                    <Fragment>
                        <span>
                            <InfoOutlinedIcon /> {t(validation.errorMessage)}
                        </span>
                    </Fragment>
                ) : (
                    helpText
                )
            }
            inputRef={props.inputRef}
            onKeyDown={handleKeyPress}
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    );
};

export default CurrencyField;
