import { EMAIL_REGEX } from '../../config/constants';
import { Validations, ValidationUtils } from '../../shared/util/validation-utils';

const EMAIL_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'login.email.validation.required',
    },
    format: {
        predicate: it => !!it && EMAIL_REGEX.test(it),
        errorMessage: 'login.email.validation.format',
    },
    max_length: {
        predicate: it => !it?.length || it.length <= 100,
        errorMessage: 'login.email.validation.max-length',
    },
};
export const validateEmail = (value: string) => ValidationUtils.validate(value, EMAIL_VALIDATION);

const PASSWORD_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'login.password.validation.required',
    },
    min_length: {
        predicate: it => !!it?.length && it.length >= 4,
        errorMessage: 'login.password.validation.min-length',
    },
    max_length: {
        predicate: it => !it?.length || it.length <= 100,
        errorMessage: 'login.password.validation.max-length',
    },
};
export const validatePassword = (value: string) => ValidationUtils.validate(value, PASSWORD_VALIDATION);
