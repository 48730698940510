import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Cancel';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { ProposeEmployeeDeleteDocument } from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-document/propose-employee-document';
import { isNull } from 'lodash';
import { ProposeEmployeeDocumentFileUpload } from 'model/employee-borrower/propose/document.model';
import { EmployeeDocumentType } from 'model/enums/document-type';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import './document-file-input.scss';

interface DocumentFileInputProps {
    id?: string;
    title: string;
    description: string;
    limit: number;
    onChange: (data: ProposeEmployeeDocumentFileUpload) => void;
    fileData: ProposeEmployeeDocumentFileUpload[];
    fileType: EmployeeDocumentType;
    handleDelete: (params: ProposeEmployeeDeleteDocument) => void;
    handleDownload: (id: number | undefined) => void;
    proposeId: number | undefined;
}

interface Event<T = EventTarget> {
    target: T;
}

export const DocumentFileInput = (props: DocumentFileInputProps) => {
    const { title, description, limit, onChange, fileData, handleDelete, fileType, proposeId, id, handleDownload } = props;
    const { t } = useTranslation();
    const [messageError, setShowMessageError] = useState<boolean>(false);

    const filteredFileDataType = fileData.filter(({ documentFile }) => documentFile?.type === fileType);

    const userAgent = navigator.userAgent || navigator.vendor;
    let fileAccept = 'image/x-png, image/jpg, image/jpeg';

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        fileAccept = 'image/x-png, image/jpg, image/jpeg, application/pdf';
    } else if (/android/i.test(userAgent)) {
        fileAccept = 'image/*';
    }

    const fileToByteArray = (file: File): Promise<number[]> => {
        return new Promise((resolve, reject) => {
            let reader: FileReader | null = new FileReader();
            const fileByteArray: number[] = [];

            const cleanUp = () => {
                if (reader != null) {
                    reader.onloadend = null; // Remover o event listener
                    reader?.abort(); // Parar a leitura do arquivo
                    reader = null; // Sugerir a liberação do FileReader
                }
            };

            reader.onloadend = evt => {
                if (evt?.target?.readyState === FileReader.DONE) {
                    const arrayBuffer = evt.target.result as ArrayBuffer;
                    const array = new Uint8Array(arrayBuffer);
                    for (const byte of array) {
                        fileByteArray.push(byte);
                    }
                    resolve(fileByteArray);
                }
                cleanUp();
            };
            reader.onerror = () => {
                reject(new Error('Error reading the file'));
                cleanUp();
            };

            try {
                reader.readAsArrayBuffer(file);
            } catch (e) {
                reject(e);
            }
        });
    };

    const handleChange = async (event: Event<HTMLInputElement>) => {
        if (isNull(event.target.files)) return;
        if (limit && filteredFileDataType.length >= limit) return;
        if (!proposeId) return;
        if (!event.target.files[0]) return;

        setShowMessageError(false);

        const file = event.target.files[0];
        const fileName = event.target.files[0].name;
        const fileSize = event.target.files[0].size / 1024 / 1024; // MB

        if (fileSize > 80) {
            setShowMessageError(true);
            return;
        }

        const byteArray = await fileToByteArray(file);

        const _proposeDocumentFile: ProposeEmployeeDocumentFileUpload = {
            proposeId,
            documentFile: {
                data: byteArray,
                name: fileName,
                type: fileType,
                uniqueKey: uuidV4(),
            },
        };

        onChange(_proposeDocumentFile);
    };

    return (
        <div className="document-file-input">
            <div className="document-file-input__label">
                <span className="document-file-input__label--title"> {title} </span>
                <span className="document-file-input__label--description"> {description} </span>
            </div>
            {limit && filteredFileDataType.length < limit && (
                <>
                    <label style={{ margin: '12px 0px' }}>
                        <input id={id} style={{ display: 'none' }} type="file" onChange={handleChange} accept={fileAccept} />
                        <span className="document-file-input__file">
                            <InsertDriveFileIcon fontSize="small" />
                            <span className="document-file-input__file--placeholder">
                                {t('employee-borrower.propose.documentData.inputFile')}
                            </span>
                        </span>
                    </label>
                    {messageError && (
                        <div className="document-file-input__file--error-message">
                            {t('employee-borrower.propose.documentData.errorMessage')}
                        </div>
                    )}
                </>
            )}
            {filteredFileDataType.map(({ documentFile }, index) => (
                <div key={index} className="document-file-input__file--selected">
                    <AttachFileIcon fontSize="small" className="document-file-input__file--selected-file-icon" />
                    <span className="document-file-input__file--selected-value" onClick={() => handleDownload(documentFile?.documentId)}>
                        {documentFile?.name}
                    </span>
                    <CancelIcon
                        fontSize="small"
                        className="document-file-input__file--selected-button-cancel"
                        onClick={() =>
                            documentFile?.documentId
                                ? handleDelete({ documentId: documentFile?.documentId })
                                : handleDelete({ uniqueKey: documentFile?.uniqueKey })
                        }
                    />
                </div>
            ))}
        </div>
    );
};

export default DocumentFileInput;
