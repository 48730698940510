import { CircularProgress } from '@material-ui/core';
import BackdropMaterial from '@material-ui/core/Backdrop';
import React from 'react';
import './backdrop.scss';

interface BackDropProps {
    open: boolean;
    loadingMessage?: string;
}

export const BackDrop = (props: BackDropProps) => {
    const { open, loadingMessage } = props;

    return (
        <BackdropMaterial open={open}>
            <div className='backdrop__loading-message'> {loadingMessage} </div>
            <CircularProgress color="inherit" />
        </BackdropMaterial>
    );
};

export default BackDrop;
