import AddIcon from '@material-ui/icons/Add';
import SimpleCheckboxInput from 'components/inputs/checkbox-input/simple-checkbox-input';
import Loading from 'components/loading/loading';
import ModalMessage from 'components/modals/modal-message/modal-message';
import CreateProposeEmployeeAccount from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-account/create-propose-employee-account/create-propose-employee-account';
import SelectProposeEmployeeAccount from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-account/select-propose-employee-account/select-propose-employee-account';
import { validateProposeEmployeeAccount } from 'features/employee-borrower/components/propose-employee/validation/propose-employee-account-validation';
import { useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import { defaultProposeEmployeeAccountData, ProposeEmployeeAccountData } from 'model/employee-borrower/propose/account.model';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    employeeBorrowerBanksAccountsRequest,
    employeeBorrowerBanksAccountsResetState,
} from 'reducer/employeeBorrower/bank-account/actions';
import { updateEmployeeBorrowerProposeAccountResetState } from 'reducer/employeeBorrower/propose/propose-account-data/update-propose-account-data/actions';
import { useEmployeeBorrowerBanksAccountsState, useRootDispatch, useUpdateEmployeeBorrowerProposeAccountDataState } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import '../propose-employee-steps.scss';

const I18N_PREFIX = 'employee-borrower.propose.account';
interface ProposeEmployeeAccountStepProps {
    handleChange: (value: Partial<ProposeEmployeeAccountData>) => void;
    proposeAccountData: ProposeEmployeeAccountData;
    isNewAccount: boolean;
    setIsNewAccount: (value: boolean) => void;
}

export const ProposeEmployeeAccountStep = (props: ProposeEmployeeAccountStepProps) => {
    const { isNewAccount, setIsNewAccount, handleChange, proposeAccountData } = props;

    const [shouldRequestLoading, setShouldRequestLoading] = useState<boolean>(false);
    const [openModalMessage, setOpenModalMessage] = useState<boolean>(true);

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const {
        toDisabledButtonNext,
        toNextStep,
        isEditing,
        setCurrentStep,
        setIsEditing,
        toCheckCompletedSteps,
    } = useEmployeeBorrowerContext();

    const { status: proposeAccountStatus } = useUpdateEmployeeBorrowerProposeAccountDataState();
    const { banksAccounts, status: bankAccountsStatus } = useEmployeeBorrowerBanksAccountsState();

    const { toastSuccess } = useToast();

    useEffect(() => {
        dispatch(employeeBorrowerBanksAccountsRequest());
    }, [dispatch]);

    const isDisabledNextStepButton: boolean = useMemo(() => {
        return !validateProposeEmployeeAccount(proposeAccountData);
    }, [proposeAccountData]);

    useEffect(() => {
        toDisabledButtonNext(isDisabledNextStepButton);
    }, [isDisabledNextStepButton, toDisabledButtonNext]);

    useEffect(() => {
        if (proposeAccountStatus === HttpRequestStatus.ONGOING) {
            setShouldRequestLoading(true);
        }
        if (proposeAccountStatus === HttpRequestStatus.SUCCESS) {
            setIsNewAccount(false);
            setIsEditing(false);
            dispatch(updateEmployeeBorrowerProposeAccountResetState());
            dispatch(employeeBorrowerBanksAccountsResetState());

            if (!isEditing) {
                toNextStep();
                return;
            }

            toastSuccess(t(`${I18N_PREFIX}.edit-success`));
            toCheckCompletedSteps('manager', 'account');

            setCurrentStep('summary');
        }
    }, [
        proposeAccountStatus,
        toNextStep,
        dispatch,
        isEditing,
        setCurrentStep,
        setIsEditing,
        toCheckCompletedSteps,
        toastSuccess,
        t,
        setIsNewAccount,
    ]);

    const handleCheckbox = () => {
        if (proposeAccountData.accountData?.jointAccount) {
            handleChange({ accountData: { ...proposeAccountData.accountData, jointAccount: false } });
            return;
        }

        handleChange({ accountData: { ...proposeAccountData.accountData, jointAccount: true } });
    };

    const isCreatedAccount = banksAccounts?.content?.length === 0 || isNewAccount;

    const isLoading = bankAccountsStatus !== HttpRequestStatus.SUCCESS;
    return (
        <>
            {shouldRequestLoading ? (
                <div className="container__employee-borrower--loading">
                    <Loading />
                </div>
            ) : (
                <div className="container__propose-employee-step">
                    <div className="header__propose-employee-step">
                        <span className="header__propose-employee-step--title"> {t('employee-borrower.title')} </span>
                        <span className="header__propose-employee-step--subtitle"> {t(`${I18N_PREFIX}.subtitle`)} </span>
                        {!isCreatedAccount && (
                            <span className="header__propose-employee-step--description"> {t(`${I18N_PREFIX}.description`)} </span>
                        )}
                    </div>
                    <div className="form__propose-employee-step--card">
                        {isCreatedAccount ? (
                            <CreateProposeEmployeeAccount
                                handleChange={handleChange}
                                proposeAccountData={proposeAccountData}
                                handleCheckbox={handleCheckbox}
                            />
                        ) : isLoading ? (
                            <div className="container__employee-borrower--loading">
                                <Loading />
                            </div>
                        ) : (
                            <>
                                <SelectProposeEmployeeAccount
                                    handleChange={handleChange}
                                    proposeAccountData={proposeAccountData}
                                    banksAccounts={banksAccounts?.content}
                                />
                                <div className="form__propose-employee-step--input">
                                    <SimpleCheckboxInput
                                        id="inputText_account_jointAccount"
                                        value={proposeAccountData.accountData?.jointAccount ?? false}
                                        label={`${I18N_PREFIX}.input.jointAccount.label`}
                                        onChange={handleCheckbox}
                                    />
                                </div>
                                <div
                                    className="form__propose-employee-step--card__dashed-button"
                                    onClick={() => {
                                        setIsNewAccount(true);
                                        handleChange({ accountData: defaultProposeEmployeeAccountData.accountData });
                                    }}
                                >
                                    <span>
                                        <AddIcon /> {t(`${I18N_PREFIX}.new-account`).toLocaleUpperCase()}
                                    </span>
                                </div>
                                {openModalMessage && (
                                    <ModalMessage
                                        title={t(`${I18N_PREFIX}.modal.account-attention.title`)}
                                        message={t(`${I18N_PREFIX}.modal.account-attention.description`)}
                                        onCancel={() => {
                                            setOpenModalMessage(false);
                                        }}
                                        onClose={() => setOpenModalMessage(false)}
                                        outlinedButtonLabel={t(`${I18N_PREFIX}.modal.account-attention.continue`)}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ProposeEmployeeAccountStep;
