import React from 'react';
import './App.scss';
import AppRoutes from './shared/routes/routes';

const baseHref = '/';

export class App extends React.Component {
    render() {
        return (
            <div className="page-container">
                <AppRoutes basename={baseHref} />
            </div>
        );
    }
}

export default App;
