import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonAction from 'components/buttons/button-action/button-action';
import IdentificationFormatter from 'components/format/identification/Identification';
import { ICompanyEmployee } from 'model/company-employee.model';
import './company-item.scss';

export interface CompanyItemProps {
    company: ICompanyEmployee;
    onAccess: (company: ICompanyEmployee) => void;
}

export const CompanyItem = (props: CompanyItemProps) => {
    const { t } = useTranslation();
    const { company } = props;

    const handleAccess = () => {
        props.onAccess(company);
    };

    return (
        <div className="company-selection-item">
            <div className="company-selection-item__company-identification">
                <IdentificationFormatter type={company.company?.type} value={company.company?.identification} />
                <span>{company.company?.name}</span>
            </div>
            <div className="company-selection-item__selection-border" />
            {/* <div className="company-selection-item__company-credits">
                <span>{t('modal-company.available-credits').toUpperCase()}</span>
                <Currency value={company?.value} />
            </div> */}
            <div className="company-selection-item__actions">
                <ButtonAction label={t('modal-company.access').toUpperCase()} onClick={handleAccess} />
            </div>
        </div>
    );
};

export default CompanyItem;
