import { EmployeeBorrowerSimulateCalculation, EmployeeBorrowerSimulateCalculationRequest } from 'model/employee-borrower/simulate.model';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { EmployeeBorrowerLoanCalculationActionTypes } from './types';

export const employeeBorrowerUpdateLoanCalculationRequest = (request: EmployeeBorrowerSimulateCalculationRequest ) => 
action(EmployeeBorrowerLoanCalculationActionTypes.EMPLOYEE_BORROWER_LOAN_CALCULATION_REQUEST, request);

export const employeeBorrowerUpdateLoanCalculationSuccess = (data: EmployeeBorrowerSimulateCalculation) => 
action(EmployeeBorrowerLoanCalculationActionTypes.EMPLOYEE_BORROWER_LOAN_CALCULATION_SUCCESS, data);

export const employeeBorrowerUpdateLoanCalculationError = (error: GiroWebappError) => 
action(EmployeeBorrowerLoanCalculationActionTypes.EMPLOYEE_BORROWER_LOAN_CALCULATION_ERROR, error);

export const employeeBorrowerUpdateLoanCalculationResetState = () => 
action(EmployeeBorrowerLoanCalculationActionTypes.EMPLOYEE_BORROWER_LOAN_CALCULATION_RESET_STATE);
