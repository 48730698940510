import { AxiosResponse } from 'axios';
import { ProposeEmployeeDocumentFileUpload } from 'model/employee-borrower/propose/document.model';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { updateEmployeeBorrowerProposeDocumentFileUploadError, updateEmployeeBorrowerProposeDocumentFileUploadRequest, updateEmployeeBorrowerProposeDocumentFileUploadSuccess } from './actions';
import { UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes } from './types';


function* handleUpdateEmployeeBorrowerProposeDocumentFileUpload(action: ReturnType<typeof updateEmployeeBorrowerProposeDocumentFileUploadRequest>) {
    try {
        const _payload: ProposeEmployeeDocumentFileUpload[] = action.payload

        const result: AxiosResponse<ProposeEmployeeSimple>[] = yield all(_payload.map((uploadRequest) => {
            return call(proposeApi.updateProposeDocumentFileUploadRequest, uploadRequest)
        }));

        const errorResponse = result.find((response) => response.status !== HttpStatus.CREATED)

        if (errorResponse) {
            const mapped = ErrorUtils.mapGiroApiError(errorResponse);
            yield put(markError(mapped));
            yield put(updateEmployeeBorrowerProposeDocumentFileUploadError(mapped));
            return;
        }
        yield put(updateEmployeeBorrowerProposeDocumentFileUploadSuccess(result[0].data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateEmployeeBorrowerProposeDocumentFileUploadError(mapped));
    }
}


function* watchUpdateEmployeeBorrowerProposeDocumentFileUpload() {
    yield takeLeading(UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_REQUEST, handleUpdateEmployeeBorrowerProposeDocumentFileUpload);
}

function* updateEmployeeBorrowerProposeDocumentFileUploadSaga() {
    yield all([fork(watchUpdateEmployeeBorrowerProposeDocumentFileUpload)]);
}

export default updateEmployeeBorrowerProposeDocumentFileUploadSaga;
