import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { UserFirstAccessRequest, UserAccessToken } from '../../../model/user';
import { FirstAccessActionTypes } from './types';

export const firstAccessRequest = (request: UserFirstAccessRequest) =>
    action(FirstAccessActionTypes.FIRST_ACCESS_REQUEST, request);
export const firstAccessSuccess = (user: UserAccessToken) => action(FirstAccessActionTypes.FIRST_ACCESS_SUCCESS, user);
export const firstAccessError = (error: GiroWebappError) => action(FirstAccessActionTypes.FIRST_ACCESS_ERROR, error);
export const firstAccessResetState = () => action(FirstAccessActionTypes.FIRST_ACCESS_RESET_STATE);
