import Grid from '@material-ui/core/Grid';
import ButtonBack from 'components/buttons/button-back/button-back';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import { validateEmail } from 'features/first-access/validation-constants';
import { useProfileContext } from 'features/profile/profile-context';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { updatePersonResetState } from 'reducer/account/update-person/actions';
import { usePersonProfileState, useRootDispatch } from 'reducer/hooks';
import { personProfileRequest } from 'reducer/person/profile/actions';
import './profile-editing.scss';

const usePersonProfileRequest = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(personProfileRequest());
    }, [dispatch]);
};

const usePersonProfileAwareness = () => {
    const state = usePersonProfileState();

    const isLoading = state.status !== HttpRequestStatus.ERROR && state.status !== HttpRequestStatus.SUCCESS;

    return { isLoading };
};

export const ProfileEdit = () => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    usePersonProfileRequest();
    const state = usePersonProfileState();

    const {
        showValidation,
        handleUpdatePerson,
        isUpdatingPerson,
        updatePersonSuccess,
        setActiveId,
        setPersonUpdate,
        personUpdate,
    } = useProfileContext();

    const [name, setName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const { isLoading } = usePersonProfileAwareness();

    const handleRefresh = () => {
        dispatch(personProfileRequest());
    };

    React.useEffect(() => {
        if (updatePersonSuccess) {
            setActiveId('data');
            dispatch(updatePersonResetState());
        }
    }, [updatePersonSuccess]);

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.SUCCESS && state.profile) {
            setName(state.profile?.name ?? '');
            setEmail(state.profile?.email ?? '');
            setPersonUpdate({ ...personUpdate, email: state.profile?.email ?? '' });
        }
    }, [state.status, state.profile]);

    return (
        <div className="profile-display__items">
            <div className="container">
                {isLoading ? (
                    <div className="personal-data__items--loading">
                        <Loading />
                    </div>
                ) : state.profile ? (
                    <>
                        <Grid id="data">
                            <div className="personal-data__items--title">{t('profile.user.data.edit')}</div>
                            <Grid item className="personal-data__items--input">
                                <SimpleTextInput id="name" label={t('profile.user.data.name.label')} value={name} readOnly />
                            </Grid>
                            <Grid item className="personal-data__items--input">
                                <ValidTextInput
                                    placeholder={t('profile.user.data.email.placeholder')}
                                    label={t('profile.user.data.email.label')}
                                    value={email}
                                    onChange={email => handleUpdatePerson({ email })}
                                    validate={validateEmail}
                                    showValidation={showValidation}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <div className="personal-data__items--has-error">
                        <div className="personal-data__items__text">{t('header.error.message')}</div>
                        <ButtonBack label={t('header.error.refresh').toUpperCase()} onClick={handleRefresh} />
                    </div>
                )}
                {isUpdatingPerson && (
                    <div className="personal-data__items--loading">
                        <Loading />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileEdit;
