import { isValidCNPJ as validateCNPJ, isValidCPF as validateCPF } from '@brazilian-utils/brazilian-utils';

const VALID_NUMBER_CHARATERS_REGEX = new RegExp(/[^0-9]/g);

const isValidCPF = (value: string): boolean => {
    return validateCPF(value);
};

const isValidCNPJ = (value: string): boolean => {
    return validateCNPJ(value);
};

const getNumberFromIdentification = (value: string | undefined): string | undefined => {
    if (!value) return;

    return value.replace(VALID_NUMBER_CHARATERS_REGEX, "");
}

const IdentificationUtils = {
    isValidCPF,
    isValidCNPJ,
    getNumberFromIdentification,
};
export default IdentificationUtils;
