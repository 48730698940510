import { EMAIL_REGEX, PHONE_REGEX } from '../../../../config/constants';
import { Validations, ValidationUtils } from '../../../../shared/util/validation-utils';

const EMAIL_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'accept-invite.identification.email.validation.required',
    },
    format: {
        predicate: it => !!it && EMAIL_REGEX.test(it),
        errorMessage: 'accept-invite.identification.email.validation.format',
    },
    max_length: {
        predicate: it => !it?.length || it.length <= 100,
        errorMessage: 'accept-invite.identification.email.validation.max-length',
    },
};
export const validateEmail = (value: string) => ValidationUtils.validate(value, EMAIL_VALIDATION);

const PERSON_NAME_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'accept-invite.identification.person-name.validation.required',
    },
    email_format: {
        predicate: it => !it?.length || it.length <= 255,
        errorMessage: 'accept-invite.identification.person-name.validation.max-length',
    },
};
export const validatePersonName = (value: string) => ValidationUtils.validate(value, PERSON_NAME_VALIDATION);

const PASSWORD_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'accept-invite.password.password-field.validation.required',
    },
    min_length: {
        predicate: it => !!it?.length && it.length >= 4,
        errorMessage: 'accept-invite.password.password-field.validation.min-length',
    },
    max_length: {
        predicate: it => !it?.length || it.length <= 100,
        errorMessage: 'accept-invite.password.password-field.validation.max-length',
    },
};
export const validatePassword = (value: string) => ValidationUtils.validate(value, PASSWORD_VALIDATION);

export interface PasswordMatch {
    password: string;
    match: string;
}

const PASSWORD_MATCH_VALIDATION: Validations<PasswordMatch> = {
    required: {
        predicate: it => !!it && !!it.match,
        errorMessage: 'accept-invite.password.confirm-field.validation.required',
    },
    min_length: {
        predicate: it => !!it?.match?.length && it.match.length >= 4,
        errorMessage: 'accept-invite.password.confirm-field.validation.min-length',
    },
    max_length: {
        predicate: it => !it?.match?.length || it.match.length <= 100,
        errorMessage: 'accept-invite.password.confirm-field.validation.max-length',
    },
    match: {
        predicate: it => !!it && !!it.password && !!it.match && it.password === it.match,
        errorMessage: 'accept-invite.password.confirm-field.validation.match',
    },
};

export const validatePasswordMatch = (value: PasswordMatch) => ValidationUtils.validate(value, PASSWORD_MATCH_VALIDATION);

const WHATSAPP_NUMBER_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'accept-invite.contact.whatsapp.validation.required',
    },
    format: {
        predicate: it => !!it && PHONE_REGEX.test(it),
        errorMessage: 'accept-invite.contact.whatsapp.validation.format',
    },
};

export const validateWhatsappNumber = (value: string) => ValidationUtils.validate(value, WHATSAPP_NUMBER_VALIDATION);

const PHONE_NUMBER_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'accept-invite.contact.phone.validation.required',
    },
    format: {
        predicate: it => !!it && PHONE_REGEX.test(it),
        errorMessage: 'accept-invite.contact.phone.validation.format',
    },
};

export const validatePhoneNumber = (value: string) => ValidationUtils.validate(value, PHONE_NUMBER_VALIDATION);
