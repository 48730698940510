import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { InviteToAcceptActionTypes, InviteToAcceptState } from './types';

export const initialState: InviteToAcceptState = {
    status: HttpRequestStatus.NOOP,
    invite: undefined,
    company: undefined,
    error: undefined,
};

const reducer: Reducer<InviteToAcceptState> = (state = initialState, action): InviteToAcceptState => {
    switch (action.type) {
        case InviteToAcceptActionTypes.INVITE_TO_ACCEPT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, invite: undefined, error: undefined };
        }
        case InviteToAcceptActionTypes.INVITE_TO_ACCEPT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, invite: action.payload, error: undefined };
        }
        case InviteToAcceptActionTypes.INVITE_TO_ACCEPT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case InviteToAcceptActionTypes.INVITE_TO_ACCEPT_COMPANY_UPDATE: {
            return { ...state, company: action.payload };
        }

        default: {
            return state;
        }
    }
};

export { reducer as inviteToAcceptReducer };
