import { AxiosResponse } from 'axios';
import { IEmployeeTimeEntry } from '../../model/employee-time-entry.model';
import { api } from './api';

const BASE_URL = 'api/webapp/employee-time-entries';

export const employeeTimeEntries = () => {
    const findAllByMonth = (): Promise<AxiosResponse<IEmployeeTimeEntry[]>> => {
        return api.get<IEmployeeTimeEntry[]>(`${BASE_URL}`);
    };

    return {
        findAllByMonth,
    };
};

export default employeeTimeEntries();
