import { INTL_LOCALE, PERCENTAGE_FIXED_DECIMAL } from 'config/constants';

export const NON_DIGIT_SEARCH_REGEX = /\D/g;
export const NON_DECIMAL_SEARCH_REGEX = /[\D,]/g;
export const NON_FLOAT_CHAR_SEARCH_REGEX = /[^\d.]/g;

export const MASK_DIGIT_REGEX = /\d/;

export const PERCENTAGE_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    style: 'percent',
    minimumFractionDigits: PERCENTAGE_FIXED_DECIMAL,
    maximumFractionDigits: PERCENTAGE_FIXED_DECIMAL,
});
