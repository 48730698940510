import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { ProposeEmployeeManagerInfo } from 'model/employee-borrower/propose/manager.model';

import { ManagerInfoActionTypes } from './types';

export const managerInfoRequest = () => action(ManagerInfoActionTypes.MANAGER_INFO_REQUEST);
export const managerInfoSuccess = (data: ProposeEmployeeManagerInfo) => action(ManagerInfoActionTypes.MANAGER_INFO_SUCCESS, data);
export const managerInfoError = (error: GiroWebappError) => action(ManagerInfoActionTypes.MANAGER_INFO_ERROR, error);
export const managerInfoResetState = () => action(ManagerInfoActionTypes.MANAGER_INFO_RESET_STATE);
