import BaseReadOnlyField from 'features/employee-borrower/components/inputs/base-read-only-field';
import React from 'react';

interface NumberReadOnlyFieldProps {
    label: string;
    value: number;
    iconLabel?: React.ReactElement;
    prefix?: string;
}

export const NumberReadOnlyField = (props: NumberReadOnlyFieldProps) => {
    const { value, iconLabel, label, prefix } = props;

    return <BaseReadOnlyField label={label} iconLabel={iconLabel} value={String(value) ?? '-'} prefix={prefix} />;
};

export default NumberReadOnlyField;
