import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { Token } from '../../model/authentication';

class AuthUtil {
    private AUTH_TOKEN_KEY = 'webapp-authenticationToken';

    isAuthenticated(): boolean {
        const token = localStorage.getItem(this.AUTH_TOKEN_KEY) ?? null;
        if (token != null) {
            const jwt: any = jwt_decode(token);
            if (jwt != null) {
                return moment().millisecond(jwt.exp).isAfter(moment());
            }
        }
        return !!localStorage.getItem(this.AUTH_TOKEN_KEY);
    }

    getToken(): Token | null {
        const _auth: string | null = localStorage.getItem(this.AUTH_TOKEN_KEY);
        return {
            id_token: _auth,
        };
    }

    setToken(token: Token): void {
        const _auth = token.id_token;
        localStorage.setItem(this.AUTH_TOKEN_KEY, _auth as string);
    }

    removeToken(): void {
        localStorage.removeItem(this.AUTH_TOKEN_KEY);
    }

    clear(): void {
        localStorage.clear();
    }
}

export default new AuthUtil();
