import Modal from '@material-ui/core/Modal';
import ButtonAction from 'components/buttons/button-action/button-action';
import Currency from 'components/format/currency/Currency';
import { GIRO_PIPEFY_EMPLOYEE_FORM_LINK } from 'config/constants';
import { EmployeeAnticipationStatus } from 'model/enums/employee-anticipation-status.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { useEmployeeAnticipationState, useEmployeeMonthlyCreditState } from 'reducer/hooks';
import './anticipation-success.scss';

export interface AnticipationSuccessProps {
    open: boolean;
    onClose: () => void;
}

export const AnticipationSuccess = (props: AnticipationSuccessProps) => {
	const { t } = useTranslation();
	const { employeeMonthlyCredit } = useEmployeeMonthlyCreditState();
	const { employeeAnticipation } = useEmployeeAnticipationState();

	const processedAnticipation = employeeMonthlyCredit?.anticipations?.filter((it) => it.status === EmployeeAnticipationStatus.PROCESSED);
	const hasSignedTerm = processedAnticipation !== undefined && processedAnticipation.length > 0 ? true : false;

    const [linkUsed, setLinkUsed] = React.useState<boolean>(false);

    const handleLinkClick = () => setLinkUsed(true);

    return (
        <div className="container">
            <div className="body__anticipation-success">
                <Modal open={props.open} disableBackdropClick disableEscapeKeyDown>
                    <div id="ref-base-component">
                        <Row style={{ background: 'white', height: '100vh' }} className="align-items-center">
                            <Col md="5" style={{ margin: 'auto' }}>
                                <div className="body__anticipation-success--itens">
                                    {hasSignedTerm && (
                                        <>
                                            <div className="body__anticipation-success--image" />
                                            <div className="body__anticipation-success--subtitle">
                                                {t('anticipation-simulation.anticipation-success.subtitle')}
                                            </div>

                                            <div className="body__anticipation-success--final-text">
                                                {t('anticipation-simulation.anticipation-success.text-start')}
                                                <span>
                                                    {t('anticipation-simulation.anticipation-success.days-to-pay', {
                                                        count: Math.max(
                                                            1,
                                                            Math.round(
                                                                (employeeMonthlyCredit?.employee?.program?.estimatedPaymentDateInHours ??
                                                                    0) / 24
                                                            )
                                                        ),
                                                    })}
                                                </span>
                                                {t('anticipation-simulation.anticipation-success.text-value')}
                                                <span>
                                                    <Currency value={employeeAnticipation?.employeeAmount ?? 0} />
                                                </span>
                                                <span> {t('anticipation-simulation.anticipation-success.text-middle')}</span>
                                                {t('anticipation-simulation.anticipation-success.text-end')}
                                            </div>
                                        </>
                                    )}

                                    {!hasSignedTerm && (
                                        <>
                                            <div className="body__anticipation-success--subtitle">
                                                {t('anticipation-simulation.pending-term-signature.subtitle')}
                                            </div>
                                            <div className="body__anticipation-success--text">
                                                {t('anticipation-simulation.pending-term-signature.message')}
                                            </div>

                                            <a
                                                className="body__anticipation-success--link"
                                                href={GIRO_PIPEFY_EMPLOYEE_FORM_LINK}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={handleLinkClick}
                                            >
                                                {t('anticipation-simulation.pending-term-signature.link')}
                                            </a>
                                            <div className="body__anticipation-success--final-text">
                                                {t('anticipation-simulation.pending-term-signature.text-start')}
                                                <span>
                                                    {t('anticipation-simulation.pending-term-signature.days-to-pay', {
                                                        count: Math.max(
                                                            1,
                                                            Math.round(
                                                                (employeeMonthlyCredit?.employee?.program?.estimatedPaymentDateInHours ??
                                                                    0) / 24
                                                            )
                                                        ),
                                                    })}
                                                </span>
                                                {t('anticipation-simulation.pending-term-signature.text-value')}
                                                <span>
                                                    <Currency value={employeeAnticipation?.employeeAmount ?? 0} />
                                                </span>
                                                <span> {t('anticipation-simulation.pending-term-signature.text-middle')}</span>
                                                {t('anticipation-simulation.pending-term-signature.text-end')}
                                            </div>
                                        </>
                                    )}
                                    <ButtonAction
                                        label={t('anticipation-simulation.anticipation-success.button.ok').toUpperCase()}
                                        disabled={!hasSignedTerm && !linkUsed}
                                        onClick={props.onClose}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default AnticipationSuccess;
