import Loading from 'components/loading/loading';
import CardAccountSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-account-summary/card-account-summary';
import CardAddressDataSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-address-data-summary/card-address-summary';
import CardDocumentDataSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-document-data-summary/card-document-data-summary';
import CardGeneralDataSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-general-data-summary/card-general-data-summary';
import CardManagerSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-manager-summary/card-manager-summary';
import CardSimulateSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-simulate-summary/card-simulate-summary';
import CardSupplementaryDataSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-supplementary-data-summary/card-supplementary-data-summary';
import { useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import ManagerMode from 'model/enums/manager-mode';
import { PayrollLoanProposeStatus } from 'model/enums/propose-status';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { employeeBorrowerProposeConfirmResetState } from 'reducer/employeeBorrower/propose/propose-confirm/actions';
import { employeeBorrowerProposeRecentResetState } from 'reducer/employeeBorrower/propose/propose-recent/actions';
import {
    employeeBorrowerProposeSummaryRequest,
    employeeBorrowerProposeSummaryResetState,
} from 'reducer/employeeBorrower/propose/propose-summary/actions';
import { useEmployeeBorrowerProposeConfirmState, useEmployeeBorrowerProposeSummaryState, useRootDispatch } from 'reducer/hooks';
import '../propose-employee-steps.scss';

const I18N_PREFIX = 'employee-borrower.propose';

interface ProposeEmployeeSummaryStepProps {
    proposeId: number | undefined;
    managerMode?: ManagerMode | undefined;
    setIsReloadPageRequestSummary: (value: boolean) => void;
}

export const ProposeEmployeeSummaryStep = (props: ProposeEmployeeSummaryStepProps) => {
    const { proposeId, setIsReloadPageRequestSummary } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();

    const { toDisabledButtonNext, setCurrentStep, setIsEditing, isManualManager } = useEmployeeBorrowerContext();

    const { proposeSummary, status: proposeSummaryStatus } = useEmployeeBorrowerProposeSummaryState();
    const { status: confirmProposeStatus } = useEmployeeBorrowerProposeConfirmState();

    useEffect(() => {
        toDisabledButtonNext(false);
        setIsReloadPageRequestSummary(false);
    }, [toDisabledButtonNext, setIsReloadPageRequestSummary]);

    useEffect(() => {
        if (!proposeId) return;
        dispatch(employeeBorrowerProposeSummaryRequest(proposeId));
    }, [dispatch, proposeId]);

    useEffect(() => {
        if (confirmProposeStatus === HttpRequestStatus.SUCCESS) {
            dispatch(employeeBorrowerProposeRecentResetState());
            dispatch(employeeBorrowerProposeSummaryResetState());
            dispatch(employeeBorrowerProposeConfirmResetState());
            history.push('/proposes/history');
        }
    }, [dispatch, confirmProposeStatus, history]);

    const isLoading = proposeSummaryStatus !== HttpRequestStatus.SUCCESS;

    const disabledEdition =
        proposeSummary?.status === PayrollLoanProposeStatus.OPERATIONAL_REVIEW ||
        proposeSummary?.status === PayrollLoanProposeStatus.APPROVED ||
        proposeSummary?.status === PayrollLoanProposeStatus.WAITING_SIGNATURE ||
        proposeSummary?.status === PayrollLoanProposeStatus.SIGNED;

    return isLoading ? (
        <div className="container__employee-borrower--loading">
            <Loading />
        </div>
    ) : (
        <>
            <div className="container__propose-employee-step">
                <div className="header__propose-employee-step">
                    <span className="header__propose-employee-step--title"> {t('employee-borrower.title')} </span>
                    {proposeSummary?.status === PayrollLoanProposeStatus.HISTORY && (
                        <span className="header__propose-employee-step--subtitle"> {t(`${I18N_PREFIX}.summary.subtitle`)} </span>
                    )}
                </div>
                <div className="summary-container">
                    <div style={{ marginBottom: '50px' }}>
                        <CardAccountSummary
                            loanBankAccount={proposeSummary?.loanAccountData}
                            onClick={() => {
                                setCurrentStep('account');
                                setIsEditing(true);
                            }}
                            disabled={disabledEdition}
                        />
                        {isManualManager && (
                            <CardManagerSummary
                                manager={proposeSummary?.manager}
                                onClick={() => {
                                    if (!isManualManager) return;
                                    setCurrentStep('manager');
                                    setIsEditing(true);
                                }}
                                disabled={!isManualManager || proposeSummary?.status !== PayrollLoanProposeStatus.HISTORY}
                            />
                        )}
                        <CardGeneralDataSummary
                            generalData={proposeSummary?.generalData}
                            onClick={() => {
                                setCurrentStep('generalData');
                                setIsEditing(true);
                            }}
                            disabled={disabledEdition}
                        />
                        <CardSupplementaryDataSummary
                            supplementaryData={proposeSummary?.supplementaryData}
                            onClick={() => {
                                setCurrentStep('supplementaryData');
                                setIsEditing(true);
                            }}
                            disabled={disabledEdition}
                        />
                        <CardAddressDataSummary
                            addressData={proposeSummary?.address}
                            onClick={() => {
                                setCurrentStep('addressData');
                                setIsEditing(true);
                            }}
                            disabled={disabledEdition}
                        />
                        <CardDocumentDataSummary
                            documentData={proposeSummary?.documentData}
                            onClick={() => {
                                setCurrentStep('documentData');
                                setIsEditing(true);
                            }}
                            disabled={disabledEdition}
                        />
                    </div>
                    <CardSimulateSummary
                        summary={proposeSummary}
                        onClick={() => {
                            setCurrentStep('simulate');
                            setIsEditing(true);
                        }}
                        disabled={proposeSummary?.status !== PayrollLoanProposeStatus.HISTORY}
                    />
                </div>
            </div>
        </>
    );
};

export default ProposeEmployeeSummaryStep;
