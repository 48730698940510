import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum UpdateEmployeeBorrowerProposeAddressDataActionTypes {
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_REQUEST = '@@updateEmployeeBorrowerProposeAddressData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_REQUEST',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_SUCCESS = '@@updateEmployeeBorrowerProposeAddressData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_SUCCESS',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_ERROR = '@@updateEmployeeBorrowerProposeAddressData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_ERROR',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_RESET_STATE = '@@updateEmployeeBorrowerProposeAddressData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_RESET_STATE',
}

export interface UpdateEmployeeBorrowerProposeAddressDataState extends BaseStoreState {
    readonly proposeAddressData?: ProposeEmployeeSimple;
}
