import FieldBasic from 'components/field-basic/field-basic';
import React, { Fragment } from 'react';
import './base-read-only-field.scss';

interface BaseReadOnlyFieldProps {
    label: string;
    value: string;
    iconLabel?: React.ReactElement;
    prefix?: string;
}

export const BaseReadOnlyField = (props: BaseReadOnlyFieldProps) => {
    const { label, iconLabel, value, prefix } = props;

    return (
        <div className="base-read-only-field">
            <FieldBasic
                label={
                    <Fragment>
                        {iconLabel && <span> {iconLabel} </span>}
                        <span> {label} </span>
                    </Fragment>
                }
                value={prefix ? `${value} ${prefix}` : value}
                autoFocus
                readOnly
            />
        </div>
    );
};

export default BaseReadOnlyField;
