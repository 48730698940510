import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import Loading from '../../components/loading/loading';
import { ErrorConstants, ErrorType } from '../../model/enums/error-constants';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { ErrorHandlingCustomization } from '../../model/error';
import { resetPasswordRequest } from '../../reducer/account/reset-password/actions';
import { hasAcceptedTermsResetState } from '../../reducer/account/terms-of-use/actions';
import { userAccountResetState } from '../../reducer/account/user-account/actions';
import { customizeErrorHandling } from '../../reducer/application/error/actions';
import { defaultCompanyEmployeeResetState } from '../../reducer/company/default/actions';
import { usePasswordResetState, useRootDispatch, useUserByResetKeyState } from '../../reducer/hooks';
import { userByResetKeyRequest, userByResetKeyResetState } from '../../reducer/user/reset-key/actions';
import authUtil from '../../services/api/authUtil';
import { useIsMobile } from '../../shared/responsive/media-query';
import Password from './components/password/password';
import ResetResponsive from './components/reset-responsive/reset-responsive';
import ResetSuccess from './components/reset-success/reset-success';
import './password-reset.scss';

interface PasswordResetParams {
    key: string;
}

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        const _record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.user.resetKeyDoesNotExists': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'password-reset.error.understood',
                    handler: history => history.push('/'),
                },
            },
        };
        dispatch(customizeErrorHandling({ record: _record }));
    }, [dispatch]);
};

const useFindUserByResetKey = (key: string) => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(userByResetKeyRequest(key));
    }, [key, dispatch]);
};

const useUserByResetKeyAwareness = () => {
    const state = useUserByResetKeyState();

    const isLoading = state.status !== HttpRequestStatus.ERROR && state.status !== HttpRequestStatus.SUCCESS;

    return { isLoading };
};

const useCleanState = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(userByResetKeyResetState());
        };
    }, [dispatch]);
};

const useShowSuccessModal = () => {
    const state = usePasswordResetState();

    const [showModal, setShowModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.SUCCESS) {
            setShowModal(true);
        }
    }, [state.status]);

    return { showModal };
};

export const PasswordReset = () => {
    const { key } = useParams<PasswordResetParams>();
    const isMobile = useIsMobile();
    const history = useHistory();

    const passwordResetState = usePasswordResetState();
    const dispatch = useRootDispatch();
    useFindUserByResetKey(key);
    useCleanState();

    const { user } = useUserByResetKeyState();
    const { isLoading } = useUserByResetKeyAwareness();
    const { showModal } = useShowSuccessModal();

    useErrorConfiguration();

    const handleFinish = (password: string, confirm: string) => {
        dispatch(resetPasswordRequest({ key, password, confirm }));
    };

    React.useEffect(() => {
        authUtil.clear();
        dispatch(userAccountResetState());
        dispatch(hasAcceptedTermsResetState());
        dispatch(defaultCompanyEmployeeResetState());
    }, [dispatch]);

    const handleClose = () => {
        history.push('/');
    };

    if (isLoading) {
        return (
            <div className="container__password-reset">
                <Container className="password-reset--loading">
                    <Loading />
                </Container>
            </div>
        );
    }

    if (isMobile) {
        return (
            <div className="container__password-reset">
                <Container>
                    <ResetResponsive
                        username={user?.login ?? ''}
                        isLoading={passwordResetState.status === HttpRequestStatus.ONGOING}
                        onFinish={handleFinish}
                    />
                </Container>
                <ResetSuccess open={showModal} onClose={handleClose} />
            </div>
        );
    }

    return (
        <div className="container__password-reset">
            <Container>
                <Password
                    username={user?.login ?? ''}
                    onFinish={handleFinish}
                    isLoading={passwordResetState.status === HttpRequestStatus.ONGOING}
                />
            </Container>
            <ResetSuccess open={showModal} onClose={handleClose} />
        </div>
    );
};
export default PasswordReset;
