import { ProposeEmployeeManager } from 'model/employee-borrower/propose/manager.model';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { UpdateEmployeeBorrowerProposeManagerActionTypes } from './types';

export const updateEmployeeBorrowerProposeManagerRequest = (request: ProposeEmployeeManager) => action(UpdateEmployeeBorrowerProposeManagerActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_REQUEST, request);
export const updateEmployeeBorrowerProposeManagerSuccess = (data: ProposeEmployeeSimple) => action(UpdateEmployeeBorrowerProposeManagerActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_SUCCESS, data);
export const updateEmployeeBorrowerProposeManagerError = (error: GiroWebappError) => action(UpdateEmployeeBorrowerProposeManagerActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_ERROR, error);
export const updateEmployeeBorrowerProposeManagerResetState = () => action(UpdateEmployeeBorrowerProposeManagerActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_RESET_STATE);
