import { Grid } from '@material-ui/core';
import ButtonBack from 'components/buttons/button-back/button-back';
import Card from 'components/card/card';
import Currency from 'components/format/currency/Currency';
import FormattedDate from 'components/format/date/formatted-date';
import Percent from 'components/format/percent/percent';
import Loading from 'components/loading/loading';
import ReadOnlyTextField from 'components/readonly-text-field/readonly-text-field';
import { EmployeeBorrowerSimulateCalculation, EmployeeBorrowerSimulateCalculationRequest } from 'model/employee-borrower/simulate.model';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { employeeBorrowerUpdateLoanCalculationResetState } from 'reducer/employeeBorrower/loan-calculation/actions';
import { employeeBorrowerProposeRecentRequest } from 'reducer/employeeBorrower/propose/propose-recent/actions';
import {
    employeeBorrowerSimulateCalculationRequest,
    employeeBorrowerSimulateCalculationResetState,
} from 'reducer/employeeBorrower/simulate-calculation/actions';
import {
    useEmployeeBorrowerProposeRecentState,
    useEmployeeBorrowerProposeSummaryState,
    useEmployeeBorrowerSimulateCalculationState,
    useRootDispatch,
    useUpdateEmployeeBorrowerLoanCalculationState,
} from 'reducer/hooks';
import './simulate-card-detail.scss';

const I18N_PREFIX = 'employee-borrower.simulate.detail';

interface SimulateCardDetailProps {
    simulateCalculationRequest: EmployeeBorrowerSimulateCalculationRequest;
    setIsReloadPageRequestSummary: (value: boolean) => void;
}

export const SimulateCardDetail = (props: SimulateCardDetailProps) => {
    const { simulateCalculationRequest, setIsReloadPageRequestSummary } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { simulateCalculation, status: simulateCalculationStatus } = useEmployeeBorrowerSimulateCalculationState();
    const { status: updateSimulateCalculationStatus } = useUpdateEmployeeBorrowerLoanCalculationState();

    const { status: proposeSummaryStatus, proposeSummary } = useEmployeeBorrowerProposeSummaryState();
    const { status: proposeRecentStatus } = useEmployeeBorrowerProposeRecentState();

    const [simulateCalculationInfo, setSimulateCalculationInfo] = useState<EmployeeBorrowerSimulateCalculation>(
        simulateCalculation ?? { ...proposeSummary }
    );

    useEffect(() => {
        if (simulateCalculationStatus === HttpRequestStatus.SUCCESS) {
            setIsReloadPageRequestSummary(false);
            dispatch(employeeBorrowerProposeRecentRequest());
            dispatch(employeeBorrowerSimulateCalculationResetState());
            dispatch(employeeBorrowerUpdateLoanCalculationResetState());
        }
    }, [dispatch, simulateCalculationStatus, setIsReloadPageRequestSummary]);

    useEffect(() => {
        if (updateSimulateCalculationStatus === HttpRequestStatus.SUCCESS) {
            setIsReloadPageRequestSummary(false);
            dispatch(employeeBorrowerProposeRecentRequest());
            dispatch(employeeBorrowerUpdateLoanCalculationResetState());
        }
    }, [dispatch, updateSimulateCalculationStatus, setIsReloadPageRequestSummary]);

    useEffect(() => {
        if (!simulateCalculation) return;
        if (simulateCalculationStatus !== HttpRequestStatus.SUCCESS) return;

        setSimulateCalculationInfo(simulateCalculation);
    }, [simulateCalculationStatus, simulateCalculation, setSimulateCalculationInfo]);

    useEffect(() => {
        if (simulateCalculation) return;
        if (proposeSummaryStatus !== HttpRequestStatus.SUCCESS) return;

        setSimulateCalculationInfo({ ...proposeSummary });
    }, [proposeSummaryStatus, proposeSummary, setSimulateCalculationInfo, simulateCalculation]);

    const hasError = simulateCalculationStatus === HttpRequestStatus.ERROR;

    const handleTryAgain = () => {
        dispatch(employeeBorrowerSimulateCalculationRequest(simulateCalculationRequest));
    };

    if (hasError) {
        return (
            <div className="error__employee-borrower">
                <div className="error-message">{t('global.employee-borrower.simulate.error-message')}</div>
                <ButtonBack label={t('global.try-again')} onClick={handleTryAgain} />
            </div>
        );
    }

    const isLoading =
        (simulateCalculationStatus !== HttpRequestStatus.SUCCESS && simulateCalculationStatus !== HttpRequestStatus.NOOP) ||
        proposeSummaryStatus !== HttpRequestStatus.SUCCESS ||
        proposeRecentStatus !== HttpRequestStatus.SUCCESS ||
        updateSimulateCalculationStatus === HttpRequestStatus.ONGOING;

    return (
        <>
            {isLoading ? (
                <div className="container__employee-borrower--loading">
                    <Loading />
                </div>
            ) : (
                <>
                    <div className="header__simulate-card-detail">
                        <span className="header__simulate-card-detail--title"> {t('employee-borrower.title')} </span>
                        <span className="header__simulate-card-detail--subtitle"> {t(`${I18N_PREFIX}.subtitle`)} </span>
                        <span className="header__simulate-card-detail--description"> {t(`${I18N_PREFIX}.description`)} </span>
                    </div>
                    <div className="card__simulate-card-detail">
                        <Card>
                            <div className="card__simulate-card-detail--read-only">
                                <Grid container>
                                    <Grid item xs={6}>
                                        <ReadOnlyTextField
                                            label={`${I18N_PREFIX}.input.availableCredit.label`}
                                            value={<Currency value={simulateCalculationInfo?.maxLoanValue ?? 0} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReadOnlyTextField
                                            label={`${I18N_PREFIX}.input.receiptDeadline.label`}
                                            value={
                                                <Fragment>
                                                    {`${simulateCalculationInfo?.receiptDeadline ?? 0} ${t(
                                                        `${I18N_PREFIX}.input.receiptDeadline.prefix`
                                                    )} `}
                                                </Fragment>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReadOnlyTextField
                                            label={`${I18N_PREFIX}.input.loanValue.label`}
                                            value={
                                                <Fragment>
                                                    <strong>
                                                        <Currency value={simulateCalculationInfo?.requestedValue ?? 0} />
                                                    </strong>
                                                </Fragment>
                                            }
                                            alignCenter
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="card__simulate-card-detail--read-only">
                                <Grid container>
                                    <Grid item xs={6}>
                                        <ReadOnlyTextField
                                            label={`${I18N_PREFIX}.input.monthlyTaxPercentage.label`}
                                            value={
                                                <Fragment>
                                                    <Percent
                                                        value={Number(simulateCalculationInfo?.monthlyTaxPercentage ?? 0) / 100}
                                                        decimalNumber={false}
                                                    />{' '}
                                                    a.m.
                                                </Fragment>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReadOnlyTextField
                                            label={`${I18N_PREFIX}.input.yearlyTotalEffectiveCostPercentage.label`}
                                            value={
                                                <Fragment>
                                                    <Percent
                                                        value={
                                                            Number(simulateCalculationInfo?.yearlyTotalEffectiveCostPercentage ?? 0) / 100
                                                        }
                                                        decimalNumber={false}
                                                    />{' '}
                                                    a.a.
                                                </Fragment>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReadOnlyTextField
                                            label={`${I18N_PREFIX}.input.installmentNumber.label`}
                                            value={
                                                <Fragment>
                                                    <strong> {`${simulateCalculationInfo?.installmentNumber ?? 0}x`}</strong>{' '}
                                                    <Currency value={simulateCalculationInfo?.installmentValue} />
                                                </Fragment>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReadOnlyTextField
                                            label={`${I18N_PREFIX}.input.dateToFirstPay.label`}
                                            value={
                                                simulateCalculationInfo?.dateToFirstPay ? (
                                                    <FormattedDate date={simulateCalculationInfo?.dateToFirstPay} />
                                                ) : (
                                                    '-'
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </div>
                </>
            )}
        </>
    );
};

export default SimulateCardDetail;
