import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { EmployeeTimeEntryActionTypes, EmployeeTimeEntryState } from './types';

export const initialState: EmployeeTimeEntryState = {
    status: HttpRequestStatus.NOOP,
    employeeTimeEntries: undefined,
    error: undefined,
};

const reducer: Reducer<EmployeeTimeEntryState> = (state = initialState, action): EmployeeTimeEntryState => {
    switch (action.type) {
        case EmployeeTimeEntryActionTypes.EMPLOYEE_TIME_ENTRY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeTimeEntryActionTypes.EMPLOYEE_TIME_ENTRY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, employeeTimeEntries: action.payload, error: undefined };
        }
        case EmployeeTimeEntryActionTypes.EMPLOYEE_TIME_ENTRY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeTimeEntryActionTypes.EMPLOYEE_TIME_ENTRY_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeTimeEntryReducer };
