import { maxValidateLoanValue, maxValidateSalaryValue, minValidateLoanValue, validateMaxInstallmentValue } from 'features/employee-borrower/components/simulate/validation/simulation-validation';
import { defaultProposeEmployeeAccountData, ProposeEmployeeAccountData } from 'model/employee-borrower/propose/account.model';
import { defaultProposeEmployeeAddressData, ProposeEmployeeAddressData } from 'model/employee-borrower/propose/address.model';
import { defaultProposeEmployeeDocumentData, defaultProposeEmployeeDocumentFileUpload, ProposeEmployeeDocumentData, ProposeEmployeeDocumentFileUpload } from 'model/employee-borrower/propose/document.model';
import { defaultProposeEmployeeGeneralData, ProposeEmployeeGeneralData } from 'model/employee-borrower/propose/general-data.model';
import { defaultProposeEmployeeManager, ProposeEmployeeManager } from 'model/employee-borrower/propose/manager.model';
import { defaultProposeEmployeeSupplementaryData, ProposeEmployeeSupplementaryData } from 'model/employee-borrower/propose/supplementary-data.model';
import { defaultEmployeeBorrowerSimulateCalculationRequest, EmployeeBorrowerSimulateCalculationRequest } from 'model/employee-borrower/simulate.model';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { useEffect, useState } from 'react';
import { useEmployeeBorrowerBanksAccountsState, useEmployeeBorrowerProposeRecentState, useEmployeeBorrowerProposeSummaryState, useEmployeeBorrowerSimulateState, useEmployeeCompanyDefaultState } from 'reducer/hooks';


export const useProposeSimulate = () => {
    const [simulateCalculationRequest, setSimulateCalculationRequest] = useState<EmployeeBorrowerSimulateCalculationRequest>(
        defaultEmployeeBorrowerSimulateCalculationRequest
    );

    const { status: summaryStatus, proposeSummary } = useEmployeeBorrowerProposeSummaryState();
    const { simulate } = useEmployeeBorrowerSimulateState();
    const { company } = useEmployeeCompanyDefaultState();

    useEffect(() => {
        if (summaryStatus !== HttpRequestStatus.SUCCESS) return;
        if (!proposeSummary?.requestedLoanValue) return;

        setSimulateCalculationRequest({ installmentNumber: proposeSummary.installmentNumber, loanValue: proposeSummary.requestedLoanValue })
    }, [summaryStatus, proposeSummary])

    const handleLoanValidation = (): boolean => {
        if (!simulateCalculationRequest.loanValue || !simulate?.minLoanValue || !simulate?.maxLoanValue) return false;

        const isValidMinLoanValue = minValidateLoanValue({
            loanValue: simulateCalculationRequest.loanValue,
            minLoanValue: simulate?.minLoanValue,
        }).isValid

        const isValidMaxLoanValue = maxValidateLoanValue({
            loanValue: simulateCalculationRequest.loanValue,
            maxLoanValue: simulate?.maxLoanValue,
        }).isValid

        const isValidMaxSalaryValue = maxValidateSalaryValue({
            loanValue: simulateCalculationRequest.loanValue,
            installmentNumber: simulateCalculationRequest.installmentNumber,
            salary: company?.salary
        }).isValid

        const isValidMaxInstallmentValue = validateMaxInstallmentValue(
            simulateCalculationRequest.loanValue,
            simulateCalculationRequest.installmentNumber,
            simulate?.maxInstallmentValue
        ).isValid;


        if (!isValidMinLoanValue) {
            setSimulateCalculationRequest({ ...simulateCalculationRequest, loanValue: simulate.minLoanValue });
            return false
        }
        if (!isValidMaxLoanValue) {
            setSimulateCalculationRequest({ ...simulateCalculationRequest, loanValue: simulate?.maxLoanValue });
            return false;
        }
        if (!isValidMaxSalaryValue) {
            return false;
        }
        if (!isValidMaxInstallmentValue) {
            return false;
        }


        return true
    };


    return { simulateCalculationRequest, setSimulateCalculationRequest, handleLoanValidation };
};

export const useProposeGeneralData = () => {
    const [proposeGeneralDataRequest, setProposeGeneralDataRequest] = useState<ProposeEmployeeGeneralData>(defaultProposeEmployeeGeneralData);

    const { status: summaryStatus, proposeSummary } = useEmployeeBorrowerProposeSummaryState();

    useEffect(() => {
        if (summaryStatus !== HttpRequestStatus.SUCCESS) return;
        if (!proposeSummary?.generalData) return;

        setProposeGeneralDataRequest({ generalData: { ...proposeSummary.generalData } })
    }, [summaryStatus, proposeSummary])

    const handleChangeProposeGeneralData = (value: Partial<ProposeEmployeeGeneralData>) => {
        setProposeGeneralDataRequest({ ...proposeGeneralDataRequest, ...value });
    };

    return { proposeGeneralDataRequest, handleChangeProposeGeneralData };
};

export const useProposeSupplementaryData = () => {
    const [proposeSupplementaryDataRequest, setProposeSupplementaryDataRequest] = useState<ProposeEmployeeSupplementaryData>(
        defaultProposeEmployeeSupplementaryData
    );

    const { status: summaryStatus, proposeSummary } = useEmployeeBorrowerProposeSummaryState();

    useEffect(() => {
        if (summaryStatus !== HttpRequestStatus.SUCCESS) return;
        if (!proposeSummary?.supplementaryData) return;

        setProposeSupplementaryDataRequest({ supplementaryData: { ...proposeSummary.supplementaryData } })
    }, [summaryStatus, proposeSummary])

    const handleChangeProposeSupplementaryData = (value: Partial<ProposeEmployeeSupplementaryData>) => {
        setProposeSupplementaryDataRequest({ ...proposeSupplementaryDataRequest, ...value });
    };

    return { proposeSupplementaryDataRequest, handleChangeProposeSupplementaryData };
};


export const useProposeAddressData = () => {
    const [proposeAddressDataRequest, setProposeAddressDataRequest] = useState<ProposeEmployeeAddressData>(
        defaultProposeEmployeeAddressData
    );

    const { status: summaryStatus, proposeSummary } = useEmployeeBorrowerProposeSummaryState();

    useEffect(() => {
        if (summaryStatus !== HttpRequestStatus.SUCCESS) return;
        if (!proposeSummary?.address) return;

        setProposeAddressDataRequest({ addressData: { ...proposeSummary.address } })
    }, [summaryStatus, proposeSummary])


    const handleChangeProposeAddressData = (value: Partial<ProposeEmployeeAddressData>) => {
        setProposeAddressDataRequest({ ...proposeAddressDataRequest, ...value });
    };

    return { proposeAddressDataRequest, handleChangeProposeAddressData };
};

export const useProposeDocumentData = (proposeId: number | undefined) => {
    const [proposeDocumentDataRequest, setProposeDocumentDataRequest] = useState<ProposeEmployeeDocumentData>(
        defaultProposeEmployeeDocumentData
    );

    const [proposeDocumentFileUploadRequest, setProposeDocumentFileUploadRequest] = useState<ProposeEmployeeDocumentFileUpload[]>(
        defaultProposeEmployeeDocumentFileUpload
    );

    const { proposeRecent } = useEmployeeBorrowerProposeRecentState();
    const { status: summaryStatus, proposeSummary } = useEmployeeBorrowerProposeSummaryState();

    useEffect(() => {
        if (summaryStatus !== HttpRequestStatus.SUCCESS) return;
        if (!proposeSummary?.documentData) return;
        if (!proposeSummary?.documentData.documents) return;
        if (!proposeId && !proposeRecent?.id) return;

        setProposeDocumentDataRequest({ documentData: { ...proposeSummary.documentData } });
        const _mappedFiles: ProposeEmployeeDocumentFileUpload[] = proposeSummary?.documentData.documents.map((value) => ({ proposeId: proposeId ? proposeId : proposeRecent?.id, documentFile: { ...value, data: [] } }))

        setProposeDocumentFileUploadRequest(_mappedFiles)

    }, [summaryStatus, proposeSummary, proposeRecent, proposeId])

    const handleChangeDocumentData = (value: Partial<ProposeEmployeeDocumentData>) => {
        setProposeDocumentDataRequest({ ...proposeDocumentDataRequest, ...value });
    };

    const handleChangeDocumentFileUpload = (value: Partial<ProposeEmployeeDocumentFileUpload>) => {
        setProposeDocumentFileUploadRequest([...proposeDocumentFileUploadRequest, value]);
    };

    return {
        proposeDocumentDataRequest,
        proposeDocumentFileUploadRequest,
        handleChangeDocumentData,
        handleChangeDocumentFileUpload,
        setProposeDocumentFileUploadRequest,
    };
}

export const useProposeManager = () => {
    const [proposeManagerRequest, setProposeManagerRequest] = useState<ProposeEmployeeManager>(defaultProposeEmployeeManager);

    const { status: summaryStatus, proposeSummary } = useEmployeeBorrowerProposeSummaryState();

    useEffect(() => {
        if (summaryStatus !== HttpRequestStatus.SUCCESS) return;
        if (!proposeSummary?.manager) return;

        setProposeManagerRequest({ analystStatus: proposeSummary.analystStatus, manager: { ...proposeSummary?.manager } })
    }, [summaryStatus, proposeSummary])

    const handleChangeProposeManager = (value: Partial<ProposeEmployeeManager>) => {
        setProposeManagerRequest({ ...proposeManagerRequest, ...value });
    };

    return { proposeManagerRequest, handleChangeProposeManager };
};

export const useProposeAccount = () => {
    const [proposeAccountDataRequest, setProposeAccountDataRequest] = useState<ProposeEmployeeAccountData>(
        defaultProposeEmployeeAccountData
    );

    const [isNewAccount, setIsNewAccount] = useState<boolean>(false);

    const { banksAccounts } = useEmployeeBorrowerBanksAccountsState();

    const { status: summaryStatus, proposeSummary } = useEmployeeBorrowerProposeSummaryState();

    const _selectedAccount = banksAccounts?.content?.find(({ accountNumber }) => accountNumber === proposeSummary?.loanAccountData.accountNumber)

    useEffect(() => {
        if (summaryStatus !== HttpRequestStatus.SUCCESS) return;
        if (!proposeSummary?.loanAccountData) return;
        if (isNewAccount) return;

        setProposeAccountDataRequest({ accountData: { ..._selectedAccount, jointAccount: proposeSummary.loanAccountData.jointAccount ?? false } })
    }, [summaryStatus, proposeSummary, _selectedAccount, isNewAccount])

    const handleChangeProposeAccount = (value: Partial<ProposeEmployeeAccountData>) => {
        setProposeAccountDataRequest({ ...proposeAccountDataRequest, ...value });
    };

    return { proposeAccountDataRequest, handleChangeProposeAccount, isNewAccount, setIsNewAccount };
};
