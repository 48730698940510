import { ProgramToList } from '../../../model/program';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum ListProgramsActionTypes {
    LIST_PROGRAMS_REQUEST = '@@listPrograms/LIST_PROGRAMS_REQUEST',
    LIST_PROGRAMS_SUCCESS = '@@listPrograms/LIST_PROGRAMS_SUCCESS',
    LIST_PROGRAMS_ERROR = '@@listPrograms/LIST_PROGRAMS_ERROR',
}

export interface ListProgramsState extends BaseStoreState {
    readonly programs?: ProgramToList[];
}
