import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import CurrencyField from '../../../../../components/field-basic/currency-field';
import Currency from '../../../../../components/format/currency/Currency';
import { IEmployeeProgramTaxRange } from '../../../../../model/employee-program-tax-range.model';
import { EmployeeAnticipationStatus } from '../../../../../model/enums/employee-anticipation-status.model';
import { useEmployeeMonthlyCreditState, useRootDispatch } from '../../../../../reducer/hooks';
import CurrencyUtils from '../../../../../shared/util/currency-utils';
import { ValidationResult } from '../../../../../shared/util/validation-utils';
import './monthly-credit-header.scss';

export interface AnticipationCreditHeaderProps {
    onRequest: (amount: number, tax: number) => void;
    openTaxes: () => void;
}

export const findTaxByAmountWithTax = (taxes?: IEmployeeProgramTaxRange[], value: number = 0): number => {
    const taxByRange = taxes?.filter(it => it?.active)?.find(it => CurrencyUtils.isInRange(it, value - (it?.taxAmount ?? 0)));
    if (taxByRange != null) {
        return taxByRange?.taxAmount ?? 0;
    }
    return (
        taxes?.filter(it => it?.active)?.reduce((one, other) => ((one?.upperAmount ?? 0) > (other?.upperAmount ?? 0) ? one : other))
            ?.taxAmount ?? 0
    );
};

export const AnticipationCreditHeader = (props: AnticipationCreditHeaderProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const [anticipationAmount, setAnticipationAmount] = useState<number>(-1);
    const [taxAmount, setTaxAmount] = useState<number>(0);

    const { employeeMonthlyCredit } = useEmployeeMonthlyCreditState();
    const program = employeeMonthlyCredit?.employee?.program;
    const taxes = employeeMonthlyCredit?.employee?.program?.taxes;

    const monthlyDeadlineDay = program?.monthlyDeadlineDay ?? 30;
    const today = moment().date();

    const monthlyAnticipationQuantityLimit = program?.monthlyAnticipationQuantityLimit ?? 0;
    const minAmount = program?.minAnticipationAmount ?? 0;

    const anticipationLimitAmount =
        (employeeMonthlyCredit?.availableAmount ?? 0) - findTaxByAmountWithTax(taxes, employeeMonthlyCredit?.availableAmount);

    const availableAmount =
        (employeeMonthlyCredit?.availableAmount ?? 0) - findTaxByAmountWithTax(taxes, employeeMonthlyCredit?.availableAmount);

    const currentRequestedAnticipationQuantity =
        employeeMonthlyCredit?.anticipations?.filter(it => it.status !== EmployeeAnticipationStatus.REJECTED).length ?? 0;

    React.useEffect(() => {
        if (availableAmount >= 0) {
            setAnticipationAmount(availableAmount);
            const tax = taxes?.filter(it => it.active)?.find(it => CurrencyUtils.isInRange(it, availableAmount));
            setTaxAmount(tax?.taxAmount ?? 0);
        }
    }, [taxes, availableAmount, dispatch]);

    if (monthlyDeadlineDay <= today) {
        return (
            <AnticipationUnavailable>
                <div className="scroll--text-title">
                    <div>{t('anticipation-simulation.simulation-details.unavailable.deadline.title').toUpperCase()} </div>
                </div>
                <div className="scroll--text-title">
                    <div>{t('anticipation-simulation.simulation-details.unavailable.deadline.message').toUpperCase()} </div>
                </div>
            </AnticipationUnavailable>
        );
    }

    if (currentRequestedAnticipationQuantity > monthlyAnticipationQuantityLimit) {
        return (
            <AnticipationUnavailable>
                <div className="scroll--text-title">
                    <div>{t('anticipation-simulation.simulation-details.unavailable.max-request.title').toUpperCase()} </div>
                </div>
                <div className="scroll--text-price">{currentRequestedAnticipationQuantity}</div>
                <div className="scroll--text-title">
                    <div>{t('anticipation-simulation.simulation-details.unavailable.max-request.message').toUpperCase()} </div>
                </div>
            </AnticipationUnavailable>
        );
    }

    if (availableAmount < minAmount) {
        return (
            <AnticipationUnavailable>
                <div className="scroll--text-title">
                    <div>{t('anticipation-simulation.simulation-details.unavailable.min-amount.title').toUpperCase()} </div>
                </div>
                <div className="scroll--text-price">
                    <Currency value={availableAmount ?? 0} />
                </div>
                <div className="scroll--text-title">
                    <div>{t('anticipation-simulation.simulation-details.unavailable.min-amount.message').toUpperCase()} </div>
                </div>
                <br />
                <div className="scroll--text-value" style={{ flex: '0 1' }}>
                    <div className="scroll--value-original">
                        <div>{t('anticipation-simulation.simulation-details.min-amount')} </div>
                        <span>
                            <Currency value={employeeMonthlyCredit?.employee?.program?.minAnticipationAmount} />
                        </span>
                        <div className="scroll--value-original taxes">
                            <div className="scroll--value-itens">
                                <div className="scroll--value-img" onClick={props.openTaxes} />
                            </div>
                        </div>
                    </div>
                </div>
            </AnticipationUnavailable>
        );
    }

    const handleAmount = (value: number) => {
        const amount = value / 100;
        setAnticipationAmount(amount);
        const tax = taxes?.filter(it => it.active)?.find(it => CurrencyUtils.isInRange(it, amount));
        setTaxAmount(tax?.taxAmount ?? 0);
    };

    const handleValidate = (value: number): ValidationResult => {
        const _amount = value / 100;
        if (_amount <= anticipationLimitAmount && _amount >= minAmount) {
            return {
                isValid: true,
            };
        }
        return { isValid: false, errorMessage: 'anticipation-simulation.invalid-amount' };
    };

    return (
        <ContainterWrapper>
            <div className="scroll--text">
                <div className="scroll--text-title">
                    <div>{t('anticipation-simulation.simulation-details.title-start').toUpperCase()} </div>
                    <div style={{ paddingLeft: '0px', paddingRight: '5px' }} />
                    <div>{t('anticipation-simulation.simulation-details.title-end').toUpperCase()} </div>
                </div>
                <div className="scroll--text-price">
                    {anticipationAmount >= 0 && (
                        <CurrencyField showValidation validate={handleValidate} value={availableAmount * 100} onChange={handleAmount} />
                    )}
                </div>
                <button className="scroll--text-button" onClick={() => props.onRequest(anticipationAmount, taxAmount)}>
                    {t('anticipation-simulation.simulation-details.request').toUpperCase()}
                </button>
                <div className="scroll--text-value">
                    <div className="scroll--value-original">
                        <div>{t('anticipation-simulation.simulation-details.original-value')} </div>
                        <span>
                            <Currency value={availableAmount ?? 0} />
                        </span>
                    </div>
                    <div className="scroll--value-separator" />
                    <div className="scroll--value-original taxes">
                        <div className="scroll--value-itens">
                            <div>{t('anticipation-simulation.simulation-details.discount-value')}</div>
                            <div className="scroll--value-img" onClick={props.openTaxes} />
                        </div>
                        <span>
                            <Currency value={taxAmount} />
                        </span>
                    </div>
                    <div className="scroll--value-separator" />
                    <div className="scroll--value-original">
                        <div className="scroll--value-itens">
                            <div>{t('anticipation-simulation.simulation-details.min-amount-info')}</div>
                        </div>
                        <span>
                            <Currency value={minAmount} />
                        </span>
                    </div>
                </div>
            </div>
        </ContainterWrapper>
    );
};

const AnticipationUnavailable: React.FunctionComponent = ({ children }) => {
    const { t } = useTranslation();
    const { employeeMonthlyCredit } = useEmployeeMonthlyCreditState();
    const program = employeeMonthlyCredit?.employee?.program;
    const monthlyDeadlineDay = program?.monthlyDeadlineDay ?? 30;
    const monthlyAnticipationQuantityLimit = program?.monthlyAnticipationQuantityLimit ?? 0;

    return (
        <ContainterWrapper>
            <div className="scroll--text" style={{ minHeight: '90vh' }}>
                {children}
                <br />
                <div className="scroll--text-terms">
                    {t('anticipation-simulation.simulation-details.limit-day', { limit: monthlyDeadlineDay })}
                </div>
                <div className="scroll--text-terms">
                    {t('anticipation-simulation.simulation-details.limit-anticipations', { limit: monthlyAnticipationQuantityLimit })}
                </div>
            </div>
        </ContainterWrapper>
    );
};

const ContainterWrapper: React.FunctionComponent = ({ children }) => {
    return (
        <div className="container__scroll">
            <Container>
                <Row>
                    <Col>{children}</Col>
                </Row>
            </Container>
        </div>
    );
};

export default AnticipationCreditHeader;
