import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { employeeBorrowerProposeDocumentDownloadError, employeeBorrowerProposeDocumentDownloadRequest, employeeBorrowerProposeDocumentDownloadSuccess } from 'reducer/employeeBorrower/propose/propose-document-data/download-propose-document/action';
import { EmployeeBorrowerProposeDocumentDownloadTypes } from 'reducer/employeeBorrower/propose/propose-document-data/download-propose-document/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleEmployeeBorrowerProposeDocumentDownload(action: ReturnType<typeof employeeBorrowerProposeDocumentDownloadRequest>) {
    try {
        const result: AxiosResponse<Uint8Array> = yield call(proposeApi.getDocumentDownload, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerProposeDocumentDownloadError(mapped));
            return;
        }
        yield put(employeeBorrowerProposeDocumentDownloadSuccess(result.data));

        const _fileName = result.headers['content-disposition']?.split('filename=')[1];
        const _type = result.headers['content-type'];
        const _blob = new Blob([result.data], { type: _type });
        const _url = window.URL.createObjectURL(_blob);
        const _link: HTMLAnchorElement = document.createElement('a');
        _link.href = _url;
        _link.setAttribute('download', _fileName);
        document.body.appendChild(_link);
        _link.click();
        _link.remove();


    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerProposeDocumentDownloadError(mapped));
    }
}

function* watchEmployeeBorrowerProposeDocumentDownload() {
    yield takeEvery(EmployeeBorrowerProposeDocumentDownloadTypes.EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_REQUEST, handleEmployeeBorrowerProposeDocumentDownload);
}

function* employeeBorrowerProposeDocumentDownloadSaga() {
    yield all([fork(watchEmployeeBorrowerProposeDocumentDownload)]);
}

export default employeeBorrowerProposeDocumentDownloadSaga;
