// Update address data
import { updateEmployeeBorrowerProposeAddressDataReducer } from 'reducer/employeeBorrower/propose/propose-address-data/update-propose-address-data/reducer';
import updateEmployeeBorrowerProposeAddressDataSagas from 'reducer/employeeBorrower/propose/propose-address-data/update-propose-address-data/sagas';
import { UpdateEmployeeBorrowerProposeAddressDataState } from 'reducer/employeeBorrower/propose/propose-address-data/update-propose-address-data/types';

//
import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

export interface EmployeeBorrowerProposeAddressDataState {
    readonly updateProposeAddressData: UpdateEmployeeBorrowerProposeAddressDataState;
}

export const EmployeeBorrowerProposeAddressDataReducer: ReducersMapObject<EmployeeBorrowerProposeAddressDataState, AnyAction> = {
    updateProposeAddressData: updateEmployeeBorrowerProposeAddressDataReducer
};

export function* employeeBorrowerProposeAddressDataSagas() {
    yield all([fork(updateEmployeeBorrowerProposeAddressDataSagas)]);
}
