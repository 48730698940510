import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import './context-ribbon.scss';

export const ContextRibbon: React.FunctionComponent = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const handleBack = () => {
        history.goBack();
    };

    return (
        <div className="context-ribbon">
            <Container>
                <div className="context-ribbon__back" onClick={handleBack}>
                    <div className="context-ribbon__back-icon" />
                    <span>{t('global.back')} </span>
                </div>
            </Container>
        </div>
    );
};

export default ContextRibbon;
