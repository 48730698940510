import Button from '@material-ui/core/Button';
import { InputAdornmentTypeMap } from '@material-ui/core/InputAdornment';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './standard-button.scss';

export interface StandardButtonProps {
    label: string;
    onClick?: () => void;
    disabled?: boolean;
    adornment?: OverridableComponent<InputAdornmentTypeMap>;
    size?: 'small' | 'medium' | 'large';
    endIcon?: React.ReactNode;
}

export const StandardButton = (props: StandardButtonProps): React.JSX.Element => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (!props.disabled && props.onClick) {
            props.onClick();
        }
    };

    return (
        <div className={clsx('button__style--standard', props.size)}>
            <Button variant="contained" onClick={handleClick} disabled={props.disabled} endIcon={props.endIcon}>
                <span> {t(props.label)} </span>
                <span> {props.adornment} </span>
            </Button>
        </div>
    );
};

export default StandardButton;
