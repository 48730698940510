import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './edit-icon-button.scss';

export interface EditIconButtonProps {
    onClick?: () => void;
    disabled?: boolean;
}

const EditIconButton = (props: EditIconButtonProps): React.JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="button__style--edit-icon">
            <Button variant="contained" onClick={props.onClick} disabled={props.disabled}>
                <Tooltip title={t('tooltips.edit') ?? ''} aria-label="visualize">
                    <Icon>
                        <div className="button__style--edit-icon--icon" />
                    </Icon>
                </Tooltip>
            </Button>
        </div>
    );
};

export default EditIconButton;
