import { useDispatch, useSelector } from 'react-redux';
import { FirstAccessState } from 'reducer/account/first-access/types';
import { UpdatePersonState } from 'reducer/account/update-person/types';
import { SearchBanksState } from 'reducer/bank/search-banks/types';
import { SearchCitiesState } from 'reducer/city/search-cities/types';
import { EmployeeBorrowerAnalystSearchManagersState } from 'reducer/employeeBorrower/analyst/search-managers/types';
import { EmployeeBorrowerBanksAccountsState } from 'reducer/employeeBorrower/bank-account/types';
import { EmployeeBorrowerAvailableServicesState } from 'reducer/employeeBorrower/employee-service/available-services/types';
import { EmployeeBorrowerLoanCalculationState } from 'reducer/employeeBorrower/loan-calculation/types';
import { UpdateEmployeeBorrowerProposeAccountState } from 'reducer/employeeBorrower/propose/propose-account-data/update-propose-account-data/types';
import { UpdateEmployeeBorrowerProposeAddressDataState } from 'reducer/employeeBorrower/propose/propose-address-data/update-propose-address-data/types';
import { EmployeeBorrowerProposeConfirmState } from 'reducer/employeeBorrower/propose/propose-confirm/types';
import { EmployeeBorrowerProposeDocumentDeleteState } from 'reducer/employeeBorrower/propose/propose-document-data/delete-propose-document-data/types';
import { UpdateEmployeeBorrowerProposeDocumentDataState } from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-data/types';
import { UpdateEmployeeBorrowerProposeDocumentFileUploadState } from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-file-upload/types';
import { UpdateEmployeeBorrowerProposeGeneralDataState } from 'reducer/employeeBorrower/propose/propose-general-data/update-propose-general-data/types';
import { UpdateEmployeeBorrowerProposeManagerState } from 'reducer/employeeBorrower/propose/propose-manager/update-propose-manager/types';
import { EmployeeOperatorFeedbackState } from 'reducer/employeeBorrower/propose/propose-operator-feedback/types';
import { EmployeeBorrowerProposeRecentState } from 'reducer/employeeBorrower/propose/propose-recent/types';
import { EmployeeBorrowerProposeSendRequestedChangesState } from 'reducer/employeeBorrower/propose/propose-send-requested-changes/types';
import { EmployeeBorrowerProposeSummaryState } from 'reducer/employeeBorrower/propose/propose-summary/types';
import { UpdateEmployeeBorrowerProposeSupplementaryDataState } from 'reducer/employeeBorrower/propose/propose-supplementary-data/update-propose-supplementary-data/types';
import { EmployeeBorrowerSimulateCalculationState } from 'reducer/employeeBorrower/simulate-calculation/types';
import { EmployeeBorrowerSimulateState } from 'reducer/employeeBorrower/simulate/types';
import { CancelProposeEmployeeState } from 'reducer/employeeCancelPropose/types';
import { HistoryEmployeeProposesState } from 'reducer/history-proposes/types';
import { ManagerInfoState } from 'reducer/manager/managerInfo/types';
import { ManagerModeState } from 'reducer/manager/managerMode/types';
import { IRootState } from '.';
import { RootDispatcher } from '..';
import { AcceptTermsState } from './account/accept-terms/types';
import { ChangePasswordState } from './account/change-password/types';
import { ForgotPasswordState } from './account/forgot-password/types';
import { UserPasswordResetState } from './account/reset-password/types';
import { TermsOfUseState } from './account/terms-of-use/types';
import { UserAccountState } from './account/user-account/types';
import { ApplicationErrorState } from './application/error/types';
import { AuthenticationState } from './authentication/types';
import { DefaultCompanyEmployeeState } from './company/default/types';
import { MyCompaniesState } from './company/myCompanies/types';
import { EmployeeAnticipationState } from './employeeAnticipation/types';
import { EmployeeMonthlyCreditState } from './employeeMonthlyCredit/types';
import { EmployeeTimeEntryState } from './employeeTimeEntry/types';
import { InviteToAcceptState } from './invite/toAccept/types';
import { PersonProfileState } from './person/profile/types';
import { ProviderByInviteState } from './person/provider-invite/types';
import { UserByResetKeyState } from './user/reset-key/types';
import { ValidateEmailState } from './user/validate-email/types';

export const useRootDispatch = () => useDispatch<RootDispatcher>();

export const useAuthenticationState = () => useSelector<IRootState, AuthenticationState>((state: IRootState) => state.authentication);
export const usePasswordResetState = () => useSelector<IRootState, UserPasswordResetState>((state: IRootState) => state.passwordReset);
export const useUserAccountState = () => useSelector<IRootState, UserAccountState>((state: IRootState) => state.userAccout);
export const useApplicationErrorState = () => useSelector<IRootState, ApplicationErrorState>((state: IRootState) => state.applicationError);
export const useProviderByInviteState = () => useSelector<IRootState, ProviderByInviteState>((state: IRootState) => state.providerByInvite);
export const useTermsOfUseState = () => useSelector<IRootState, TermsOfUseState>((state: IRootState) => state.termsOfUse);
export const useMyCompaniesState = () => useSelector<IRootState, MyCompaniesState>((state: IRootState) => state.myCompanies);
export const useEmployeeCompanyDefaultState = () =>
    useSelector<IRootState, DefaultCompanyEmployeeState>((state: IRootState) => state.companyDefault);
export const useEmployeeMonthlyCreditState = () =>
    useSelector<IRootState, EmployeeMonthlyCreditState>((state: IRootState) => state.employeeMonthlyCredit);
export const useEmployeeAnticipationState = () =>
    useSelector<IRootState, EmployeeAnticipationState>((state: IRootState) => state.employeeAnticipation);
export const useEmployeeTimeEntryState = () =>
    useSelector<IRootState, EmployeeTimeEntryState>((state: IRootState) => state.employeeTimeEntry);
export const useInviteToAcceptState = () => useSelector<IRootState, InviteToAcceptState>((state: IRootState) => state.inviteToAccept);
export const useChangePasswordState = () => useSelector<IRootState, ChangePasswordState>((state: IRootState) => state.changePassword);
export const useForgotPasswordState = () => useSelector<IRootState, ForgotPasswordState>((state: IRootState) => state.forgotPassword);
export const useFirstAccessState = () => useSelector<IRootState, FirstAccessState>((state: IRootState) => state.firstAccess);
export const useAcceptTermsState = () => useSelector<IRootState, AcceptTermsState>((state: IRootState) => state.acceptTerms);
export const useUserByResetKeyState = () => useSelector<IRootState, UserByResetKeyState>((state: IRootState) => state.userByResetKey);
export const useValidateEmailState = () => useSelector<IRootState, ValidateEmailState>((state: IRootState) => state.validateEmail);
export const usePersonProfileState = () => useSelector<IRootState, PersonProfileState>((state: IRootState) => state.personProfile);
export const usePersonUpdateProfileState = () => useSelector<IRootState, UpdatePersonState>((state: IRootState) => state.updatePerson);

// Employee Borrower Analyst
export const useEmployeeBorrowerAnalystSearchManagersState = () =>
    useSelector<IRootState, EmployeeBorrowerAnalystSearchManagersState>((state: IRootState) => state.employeeBorrowerSearchManagers);

// Employee Borrower Propose
export const useEmployeeBorrowerProposeRecentState = () =>
    useSelector<IRootState, EmployeeBorrowerProposeRecentState>((state: IRootState) => state.employeeBorrowerRecent);
export const useUpdateEmployeeBorrowerProposeGeneralDataState = () =>
    useSelector<IRootState, UpdateEmployeeBorrowerProposeGeneralDataState>((state: IRootState) => state.updateProposeGeneralData);
export const useUpdateEmployeeBorrowerProposeSupplementaryDataState = () =>
    useSelector<IRootState, UpdateEmployeeBorrowerProposeSupplementaryDataState>(
        (state: IRootState) => state.updateProposeSupplementaryData
    );
export const useUpdateEmployeeBorrowerProposeAddressDataState = () =>
    useSelector<IRootState, UpdateEmployeeBorrowerProposeAddressDataState>((state: IRootState) => state.updateProposeAddressData);
export const useUpdateEmployeeBorrowerProposeDocumentDataState = () =>
    useSelector<IRootState, UpdateEmployeeBorrowerProposeDocumentDataState>((state: IRootState) => state.updateProposeDocumentData);
export const useUpdateEmployeeBorrowerProposeDocumentFileUploadState = () =>
    useSelector<IRootState, UpdateEmployeeBorrowerProposeDocumentFileUploadState>(
        (state: IRootState) => state.updateProposeDocumentFileUpload
    );
export const useUpdateEmployeeBorrowerProposeManagerState = () =>
    useSelector<IRootState, UpdateEmployeeBorrowerProposeManagerState>((state: IRootState) => state.updateProposeManager);
export const useUpdateEmployeeBorrowerProposeAccountDataState = () =>
    useSelector<IRootState, UpdateEmployeeBorrowerProposeAccountState>((state: IRootState) => state.updateProposeAccountData);
export const useEmployeeBorrowerBanksAccountsState = () =>
    useSelector<IRootState, EmployeeBorrowerBanksAccountsState>((state: IRootState) => state.employeeBorrowerBanksAccounts);
export const useEmployeeBorrowerProposeSummaryState = () =>
    useSelector<IRootState, EmployeeBorrowerProposeSummaryState>((state: IRootState) => state.employeeBorrowerSummary);
export const useEmployeeBorrowerProposeConfirmState = () =>
    useSelector<IRootState, EmployeeBorrowerProposeConfirmState>((state: IRootState) => state.employeeBorrowerConfirmPropose);
export const useEmployeeBorrowerProposeDocumentDeleteState = () =>
    useSelector<IRootState, EmployeeBorrowerProposeDocumentDeleteState>((state: IRootState) => state.proposeDocumentDelete);
export const useEmployeeBorrowerProposeSendRequestedChangesState = () =>
    useSelector<IRootState, EmployeeBorrowerProposeSendRequestedChangesState>(
        (state: IRootState) => state.employeeBorrowerProposeSendRequestedChanges
    );

// Employee Borrower Simulate
export const useEmployeeBorrowerSimulateState = () =>
    useSelector<IRootState, EmployeeBorrowerSimulateState>((state: IRootState) => state.employeeBorrowerSimulate);
export const useEmployeeBorrowerSimulateCalculationState = () =>
    useSelector<IRootState, EmployeeBorrowerSimulateCalculationState>((state: IRootState) => state.employeeBorrowerSimulateCalculation);
export const useUpdateEmployeeBorrowerLoanCalculationState = () =>
    useSelector<IRootState, EmployeeBorrowerLoanCalculationState>((state: IRootState) => state.employeeBorrowerLoanCalculation);

// Employee Borrower Services
export const useEmployeeBorrowerAvailableServicesState = () =>
    useSelector<IRootState, EmployeeBorrowerAvailableServicesState>((state: IRootState) => state.employeeAvailableServices);

// City
export const useSearchCitiesState = () => useSelector<IRootState, SearchCitiesState>((state: IRootState) => state.searchCities);

// Bank
export const useSearchBanksState = () => useSelector<IRootState, SearchBanksState>((state: IRootState) => state.searchBanks);

// History Proposes Employee
export const useListProposeHistoryState = () =>
    useSelector<IRootState, HistoryEmployeeProposesState>((state: IRootState) => state.listHistoryEmployeePropose);

// Cancel Propose Employee
export const useCancelProposeEmployeeState = () =>
    useSelector<IRootState, CancelProposeEmployeeState>((state: IRootState) => state.cancelProposeEmployee);

// Propose Employee ManagerMode
export const useManagerModeProposeEmployeeState = () => useSelector<IRootState, ManagerModeState>((state: IRootState) => state.managerMode);

// Propose Employee ManagerInfo
export const useManagerInfoProposeEmployeeState = () => useSelector<IRootState, ManagerInfoState>((state: IRootState) => state.managerInfo);

// Cancel Propose Employee
export const useEmployeeOperatorFeedbackState = () =>
    useSelector<IRootState, EmployeeOperatorFeedbackState>((state: IRootState) => state.employeeOperatorFeedback);
