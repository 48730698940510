import { DATE_FNS_DAY_ONLY_DATE_FORMAT, DATE_FNS_LONG_FULL_DATE_FORMAT, DATE_FNS_SHORT_FULL_DATE_FORMAT } from 'config/constants';
import React from 'react';
import FormatterUtils from 'shared/util/format-utils';

export type LocalDateValue = string | undefined | null;

export enum LocalDateFormatType {
    SHORT_FULL_DATE = 'SHORT_FULL_DATE',
    LONG_FULL_DATE = 'LONG_FULL_DATE',
    DAY_ONLY = 'DAY_ONLY',
}

export interface LocalDateFormatterProps {
    value: LocalDateValue;
    type: LocalDateFormatType;
}

export const LocalDateFormatter = (props: LocalDateFormatterProps) => {
    const { value, type } = props;
    const parsed = FormatterUtils.parseDateFromStringISO(value);

    return (
        <>
            {
                ({
                    SHORT_FULL_DATE: <> {parsed && FormatterUtils.formatLocalDate(parsed, DATE_FNS_SHORT_FULL_DATE_FORMAT)}</>,
                    LONG_FULL_DATE: <>{parsed && FormatterUtils.formatLocalDate(parsed, DATE_FNS_LONG_FULL_DATE_FORMAT)}</>,
                    DAY_ONLY: <>{parsed && FormatterUtils.formatLocalDate(parsed, DATE_FNS_DAY_ONLY_DATE_FORMAT)}</>,
                } as Record<LocalDateFormatType, React.ReactNode>)[type]
            }
        </>
    );
};
