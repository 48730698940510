import { AxiosResponse } from 'axios';
import { ProposeEmployeeManagerMode } from 'model/employee-borrower/propose/manager.model';
import { api } from 'services/api/api';

const BASE_URL = '/api/payroll-loan/employee';

export const managerModeApi = () => {
    const getManagerMode = (): Promise<AxiosResponse<ProposeEmployeeManagerMode>> => {
        return api.get<ProposeEmployeeManagerMode>(`${BASE_URL}/programs/manager-mode`);
    };

    return {
        getManagerMode,
    };
};

export default managerModeApi();
