import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import App from './App';
// import DevTools from './config/devtools';
import i18n from './config/i18n';
import './index.css';
import { IRootState } from './reducer';
import configureStore from './reducer/configureStore';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './shared/error/error-boundary';
import 'react-toastify/dist/ReactToastify.css';

// const devTools = process.env.NODE_ENV === 'development' ? <DevTools /> : null;

Sentry.init({
    dsn: 'https://b57ad9605794422189308e20cc54eb67@o613445.ingest.sentry.io/5749040',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.25,
    environment: process.env.NODE_ENV,
    debug: process.env.NODE_ENV === 'development',
    enabled: process.env.NODE_ENV !== 'development',
});

const initialState = window.INITIAL_REDUX_STATE;
const store: Store<IRootState> = configureStore(initialState);

export type RootDispatcher = typeof store.dispatch;

const rootEl = document.getElementById('root');

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <>
                    {/* If this slows down the app in dev disable it and enable when required  */}
                    {/* {devTools} */}
                    <App />
                </>
            </I18nextProvider>
        </Provider>
    </ErrorBoundary>,
    rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
