import { HistoryListProposeEmployee } from 'model/employee-history-proposes';
import { BaseStoreState } from 'reducer/baseStoreState';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum HistoryEmployeeProposesTypes {
    HISTORY_LIST_EMPLOYEE_PROPOSES_REQUEST = '@@historyListEmployeeProposes/HISTORY_LIST_EMPLOYEE_PROPOSES_REQUEST',
    HISTORY_LIST_EMPLOYEE_PROPOSES_SUCCESS = '@@historyListEmployeeProposes/HISTORY_LIST_EMPLOYEE_PROPOSES_SUCCESS',
    HISTORY_LIST_EMPLOYEE_PROPOSES_ERROR = '@@historyListEmployeeProposes/HISTORY_LIST_EMPLOYEE_PROPOSES_ERROR',
    HISTORY_LIST_EMPLOYEE_PROPOSES_RESET_STATE = '@@historyListEmployeeProposes/HISTORY_LIST_EMPLOYEE_PROPOSES_RESET_STATE',
}

export interface HistoryEmployeeProposesState extends BaseStoreState {
    readonly proposes?: Page<HistoryListProposeEmployee>;
}
