export interface EmployeeBorrowerSimulate {
    maxInstallmentNumber?: number;
    minLoanValue?: number;
    minInstallmentNumber?: number;
    maxLoanValue?: number;
    maxInstallmentValue?: number;
    deadlineForReceipt?: number;
    dateToFirstPay?: Date;
}

export const defaultEmployeeBorrowerSimulate: EmployeeBorrowerSimulate = {
    maxInstallmentNumber: undefined,
    minLoanValue: undefined,
    minInstallmentNumber: undefined,
    maxLoanValue: undefined,
    deadlineForReceipt: undefined,
    dateToFirstPay: undefined,
};

export interface EmployeeBorrowerSimulateCalculation {
    id?: number;
    maxLoanValue?: number;
    deadlineForReceipt?: number;
    requestedValue?: number;
    monthlyTaxPercentage?: number;
    yearlyTotalEffectiveCostPercentage?: number;
    installmentNumber?: number;
    installmentValue?: number;
    dateToFirstPay?: Date;
    loanTotalValue?: number;
    receiptDeadline?: number;
}

export interface EmployeeBorrowerSimulateCalculationRequest {
    proposeId?: number;
    loanValue?: number;
    installmentNumber?: number;
}

export const defaultEmployeeBorrowerSimulateCalculationRequest = {
    loanValue: undefined,
    installmentNumber: undefined,
    proposeId: undefined
};
