import React from 'react';

export type Comparable = string | number | Date | undefined;

export interface MonthlyCreditInfoContextProps {}

export const MonthlyCreditInfoContext = React.createContext<MonthlyCreditInfoContextProps>({} as MonthlyCreditInfoContextProps);

export const MonthlyCreditInfoProvider: React.FunctionComponent = props => {
    const value: MonthlyCreditInfoContextProps = {};

    return <MonthlyCreditInfoContext.Provider value={value}>{props.children}</MonthlyCreditInfoContext.Provider>;
};

export const useMonthlyCreditInfoContext = () => React.useContext(MonthlyCreditInfoContext);

export const withMonthlyCreditInfoContext = () => <P extends object>(WrapperComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <MonthlyCreditInfoProvider>
            <WrapperComponent {...props} />
        </MonthlyCreditInfoProvider>
    );
};
