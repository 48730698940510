import { Grid } from '@material-ui/core';
import CardProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-propose-employee-summary';
import ReadOnlyFieldProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/read-only-field-propose-employee-summary/read-only-field-propose-employee-summary';
import { ProposeEmployeeManagerSummary } from 'model/employee-borrower/propose/manager.model';
import React from 'react';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'employee-borrower.propose.manager';

interface CardManagerSummaryProps {
    manager?: ProposeEmployeeManagerSummary;
    onClick: () => void;
    disabled: boolean;
}

const CardManagerSummary = (props: CardManagerSummaryProps) => {
    const { manager, onClick, disabled } = props;

    const { t } = useTranslation();

    return (
        <CardProposeEmployeeSummary title={t(`${I18N_PREFIX}.subtitle`)} onClick={onClick} disabled={disabled}>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary value={manager?.name} label={t(`${I18N_PREFIX}.input.analyst.name.label`)} />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary value={manager?.email} label={t(`${I18N_PREFIX}.input.analyst.email.label`)} />
            </Grid>
        </CardProposeEmployeeSummary>
    );
};

export default CardManagerSummary;
