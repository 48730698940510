import { IEmployeeProgram } from './employee-program.model';

export enum TaxRangeStrategy {
    LOWER_INCLUSIVE_UPPER_INCLUSIVE = 'LOWER_INCLUSIVE_UPPER_INCLUSIVE',
    LOWER_EXCLUSIVE_UPPER_INCLUSIVE = 'LOWER_EXCLUSIVE_UPPER_INCLUSIVE',
    LOWER_INCLUSIVE_UPPER_EXCLUSIVE = 'LOWER_INCLUSIVE_UPPER_EXCLUSIVE',
    LOWER_EXCLUSIVE_UPPER_EXCLUSIVE = 'LOWER_EXCLUSIVE_UPPER_EXCLUSIVE',
}

export interface IEmployeeProgramTaxRange {
    id?: number;
    name?: string;
    lowerAmount?: number;
    upperAmount?: number;
    taxAmount?: number;
    active?: boolean;
    mode?: TaxRangeStrategy;
    program?: IEmployeeProgram;
}

export const defaultValue: Readonly<IEmployeeProgramTaxRange> = {
    active: false,
};
