import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { IEmployeeTimeEntry } from '../../model/employee-time-entry.model';
import HttpStatus from '../../model/enums/httpStatus';
import employeeTimeEntryApi from '../../services/api/employeeTimeEntry';
import { ErrorUtils } from '../../shared/error/error-utils';
import { markError } from '../application/error/actions';
import { employeeTimeEntryError, employeeTimeEntrySuccess } from './actions';
import { EmployeeTimeEntryActionTypes } from './types';

function* handleListEmployeeTimeEntry() {
    try {
        const result: AxiosResponse<IEmployeeTimeEntry[]> = yield call(employeeTimeEntryApi.findAllByMonth);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeTimeEntryError(mapped));
            return;
        }
        yield put(employeeTimeEntrySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeTimeEntryError(mapped));
    }
}

function* watchListEmployeeTimeEntries() {
    yield takeEvery(EmployeeTimeEntryActionTypes.EMPLOYEE_TIME_ENTRY_REQUEST, handleListEmployeeTimeEntry);
}

function* employeeTimeEntrySaga() {
    yield all([fork(watchListEmployeeTimeEntries)]);
}

export default employeeTimeEntrySaga;
