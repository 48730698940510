import MaterialSlide from '@material-ui/core/Slide';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ButtonAction from 'components/buttons/button-action/button-action';
import ButtonCancel from 'components/buttons/button-cancel/button-cancel';
import ButtonEditSimulation from 'components/buttons/button-edit-simulation/button-edit-simulation';
import ButtonNext from 'components/buttons/button-next/button-next';
import ButtonPrevious from 'components/buttons/button-previous/button-previous';
import Currency from 'components/format/currency/Currency';
import ModalMessage from 'components/modals/modal-message/modal-message';
import { useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import { PayrollLoanProposeStatus } from 'model/enums/propose-status';
import React,{ useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';
import { employeeBorrowerProposeSendRequestedChangesRequest } from 'reducer/employeeBorrower/propose/propose-send-requested-changes/actions';
import { useEmployeeBorrowerProposeSummaryState,useRootDispatch } from 'reducer/hooks';
import './employee-borrower-slide.scss';

const I18N_PREFIX = 'employee-borrower.slide';

interface EmployeeBorowerSlideProps {
    proposeId?: number;
    handleStepRequest: () => void;
    loanTotalValue: number | undefined;
    installmentNumber: number | undefined;
    installmentValue: number | undefined;
    isNewAccount: boolean;
    setIsNewAccount: (value: boolean) => void;
    isLoading?: boolean;
}

enum EmployeeBorrowerModalActions {
    CONFIRM_PROPOSE = 'CONFIRM_PROPOSE',
    SEND_REQUESTED_CHANGES = 'SEND_REQUESTED_CHANGES',
    CANCEL_SEND_REQUESTED_CHANGES = 'CANCEL_SEND_REQUESTED_CHANGES',
}

export const EmployeeBorrowerSlide = (props: EmployeeBorowerSlideProps) => {
    const {
        isNewAccount,
        setIsNewAccount,
        loanTotalValue,
        installmentNumber,
        installmentValue,
        handleStepRequest,
        proposeId,
        isLoading,
    } = props;
    const { t } = useTranslation();
    const [showActionModal, setShowActionModal] = useState<EmployeeBorrowerModalActions | undefined>(undefined);

    const { proposeSummary } = useEmployeeBorrowerProposeSummaryState();

    const history = useHistory();

    const dispatch = useRootDispatch();

    const {
        toNextStep,
        isOnStep,
        toPreviousStep,
        disabledButton,
        showSlide,
        isEditing,
        setIsEditing,
        setCurrentStep,
        setShowSlide,
    } = useEmployeeBorrowerContext();

    return (
        <>
            <MaterialSlide in={showSlide} direction="up" timeout={750}>
                <div className="slide__employee-borrower">
                    <Container>
                        <div className="slide__employee-borrower--container">
                            <div style={{ display: 'flex' }}>
                                <div className="slide__employee-borrower--item">
                                    <span className="slide__employee-borrower--item__title">
                                        {t(`${I18N_PREFIX}.loanTotalValue.label`)} <MonetizationOnOutlinedIcon />
                                    </span>
                                    <span className="slide__employee-borrower--item__value">
                                        <strong>{!isLoading ? <Currency value={loanTotalValue ?? 0} /> : '-'}</strong>
                                    </span>
                                </div>
                                <div className="slide__employee-borrower--item">
                                    <span className="slide__employee-borrower--item__title">
                                        {t(`${I18N_PREFIX}.installmentNumber.label`)}
                                    </span>
                                    <span className="slide__employee-borrower--item__value">
                                        {!isLoading ? (
                                            <>
                                                <strong> {`${installmentNumber ?? 0}x `}</strong>
                                                <Currency value={installmentValue} />
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="slide__employee-borrower--buttons">
                                {isOnStep('simulate') && !isEditing && (
                                    <>
                                        <div className="slide__employee-borrower--item--edit">
                                            <ButtonEditSimulation
                                                label='Editar simulação'
                                                onClick={() => {
                                                    setShowSlide(false);
                                                }}
                                            />
                                        </div>
                                        <div className="slide__employee-borrower--item">
                                            <ButtonAction
                                                label={t(`${I18N_PREFIX}.buttons.confirm`)}
                                                onClick={toNextStep}
                                                disabled={disabledButton}
                                            />
                                        </div>
                                    </>
                                )}
                                {isOnStep('summary') && !isEditing && proposeSummary?.status === PayrollLoanProposeStatus.HISTORY && (
                                    <>
                                        <div className="slide__employee-borrower--item">
                                            <ButtonPrevious onClick={() => toPreviousStep()} disabled={disabledButton} />
                                        </div>

                                        <div className="slide__employee-borrower--item">
                                            <ButtonAction
                                                label={t(`${I18N_PREFIX}.buttons.confirm`)}
                                                onClick={() => setShowActionModal(EmployeeBorrowerModalActions.CONFIRM_PROPOSE)}
                                                disabled={disabledButton}
                                            />
                                        </div>
                                    </>
                                )}
                                {!isOnStep('simulate') && !isOnStep('summary') && !isEditing && !isOnStep('account') && (
                                    <>
                                        <div className="slide__employee-borrower--item">
                                            <ButtonPrevious onClick={() => toPreviousStep()} disabled={disabledButton} />
                                        </div>
                                        <div className="slide__employee-borrower--item">
                                            <ButtonNext onClick={handleStepRequest} disabled={disabledButton} />
                                        </div>
                                    </>
                                )}
                                {isOnStep('account') && !isEditing && (
                                    <>
                                        <div className="slide__employee-borrower--item">
                                            <ButtonPrevious onClick={() => (isNewAccount ? setIsNewAccount(false) : toPreviousStep())} />
                                        </div>
                                        <div className="slide__employee-borrower--item">
                                            <ButtonNext onClick={handleStepRequest} disabled={disabledButton} />
                                        </div>
                                    </>
                                )}
                                {proposeSummary?.status === PayrollLoanProposeStatus.NEED_CHANGE && isOnStep('summary') && (
                                    <>
                                        <div className="slide__employee-borrower--item">
                                            <ButtonCancel
                                                onClick={() => {
                                                    setShowActionModal(EmployeeBorrowerModalActions.CANCEL_SEND_REQUESTED_CHANGES);
                                                }}
                                            />
                                        </div>
                                        <div className="slide__employee-borrower--item">
                                            <ButtonAction
                                                label={t(`${I18N_PREFIX}.buttons.save`)}
                                                onClick={() => setShowActionModal(EmployeeBorrowerModalActions.SEND_REQUESTED_CHANGES)}
                                                disabled={disabledButton}
                                            />
                                        </div>
                                    </>
                                )}
                                {isEditing && (
                                    <>
                                        <div className="slide__employee-borrower--item">
                                            <ButtonCancel
                                                onClick={() => {
                                                    setCurrentStep('summary');
                                                    setIsEditing(false);
                                                }}
                                            />
                                        </div>
                                        <div className="slide__employee-borrower--item">
                                            <ButtonAction
                                                label={t('entity.action.save')}
                                                onClick={handleStepRequest}
                                                disabled={disabledButton}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </Container>
                </div>
            </MaterialSlide>
            {showActionModal === EmployeeBorrowerModalActions.CONFIRM_PROPOSE && (
                <ModalMessage
                    title={t(`${I18N_PREFIX}.modal.CONFIRM_PROPOSE.title`)}
                    onClose={() => setShowActionModal(undefined)}
                    onCancel={() => setShowActionModal(undefined)}
                    onAction={() => {
                        handleStepRequest();
                        setShowActionModal(undefined);
                    }}
                />
            )}
            {showActionModal === EmployeeBorrowerModalActions.SEND_REQUESTED_CHANGES && (
                <ModalMessage
                    title={t(`${I18N_PREFIX}.modal.SEND_REQUESTED_CHANGES.title`)}
                    message={t(`${I18N_PREFIX}.modal.SEND_REQUESTED_CHANGES.message`)}
                    onClose={() => setShowActionModal(undefined)}
                    onCancel={() => setShowActionModal(undefined)}
                    onAction={() => {
                        setShowActionModal(undefined);
                        if (!proposeId) return;
                        dispatch(employeeBorrowerProposeSendRequestedChangesRequest(proposeId));
                    }}
                />
            )}
            {showActionModal === EmployeeBorrowerModalActions.CANCEL_SEND_REQUESTED_CHANGES && (
                <ModalMessage
                    title={t(`${I18N_PREFIX}.modal.CANCEL_SEND_REQUESTED_CHANGES.title`)}
                    message={t(`${I18N_PREFIX}.modal.CANCEL_SEND_REQUESTED_CHANGES.message`)}
                    onClose={() => setShowActionModal(undefined)}
                    onCancel={() => setShowActionModal(undefined)}
                    outlinedButtonLabel={t('entity.action.back')}
                    standardButtonLabel={t('entity.action.cancel')}
                    onAction={() => history.goBack()}
                />
            )}
        </>
    );
};
export default EmployeeBorrowerSlide;
