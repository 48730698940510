import Loading from 'components/loading/loading';
import ModalMessage from 'components/modals/modal-message/modal-message';
import ContextRibbon from 'features/context-ribbon/context-ribbon';
import ProposeFeedbackCard from 'features/employee-borrower/components/feedback-card/feedback-card';
import ProposeInvalidAccountCard from 'features/employee-borrower/components/invalid-account-card/invalid-account-card';
import ProposeEmployeeAccountStep from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-account/propose-employee-account';
import ProposeEmployeeAddressDataStep from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-address/propose-employee-address';
import ProposeEmployeeDocumentStep from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-document/propose-employee-document';
import ProposeEmployeeGeneralDataStep from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-general-data/propose-employee-general-data';
import ProposeEmployeeManagerStep from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-manager/propose-employee-manager';
import ProposeEmployeeSummaryStep from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/propose-employee-summary';
import ProposeEmployeeSupplementaryDataStep from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-supplementary-data/propose-employee-supplementary-data';
import { SimulateGeneralManager } from 'features/employee-borrower/components/simulate/simulate-general-manager';
import EmployeeBorrowerSlide from 'features/employee-borrower/components/slide/employee-borrower-slide';
import { EmployeeBorrowerSteps } from 'features/employee-borrower/components/step/employee-borrower-steps';
import {
    EmployeeBorrowerStep,
    useEmployeeBorrowerContext,
    withEmployeeBorrowerContext,
} from 'features/employee-borrower/employee-borrower-context';
import {
    useProposeAccount,
    useProposeAddressData,
    useProposeDocumentData,
    useProposeGeneralData,
    useProposeManager,
    useProposeSimulate,
    useProposeSupplementaryData,
} from 'features/employee-borrower/employee-borrower-hooks';
import { ProposeEmployeeAccountData } from 'model/employee-borrower/propose/account.model';
import { ProposeEmployeeAddressData } from 'model/employee-borrower/propose/address.model';
import { ProposeEmployeeDocumentData } from 'model/employee-borrower/propose/document.model';
import { ProposeEmployeeGeneralData } from 'model/employee-borrower/propose/general-data.model';
import { ProposeEmployeeManager } from 'model/employee-borrower/propose/manager.model';
import { ProposeEmployeeSupplementaryData } from 'model/employee-borrower/propose/supplementary-data.model';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { PayrollLoanProposeStatus } from 'model/enums/propose-status';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { employeeBorrowerUpdateLoanCalculationRequest } from 'reducer/employeeBorrower/loan-calculation/actions';
import { updateEmployeeBorrowerProposeAccountRequest } from 'reducer/employeeBorrower/propose/propose-account-data/update-propose-account-data/actions';
import { updateEmployeeBorrowerProposeAddressDataRequest } from 'reducer/employeeBorrower/propose/propose-address-data/update-propose-address-data/actions';
import {
    employeeBorrowerProposeConfirmRequest,
    employeeBorrowerProposeConfirmResetState,
} from 'reducer/employeeBorrower/propose/propose-confirm/actions';
import { updateEmployeeBorrowerProposeDocumentDataRequest } from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-data/actions';
import { updateEmployeeBorrowerProposeDocumentFileUploadRequest } from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-file-upload/actions';
import { updateEmployeeBorrowerProposeGeneralDataRequest } from 'reducer/employeeBorrower/propose/propose-general-data/update-propose-general-data/actions';
import { updateEmployeeBorrowerProposeManagerRequest } from 'reducer/employeeBorrower/propose/propose-manager/update-propose-manager/actions';
import {
    employeeOperatorFeedbackRequest,
    employeeOperatorFeedbackResetState,
} from 'reducer/employeeBorrower/propose/propose-operator-feedback/action';
import {
    employeeBorrowerProposeRecentRequest,
    employeeBorrowerProposeRecentResetState,
} from 'reducer/employeeBorrower/propose/propose-recent/actions';
import {
    employeeBorrowerProposeSummaryRequest,
    employeeBorrowerProposeSummaryResetState,
} from 'reducer/employeeBorrower/propose/propose-summary/actions';
import { updateEmployeeBorrowerProposeSupplementaryDataRequest } from 'reducer/employeeBorrower/propose/propose-supplementary-data/update-propose-supplementary-data/actions';
import {
    useEmployeeBorrowerProposeConfirmState,
    useEmployeeBorrowerProposeRecentState,
    useEmployeeBorrowerProposeSummaryState,
    useEmployeeBorrowerSimulateCalculationState,
    useEmployeeCompanyDefaultState,
    useEmployeeOperatorFeedbackState,
    useRootDispatch,
    useUpdateEmployeeBorrowerLoanCalculationState,
} from 'reducer/hooks';
import { proposeEmployeeManagerModeRequest, proposeEmployeeManagerModeResetState } from 'reducer/manager/managerMode/actions';
import { compose } from 'redux';
import { useToast } from 'shared/hooks/use-toast';
import { useIsMobile } from 'shared/responsive/media-query';
import './employee-borrower.scss';

type EmployeeBorrowerProps = RouteComponentProps<{ proposeId: string }>;

const I18N_PREFIX = 'employee-borrower.propose';

const EmployeeBorrower = (props: EmployeeBorrowerProps) => {
    const dispatch = useRootDispatch();

    const [isReloadPageRequestSummary, setIsReloadPageRequestSummary] = useState<boolean>(true);

    const { currentStep, isOnStep, setCurrentStep, setShowSlide, toCheckCompletedSteps, managerMode } = useEmployeeBorrowerContext();

    const { company } = useEmployeeCompanyDefaultState();

    const { status: proposeRecentStatus, proposeRecent } = useEmployeeBorrowerProposeRecentState();
    const { status: confirmedProposeStatus } = useEmployeeBorrowerProposeConfirmState();

    const { t } = useTranslation();
    const { toastSuccess } = useToast();
    const isMobile = useIsMobile();

    // proposeId from url
    const [proposeUrlId] = useState<number | undefined>(props.match.params.proposeId ? Number(props.match.params.proposeId) : undefined);

    // Summary
    const { status: summaryStatus, proposeSummary } = useEmployeeBorrowerProposeSummaryState();

    // Feedback Operator
    const { feedback } = useEmployeeOperatorFeedbackState();

    // Simulate
    const { simulateCalculationRequest, setSimulateCalculationRequest, handleLoanValidation } = useProposeSimulate();
    const { status: simulateCalculationStatus, simulateCalculation } = useEmployeeBorrowerSimulateCalculationState();
    const { status: updateSimulateCalculationStatus } = useUpdateEmployeeBorrowerLoanCalculationState();

    // General Data
    const { proposeGeneralDataRequest, handleChangeProposeGeneralData } = useProposeGeneralData();

    // Supplementary Data
    const { proposeSupplementaryDataRequest, handleChangeProposeSupplementaryData } = useProposeSupplementaryData();

    // Address Data
    const { proposeAddressDataRequest, handleChangeProposeAddressData } = useProposeAddressData();

    // Document
    const {
        proposeDocumentDataRequest,
        proposeDocumentFileUploadRequest,
        handleChangeDocumentData,
        handleChangeDocumentFileUpload,
        setProposeDocumentFileUploadRequest,
    } = useProposeDocumentData(proposeUrlId);

    // Manager
    const { proposeManagerRequest, handleChangeProposeManager } = useProposeManager();

    // Account
    const { proposeAccountDataRequest, handleChangeProposeAccount, isNewAccount, setIsNewAccount } = useProposeAccount();
    const [openModalConfirmMessage, setOpenModalConfirmMessage] = useState<boolean>(false);

    useEffect(() => {
        if (proposeUrlId) return;
        dispatch(employeeBorrowerProposeRecentResetState());
        dispatch(employeeBorrowerProposeRecentRequest());
    }, [dispatch, proposeUrlId]);

    useEffect(() => {
        if (!proposeRecent?.id) return;

        dispatch(employeeBorrowerProposeSummaryRequest(proposeRecent?.id));
    }, [dispatch, proposeRecent, proposeRecentStatus]);

    useEffect(() => {
        if (!proposeUrlId) return;

        dispatch(employeeBorrowerProposeSummaryRequest(proposeUrlId));
    }, [dispatch, proposeUrlId]);

    useEffect(() => {
        dispatch(proposeEmployeeManagerModeRequest());
    }, [dispatch]);

    useEffect(() => {
        if (proposeRecent?.id) return;
        if (proposeUrlId) return;
        if (summaryStatus !== HttpRequestStatus.NOOP) return;

        setShowSlide(false);
        setCurrentStep('simulate');
    }, [proposeRecent, setCurrentStep, setShowSlide, proposeUrlId, summaryStatus]);

    useEffect(() => {
        if (summaryStatus !== HttpRequestStatus.SUCCESS) return;

        if (!isReloadPageRequestSummary) return;

        if (proposeSummary?.loanAccountData.bank?.name) {
            toCheckCompletedSteps('account', 'employeeData');
        }

        if (proposeSummary?.manager) {
            toCheckCompletedSteps('manager', 'employeeData');
        }

        if (proposeSummary?.documentData) {
            toCheckCompletedSteps('documentData');
        }

        if (proposeSummary?.address) {
            toCheckCompletedSteps('addressData');
        }

        if (proposeSummary?.supplementaryData) {
            toCheckCompletedSteps('supplementaryData');
        }

        if (proposeSummary?.generalData) {
            toCheckCompletedSteps('generalData');
        }

        if (proposeSummary?.requestedLoanValue) {
            toCheckCompletedSteps('simulate');
            setIsReloadPageRequestSummary(false);
        }

        setShowSlide(true);

        if (proposeSummary?.status === PayrollLoanProposeStatus.HISTORY) {
            setCurrentStep('simulate');
            setShowSlide(false);

            return;
        }

        setCurrentStep('summary');
    }, [
        setCurrentStep,
        setShowSlide,
        toCheckCompletedSteps,
        summaryStatus,
        isReloadPageRequestSummary,
        setIsReloadPageRequestSummary,
        proposeSummary,
    ]);

    useEffect(() => {
        if (!proposeUrlId) return;
        if (summaryStatus !== HttpRequestStatus.SUCCESS) return;
        if (proposeSummary?.status === PayrollLoanProposeStatus.APPROVED) return;
        if (proposeSummary?.status === PayrollLoanProposeStatus.WAITING_SIGNATURE) return;
        if (proposeSummary?.status === PayrollLoanProposeStatus.CANCELLED) return;
        if (proposeSummary?.status === PayrollLoanProposeStatus.RECUSED) return;
        if (proposeSummary?.status === PayrollLoanProposeStatus.SIGNED) return;

        setCurrentStep('summary');
        setShowSlide(true);
    }, [proposeUrlId, proposeSummary, setShowSlide, setCurrentStep, summaryStatus]);

    useEffect(() => {
        if (proposeUrlId && proposeSummary?.status === PayrollLoanProposeStatus.NEED_CHANGE) {
            dispatch(employeeOperatorFeedbackRequest(proposeUrlId));
        }
    }, [dispatch, proposeUrlId, proposeSummary]);

    useEffect(() => {
        if (proposeRecent?.id && proposeSummary?.status === PayrollLoanProposeStatus.NEED_CHANGE) {
            dispatch(employeeOperatorFeedbackRequest(proposeRecent?.id));
        }
    }, [dispatch, proposeRecent, proposeSummary]);

    useEffect(() => {
        if (confirmedProposeStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.summary.send-success`));
        }
    }, [confirmedProposeStatus, toastSuccess, t]);

    useEffect(() => {
        return () => {
            dispatch(employeeBorrowerProposeConfirmResetState());
            dispatch(employeeOperatorFeedbackResetState());
            dispatch(employeeBorrowerProposeSummaryResetState());
            dispatch(employeeBorrowerProposeRecentResetState());
            dispatch(proposeEmployeeManagerModeResetState());
        };
    }, [dispatch]);

    const handleConfirmAccount = () => {
        const currentProposeId = proposeRecent?.id ?? proposeUrlId;

        const _request: ProposeEmployeeAccountData = {
            proposeId: currentProposeId,
            accountData: proposeAccountDataRequest.accountData,
        };
        setOpenModalConfirmMessage(false);
        dispatch(updateEmployeeBorrowerProposeAccountRequest(_request));
    };

    const handleStepRequest = () => {
        if (!company?.id) return;

        const currentProposeId = proposeRecent?.id ?? proposeUrlId;
        const employeeId = company?.id;

        if (!currentProposeId) return;

        if (isOnStep('simulate')) {
            const isValidLoanValue = handleLoanValidation();
            if (!isValidLoanValue) return;
            dispatch(employeeBorrowerUpdateLoanCalculationRequest({ ...simulateCalculationRequest, proposeId: currentProposeId }));
            return;
        }

        if (isOnStep('generalData')) {
            const _request: ProposeEmployeeGeneralData = {
                proposeId: currentProposeId,
                generalData: {
                    employeeId,
                    ...proposeGeneralDataRequest.generalData,
                },
            };

            dispatch(updateEmployeeBorrowerProposeGeneralDataRequest(_request));
            return;
        }

        if (isOnStep('supplementaryData')) {
            const _request: ProposeEmployeeSupplementaryData = {
                proposeId: currentProposeId,
                supplementaryData: {
                    employeeId,
                    ...proposeSupplementaryDataRequest.supplementaryData,
                },
            };

            dispatch(updateEmployeeBorrowerProposeSupplementaryDataRequest(_request));
            return;
        }

        if (isOnStep('addressData')) {
            const _request: ProposeEmployeeAddressData = {
                proposeId: currentProposeId,
                addressData: proposeAddressDataRequest.addressData,
            };

            dispatch(updateEmployeeBorrowerProposeAddressDataRequest(_request));
            return;
        }

        if (isOnStep('documentData')) {
            const _requestDocumentData: ProposeEmployeeDocumentData = {
                proposeId: currentProposeId,
                documentData: proposeDocumentDataRequest.documentData,
            };

            dispatch(updateEmployeeBorrowerProposeDocumentDataRequest(_requestDocumentData));

            const _requestFileUploadData = proposeDocumentFileUploadRequest.filter(value => !value.documentFile?.fileId);

            if (!_requestFileUploadData.length) return;

            dispatch(updateEmployeeBorrowerProposeDocumentFileUploadRequest(_requestFileUploadData));
            return;
        }

        if (isOnStep('manager')) {
            const _request: ProposeEmployeeManager = {
                proposeId: currentProposeId,
                analystStatus: proposeManagerRequest.analystStatus,
                manager: proposeManagerRequest.manager,
            };

            dispatch(updateEmployeeBorrowerProposeManagerRequest(_request));
            return;
        }

        if (isOnStep('account')) {
            setOpenModalConfirmMessage(true);
            return;
        }

        if (isOnStep('summary')) {
            dispatch(employeeBorrowerProposeConfirmRequest(currentProposeId));
        }
    };

    const showSteps = proposeSummary?.status === PayrollLoanProposeStatus.HISTORY || isOnStep('simulate');
    const showFeedbackCard = proposeSummary?.status === PayrollLoanProposeStatus.NEED_CHANGE && summaryStatus === HttpRequestStatus.SUCCESS;
    const showInvalidBankAccountCard =
        proposeSummary?.status === PayrollLoanProposeStatus.INVALID_BANK_ACCOUNT && summaryStatus === HttpRequestStatus.SUCCESS;

    return (
        <>
            <div className="main__employee-borrower">
                <ContextRibbon />
                <Container className="container__employee-borrower">
                    <Row style={isMobile ? { flexDirection: 'column', alignItems: 'center' } : {}}>
                        {showSteps && (
                            <Col style={{ position: 'relative', minHeight: '60px', display: 'flex' }} md={2}>
                                <EmployeeBorrowerSteps />
                            </Col>
                        )}
                        {showFeedbackCard && (
                            <Col style={{ position: 'relative' }} md={2}>
                                <ProposeFeedbackCard feedback={feedback?.feedback} />
                            </Col>
                        )}
                        {showInvalidBankAccountCard && (
                            <Col style={{ position: 'relative' }} md={2}>
                                <ProposeInvalidAccountCard />
                            </Col>
                        )}
                        <Col xs={12} sm={10}>
                            {
                                ({
                                    loading: (
                                        <div className="container__employee-borrower--loading">
                                            <Loading />
                                        </div>
                                    ),
                                    simulate: (
                                        <SimulateGeneralManager
                                            simulateCalculationRequest={simulateCalculationRequest}
                                            setSimulateCalculationRequest={setSimulateCalculationRequest}
                                            handleLoanValidation={handleLoanValidation}
                                            setIsReloadPageRequestSummary={setIsReloadPageRequestSummary}
                                            proposeId={proposeRecent?.id ? proposeRecent?.id : proposeUrlId}
                                        />
                                    ),
                                    employeeData: <></>,
                                    generalData: (
                                        <ProposeEmployeeGeneralDataStep
                                            handleChange={handleChangeProposeGeneralData}
                                            proposeGeneralData={proposeGeneralDataRequest}
                                        />
                                    ),
                                    supplementaryData: (
                                        <ProposeEmployeeSupplementaryDataStep
                                            proposeSupplementaryData={proposeSupplementaryDataRequest}
                                            handleChange={handleChangeProposeSupplementaryData}
                                        />
                                    ),
                                    addressData: (
                                        <ProposeEmployeeAddressDataStep
                                            proposeAddressData={proposeAddressDataRequest}
                                            handleChange={handleChangeProposeAddressData}
                                        />
                                    ),
                                    documentData: (
                                        <ProposeEmployeeDocumentStep
                                            proposeDocumentData={proposeDocumentDataRequest}
                                            proposeDocumentFileUpload={proposeDocumentFileUploadRequest}
                                            setProposeDocumentFileUpload={setProposeDocumentFileUploadRequest}
                                            handleChangeDocumentData={handleChangeDocumentData}
                                            handleChangeDocumentFileUpload={handleChangeDocumentFileUpload}
                                            setIsReloadPageRequestSummary={setIsReloadPageRequestSummary}
                                            proposeId={proposeUrlId ? proposeUrlId : proposeRecent?.id}
                                        />
                                    ),
                                    manager: (
                                        <ProposeEmployeeManagerStep
                                            handleChange={handleChangeProposeManager}
                                            proposeManager={proposeManagerRequest}
                                        />
                                    ),
                                    account: (
                                        <ProposeEmployeeAccountStep
                                            isNewAccount={isNewAccount}
                                            setIsNewAccount={setIsNewAccount}
                                            handleChange={handleChangeProposeAccount}
                                            proposeAccountData={proposeAccountDataRequest}
                                        />
                                    ),
                                    summary: (
                                        <ProposeEmployeeSummaryStep
                                            managerMode={managerMode?.managerMode}
                                            proposeId={proposeUrlId ? proposeUrlId : proposeRecent?.id}
                                            setIsReloadPageRequestSummary={setIsReloadPageRequestSummary}
                                        />
                                    ),
                                } as Record<EmployeeBorrowerStep, React.ReactNode>)[currentStep]
                            }
                        </Col>
                    </Row>
                </Container>
                <EmployeeBorrowerSlide
                    isNewAccount={isNewAccount}
                    setIsNewAccount={setIsNewAccount}
                    proposeId={proposeUrlId ? proposeUrlId : proposeRecent?.id}
                    handleStepRequest={handleStepRequest}
                    loanTotalValue={proposeSummary?.loanTotalValue ?? simulateCalculation?.loanTotalValue}
                    installmentNumber={proposeSummary?.installmentNumber ?? simulateCalculation?.installmentNumber}
                    installmentValue={proposeSummary?.installmentValue ?? simulateCalculation?.installmentValue}
                    isLoading={
                        simulateCalculationStatus === HttpRequestStatus.ONGOING ||
                        updateSimulateCalculationStatus === HttpRequestStatus.ONGOING
                    }
                />
                {openModalConfirmMessage && (
                    <ModalMessage
                        title={t(`${I18N_PREFIX}.account.modal.save-account.title`)}
                        message={t(`${I18N_PREFIX}.account.modal.save-account.description`)}
                        onCancel={() => {
                            setOpenModalConfirmMessage(false);
                        }}
                        onAction={handleConfirmAccount}
                        onClose={() => setOpenModalConfirmMessage(false)}
                        outlinedButtonLabel={t(`${I18N_PREFIX}.account.modal.save-account.back`)}
                        standardButtonLabel={t(`${I18N_PREFIX}.account.modal.save-account.confirm`)}
                    />
                )}
            </div>
        </>
    );
};

export default compose(withEmployeeBorrowerContext())(EmployeeBorrower);
