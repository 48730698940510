import clsx from 'clsx';
import { EmployeeBorrowerStep, steps, useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'shared/responsive/media-query';
import './employee-borrower-steps.scss';

const I18N_PREFIX = 'employee-borrower.step';

interface StepConectorProps {
    label: string;
}

const StepConector = (props: StepConectorProps) => {
    const { t } = useTranslation();
    const { label } = props;

    return (
        <>
            <div className="employee-borrower--container--step-conector" />
            <div className="employee-borrower--container--step-line">
                <div className="employee-borrower--container--step-icon">
                    <div className="employee-borrower--container--step-icon-icon" />
                </div>
                <div className="employee-borrower--container--step-line-label">{t(`${I18N_PREFIX}.${label}`)}</div>
            </div>
        </>
    );
};

const SmallStepConector = (props: StepConectorProps) => {
    const { t } = useTranslation();
    const { label } = props;

    return (
        <>
            <div className="employee-borrower--container--step-small-conector" />
            <div className="employee-borrower--container--step-line">
                <div className="employee-borrower--container--step-icon">
                    <div className="employee-borrower--container--step-icon-small-icon" />
                </div>
                <div className="employee-borrower--container--step-line-label-small">{t(`${I18N_PREFIX}.${label}`)}</div>
            </div>
        </>
    );
};

export const EmployeeBorrowerSteps = () => {
    const { indexedStep, completedSteps, isOnStep, isManualManager } = useEmployeeBorrowerContext();
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    const showSmallStep = isOnStep('generalData') || isOnStep('supplementaryData') || isOnStep('addressData') || isOnStep('documentData');

    if (isMobile) {
        return (
            <div className="rectangle-container">
                <div className="rectangle-bar">
                    {steps
                        .filter(item => item !== 'loading')
                        .map((label, index) => (
                            <div
                                key={index}
                                className={clsx(
                                    'rectangle-step',
                                    { disabled: !isOnStep(label) && !completedSteps[label] },
                                    { active: isOnStep(label) },
                                    { passed: index < indexedStep }
                                )}
                            />
                        ))}
                </div>
                <span>
                    {t('employee-borrower.mobileSteps', {
                        currentStep: indexedStep === 1 ? '1' : indexedStep - 1,
                        totalSteps: steps.length - 1,
                    })}
                </span>
            </div>
        );
    }

    return (
        <>
            <div className="employee-borrower--container--step edit">
                {steps.map((label, index) => (
                    <div
                        key={index}
                        className={clsx(
                            'employee-borrower--container--step-item',
                            { disabled: !isOnStep(label) && !completedSteps[label] },
                            { active: isOnStep(label) },
                            { completed: completedSteps[label] && !isOnStep(label) },
                            { passed: index < indexedStep }
                        )}
                    >
                        {
                            ({
                                simulate: <StepConector label={label} />,
                                employeeData: <StepConector label={label} />,
                                generalData: showSmallStep && <SmallStepConector label={label} />,
                                supplementaryData: showSmallStep && <SmallStepConector label={label} />,
                                addressData: showSmallStep && <SmallStepConector label={label} />,
                                documentData: showSmallStep && <SmallStepConector label={label} />,
                                manager: isManualManager && <StepConector label={label} />,
                                account: <StepConector label={label} />,
                                summary: <StepConector label={label} />,
                            } as Record<EmployeeBorrowerStep, React.ReactNode>)[label]
                        }
                    </div>
                ))}
            </div>
        </>
    );
};

export default EmployeeBorrowerSteps;
