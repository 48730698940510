import SimulateCardDetail from 'features/employee-borrower/components/simulate/simulate-card-detail/simulate-card-detail';
import SimulateCardManager from 'features/employee-borrower/components/simulate/simulate-card-manager/simulate-card-manager';
import { useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import { EmployeeBorrowerSimulateCalculationRequest } from 'model/employee-borrower/simulate.model';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React, { useEffect, useMemo } from 'react';
import { employeeBorrowerUpdateLoanCalculationRequest } from 'reducer/employeeBorrower/loan-calculation/actions';
import { employeeBorrowerSimulateCalculationRequest } from 'reducer/employeeBorrower/simulate-calculation/actions';
import { useRootDispatch, useUpdateEmployeeBorrowerLoanCalculationState } from 'reducer/hooks';

interface SimulateGeneralManagerProps {
    simulateCalculationRequest: EmployeeBorrowerSimulateCalculationRequest;
    setSimulateCalculationRequest: (value: EmployeeBorrowerSimulateCalculationRequest) => void;
    handleLoanValidation: () => boolean;
    setIsReloadPageRequestSummary: (value: boolean) => void;
    proposeId: number | undefined;
}

export const SimulateGeneralManager = (props: SimulateGeneralManagerProps) => {
    const {
        proposeId,
        simulateCalculationRequest,
        setSimulateCalculationRequest,
        handleLoanValidation,
        setIsReloadPageRequestSummary,
    } = props;

    const { toDisabledButtonNext, setShowSlide, showSlide, isEditing } = useEmployeeBorrowerContext();

    const dispatch = useRootDispatch();

    const { status: updateLoanCalculationStatus } = useUpdateEmployeeBorrowerLoanCalculationState();

    const isDisabledNextStepButton: boolean = useMemo(() => {
        return updateLoanCalculationStatus !== HttpRequestStatus.SUCCESS && updateLoanCalculationStatus !== HttpRequestStatus.NOOP;
    }, [updateLoanCalculationStatus]);

    useEffect(() => {
        toDisabledButtonNext(isDisabledNextStepButton);
    }, [toDisabledButtonNext, isDisabledNextStepButton]);

    const handleSave = () => {
        setShowSlide(true);

        if (proposeId) {
            dispatch(employeeBorrowerUpdateLoanCalculationRequest({ ...simulateCalculationRequest, proposeId }));
            return;
        }

        const _simulateCalculationRequest: EmployeeBorrowerSimulateCalculationRequest = {
            installmentNumber: simulateCalculationRequest?.installmentNumber,
            loanValue: simulateCalculationRequest?.loanValue,
        };

        dispatch(employeeBorrowerSimulateCalculationRequest(_simulateCalculationRequest));
    };

    return (
        <>
            {!showSlide || isEditing ? (
                <SimulateCardManager
                    simulateCalculationRequest={simulateCalculationRequest}
                    setSimulateCalculationRequest={setSimulateCalculationRequest}
                    handleLoanValidation={handleLoanValidation}
                    onSave={handleSave}
                />
            ) : (
                <SimulateCardDetail
                    simulateCalculationRequest={simulateCalculationRequest}
                    setIsReloadPageRequestSummary={setIsReloadPageRequestSummary}
                />
            )}
        </>
    );
};
