import { AxiosResponse } from 'axios';
import { FeedbackSimple } from 'model/employee-borrower/propose/operator-feedback.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { employeeBorrowerProposeSummaryRequest } from 'reducer/employeeBorrower/propose/propose-summary/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { employeeBorrowerProposeSendRequestedChangesError, employeeBorrowerProposeSendRequestedChangesRequest, employeeBorrowerProposeSendRequestedChangesSuccess } from './actions';
import { EmployeeBorrowerProposeSendRequestedChangesActionTypes } from './types';

function* handleEmployeeBorrowerProposeSendRequestedChanges(action: ReturnType<typeof employeeBorrowerProposeSendRequestedChangesRequest>) {
    try {
        const result: AxiosResponse<FeedbackSimple> = yield call(proposeApi.sendProposeRequestedChangesToOperator, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerProposeSendRequestedChangesError(mapped));
            return;
        }
        yield put(employeeBorrowerProposeSendRequestedChangesSuccess(result.data));
        yield put(employeeBorrowerProposeSummaryRequest(action.payload));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerProposeSendRequestedChangesError(mapped));
    }
}

function* watchEmployeeBorrowerProposeSendRequestedChanges() {
    yield takeLeading(EmployeeBorrowerProposeSendRequestedChangesActionTypes.EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_REQUEST, handleEmployeeBorrowerProposeSendRequestedChanges);
}

function* employeeBorrowerProposeSendRequestedChangesSaga() {
    yield all([fork(watchEmployeeBorrowerProposeSendRequestedChanges)]);
}

export default employeeBorrowerProposeSendRequestedChangesSaga;
