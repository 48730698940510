import { Authority } from 'model/enums/authorities';
import { GiroWebappErrorConstants } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { markError } from 'reducer/application/error/actions';
import { clearRequest } from 'reducer/authentication/actions';
import { useRootDispatch, useUserAccountState } from 'reducer/hooks';
import AuthUtil from 'services/api/authUtil';

const isDevelopment = process.env.NODE_ENV === 'development';

export const usePrivateRouteRedirect = () => {
    const dispatch = useRootDispatch();
    const history = useHistory();

    const userAccountState = useUserAccountState();

    useEffect(() => {
        if (userAccountState.status !== HttpRequestStatus.SUCCESS) return;
        if (userAccountState.account?.activated !== true) return;
        if (userAccountState.account.authorities?.some(it => it === Authority.ROLE_EMPLOYEE)) return;
        if (isDevelopment) return;

        if (userAccountState.account.authorities?.some(it => it === Authority.ROLE_ADMIN)) {
            history.push('/payroll-loan-admin');
            return;
        }

        dispatch(clearRequest());
        AuthUtil.removeToken();
        dispatch(userAccountResetState());
        dispatch(markError({ error_description: GiroWebappErrorConstants.HTTP_FORBIDDEN }));
    }, [history, userAccountState, dispatch]);

    useEffect(() => {
        if (userAccountState.status !== HttpRequestStatus.SUCCESS) return;
        if (userAccountState.account?.activated !== true) return;
        if (userAccountState.account.authorities?.some(it => it === Authority.ROLE_EMPLOYEE)) return;
        if (!isDevelopment) return;

        dispatch(clearRequest());
        AuthUtil.removeToken();
        dispatch(userAccountResetState());
        dispatch(markError({ error_description: GiroWebappErrorConstants.HTTP_FORBIDDEN }));
    }, [dispatch, history, userAccountState]);
};
