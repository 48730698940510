import { Grid, MenuItem } from '@material-ui/core';
import Card from 'components/card/card';
import SimpleCheckboxInput from 'components/inputs/checkbox-input/simple-checkbox-input';
import SimpleNumberInput from 'components/inputs/number-input/simple-number-input';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import AccountNumberMask from 'features/employee-borrower/components/inputs/masks/account-number-mask';
import AgencyNumberMask from 'features/employee-borrower/components/inputs/masks/agency-number-mask';
import DigitNumberMask from 'features/employee-borrower/components/inputs/masks/digit-number-mask';
import { validatePixKey } from 'features/employee-borrower/components/propose-employee/validation/pix-key-validation';
import { IBank } from 'model/bank.model';
import { ProposeEmployeeAccountData } from 'model/employee-borrower/propose/account.model';
import { BankAccountType } from 'model/enums/bank-account-type.model';
import { PixKeyType } from 'model/enums/pix-key-type.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { searchBanksRequest } from 'reducer/bank/search-banks/actions';

interface CreateProposeEmployeeAccountProps {
    handleChange: (value: Partial<ProposeEmployeeAccountData>) => void;
    proposeAccountData: ProposeEmployeeAccountData;
    handleCheckbox: () => void;
}

const I18N_PREFIX = 'employee-borrower.propose.account';

export const CreateProposeEmployeeAccount = (props: CreateProposeEmployeeAccountProps) => {
    const {
        handleChange,
        proposeAccountData: { accountData },
        handleCheckbox,
    } = props;

    const [showValidation, setShowValidation] = React.useState<boolean>(false);

    const handleChangePixType = (pixKeyType: PixKeyType | undefined) => {
        handleValidation(pixKeyType);
        handleChange({ accountData: { ...accountData, pixKeyType, pixKey: undefined } });
    };

    const handleValidation = (pixKeyType: PixKeyType | undefined) => {
        if (pixKeyType === undefined) {
            setShowValidation(false);
        } else {
            setShowValidation(true);
        }
    };

    const menuItems = [
        {
            value: PixKeyType.INDIVIDUAL,
            label: `${I18N_PREFIX}.input.pixKeyType.options.INDIVIDUAL`,
        },
        {
            value: PixKeyType.CORPORATION,
            label: `${I18N_PREFIX}.input.pixKeyType.options.CORPORATION`,
        },
        {
            value: PixKeyType.EMAIL,
            label: `${I18N_PREFIX}.input.pixKeyType.options.EMAIL`,
        },
        {
            value: PixKeyType.PHONE_NUMBER,
            label: `${I18N_PREFIX}.input.pixKeyType.options.PHONE_NUMBER`,
        },
        {
            value: PixKeyType.EVP,
            label: `${I18N_PREFIX}.input.pixKeyType.options.EVP`,
        },
    ];

    const { t } = useTranslation();

    return (
        <Card>
            <Grid container>
                <Grid item xs={12}>
                    <div className="form__propose-employee-step--card__input">
                        <ModalSearch<IBank>
                            id="inputText_accountData_modal_bankName"
                            itemSize={SEARCH_ITEM_SIZE_LARGE}
                            action={searchBanksRequest}
                            modalTitle={t(`${I18N_PREFIX}.input.bank.modal.title`)}
                            modalLabel={`${I18N_PREFIX}.input.bank.modal.label`}
                            modalPlaceholder={`${I18N_PREFIX}.input.bank.modal.placeholder`}
                            onSelect={bank => handleChange({ accountData: { ...accountData, bank: { id: bank.id, name: bank.name } } })}
                            renderItem={bank => bank.name}
                            statusSelector={(state: IRootState) => state.searchBanks.status}
                            dataSelector={(state: IRootState) => state.searchBanks.banks}
                        >
                            {handleOpen => (
                                <SimpleSearchInput
                                    id="inputText_accountData_bankName"
                                    label={`${I18N_PREFIX}.input.bank.label`}
                                    placeholder={`${I18N_PREFIX}.input.bank.placeholder`}
                                    value={accountData?.bank?.name ?? ''}
                                    onClick={handleOpen}
                                    onFocus={handleOpen}
                                    readOnly
                                    externalUpdate
                                />
                            )}
                        </ModalSearch>
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <div className="form__propose-employee-step--card__input">
                        <SimpleNumberInput
                            id="inputText_accountData_agencyNumber"
                            label={`${I18N_PREFIX}.input.agencyNumber.label`}
                            placeholder={`${I18N_PREFIX}.input.agencyNumber.placeholder`}
                            value={accountData?.agencyNumber}
                            onChange={agencyNumber => handleChange({ accountData: { ...accountData, agencyNumber } })}
                            MaskInputComponent={AgencyNumberMask}
                            externalUpdate
                            hasDecimal={false}
                        />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="form__propose-employee-step--card__input">
                        <SimpleNumberInput
                            id="inputText_accountData_agencyDigit"
                            label={`${I18N_PREFIX}.input.agencyDigit.label`}
                            placeholder={`${I18N_PREFIX}.input.agencyDigit.placeholder`}
                            value={accountData?.agencyDigit}
                            onChange={agencyDigit => handleChange({ accountData: { ...accountData, agencyDigit } })}
                            MaskInputComponent={DigitNumberMask}
                            externalUpdate
                        />
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <div className="form__propose-employee-step--card__input">
                        <SimpleNumberInput
                            id="inputText_accountData_accountNumber"
                            label={`${I18N_PREFIX}.input.accountNumber.label`}
                            placeholder={`${I18N_PREFIX}.input.accountNumber.placeholder`}
                            value={accountData?.accountNumber}
                            onChange={accountNumber => handleChange({ accountData: { ...accountData, accountNumber } })}
                            MaskInputComponent={AccountNumberMask}
                            externalUpdate
                        />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="form__propose-employee-step--card__input">
                        <SimpleNumberInput
                            id="inputText_accountData_accountDigit"
                            label={`${I18N_PREFIX}.input.accountDigit.label`}
                            placeholder={`${I18N_PREFIX}.input.accountDigit.placeholder`}
                            value={accountData?.accountDigit}
                            onChange={accountDigit => handleChange({ accountData: { ...accountData, accountDigit } })}
                            MaskInputComponent={DigitNumberMask}
                            externalUpdate
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="form__propose-employee-step--input" style={{ padding: '10px 6px' }}>
                        <SimpleSelectInput
                            id="inputSelect_accountData_bankAccountType"
                            label={`${I18N_PREFIX}.input.type.label`}
                            value={accountData?.type ?? ''}
                            onChange={type => handleChange({ accountData: { ...accountData, type: BankAccountType[type] } })}
                            mapperFromString={_value => (_value ? BankAccountType[_value] : undefined)}
                            placeholder={`${I18N_PREFIX}.input.type.placeholder`}
                        >
                            <MenuItem value={BankAccountType.CHECKING_ACCOUNT}>
                                {t(`${I18N_PREFIX}.input.type.options.CHECKING_ACCOUNT`)}
                            </MenuItem>
                            <MenuItem value={BankAccountType.SAVINGS_ACCOUNT}>
                                {t(`${I18N_PREFIX}.input.type.options.SAVINGS_ACCOUNT`)}
                            </MenuItem>
                        </SimpleSelectInput>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="form__propose-employee-step--card__input">
                        <SimpleSelectInput
                            mapperFromString={_value => (_value ? PixKeyType[_value] : undefined)}
                            label={t(`${I18N_PREFIX}.input.pixKeyType.label`)}
                            placeholder={t(`${I18N_PREFIX}.input.pixKeyType.placeholder`)}
                            value={accountData?.pixKeyType ?? ''}
                            externalUpdate
                        >
                            <MenuItem value={undefined} onClick={() => handleChangePixType(undefined)}>
                                {t(`${I18N_PREFIX}.input.pixKeyType.options.not-selected`)}
                            </MenuItem>
                            {menuItems.map(item => (
                                <MenuItem key={item.value} value={item.value} onClick={() => handleChangePixType(item.value)}>
                                    {t(item.label)}
                                </MenuItem>
                            ))}
                        </SimpleSelectInput>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="form__propose-employee-step--card__input">
                        <ValidTextInput
                            disabled={!accountData?.pixKeyType}
                            id="inputText_accountData_pixKey"
                            label={`${I18N_PREFIX}.input.pixKey.label`}
                            placeholder={`${I18N_PREFIX}.input.pixKey.placeholder`}
                            value={accountData?.pixKey ?? ''}
                            onChange={pixKey => handleChange({ accountData: { ...accountData, pixKey } })}
                            validate={value => validatePixKey({ pixKey: value, pixKeyType: accountData?.pixKeyType })}
                            showValidation={showValidation}
                            externalUpdate
                        />
                    </div>
                </Grid>
                <div className="form__propose-employee-step--card__input">
                    <SimpleCheckboxInput
                        id="inputText_account_jointAccount"
                        value={accountData?.jointAccount ?? false}
                        label={`${I18N_PREFIX}.input.jointAccount.label`}
                        onChange={handleCheckbox}
                    />
                </div>
            </Grid>
        </Card>
    );
};

export default CreateProposeEmployeeAccount;
