import { AxiosResponse } from 'axios';
import { EmployeeBorrowerSimulate, EmployeeBorrowerSimulateCalculation, EmployeeBorrowerSimulateCalculationRequest } from 'model/employee-borrower/simulate.model';
import { api } from 'services/api/api';

const BASE_URL = '/api/payroll-loan/employee';

export const employeeBorrowerSimulate = () => {
    const updateLoanCalculation = (request: EmployeeBorrowerSimulateCalculationRequest): Promise<AxiosResponse<EmployeeBorrowerSimulate>> => {
        return api.put<EmployeeBorrowerSimulate>(`${BASE_URL}/employee-borrowers/simulate/update`, request);
    };

    const getSimulate = (): Promise<AxiosResponse<EmployeeBorrowerSimulate>> => {
        return api.get<EmployeeBorrowerSimulate>(`${BASE_URL}/employee-borrowers/simulate/start`);
    };

    const getSimulateCalculation = (request: EmployeeBorrowerSimulateCalculationRequest): Promise<AxiosResponse<EmployeeBorrowerSimulateCalculation>> => {
        return api.post<EmployeeBorrowerSimulateCalculation>(`${BASE_URL}/employee-borrowers/simulate/calculate`, request);
    };

    return {
        getSimulate,
        getSimulateCalculation,
        updateLoanCalculation
    };
};

export default employeeBorrowerSimulate();
