import React from 'react';
import FormatterUtils from 'shared/util/format-utils';
export type PhoneValue = string | undefined | null;

export interface PhoneFormatterProps {
    value: PhoneValue;
}

export const PhoneFormatter = (props: PhoneFormatterProps) => {
    const { value } = props;
    return <>{value && FormatterUtils.formatPhone(value)}</>;
};

export const formatPhone = FormatterUtils.formatPhone;

export default PhoneFormatter;
