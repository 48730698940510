import MaterialSlider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import i18n from 'config/i18n';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationResult } from 'shared/util/validation-utils';
import './slider.scss';

const MaterialSliderCustom = withStyles({
    root: {
        color: '$organization_secundary_link;',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '1px solid #D8D8D8',
        marginTop: -7,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
        opacity: 0.85,
        backgroundColor: '#D8D8D8',
    },
})(MaterialSlider);

interface SliderProps {
    label?: string;
    maxValue?: number;
    minValue?: number;
    defaultValue?: number;
    placeholder?: string;
    onChange: (value: number) => void;
    validate?: (value: number | number[]) => ValidationResult;
    showValidation?: boolean;
    // for situations where the input is externally updated
    // should be the exception
    // avoid using unless necessary to avoid needless rerenders
    externalUpdate?: boolean;
}

export const Slider = (props: SliderProps) => {
    const { t } = useTranslation();
    const { label, maxValue, placeholder, minValue, onChange, defaultValue, externalUpdate } = props;

    const [value, setValue] = useState<number | number[]>(defaultValue ? defaultValue : 0);

    const [validation, setValidation] = React.useState<ValidationResult>(
        props.validate && value ? props.validate(value) : { isValid: true }
    );

    useEffect(() => {
        if (externalUpdate) {
            setValue(ps => (ps === defaultValue ? ps : defaultValue ?? 0));
        }
    }, [externalUpdate, defaultValue]);

    useEffect(() => {
        if (props.validate) {
            const _validation = props.validate(value);
            setValidation(_validation);
        }
    }, [props.validate, value]);

    const handleChange = (_event, newValue: number | number[]) => {
        if (onChange) {
            onChange(Number(newValue));
        }

        setValue(newValue);
    };

    const handleErrorMessage = () => {
        if (!validation.errorMessage) return;
        if (i18n.exists(validation.errorMessage)) {
            return t(validation.errorMessage);
        } else {
            return validation.errorMessage;
        }
    };

    return (
        <>
            <div className="slider">
                {label && <span className="slider--label"> {label} </span>}
                <span className="slider--value">
                    {value} {placeholder && placeholder}
                </span>
                <MaterialSliderCustom aria-label="pretto slider" value={value} onChange={handleChange} max={maxValue} min={minValue} />

                {!validation.isValid && validation.errorMessage && props.showValidation && (
                    <div className="slider--error">
                        <Fragment>
                            <span>
                                <InfoOutlinedIcon /> {handleErrorMessage()}
                            </span>
                        </Fragment>
                    </div>
                )}
            </div>
        </>
    );
};

export default Slider;
