import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { GIRO_PHONE_CONTACT } from 'config/constants';
import EntitiesMenu from 'features/header/components/entities-menu/entities-menu';
import ModalCompany from 'features/modal-company/modal-company';
import { ErrorConstants, ErrorType } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { ErrorHandlingCustomization } from 'model/error';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { hasAcceptedTermsResetState } from 'reducer/account/terms-of-use/actions';
import { userAccountRequest, userAccountResetState } from 'reducer/account/user-account/actions';
import { customizeErrorHandling } from 'reducer/application/error/actions';
import { logoutRequest } from 'reducer/authentication/actions';
import { defaultCompanyEmployeeResetState, getDefaultCompanyEmployeeRequest } from 'reducer/company/default/actions';
import { useEmployeeCompanyDefaultState, useRootDispatch, useUserAccountState } from 'reducer/hooks';
import authUtil from 'services/api/authUtil';
import { useIsCompanySelectionRequired } from 'shared/hooks/company-selection-required';
import { useIsHeaderEmployeeLoading } from 'shared/hooks/header-employee-is-loading';
import { useHandleUserNotActivated } from 'shared/hooks/user-not-activated';
import { useIsMobile } from 'shared/responsive/media-query';
import './header-employee.scss';

const useAccount = () => {
    const dispatch = useRootDispatch();
    const state = useUserAccountState();

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.NOOP) {
            dispatch(userAccountRequest());
        }
    }, [state.status, dispatch]);
};

const useCompany = () => {
    const dispatch = useRootDispatch();
    const accountState = useUserAccountState();
    const companyState = useEmployeeCompanyDefaultState();

    React.useEffect(() => {
        if (
            accountState.account?.activated &&
            accountState.status === HttpRequestStatus.SUCCESS &&
            companyState.status === HttpRequestStatus.NOOP
        ) {
            dispatch(getDefaultCompanyEmployeeRequest());
        }
    }, [accountState.status, accountState.account, companyState.status, dispatch]);
};

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();
    const history = useHistory();

    React.useEffect(() => {
        const handleLogoff = () => {
            dispatch(logoutRequest());
            authUtil.removeToken();
            history.push('/');
            dispatch(userAccountResetState());
            dispatch(hasAcceptedTermsResetState());
            dispatch(defaultCompanyEmployeeResetState());
        };

        const _record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.person.notTeamMember': {
                type: ErrorType.BLOCKING,
                message: {
                    key: 'header.header-employee.error.not-team-member',
                    options: { contact_number: GIRO_PHONE_CONTACT },
                },
                action: {
                    label: 'header.header-employee.error.exit',
                    handler: handleLogoff,
                },
            },
        };
        dispatch(dispatch(customizeErrorHandling({ record: _record })));
    }, [dispatch, history]);
};

export const HeaderEmployee = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    useErrorConfiguration();
    const isLoading = useIsHeaderEmployeeLoading();
    const requireCompanySelection = useIsCompanySelectionRequired();

    const companyState = useEmployeeCompanyDefaultState();
    useAccount();
    useCompany();
    useHandleUserNotActivated();

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleChangeCompany = () => {
        setShowModal(false);
        setTimeout(() => {
            history.replace('/dashboard');
        }, 2000);
    };

    if (isMobile) {
        return (
            <>
                <div className="page-mobile-header">
                    <div onClick={() => setShowMenu(true)}>
                        <div className="page-mobile-header--menu" />
                    </div>
                    <Link to="/dashboard">
                        <div className="page-mobile-header--logo" />
                    </Link>
                    <div className="page-mobile-header--notification">
                        <Link className="page-mobile-header--link" to="/dashboard">
                            <NotificationsNoneIcon />
                        </Link>
                    </div>
                </div>
                {showMenu && (
                    <div className="mobile-navbar">
                        <div onClick={() => setShowMenu(false)}>
                            <div className="mobile-navbar--close" />
                        </div>
                        <div onClick={() => setShowMenu(false)} className="page-header--items">
                            <EntitiesMenu />
                        </div>
                        <div onClick={() => setShowMenu(false)} className="mobile-navbar--profile">
                            <Link className="mobile-navbar--link" to="/profile">
                                <AccountCircleIcon /> {t('header.header-employee.profile')}
                            </Link>
                        </div>
                    </div>
                )}
            </>
        );
    }

    return (
        <div className="page-header">
            <div>
                <nav className="page-header--menu">
                    <Link to="/dashboard">
                        <div className="page-header--logo" />
                    </Link>
                    <div className="page-header--items">
                        <EntitiesMenu />
                    </div>
                    <div className="header-employee--notification">
                        <Link className="header-employee--link" to="/dashboard">
                            <NotificationsNoneIcon />
                        </Link>
                    </div>
                    <div className="header-employee--profile">
                        <Link className="header-employee--link" to="/profile">
                            <AccountCircleIcon /> {t('header.header-employee.profile')}
                        </Link>
                    </div>
                </nav>
                <ModalCompany
                    open={showModal}
                    required={requireCompanySelection}
                    closeModal={handleModalClose}
                    onAccess={handleChangeCompany}
                />
            </div>
        </div>
    );
};

export default HeaderEmployee;
