import ButtonBack from 'components/buttons/button-back/button-back';
import Loading from 'components/loading/loading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useIsCompanySelectionRequired } from 'shared/hooks/company-selection-required';
import { useIsHeaderEmployeeLoading } from 'shared/hooks/header-employee-is-loading';
import { useDoesHeaderEmployeeHasError } from 'shared/hooks/header-employeer-has-error';
import './header-awareness.scss';

export const HeaderAwareness: React.FunctionComponent = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const isLoading = useIsHeaderEmployeeLoading();
    const hasError = useDoesHeaderEmployeeHasError();
    const requireCompanySelection = useIsCompanySelectionRequired();

    const handleRefresh = () => {
        history.go(0);
    };

    if (isLoading) {
        return (
            <div className="header-awareness__container--loading">
                <Loading />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="header-awareness__container--has-error">
                <div className="header-awareness__text">{t('header.error.message')}</div>
                <ButtonBack label={t('header.error.refresh').toUpperCase()} onClick={handleRefresh} />
            </div>
        );
    }

    if (requireCompanySelection) {
        return <></>;
    }

    return <>{props.children}</>;
};

export default HeaderAwareness;
