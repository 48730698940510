import { Authority } from 'model/enums/authorities';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { useEffect } from 'react';
import { hasAcceptedTermsRequest } from 'reducer/account/terms-of-use/actions';
import { useRootDispatch, useUserAccountState } from 'reducer/hooks';

export const useValidateTerms = () => {
    const dispatch = useRootDispatch();
    const userAccountState = useUserAccountState();

    useEffect(() => {
        if (
            userAccountState.status === HttpRequestStatus.SUCCESS &&
            userAccountState.account?.activated &&
            userAccountState.account?.authorities?.find(it => it === Authority.ROLE_EMPLOYEE)
        ) {
            dispatch(hasAcceptedTermsRequest());
        }
    }, [userAccountState, dispatch]);
};
