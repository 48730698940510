import { PersonToProfileUpdate } from 'model/person';
import { BaseStoreState } from '../../baseStoreState';

export enum UpdatePersonActionTypes {
    UPDATE_PERSON_REQUEST = '@@updatePerson/UPDATE_PERSON_REQUEST',
    UPDATE_PERSON_SUCCESS = '@@updatePerson/UPDATE_PERSON_SUCCESS',
    UPDATE_PERSON_ERROR = '@@updatePerson/UPDATE_PERSON_ERROR',
    UPDATE_PERSON_RESET_STATE = '@@updatePerson/UPDATE_PERSON_RESET_STATE',
}

export interface UpdatePersonState extends BaseStoreState {
    readonly person?: PersonToProfileUpdate;
}