import BaseReadOnlyField from 'features/employee-borrower/components/inputs/base-read-only-field';
import React from 'react';
import FormatUtils from 'shared/util/format-utils';

interface DateReadOnlyFieldProps {
    label: string;
    value?: Date;
    iconLabel?: React.ReactElement;
}

export const DateReadOnlyField = (props: DateReadOnlyFieldProps) => {
    const { value, iconLabel, label } = props;

    const valueDisplay = (): string | undefined => {
        if (!value) return;

        return FormatUtils.formatDate(value);
    };

    return <BaseReadOnlyField label={label} iconLabel={iconLabel} value={valueDisplay() ?? '-'} />;
};

export default DateReadOnlyField;
