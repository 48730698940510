import { AxiosResponse } from 'axios';
import { IEmployeeProgram } from '../../model/employee-program.model';
import { api } from './api';

const BASE_URL = 'api/webapp/employee-programs';

export const employeePrograms = () => {
    const findAll = (): Promise<AxiosResponse<IEmployeeProgram>> => {
        return api.get<IEmployeeProgram>(`${BASE_URL}`);
    };

    return {
        findAll,
    };
};

export default employeePrograms();
