// Update general data
import { updateEmployeeBorrowerProposeGeneralDataReducer } from 'reducer/employeeBorrower/propose/propose-general-data/update-propose-general-data/reducer';
import updateEmployeeBorrowerProposeGeneralDataSagas from 'reducer/employeeBorrower/propose/propose-general-data/update-propose-general-data/sagas';
import { UpdateEmployeeBorrowerProposeGeneralDataState } from 'reducer/employeeBorrower/propose/propose-general-data/update-propose-general-data/types';

//
import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

export interface EmployeeBorrowerProposeGeneralDataState {
    readonly updateProposeGeneralData: UpdateEmployeeBorrowerProposeGeneralDataState;
}

export const EmployeeBorrowerProposeGeneralDataReducer: ReducersMapObject<EmployeeBorrowerProposeGeneralDataState, AnyAction> = {
    updateProposeGeneralData: updateEmployeeBorrowerProposeGeneralDataReducer
};

export function* employeeBorrowerProposeGeneralDataSagas() {
    yield all([fork(updateEmployeeBorrowerProposeGeneralDataSagas)]);
}
