import { AxiosError, AxiosResponse } from 'axios';
import { GiroApiErrorConstants } from '../../model/enums/error-constants';
import HttpStatus from '../../model/enums/httpStatus';
import { BadRequestError, ForbiddenError, GiroApiError, GiroWebappError, UnauthorizedError, UnknownError } from '../../model/error';
import AuthUtil from '../../services/api/authUtil';

const mapLocalError = (error: any): GiroWebappError => {
    if ((error as AxiosError).response) {
        const response = (error as AxiosError).response;
        const value: GiroApiError = {
            httpStatus: response?.status,
            message: response?.data?.message,
            code: response?.data?.code,
            fieldErrors: response?.data?.fieldErrors,
            error_description: response?.data?.error_description,
        };
        return mapHttpError(value);
    }
    if (error instanceof Error && error.stack) {
        if (process.env.NODE_ENV === 'development') {
            console.debug(error);
        }
    }

    return UnknownError;
};

const mapGiroApiError = (response: AxiosResponse): GiroApiError => {
    const value: GiroApiError = {
        httpStatus: response.status,
        message: response.data?.message,
        code: response.data?.code,
        fieldErrors: response.data?.fieldErrors,
        error_description: response.data?.error_description,
    };
    return mapHttpError(value);
};

const mapHttpError = (error: GiroApiError): GiroApiError => {
    if (error?.httpStatus === HttpStatus.UNAUTHORIZED) {
        if (
            error.error_description &&
            [GiroApiErrorConstants.USER_NOT_FOUND, GiroApiErrorConstants.INVALID_PASSWORD].find(it => it === error.error_description)
        ) {
            return error;
        } else if (error.error_description && GiroApiErrorConstants.USER_NOT_ACTIVATE === error.error_description) {
            AuthUtil.removeToken();
            return error;
        } else {
            AuthUtil.removeToken();
            return UnauthorizedError;
        }
    }
    if (error?.httpStatus === HttpStatus.FORBIDDEN) {
        return ForbiddenError;
    }

    if (error?.httpStatus === HttpStatus.BAD_REQUEST) {
        if (error?.message) {
            return error;
        }
        return BadRequestError;
    }

    return error;
};

export const ErrorUtils = {
    mapLocalError,
    mapGiroApiError,
};
