import { AxiosResponse } from 'axios';
import { EmployeeBorrowerAvailableServices } from 'model/employee-borrower/employee-services.model';
import { api } from 'services/api/api';

const BASE_URL = '/api/payroll-loan/employee/employee-borrowers';

export const employeeBorrowerService = () => {
    const availableServices = (): Promise<AxiosResponse<EmployeeBorrowerAvailableServices>> => {
        return api.get<EmployeeBorrowerAvailableServices>(`${BASE_URL}/available-services`);
    };

    return {
        availableServices,
    };
};

export default employeeBorrowerService();
