import { ProposeEmployeeSupplementaryData } from 'model/employee-borrower/propose/supplementary-data.model';
import { MaritalStatus } from 'model/enums/marital-status';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const SUPPLEMENTARY_DATA_INFO_EDUCATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateSupplementaryDataEducation = (value: string | undefined | null) => ValidationUtils.validate(value, SUPPLEMENTARY_DATA_INFO_EDUCATION_VALIDATION);

const SUPPLEMENTARY_DATA_INFO_PLACE_BIRTH_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateSupplementaryDataPlaceBirth = (value: string | undefined | null) => ValidationUtils.validate(value, SUPPLEMENTARY_DATA_INFO_PLACE_BIRTH_VALIDATION);

const SUPPLEMENTARY_DATA_INFO_NATIONALITY_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateSupplementaryDataNationality = (value: string | undefined | null) => ValidationUtils.validate(value, SUPPLEMENTARY_DATA_INFO_NATIONALITY_VALIDATION);

const SUPPLEMENTARY_DATA_INFO_MARTIAL_STATUS_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateSupplementaryDataMartialStatus = (value: string | undefined | null) => ValidationUtils.validate(value, SUPPLEMENTARY_DATA_INFO_MARTIAL_STATUS_VALIDATION);

const SUPPLEMENTARY_DATA_INFO_SPOUSE_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateSupplementaryDataSpouseName = (value: string | undefined | null) => ValidationUtils.validate(value, SUPPLEMENTARY_DATA_INFO_SPOUSE_NAME_VALIDATION);

const SUPPLEMENTARY_DATA_INFO_SPOUSE_BIRTHDATE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateSupplementaryDataSpouseBirthDate = (value: string | undefined | null) => ValidationUtils.validate(value, SUPPLEMENTARY_DATA_INFO_SPOUSE_BIRTHDATE_VALIDATION);

const SUPPLEMENTARY_DATA_INFO_DEPENDENTS_NUMBER_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty
    },
    minLength: {
        predicate: ValidationUtils.NUMBER.minSize(1)
    },
};

export const validateSupplementaryDataDependentsNumber = (value: number | undefined | null) => ValidationUtils.validate(value, SUPPLEMENTARY_DATA_INFO_DEPENDENTS_NUMBER_VALIDATION);

const SUPPLEMENTARY_DATA_INFO_SPOUSE_IDENTIFICATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    valid: {
        predicate: ValidationUtils.STRING.ValidCPF,
        errorMessage: `employee-borrower.propose.documentData.input.cpf.error-message`
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateSupplementaryDataSpouseIdentification = (value: string | undefined | null) => ValidationUtils.validate(value, SUPPLEMENTARY_DATA_INFO_SPOUSE_IDENTIFICATION_VALIDATION);


const validateSpouseByMaritalStatus = (value: ProposeEmployeeSupplementaryData): boolean => {
    const { supplementaryData } = value;

    if (supplementaryData?.maritalStatus !== MaritalStatus.MARRIED) {
        return true;
    }

    return validateSupplementaryDataSpouseName(supplementaryData.spouseName).isValid &&
        validateSupplementaryDataSpouseIdentification(supplementaryData.spouseIdentification).isValid &&
        validateSupplementaryDataSpouseBirthDate(supplementaryData.spouseBirthDate).isValid;
}

export const validateProposeEmployeeSupplementaryData = (value: ProposeEmployeeSupplementaryData | undefined): boolean => {
    if (!value) return false;

    const { supplementaryData } = value;

    return (
        validateSupplementaryDataEducation(supplementaryData?.education).isValid &&
        validateSupplementaryDataNationality(supplementaryData?.nationality).isValid &&
        validateSupplementaryDataMartialStatus(supplementaryData?.maritalStatus).isValid &&
        validateSupplementaryDataPlaceBirth(supplementaryData?.birthCity).isValid &&
        validateSupplementaryDataPlaceBirth(supplementaryData?.birthState).isValid &&
        validateSpouseByMaritalStatus(value)
    );
};

