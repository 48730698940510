import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { EmployeeBorrowerLoanCalculationActionTypes, EmployeeBorrowerLoanCalculationState } from './types';

export const initialState: EmployeeBorrowerLoanCalculationState = {
    status: HttpRequestStatus.NOOP,
    loanCalculation: undefined,
    error: undefined
};

const reducer: Reducer<EmployeeBorrowerLoanCalculationState> = (state = initialState, action): EmployeeBorrowerLoanCalculationState => {
    switch (action.type) {
        case EmployeeBorrowerLoanCalculationActionTypes.EMPLOYEE_BORROWER_LOAN_CALCULATION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeBorrowerLoanCalculationActionTypes.EMPLOYEE_BORROWER_LOAN_CALCULATION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, loanCalculation: action.payload, error: undefined };
        }
        case EmployeeBorrowerLoanCalculationActionTypes.EMPLOYEE_BORROWER_LOAN_CALCULATION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case EmployeeBorrowerLoanCalculationActionTypes.EMPLOYEE_BORROWER_LOAN_CALCULATION_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as employeeBorrowerLoanCalculationReducer };
