import ReactPaginate from 'react-paginate';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { PAGE_RANGE_DISPLAYED, MARGIN_PAGES_DISPLAYED, BREAK_LABEL } from './constants';
import './pagination.scss';
import React from 'react';

interface PaginationProps {
    totalPages: number;
    onChange: (selectedItem: { selected: number }) => void;
    page: number;
}

const Pagination = (props: PaginationProps) => {
    const { totalPages, onChange, page } = props;

    return (
        <footer className="page-container--pagination">
            {totalPages !== 0 && (
                <ReactPaginate
                    previousLabel={<ChevronLeftIcon />}
                    nextLabel={<ChevronRightIcon />}
                    forcePage={page}
                    pageCount={totalPages}
                    marginPagesDisplayed={MARGIN_PAGES_DISPLAYED}
                    pageRangeDisplayed={PAGE_RANGE_DISPLAYED}
                    onPageChange={onChange}
                    breakLabel={BREAK_LABEL}
                    activeClassName={'page-container--pagination-item-active'}
                    breakClassName={'page-container--pagination-item'}
                    containerClassName={'pagination'}
                    pageClassName={'page-container--pagination-item'}
                    previousClassName={totalPages > 1 ? 'page-container--pagination-icon' : 'page-container--pagination-icon-disabled'}
                    nextClassName={totalPages > 1 ? 'page-container--pagination-icon' : 'page-container--pagination-icon-disabled'}
                />
            )}
        </footer>
    );
};

export default Pagination;
