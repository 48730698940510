import Grid from '@material-ui/core/Grid';
import ButtonBack from 'components/buttons/button-back/button-back';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePersonProfileState, useRootDispatch } from 'reducer/hooks';
import { personProfileRequest } from 'reducer/person/profile/actions';
import './profile-display.scss';

const usePersonProfileRequest = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(personProfileRequest());
    }, [dispatch]);
};

const usePersonProfileAwareness = () => {
    const state = usePersonProfileState();

    const isLoading = state.status !== HttpRequestStatus.ERROR && state.status !== HttpRequestStatus.SUCCESS;

    return { isLoading };
};

export const ProfileDisplay = () => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    usePersonProfileRequest();
    const state = usePersonProfileState();

    const [name, setName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const { isLoading } = usePersonProfileAwareness();

    const handleRefresh = () => {
        dispatch(personProfileRequest());
    };

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.SUCCESS && state.profile) {
            setName(state.profile?.name ?? '');
            setEmail(state.profile?.email ?? '');
        }
    }, [state.status, state.profile]);

    return (
        <div className="profile-display__items">
            <div className="container">
                {isLoading ? (
                    <div className="personal-data__items--loading">
                        <Loading />
                    </div>
                ) : state.profile ? (
                    <>
                        <Grid id="data">
                            <div className="personal-data__items--title">{t('profile.user.data.title')}</div>
                            <Grid item className="personal-data__items--text">
                                <span className="personal-data__items--text-first">{t('profile.user.data.name.label')}</span>
                                <span className="personal-data__items--text-second">{name}</span>
                            </Grid>
                            <Grid item className="personal-data__items--text">
                                <span className="personal-data__items--text-first">{t('profile.user.data.email.label')}</span>
                                <span className="personal-data__items--text-second">{email}</span>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <div className="personal-data__items--has-error">
                        <div className="personal-data__items__text">{t('header.error.message')}</div>
                        <ButtonBack label={t('header.error.refresh').toUpperCase()} onClick={handleRefresh} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileDisplay;
