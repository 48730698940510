import React from 'react';

export const useScript = (url: string) => {
    React.useEffect(() => {
        const script: HTMLScriptElement = document.createElement('script');
        script.src = url;
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};

export default useScript;