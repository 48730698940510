import { ButtonClose } from 'components/buttons/button-close/button-close';
import OutlinedButton from 'components/buttons/outlined-button/outlined-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import React from 'react';
import { Modal } from 'reactstrap';
import './modal-message.scss';

export interface ModalMessageProps {
    title?: string;
    children?: React.ReactNode;
    message?: string;
    onClose: () => void;
    onAction?: () => void;
    onCancel?: () => void;
    disabled?: boolean;
    outlinedButtonLabel?: string;
    standardButtonLabel?: string;
}

const convertNewLinesToBreaks = text => {
    return text.split('\n').map((item, index) => (
        <React.Fragment key={index}>
            {item}
            <br />
        </React.Fragment>
    ));
};

export const ModalMessage = (props: ModalMessageProps) => {
    const { message, title, onClose, onAction, onCancel, children, disabled = false, outlinedButtonLabel, standardButtonLabel } = props;

    return (
        <Modal isOpen>
            <div className="modal-message">
                <article className="modal-message--content">
                    <header className="modal-message--header">
                        <ButtonClose onClick={onClose} />
                    </header>
                    <div className="modal-message--message">
                        <p className="modal-message--title">{title}</p>
                        {message && <p className="modal-message--text">{convertNewLinesToBreaks(message)}</p>}
                        {children && children}
                    </div>
                    {(onCancel ?? onAction) && (
                        <div className="modal-message--actions">
                            {onCancel && <OutlinedButton label={outlinedButtonLabel ?? 'entity.action.cancel'} onClick={onCancel} />}
                            {onAction && (
                                <StandardButton
                                    label={standardButtonLabel ?? 'entity.action.confirm'}
                                    onClick={onAction}
                                    disabled={disabled}
                                />
                            )}
                        </div>
                    )}
                </article>
            </div>
        </Modal>
    );
};

export default ModalMessage;
