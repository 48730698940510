import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { PersonToProfile } from '../../../model/person';
import personApi from '../../../services/api/personApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { personProfileError, personProfileSuccess } from './actions';
import { PersonProfileActionTypes } from './types';

function* handlePersonProfile() {
    try {
        const result: AxiosResponse<PersonToProfile> = yield call(personApi.getProfile);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(personProfileError(mapped));
            return;
        }
        yield put(personProfileSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(personProfileError(mapped));
    }
}

function* watchPersonProfile() {
    yield takeEvery(PersonProfileActionTypes.PERSON_PROFILE_REQUEST, handlePersonProfile);
}

function* personProfileSaga() {
    yield all([fork(watchPersonProfile)]);
}

export default personProfileSaga;
