import { MenuItem } from '@material-ui/core';
import SimpleCheckboxInput from 'components/inputs/checkbox-input/simple-checkbox-input';
import ValidCpfInput from 'components/inputs/cpf-input/valid-cpf-input';
import SimpleLocalDateInput from 'components/inputs/date-input/simple-local-date-input';
import SimpleNumberInput from 'components/inputs/number-input/simple-number-input';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import DependentsNumberMask from 'features/employee-borrower/components/inputs/masks/dependents-number-mask';
import {
    validateProposeEmployeeSupplementaryData,
    validateSupplementaryDataDependentsNumber,
    validateSupplementaryDataSpouseIdentification,
} from 'features/employee-borrower/components/propose-employee/validation/propose-employee-supplementary-data-validation';
import { useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import { ProposeEmployeeSupplementaryData } from 'model/employee-borrower/propose/supplementary-data.model';
import { EducationType } from 'model/enums/education-type';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { MaritalStatus } from 'model/enums/marital-status';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateEmployeeBorrowerProposeSupplementaryDataResetState } from 'reducer/employeeBorrower/propose/propose-supplementary-data/update-propose-supplementary-data/actions';
import { useRootDispatch, useUpdateEmployeeBorrowerProposeSupplementaryDataState } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import '../propose-employee-steps.scss';
import Loading from 'components/loading/loading';

const I18N_PREFIX = 'employee-borrower.propose.supplementaryData';

interface ProposeEmployeeSupplementaryDataProps {
    handleChange: (value: Partial<ProposeEmployeeSupplementaryData>) => void;
    proposeSupplementaryData: ProposeEmployeeSupplementaryData;
}

export const ProposeEmployeeSupplementaryDataStep = (props: ProposeEmployeeSupplementaryDataProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const [shouldLoading, setShouldLoading] = useState<boolean>(false)

    const { handleChange, proposeSupplementaryData } = props;

    const { supplementaryData } = proposeSupplementaryData;

    const { status: supplementaryDataStatus } = useUpdateEmployeeBorrowerProposeSupplementaryDataState();

    const { toDisabledButtonNext, toNextStep, isEditing, setCurrentStep, setIsEditing } = useEmployeeBorrowerContext();

    const [checkbox, setCheckBox] = useState<boolean>(false);

    const { toastSuccess } = useToast();

    const isDisabledNextStepButton: boolean = useMemo(() => {
        if (!validateProposeEmployeeSupplementaryData(proposeSupplementaryData)) {
            return true;
        }
        return checkbox && !validateSupplementaryDataDependentsNumber(proposeSupplementaryData.supplementaryData?.dependentsNumber).isValid;
    }, [proposeSupplementaryData, checkbox]);

    useEffect(() => {
        toDisabledButtonNext(isDisabledNextStepButton);
    }, [isDisabledNextStepButton, toDisabledButtonNext]);

    useEffect(() => {
        if(supplementaryDataStatus === HttpRequestStatus.ONGOING){
            setShouldLoading(true)
        }
        if (supplementaryDataStatus === HttpRequestStatus.SUCCESS) {
            dispatch(updateEmployeeBorrowerProposeSupplementaryDataResetState());

            if (!isEditing) {
                toNextStep();
                return;
            }
            setIsEditing(false);
            toastSuccess(t(`${I18N_PREFIX}.edit-success`));
            setCurrentStep('summary');
        }
    }, [supplementaryDataStatus, toNextStep, dispatch, isEditing, setCurrentStep, setIsEditing, toastSuccess, t]);

    useEffect(() => {
        if (supplementaryData?.dependentsNumber) {
            setCheckBox(true);
        }
    }, [supplementaryData, setCheckBox]);

    const handleSelectMaritalStatus = (maritalStatus: string) => {
        if (MaritalStatus[maritalStatus] === MaritalStatus.MARRIED) {
            handleChange({ supplementaryData: { ...supplementaryData, maritalStatus: MaritalStatus[maritalStatus] } });
            return;
        }

        handleChange({
            supplementaryData: {
                ...supplementaryData,
                maritalStatus: MaritalStatus[maritalStatus],
                spouseBirthDate: undefined,
                spouseName: undefined,
                spouseIdentification: undefined,
            },
        });
    };

    const handleCheckBox = () => {
        handleChange({ supplementaryData: { ...supplementaryData, dependentsNumber: undefined } });

        setCheckBox(!checkbox);
    };

    return (
        <>
            {shouldLoading ? (
                <div className='container__employee-borrower--loading'>
                    <Loading />
                </div>
            ) : (    
                <div className="container__propose-employee-step">
                    <div className="header__propose-employee-step">
                        <span className="header__propose-employee-step--title"> {t('employee-borrower.title')} </span>
                        <span className="header__propose-employee-step--subtitle"> {t(`${I18N_PREFIX}.subtitle`)} </span>
                    </div>
                    <div className="form__propose-employee-step">
                        <div className="form__propose-employee-step--input">
                            <SimpleSelectInput
                                id="inputSelect_supplementaryData_educationLevel"
                                label={`${I18N_PREFIX}.input.education.label`}
                                value={supplementaryData?.education ?? ''}
                                onChange={education =>
                                    handleChange({ supplementaryData: { ...supplementaryData, education: EducationType[education] } })
                                }
                                mapperFromString={_value => (_value ? EducationType[_value] : undefined)}
                                placeholder={`${I18N_PREFIX}.input.education.placeholder`}
                            >
                                <MenuItem value={EducationType.ELEMENTARY_SCHOOL}>
                                    {t(`${I18N_PREFIX}.input.education.options.ELEMENTARY_SCHOOL`)}
                                </MenuItem>
                                <MenuItem value={EducationType.HIGH_SCHOOL}>{t(`${I18N_PREFIX}.input.education.options.HIGH_SCHOOL`)}</MenuItem>
                                <MenuItem value={EducationType.UNIVERSITY_EDUCATION}>
                                    {t(`${I18N_PREFIX}.input.education.options.UNIVERSITY_EDUCATION`)}
                                </MenuItem>
                                <MenuItem value={EducationType.TECHNICAL_EDUCATION}>
                                    {t(`${I18N_PREFIX}.input.education.options.TECHNICAL_EDUCATION`)}
                                </MenuItem>
                                <MenuItem value={EducationType.POSTGRADUATE_EDUCATION}>
                                    {t(`${I18N_PREFIX}.input.education.options.POSTGRADUATE_EDUCATION`)}
                                </MenuItem>
                                <MenuItem value={EducationType.DOCTORATE_DEGREE}>
                                    {t(`${I18N_PREFIX}.input.education.options.DOCTORATE_DEGREE`)}
                                </MenuItem>
                                <MenuItem value={EducationType.MASTER_DEGREE}>
                                    {t(`${I18N_PREFIX}.input.education.options.MASTER_DEGREE`)}
                                </MenuItem>
                            </SimpleSelectInput>
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleTextInput
                                id="inputText_supplementaryData_birthCity"
                                onChange={birthCity => handleChange({ supplementaryData: { ...supplementaryData, birthCity } })}
                                value={supplementaryData?.birthCity ?? ''}
                                label={`${I18N_PREFIX}.input.cityBirth.label`}
                                placeholder={`${I18N_PREFIX}.input.cityBirth.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleTextInput
                                id="inputText_supplementaryData_birthState"
                                onChange={birthState => handleChange({ supplementaryData: { ...supplementaryData, birthState } })}
                                value={supplementaryData?.birthState ?? ''}
                                label={`${I18N_PREFIX}.input.stateBirth.label`}
                                placeholder={`${I18N_PREFIX}.input.stateBirth.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleTextInput
                                id="inputText_supplementaryData_nationality"
                                onChange={nationality => handleChange({ supplementaryData: { ...supplementaryData, nationality } })}
                                value={supplementaryData?.nationality ?? ''}
                                label={`${I18N_PREFIX}.input.nationality.label`}
                                placeholder={`${I18N_PREFIX}.input.nationality.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleTextInput
                                id="inputText_supplementaryData_mothersName"
                                onChange={mothersName => handleChange({ supplementaryData: { ...supplementaryData, mothersName } })}
                                value={supplementaryData?.mothersName ?? ''}
                                label={`${I18N_PREFIX}.input.mothersName.label`}
                                placeholder={`${I18N_PREFIX}.input.mothersName.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleTextInput
                                id="inputText_supplementaryData_fathersName"
                                onChange={fathersName => handleChange({ supplementaryData: { ...supplementaryData, fathersName } })}
                                value={supplementaryData?.fathersName ?? ''}
                                label={`${I18N_PREFIX}.input.fathersName.label`}
                                placeholder={`${I18N_PREFIX}.input.fathersName.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleSelectInput
                                id="inputSelect_supplementaryData_maritalStatus"
                                label={`${I18N_PREFIX}.input.maritalStatus.label`}
                                value={supplementaryData?.maritalStatus ?? ''}
                                onChange={handleSelectMaritalStatus}
                                mapperFromString={_value => (_value ? MaritalStatus[_value] : undefined)}
                                placeholder={`${I18N_PREFIX}.input.maritalStatus.placeholder`}
                            >
                                <MenuItem value={MaritalStatus.SINGLE}>{t(`${I18N_PREFIX}.input.maritalStatus.options.SINGLE`)}</MenuItem>
                                <MenuItem value={MaritalStatus.MARRIED}>{t(`${I18N_PREFIX}.input.maritalStatus.options.MARRIED`)}</MenuItem>
                                <MenuItem value={MaritalStatus.DIVORCED}>{t(`${I18N_PREFIX}.input.maritalStatus.options.DIVORCED`)}</MenuItem>
                                <MenuItem value={MaritalStatus.SEPARATED}>{t(`${I18N_PREFIX}.input.maritalStatus.options.SEPARATED`)}</MenuItem>
                                <MenuItem value={MaritalStatus.WIDOWED}>{t(`${I18N_PREFIX}.input.maritalStatus.options.WIDOWED`)}</MenuItem>
                            </SimpleSelectInput>
                        </div>
                        {supplementaryData?.maritalStatus === MaritalStatus.MARRIED && (
                            <>
                                <div className="form__propose-employee-step--input">
                                    <SimpleTextInput
                                        id="inputText_supplementaryData_spouseName"
                                        onChange={spouseName => handleChange({ supplementaryData: { ...supplementaryData, spouseName } })}
                                        value={supplementaryData?.spouseName ?? ''}
                                        label={`${I18N_PREFIX}.input.spouseName.label`}
                                        placeholder={`${I18N_PREFIX}.input.spouseName.placeholder`}
                                    />
                                </div>
                                <div className="form__propose-employee-step--input">
                                    <SimpleLocalDateInput
                                        id="inputDate_supplementaryData_spouseBirthDate"
                                        onChange={spouseBirthDate =>
                                            handleChange({ supplementaryData: { ...supplementaryData, spouseBirthDate } })
                                        }
                                        value={supplementaryData?.spouseBirthDate ?? ''}
                                        label={`${I18N_PREFIX}.input.spouseBirthDate.label`}
                                        placeholder={`${I18N_PREFIX}.input.spouseBirthDate.placeholder`}
                                    />
                                </div>
                                <div className="form__propose-employee-step--input">
                                    <ValidCpfInput
                                        id="inputText_supplementaryData_spouseIdentification"
                                        validate={validateSupplementaryDataSpouseIdentification}
                                        showValidation
                                        onChange={spouseIdentification =>
                                            handleChange({ supplementaryData: { ...supplementaryData, spouseIdentification } })
                                        }
                                        value={supplementaryData?.spouseIdentification ?? ''}
                                        label={`${I18N_PREFIX}.input.spouseIdentification.label`}
                                        placeholder={`${I18N_PREFIX}.input.spouseIdentification.placeholder`}
                                        externalUpdate
                                    />
                                </div>
                            </>
                        )}
                        <div className="form__propose-employee-step--input">
                            <SimpleCheckboxInput
                                id="inputCheckbox_supplementaryData_hasDependents"
                                value={checkbox}
                                label={`${I18N_PREFIX}.input.hasDependents.label`}
                                onChange={handleCheckBox}
                                externalUpdate
                            />
                        </div>
                        {checkbox && (
                            <div className="form__propose-employee-step--input">
                                <SimpleNumberInput
                                    id="inputText_supplementaryData_dependentsNumber"
                                    label={`${I18N_PREFIX}.input.dependentsNumber.label`}
                                    placeholder={`${I18N_PREFIX}.input.dependentsNumber.placeholder`}
                                    value={supplementaryData?.dependentsNumber!}
                                    onChange={dependentsNumber =>
                                        handleChange({ supplementaryData: { ...supplementaryData, dependentsNumber } })
                                    }
                                    MaskInputComponent={DependentsNumberMask}
                                    externalUpdate
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ProposeEmployeeSupplementaryDataStep;
