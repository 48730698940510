import { AxiosResponse } from 'axios';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { employeeBorrowerProposeConfirmError, employeeBorrowerProposeConfirmRequest, employeeBorrowerProposeConfirmSuccess } from './actions';
import { EmployeeBorrowerProposeConfirmActionTypes } from './types';

function* handleEmployeeBorrowerProposeConfirm(action: ReturnType<typeof employeeBorrowerProposeConfirmRequest>) {
    try {
        const result: AxiosResponse<ProposeEmployeeSimple> = yield call(proposeApi.confirmProposeRequest, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerProposeConfirmError(mapped));
            return;
        }
        yield put(employeeBorrowerProposeConfirmSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerProposeConfirmError(mapped));
    }
}

function* watchEmployeeBorrowerProposeConfirm() {
    yield takeLeading(EmployeeBorrowerProposeConfirmActionTypes.EMPLOYEE_BORROWER_PROPOSE_CONFIRM_REQUEST, handleEmployeeBorrowerProposeConfirm);
}

function* employeeBorrowerProposeConfirmSaga() {
    yield all([fork(watchEmployeeBorrowerProposeConfirm)]);
}

export default employeeBorrowerProposeConfirmSaga;
