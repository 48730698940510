import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum UpdateEmployeeBorrowerProposeGeneralDataActionTypes {
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_REQUEST = '@@updateEmployeeBorrowerProposeGeneralData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_REQUEST',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_SUCCESS = '@@updateEmployeeBorrowerProposeGeneralData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_SUCCESS',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_ERROR = '@@updateEmployeeBorrowerProposeGeneralData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_ERROR',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_RESET_STATE = '@@updateEmployeeBorrowerProposeGeneralData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_RESET_STATE',
}

export interface UpdateEmployeeBorrowerProposeGeneralDataState extends BaseStoreState {
    readonly proposeGeneralData?: ProposeEmployeeSimple;
}
