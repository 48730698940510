import Button from '@material-ui/core/Button';
import { InputAdornmentTypeMap } from '@material-ui/core/InputAdornment';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import './button-back.scss';

export interface ButtonBackProps {
    label: string;
    onClick: () => void;
    style?: any;
    adornment?: OverridableComponent<InputAdornmentTypeMap>;
}

export const ButtonBack = (props: ButtonBackProps): React.JSX.Element => {
    return (
        <div className="button__style--back" {...props}>
            <Button variant="contained" onClick={props.onClick}>
                <span> {props.adornment} </span>
                <span> {props.label} </span>
            </Button>
        </div>
    );
};

export default ButtonBack;
