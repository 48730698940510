import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { UpdateEmployeeBorrowerProposeAccountActionTypes, UpdateEmployeeBorrowerProposeAccountState } from './types';

export const initialState: UpdateEmployeeBorrowerProposeAccountState = {
    status: HttpRequestStatus.NOOP,
    proposeAccount: undefined,
    error: undefined
};

const reducer: Reducer<UpdateEmployeeBorrowerProposeAccountState> = (state = initialState, action): UpdateEmployeeBorrowerProposeAccountState => {
    switch (action.type) {
        case UpdateEmployeeBorrowerProposeAccountActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case UpdateEmployeeBorrowerProposeAccountActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, proposeAccount: action.payload, error: undefined };
        }
        case UpdateEmployeeBorrowerProposeAccountActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case UpdateEmployeeBorrowerProposeAccountActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as updateEmployeeBorrowerProposeAccountReducer };
