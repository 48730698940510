import { EMAIL_REGEX, PHONE_PIX_REGEX } from 'config/constants';
import { ProposeEmployeeAccountData } from 'model/employee-borrower/propose/account.model';
import { PixKeyType } from 'model/enums/pix-key-type.model';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'employee-borrower.propose.account.input.pixKey.validation';

interface PixKeyValidation {
    pixKey?: string;
    pixKeyType?: PixKeyType;
}

const validatePixKeyByType = (params: PixKeyValidation | null | undefined): boolean => {
    if (!params?.pixKey || !params.pixKeyType) {
        return false
    }

    const validations: Record<PixKeyType, () => boolean> = {
        CORPORATION: () => ValidationUtils.STRING.isValidCNPJ(params?.pixKey),
        INDIVIDUAL: () => ValidationUtils.STRING.isValidCPF(params?.pixKey),
        EMAIL: () => EMAIL_REGEX.test(params?.pixKey as string),
        PHONE_NUMBER: () => PHONE_PIX_REGEX.test(params?.pixKey as string),
        EVP: () => !!ValidationUtils.STRING.maxLength(70)
    }

    return validations[params.pixKeyType]()
}

const PIX_KEY_VALIDATION: Validations<PixKeyValidation | undefined | null> = {
    required: {
        predicate: it => ValidationUtils.STRING.isNotBlank(it?.pixKey),
        errorMessage: `${I18N_PREFIX}.required`
    },
    maxLength: {
        predicate: it => !!it?.pixKey && it?.pixKey.length <= 70,
        errorMessage: `${I18N_PREFIX}.maxLength`
    },
    byType: {
        predicate: (it) => validatePixKeyByType(it),
        errorMessage: `${I18N_PREFIX}.invalidPixKey`
    }
}

export const validatePixKey = (value: PixKeyValidation | undefined | null) => ValidationUtils.validate(value, PIX_KEY_VALIDATION)

export const validatePix = (value: ProposeEmployeeAccountData | undefined | null) => {
    if (!value) {
        return false
    }

    if (!value.accountData?.pixKeyType) return true;

    const pixKeyValidation: PixKeyValidation = {
        pixKey: value.accountData?.pixKey,
        pixKeyType: PixKeyType[value.accountData?.pixKeyType]
    }

    return (
        validatePixKey(pixKeyValidation).isValid
    )
}
