import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './clear-button.scss';

const I18N_PREFIX = 'entity.action';

export interface ClearButtonProps {
    onClick?: () => void;
}

export const ClearButton = (props: ClearButtonProps): React.JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="button__style--clear">
            <Button variant="contained" onClick={props.onClick}>
                <span>{t(`${I18N_PREFIX}.clear`)} </span>
            </Button>
        </div>
    );
};

export default ClearButton;
