import { AxiosResponse } from 'axios';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { updateEmployeeBorrowerProposeManagerError, updateEmployeeBorrowerProposeManagerRequest, updateEmployeeBorrowerProposeManagerSuccess, } from './actions';
import { UpdateEmployeeBorrowerProposeManagerActionTypes } from './types';

function* handleUpdateEmployeeBorrowerProposeManager(action: ReturnType<typeof updateEmployeeBorrowerProposeManagerRequest>) {
    try {
        const result: AxiosResponse<ProposeEmployeeSimple> = yield call(proposeApi.updateProposeManagerRequest, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(updateEmployeeBorrowerProposeManagerError(mapped));
            return;
        }
        yield put(updateEmployeeBorrowerProposeManagerSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateEmployeeBorrowerProposeManagerError(mapped));
    }
}

function* watchUpdateEmployeeBorrowerProposeManager() {
    yield takeLeading(UpdateEmployeeBorrowerProposeManagerActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_REQUEST, handleUpdateEmployeeBorrowerProposeManager);
}

function* updateEmployeeBorrowerProposeManagerSaga() {
    yield all([fork(watchUpdateEmployeeBorrowerProposeManager)]);
}

export default updateEmployeeBorrowerProposeManagerSaga;
