import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './filter-button.scss';

export interface FilterButtonProps {
    onClick: () => void;
    label?: string;
    disabled?: boolean;
    icon?: React.ReactFragment;
    isActiveFilter?: boolean;
}

const FilterButton = (props: FilterButtonProps): React.JSX.Element => {
    const { t } = useTranslation();
    const { disabled, onClick, label, icon, isActiveFilter } = props;

    return (
        <div className={isActiveFilter ? 'button__style--filter-active' : 'button__style--filter'}>
            <Button
                variant="contained"
                onClick={onClick}
                startIcon={
                    icon ? (
                        icon
                    ) : (
                        <Icon>
                            <FilterListIcon />
                        </Icon>
                    )
                }
                disabled={disabled}
            >
                <span className="button__style--filter-label">{label ? label : t('entity.action.filter')}</span>
            </Button>
        </div>
    );
};

export default FilterButton;
