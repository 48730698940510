import { AxiosResponse } from 'axios';
import { ProposeEmployeeBankAccount } from 'model/employee-borrower/propose/account.model';
import { api } from 'services/api/api';
import { Page } from 'services/page';

const BASE_URL = '/api/payroll-loan/employee';

export const employeeBorrowerBankAccount = () => {
    const bankAccountsRequest = (): Promise<AxiosResponse<Page<ProposeEmployeeBankAccount>>> => {
        return api.get<Page<ProposeEmployeeBankAccount>>(`${BASE_URL}/bank-accounts`);
    };

    return {
        bankAccountsRequest,
    };
};

export default employeeBorrowerBankAccount();
