import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { EmployeeBorrowerAvailableServicesActionTypes, EmployeeBorrowerAvailableServicesState } from './types';

export const initialState: EmployeeBorrowerAvailableServicesState = {
    status: HttpRequestStatus.NOOP,
    availableServices: undefined,
    error: undefined,
};

const reducer: Reducer<EmployeeBorrowerAvailableServicesState> = (state = initialState, action): EmployeeBorrowerAvailableServicesState => {
    switch (action.type) {
        case EmployeeBorrowerAvailableServicesActionTypes.EMPLOYEE_BORROWER_AVAILABLE_SERVICES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeBorrowerAvailableServicesActionTypes.EMPLOYEE_BORROWER_AVAILABLE_SERVICES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, availableServices: action.payload, error: undefined };
        }
        case EmployeeBorrowerAvailableServicesActionTypes.EMPLOYEE_BORROWER_AVAILABLE_SERVICES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeBorrowerAvailableServicesActionTypes.EMPLOYEE_BORROWER_AVAILABLE_SERVICES_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeBorrowerAvailableServicesReducer };
