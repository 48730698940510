import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes, UpdateEmployeeBorrowerProposeDocumentFileUploadState } from './types';

export const initialState: UpdateEmployeeBorrowerProposeDocumentFileUploadState = {
    status: HttpRequestStatus.NOOP,
    proposeDocumentFileUpload: undefined,
    error: undefined
};

const reducer: Reducer<UpdateEmployeeBorrowerProposeDocumentFileUploadState> = (state = initialState, action): UpdateEmployeeBorrowerProposeDocumentFileUploadState => {
    switch (action.type) {
        case UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, proposeDocumentFileUpload: action.payload, error: undefined };
        }
        case UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateEmployeeBorrowerProposeDocumentFileUploadReducer };
