import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import clsx from 'clsx';
import React from 'react';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import CpfMask from './cpf-mask';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;
type BaseValidation = Omit<ValidInputProps<string>, 'children'>;
type Base = BaseInput & BaseValidation;

export interface ValidCpfInputProps extends Base {
    onChange: (value: string | undefined) => void;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
}

export const ValidCpfInput = (props: ValidCpfInputProps) => {
    const { validate, showValidation, value, MaskInputComponent, onChange, ...otherProps } = props;

    const handleChange = (_value: string) => {
        const normalized = InputUtils.removeNonDigits(_value);
        
        onChange(normalized);
    };

    return (
        <ValidInput value={value} validate={validate} showValidation={showValidation} className={clsx('base-input', { dirty: value })}>
            {({ error }) => (
                <BaseMaskInput
                    {...otherProps}
                    error={error}
                    value={value}
                    onChange={handleChange}
                    MaskInputComponent={MaskInputComponent ?? CpfMask}
                />
            )}
        </ValidInput>
    );
};

export default ValidCpfInput;