import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import React from 'react';
import MaskedInput from 'react-text-mask';

const DATE_MASK = (value: string) => {
    let dayMask = [/[0-3]/, /\d/];
    let monthMask: RegExp[] | [] = [];
    let yearMask: RegExp[] | [] = [];

    const day = `${value[0]}${value[1]}`;
    const month = `${value[2]}${value[3]}`;
    const year = `${value[5]}${value[6]}${value[7]}${value[8]}`;

    if (day.startsWith('3')) {
        dayMask = [/[3]/, /[0|1]/];
    }

    if (month.startsWith('0') || month[1].startsWith('0')) {
        monthMask = [/[0]/, /[1-9]/];
    } else if (month.startsWith('1') || month[1].startsWith('1')) {
        monthMask = [/[1]/, /[0-2]/];
    }
    if (year.startsWith('1') || year[1].startsWith('1')) {
        yearMask = [/[1]/, /[9]/, /[5-9]/, /\d/];
    } else if (year.startsWith('2') || year[1].startsWith('2')) {
        yearMask = [/[2]/, /[0]/, /[0|1|2]/, /\d/];
    }

    return [...dayMask, '/', ...monthMask, '/', ...yearMask];
};

export const LocalDateInputMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={DATE_MASK} guide={false} />;
};

export default LocalDateInputMask;
