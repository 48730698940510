import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum UpdateEmployeeBorrowerProposeManagerActionTypes {
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_REQUEST = '@@updateEmployeeBorrowerProposeManager/UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_REQUEST',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_SUCCESS = '@@updateEmployeeBorrowerProposeManager/UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_SUCCESS',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_ERROR = '@@updateEmployeeBorrowerProposeManager/UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_ERROR',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_RESET_STATE = '@@updateEmployeeBorrowerProposeManager/UPDATE_EMPLOYEE_BORROWER_PROPOSE_MANAGER_RESET_STATE',
}

export interface UpdateEmployeeBorrowerProposeManagerState extends BaseStoreState {
    readonly proposeManager?: ProposeEmployeeSimple;
}
