import { ProposeEmployeeManagerInfo } from 'model/employee-borrower/propose/manager.model';
import { BaseStoreState } from '../../baseStoreState';

export enum ManagerInfoActionTypes {
    MANAGER_INFO_REQUEST = '@@managerInfo/MANAGER_INFO_REQUEST',
    MANAGER_INFO_SUCCESS = '@@managerInfo/MANAGER_INFO_SUCCESS',
    MANAGER_INFO_ERROR = '@@managerInfo/MANAGER_INFO_ERROR',
    MANAGER_INFO_RESET_STATE = '@@managerInfo/MANAGER_INFO_RESET_STATE',
}

export interface ManagerInfoState extends BaseStoreState {
    readonly data?: ProposeEmployeeManagerInfo;
}
