import { ReactComponent as NoActiveProposeIcon } from 'images/no-active-propose-icon.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './card-no-active-propose.scss';

const I18N_PREFIX = 'dashboard.card';

export const CardNoActivePropose = () => {
    const { t } = useTranslation();

    return (
        <div className="card-no-active-propose__container">
            <div className={'card-no-active-propose__container--info'}>
                <div className="card-no-active-propose__container--title">
                    <h3>
                        <NoActiveProposeIcon className={'card-no-active-propose__container--title__icon'} />
                    </h3>
                </div>

                <div className="card-no-active-propose__container--no-propose">
                    <h4> {t(`${I18N_PREFIX}.no-active-propose`)} </h4>
                </div>
            </div>
        </div>
    );
};

export default CardNoActivePropose;
