import { IEmployeeTimeEntry } from '../../model/employee-time-entry.model';
import { BaseStoreState } from '../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeTimeEntryActionTypes {
    EMPLOYEE_TIME_ENTRY_REQUEST = '@@employeeTimeEntry/EMPLOYEE_TIME_ENTRY_REQUEST',
    EMPLOYEE_TIME_ENTRY_SUCCESS = '@@employeeTimeEntry/EMPLOYEE_TIME_ENTRY_SUCCESS',
    EMPLOYEE_TIME_ENTRY_ERROR = '@@employeeTimeEntry/EMPLOYEE_TIME_ENTRY_ERROR',
    EMPLOYEE_TIME_ENTRY_RESET_STATE = '@@employeeTimeEntry/EMPLOYEE_TIME_ENTRY_RESET_STATE',
}

export interface EmployeeTimeEntryState extends BaseStoreState {
    readonly employeeTimeEntries?: IEmployeeTimeEntry[];
}
