import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { EmployeeBorrowerProposeSummaryActionTypes, EmployeeBorrowerProposeSummaryState } from './types';

export const initialState: EmployeeBorrowerProposeSummaryState = {
    status: HttpRequestStatus.NOOP,
    proposeSummary: undefined,
    error: undefined
};

const reducer: Reducer<EmployeeBorrowerProposeSummaryState> = (state = initialState, action): EmployeeBorrowerProposeSummaryState => {
    switch (action.type) {
        case EmployeeBorrowerProposeSummaryActionTypes.EMPLOYEE_BORROWER_PROPOSE_SUMMARY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeBorrowerProposeSummaryActionTypes.EMPLOYEE_BORROWER_PROPOSE_SUMMARY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, proposeSummary: action.payload, error: undefined };
        }
        case EmployeeBorrowerProposeSummaryActionTypes.EMPLOYEE_BORROWER_PROPOSE_SUMMARY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeBorrowerProposeSummaryActionTypes.EMPLOYEE_BORROWER_PROPOSE_SUMMARY_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeBorrowerProposeSummaryReducer };
