import { ProposeEmployeeSearchManagers } from 'model/employee-borrower/propose/manager.model';
import { GiroWebappError } from 'model/error';
import { SearchRequest } from 'model/reducers';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';
import { EmployeeBorrowerAnalystSearchManagersActionTypes } from './types';

export const employeeBorrowerAnalystSearchManagersRequest = (search: SearchRequest) => 
action(EmployeeBorrowerAnalystSearchManagersActionTypes.SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_REQUEST, search);

export const employeeBorrowerAnalystSearchManagersSuccess = (managers: Page<ProposeEmployeeSearchManagers>) => 
action(EmployeeBorrowerAnalystSearchManagersActionTypes.SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_SUCCESS, managers);

export const employeeBorrowerAnalystSearchManagersError = (error: GiroWebappError) => 
action(EmployeeBorrowerAnalystSearchManagersActionTypes.SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_ERROR, error);

export const employeeBorrowerAnalystSearchManagersResetState = () => 
action(EmployeeBorrowerAnalystSearchManagersActionTypes.SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_RESET_STATE);
