import { ProposeEmployeeSearchManagers } from 'model/employee-borrower/propose/manager.model';
import { BaseStoreState } from 'reducer/baseStoreState';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum EmployeeBorrowerAnalystSearchManagersActionTypes {
    SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_REQUEST  = '@@employeeBorrowerAnalystSearchManagers/SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_REQUEST',
    SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_SUCCESS = '@@employeeBorrowerAnalystSearchManagers/SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_SUCCESS',
    SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_ERROR = '@@employeeBorrowerAnalystSearchManagers/SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_ERROR',
    SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_RESET_STATE = '@@employeeBorrowerAnalystSearchManagers/SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_RESET_STATE',
}

export interface EmployeeBorrowerAnalystSearchManagersState extends BaseStoreState {
    readonly managers?: Page<ProposeEmployeeSearchManagers>;
}
