import { ProposeEmployeeHistoryFilterPageable, HistoryListProposeEmployee } from 'model/employee-history-proposes';
import { action } from 'typesafe-actions';
import { GiroWebappError } from 'model/error';
import { HistoryEmployeeProposesTypes } from './types';
import { Page } from 'services/page';

export const listHistoryEmployeeProposesRequest = (request: ProposeEmployeeHistoryFilterPageable) => action(HistoryEmployeeProposesTypes.HISTORY_LIST_EMPLOYEE_PROPOSES_REQUEST, request);
export const listHistoryEmployeeProposesSuccess = (data: Page<HistoryListProposeEmployee>) => action(HistoryEmployeeProposesTypes.HISTORY_LIST_EMPLOYEE_PROPOSES_SUCCESS, data);
export const listHistoryEmployeeProposesError = (error: GiroWebappError) => action(HistoryEmployeeProposesTypes.HISTORY_LIST_EMPLOYEE_PROPOSES_ERROR, error);
export const listHistoryEmployeeProposesResetState = () => action(HistoryEmployeeProposesTypes.HISTORY_LIST_EMPLOYEE_PROPOSES_RESET_STATE);
