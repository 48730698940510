import { useEmployeeCompanyDefaultState } from '../../reducer/hooks';
import { useIsHeaderEmployeeLoading } from './header-employee-is-loading';
import { useDoesHeaderEmployeeHasError } from './header-employeer-has-error';

export const useIsCompanySelectionRequired = () => {
    const companyState = useEmployeeCompanyDefaultState();

    const isLoading = useIsHeaderEmployeeLoading();
    const hasError = useDoesHeaderEmployeeHasError();

    return !isLoading && !hasError && !companyState.company?.id;
};
