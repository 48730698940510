import { action } from 'typesafe-actions';
import { IEmployeeProgram } from '../../model/employee-program.model';
import { GiroWebappError } from '../../model/error';
import { Page } from '../../services/page';
import { EmployeeProgramActionTypes } from './types';

export const employeeProgramRequest = (request: IEmployeeProgram) => action(EmployeeProgramActionTypes.EMPLOYEE_PROGRAM_REQUEST, request);
export const employeeProgramSuccess = (credits: Page<IEmployeeProgram>) =>
    action(EmployeeProgramActionTypes.EMPLOYEE_PROGRAM_SUCCESS, credits);
export const employeeProgramError = (error: GiroWebappError) => action(EmployeeProgramActionTypes.EMPLOYEE_PROGRAM_ERROR, error);

export const employeeProgramResetState = () => action(EmployeeProgramActionTypes.EMPLOYEE_PROGRAM_RESET_STATE);
