import { Grid } from '@material-ui/core';
import IdentificationFormatter from 'components/format/identification/Identification';
import { LocalDateFormatter, LocalDateFormatType } from 'components/format/local-date/local-date-formatter';
import CardProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-propose-employee-summary';
import ReadOnlyFieldProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/read-only-field-propose-employee-summary/read-only-field-propose-employee-summary';
import { ProposeEmployeeSupplementaryDataSummary } from 'model/employee-borrower/propose/supplementary-data.model';
import { PersonType } from 'model/enums/person-type';
import React from 'react';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'employee-borrower.propose.supplementaryData';

interface CardSupplementaryDataSummaryProps {
    supplementaryData?: ProposeEmployeeSupplementaryDataSummary;
    onClick: () => void;
    disabled?: boolean;
}

const CardSupplementaryDataSummary = (props: CardSupplementaryDataSummaryProps) => {
    const { supplementaryData, onClick, disabled } = props;

    const { t } = useTranslation();

    return (
        <CardProposeEmployeeSummary disabled={disabled} title={t(`${I18N_PREFIX}.subtitle`)} onClick={onClick}>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={t(`${I18N_PREFIX}.input.education.options.${supplementaryData?.education}`)}
                    label={t(`${I18N_PREFIX}.input.education.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={supplementaryData?.birthState ?? '-'}
                    label={t(`${I18N_PREFIX}.input.stateBirth.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={supplementaryData?.birthCity ?? '-'}
                    label={t(`${I18N_PREFIX}.input.cityBirth.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={supplementaryData?.mothersName}
                    label={t(`${I18N_PREFIX}.input.mothersName.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={supplementaryData?.fathersName}
                    label={t(`${I18N_PREFIX}.input.fathersName.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={t(`${I18N_PREFIX}.input.maritalStatus.options.${supplementaryData?.maritalStatus}`)}
                    label={t(`${I18N_PREFIX}.input.maritalStatus.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={supplementaryData?.spouseName}
                    label={t(`${I18N_PREFIX}.input.spouseName.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={
                        supplementaryData?.spouseBirthDate ? (
                            <LocalDateFormatter value={supplementaryData?.spouseBirthDate} type={LocalDateFormatType.LONG_FULL_DATE} />
                        ) : undefined
                    }
                    label={t(`${I18N_PREFIX}.input.spouseBirthDate.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={
                        supplementaryData?.spouseIdentification ? (
                            <IdentificationFormatter value={supplementaryData?.spouseIdentification} type={PersonType.INDIVIDUAL} />
                        ) : (
                            '-'
                        )
                    }
                    label={t(`${I18N_PREFIX}.input.spouseIdentification.label`)}
                />
            </Grid>
            
        </CardProposeEmployeeSummary>
    );
};

export default CardSupplementaryDataSummary;
