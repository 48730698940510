import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { PersonSimple } from '../../../model/person';
import accountApi from '../../../services/api/accountApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { acceptTermsError, acceptTermsSuccess } from './actions';
import { AcceptTermsActionTypes } from './types';

function* handleAcceptTerms() {
    try {
        const result: AxiosResponse<PersonSimple> = yield call(accountApi.acceptTerms);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(acceptTermsError(mapped));
            return;
        }
        yield put(acceptTermsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(acceptTermsError(mapped));
    }
}

function* watchAcceptTerms() {
    yield takeEvery(AcceptTermsActionTypes.ACCEPT_TERMS_REQUEST, handleAcceptTerms);
}

function* acceptTermsSaga() {
    yield all([fork(watchAcceptTerms)]);
}

export default acceptTermsSaga;
