import { AxiosResponse } from 'axios';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { employeeBorrowerProposeRecentError, employeeBorrowerProposeRecentSuccess } from './actions';
import { EmployeeBorrowerProposeRecentActionTypes } from './types';

function* handleEmployeeBorrowerProposeRecent() {
    try {
        const result: AxiosResponse<ProposeEmployeeSimple> = yield call(proposeApi.proposeRecentRequest);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerProposeRecentError(mapped));
            return;
        }
        yield put(employeeBorrowerProposeRecentSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerProposeRecentError(mapped));
    }
}

function* watchEmployeeBorrowerProposeRecent() {
    yield takeLeading(EmployeeBorrowerProposeRecentActionTypes.EMPLOYEE_BORROWER_PROPOSE_RECENT_REQUEST, handleEmployeeBorrowerProposeRecent);
}

function* employeeBorrowerProposeRecentSaga() {
    yield all([fork(watchEmployeeBorrowerProposeRecent)]);
}

export default employeeBorrowerProposeRecentSaga;
