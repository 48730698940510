import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ForgotPasswordActionTypes, ForgotPasswordState } from './types';

export const initialState: ForgotPasswordState = {
    status: HttpRequestStatus.NOOP,
    user: undefined,
    error: undefined,
};

const reducer: Reducer<ForgotPasswordState> = (state = initialState, action): ForgotPasswordState => {
    switch (action.type) {
        case ForgotPasswordActionTypes.FORGOT_PASSWORD_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, user: undefined, error: undefined };
        }
        case ForgotPasswordActionTypes.FORGOT_PASSWORD_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, user: action.payload, error: undefined };
        }
        case ForgotPasswordActionTypes.FORGOT_PASSWORD_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ForgotPasswordActionTypes.FORGOT_PASSWORD_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as forgotPasswordReducer };
