import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'reactstrap/lib/Card';
import Container from 'reactstrap/lib/Container';
import FieldPassword from '../../../../components/field-password/field-password';
import Loading from '../../../../components/loading/loading';
import PasswordStrength from '../../../../components/password-strength/password-strength';
import { useProfileContext } from '../../profile-context';
import { PasswordMatch, validatePassword, validatePasswordMatch } from '../../validation/validation';
import './profile-password-editing.scss';

const mapPasswordMatch = (p: string, m: string): PasswordMatch => ({ password: p, match: m });

export const ProfilePasswordEditing = () => {
    const { t } = useTranslation();
    const {
        showValidation,
        changePassword,
        updatePasswordSuccess,
        isUpdatingPassword,
        handlePasswordChange,
        messageVisibility,
        setMessageVisibility,
    } = useProfileContext();

    React.useEffect(() => {
        updatePasswordSuccess && setMessageVisibility(true);
    }, [updatePasswordSuccess, setMessageVisibility]);

    const closeMessage = () => {
        setMessageVisibility(false);
    };

    return (
        <div className="profile-editing__items">
            <div className="container">
                <Grid id="password">
                    <Grid item className="personal-data__items--title">
                        {t('profile.user.password.title')}
                    </Grid>
                    <Grid className="profile-editing__items--fields">
                        <Grid item xs={7}>
                            <FieldPassword
                                id="inputText_profile_currentPassword"
                                label={t('profile.user.password.label')}
                                value={''}
                                onChange={currentPassword => handlePasswordChange({ currentPassword })}
                                validate={validatePassword}
                                showValidation={showValidation}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid>
                            <Grid item xs={7}>
                                <FieldPassword
                                    id="inputText_profile_newPassword"
                                    label={t('profile.user.password.new-password.label')}
                                    value={''}
                                    onChange={newPassword => handlePasswordChange({ newPassword })}
                                    validate={validatePassword}
                                    showValidation={showValidation}
                                    autoComplete="new-password"
                                />
                            </Grid>
                            <Grid item xs={5} className="profile-editing__items--fields-block">
                                <Card className="profile-editing__items--fields-card">
                                    <div className="profile-editing__items--fields-card-text">
                                        {t('profile.user.password.strength').toUpperCase()}
                                    </div>
                                    <PasswordStrength password={changePassword.newPassword} />
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid className="confirm-password">
                            <Grid item xs={7}>
                                <FieldPassword
                                    id="inputText_profile_newPasswordConfirm"
                                    label={t('profile.user.password.password-confirm.label')}
                                    value={''}
                                    onChange={newPasswordConfirm => handlePasswordChange({ newPasswordConfirm })}
                                    validate={it => validatePasswordMatch(mapPasswordMatch(changePassword.newPassword, it))}
                                    showValidation={showValidation}
                                    autoComplete="new-password"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {isUpdatingPassword && (
                    <div className="personal-data__items--loading">
                        <Loading />
                    </div>
                )}
                {messageVisibility && (
                    <div className="profile-editing__items--message" onClick={closeMessage}>
                        <Container className="profile-editing__items--message-container">
                            <Card className="profile-editing__items--message-card">
                                <div className="profile-editing__items--message-block">
                                    <div className="profile-editing__items--message-block-positive" />
                                    <div className="profile-editing__items--message-block-text">
                                        <span>{t('profile.update.first')}</span>
                                        <span>{t('profile.update.success')}</span>
                                    </div>
                                    <div className="profile-editing__items--message-block-close" onClick={closeMessage} />
                                </div>
                            </Card>
                        </Container>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePasswordEditing;
