import ButtonNext from 'components/buttons/button-next/button-next';
import Card from 'components/card/card';
import FieldBasic from 'components/field-basic/field-basic';
import { InviteToCreateProvider } from 'model/invite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { useEmailContext, useValidationStateAwareness } from '../edit-email/edit-email-context';
import { StepsEnum, useSteps } from '../invite-steps/invite-steps-context';
import { CardVisibility, HIDE_CARDS, useIdentification } from './identification-context';
import './identification-step.scss';

export interface IdentificationUserProps {
    companyName: string;
    email: string;
    onChange: (change: Partial<InviteToCreateProvider>) => void;
}

export const IdentificationUser = (props: IdentificationUserProps): React.JSX.Element => {
    const steps = useSteps();
    const { t } = useTranslation();
    const { showCard, updateCards } = useIdentification();
    const emailContext = useEmailContext();

    const [showValidation, setValidation] = React.useState<boolean>(false);

    const { remoteUpdate } = useValidationStateAwareness();

    React.useEffect(() => {
        if (remoteUpdate) {
            if (emailContext.validateLocaly(props.email).isValid) {
                props.onChange({ email: props.email });
                steps.toNext();
            }
        }
    }, [remoteUpdate, steps, emailContext, props]);

    const handleEmailCardCLose = () => {
        updateCards(HIDE_CARDS, { EMAIL_CARD: CardVisibility.SEEN });
    };

    const handleNewEmailCardClose = () => {
        updateCards(HIDE_CARDS, { NEW_EMAIL_CARD: CardVisibility.SEEN });
    };

    const haltProgression = !emailContext.validateLocaly(props.email).isValid;

    const handleNext = async () => {
        setValidation(true);
        if (haltProgression) return;

        const emailValidation = emailContext.validateRemotely(props.email);
        if (emailValidation.isValid) {
            props.onChange({ email: props.email });
            steps.toNext();
        }
    };

    // TODO update after redux success / error

    const handleEditEmail = () => {
        steps.toStep(StepsEnum.EDIT_EMAIL);
    };

    return (
        <Row>
            <div className="body__identification">
                <Row>
                    <Col md="10" style={{ margin: 'auto' }}>
                        <div className="body__identification--title">
                            {props.companyName ? (
                                <>
                                    {t('accept-invite.identification.title.start')}
                                    <span>{props.companyName}</span>
                                    {t('accept-invite.identification.title.middle')}
                                    <span>{t('accept-invite.identification.title.end')}</span>
                                </>
                            ) : (
                                <>
                                    {t('accept-invite.identification.fallback.title.start')}
                                    <span>{t('accept-invite.identification.fallback.title.end')}</span>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
                <div className="body__identification--components">
                    <Col md="6" offset="3">
                        <form className="form__style" noValidate autoComplete="off">
                            <FieldBasic
                                label={t('accept-invite.identification.email.label')}
                                value={props.email?.toLowerCase() ?? ''}
                                autoFocus
                                readOnly
                                validate={emailContext.validateLocaly}
                                autoComplete="username"
                                showValidation={showValidation}
                            />
                            <div className="body__identification--email">
                                <span onClick={handleEditEmail}> {t('accept-invite.identification.email.edit-email')} </span>
                            </div>
                        </form>
                    </Col>
                    <Col md="3">
                        {
                            {
                                EMAIL_CARD: (
                                    <Card onClose={handleEmailCardCLose}>
                                        {t('accept-invite.identification.card.email.start')}
                                        <p>{t('accept-invite.identification.card.email.end')}</p>
                                    </Card>
                                ),
                                NEW_EMAIL_CARD: (
                                    <Card onClose={handleNewEmailCardClose}>{t('accept-invite.identification.card.new-email')}</Card>
                                ),
                                HIDE_CARDS: <></>,
                            }[showCard]
                        }
                    </Col>
                </div>
                <Col md="3">
                    <div className="container__button--second">
                        <ButtonNext onClick={handleNext} disabled={showValidation && haltProgression} isLoading={emailContext.isLoading} />
                    </div>
                </Col>
            </div>
        </Row>
    );
};

export default IdentificationUser;
