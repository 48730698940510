import { PersonType } from 'model/enums/person-type';
import React from 'react';
import FormatUtils from 'shared/util/format-utils';

export interface IdentificationFormatterProps {
    type?: PersonType;
    value?: string;
}

export const IdentificationFormatter = (props: IdentificationFormatterProps) => {
    return <>{FormatUtils.formatIdentification(props.type, props.value)}</>;
};

export default IdentificationFormatter;
