import { ProposeEmployeeDocumentData } from 'model/employee-borrower/propose/document.model';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const DOCUMENT_RG_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateDocumentRg = (value: string | undefined | null) => ValidationUtils.validate(value, DOCUMENT_RG_VALIDATION);

const DOCUMENT_CPF_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    valid: {
        predicate: ValidationUtils.STRING.ValidCPF,
        errorMessage: `employee-borrower.propose.documentData.input.cpf.error-message`
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateDocumentCpf = (value: string | undefined | null) => ValidationUtils.validate(value, DOCUMENT_CPF_VALIDATION);

const DOCUMENT_ISSUE_DATE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateDocumentIssueDate = (value: string | undefined | null) => ValidationUtils.validate(value, DOCUMENT_ISSUE_DATE_VALIDATION);

const DOCUMENT_ISSUING_AGENCY_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateDocumentIssuingAgency = (value: string | undefined | null) => ValidationUtils.validate(value, DOCUMENT_ISSUING_AGENCY_VALIDATION);

const DOCUMENT_AGENCY_ISSUING_ACRONYM_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(2),
        errorMessage: `employee-borrower.propose.documentData.input.agencyIssuingAcronym.error-message`
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(2),
        errorMessage: `employee-borrower.propose.documentData.input.agencyIssuingAcronym.error-message`
    },
};

export const validateDocumenAgencyIssuingAcronym = (value: string | undefined | null) => ValidationUtils.validate(value, DOCUMENT_AGENCY_ISSUING_ACRONYM_VALIDATION);


export const validateProposeEmployeeDocument = (value: ProposeEmployeeDocumentData | undefined): boolean => {
    if (!value) return true;
    return (
        validateDocumentRg(value.documentData?.rg).isValid &&
        validateDocumentCpf(value.documentData?.cpf).isValid &&
        validateDocumentIssueDate(value.documentData?.issueDate).isValid &&
        validateDocumentIssuingAgency(value.documentData?.issuingAgency).isValid &&
        validateDocumenAgencyIssuingAcronym(value?.documentData?.agencyIssuingAcronym).isValid
    );
};