import clsx from 'clsx';
import StandardButton from 'components/buttons/standard-button/standard-button';
import Currency from 'components/format/currency/Currency';
import { LocalDateFormatter, LocalDateFormatType } from 'components/format/local-date/local-date-formatter';
import { ReactComponent as PayrollloanIcon } from 'images/payrollloan-icon.svg';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { PayrollLoanProposeStatus } from 'model/enums/propose-status';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './card-payrollloan-recent-propose.scss';

interface CardPayrollLoanRecentProposeProps {
    proposeRecent: ProposeEmployeeSimple | undefined;
}

const I18N_PREFIX = 'dashboard.card.recent-propose.payroll-loan';

export const CardPayrollLoanRecentPropose = (props: CardPayrollLoanRecentProposeProps) => {
    const { proposeRecent } = props;
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className="card-payrollloan-recent-propose__container">
            <div className={'card-payrollloan-recent-propose__container--info'}>
                <div className="card-payrollloan-recent-propose__container--title">
                    <h3>
                        <PayrollloanIcon className={'card-payrollloan-recent-propose__container--title__icon'} />
                        {t(`${I18N_PREFIX}.title`)}
                    </h3>
                    <span className="card-payrollloan-recent-propose__container-status">
                        <span
                            className={clsx('card-payrollloan-recent-propose__container-status', {
                                HISTORY: proposeRecent?.status === PayrollLoanProposeStatus.HISTORY,
                                UNKNOWN_MANAGER: proposeRecent?.status === PayrollLoanProposeStatus.UNKNOWN_MANAGER,
                                MANAGER_REVIEW: proposeRecent?.status === PayrollLoanProposeStatus.MANAGER_REVIEW,
                                HUMAN_RESOURCES_REVIEW: proposeRecent?.status === PayrollLoanProposeStatus.HUMAN_RESOURCES_REVIEW,
                                OPERATIONAL_REVIEW: proposeRecent?.status === PayrollLoanProposeStatus.OPERATIONAL_REVIEW,
                                NEED_CHANGE: proposeRecent?.status === PayrollLoanProposeStatus.NEED_CHANGE,
                                INVALID_BANK_ACCOUNT: proposeRecent?.status === PayrollLoanProposeStatus.INVALID_BANK_ACCOUNT,
                                APPROVED: proposeRecent?.status === PayrollLoanProposeStatus.APPROVED,
                                WAITING_SIGNATURE: proposeRecent?.status === PayrollLoanProposeStatus.WAITING_SIGNATURE,
                                SIGNED: proposeRecent?.status === PayrollLoanProposeStatus.SIGNED,
                                CANCELLED: proposeRecent?.status === PayrollLoanProposeStatus.CANCELLED,
                                RECUSED: proposeRecent?.status === PayrollLoanProposeStatus.RECUSED,
                            })}
                        >
                            {t(`${I18N_PREFIX}.status-options.${proposeRecent?.status}`).toUpperCase()}
                        </span>
                    </span>
                </div>
                <div className="card-payrollloan-recent-propose__container--date">
                    <LocalDateFormatter value={proposeRecent?.requestedDate} type={LocalDateFormatType.SHORT_FULL_DATE} />
                </div>
                <div className="card-payrollloan-recent-propose__container--footer">
                    <div className="card-payrollloan-recent-propose__container--credit-available">
                        <h4> {t(`${I18N_PREFIX}.credit-value`)} </h4>
                        <span className="card-payrollloan-recent-propose__container--credit-value">
                            <Currency value={proposeRecent?.requestedLoanValue} />
                        </span>
                    </div>
                    <div className="card-payrollloan-recent-propose__container--edit">
                        <StandardButton
                            size="small"
                            label={t(`${I18N_PREFIX}.button-label`)}
                            onClick={() => history.push('/employee-borrower/payroll-loan')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardPayrollLoanRecentPropose;
