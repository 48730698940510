import { AxiosResponse } from 'axios';
import { ProposeEmployeeBankAccount } from 'model/employee-borrower/propose/account.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import bankAccountApi from 'services/api/employee-borrower/bankAccountApi';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';
import { employeeBorrowerBanksAccountsError, employeeBorrowerBanksAccountsSuccess } from './actions';
import { EmployeeBorrowerBanksAccountsActionTypes } from './types';

function* handleGetEmployeeBorrowerBanksAccounts() {
    try {
        const result: AxiosResponse<Page<ProposeEmployeeBankAccount>> = yield call(bankAccountApi.bankAccountsRequest);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerBanksAccountsError(mapped));
            return;
        }
        yield put(employeeBorrowerBanksAccountsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerBanksAccountsError(mapped));
    }
}

function* watchGetEmployeeBorrowerBanksAccounts() {
    yield takeEvery(EmployeeBorrowerBanksAccountsActionTypes.EMPLOYEE_BORROWER_BANKS_ACCOUNTS_REQUEST, handleGetEmployeeBorrowerBanksAccounts);
}

function* employeeBorrowerBanksAccountsSaga() {
    yield all([fork(watchGetEmployeeBorrowerBanksAccounts)]);
}

export default employeeBorrowerBanksAccountsSaga;
