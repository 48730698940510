import { GenderType } from 'model/enums/gender-type';

export interface ProposeEmployeeGeneralData {
    proposeId?: number,
    generalData?: {
        employeeId?: number;
        name?: string;
        mainPhoneNumber?: {
            id?: number,
            number?: string,
        },
        birthDate?: string;
        gender?: GenderType;
    }
}

export const defaultProposeEmployeeGeneralData: Readonly<ProposeEmployeeGeneralData> = {
    proposeId: undefined,
    generalData: undefined
}


export interface ProposeEmployeeGeneralDataSummary {
    id: number;
    name: string;
    mainPhoneNumber?: {
        id?: number;
        number?: string;
    };
    birthDate: string;
    gender?: GenderType;
}