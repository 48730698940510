import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import React from 'react';
import './card-propose-employee-summary.scss';

interface CardProposeEmployeeSummaryProps {
    children: React.ReactNode;
    title: string;
    onClick: () => void;
    disabled?: boolean;
}

const CardProposeEmployeeSummary = (props: CardProposeEmployeeSummaryProps) => {
    const { children, title, onClick, disabled } = props;

    return (
        <>
            <div className={clsx(`card__propose-employee-summary`, { disabled })}>
                <div className="card__propose-employee-summary--header">
                    <span className="card__propose-employee-summary--header-title"> {title} </span>
                    {!disabled && (
                        <span>
                            <EditIconButton onClick={!disabled && onClick} />
                        </span>
                    )}
                </div>
                <Grid container spacing={3}>
                    {children}
                </Grid>
            </div>
        </>
    );
};

export default CardProposeEmployeeSummary;
