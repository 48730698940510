import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { ProposeEmployeeSupplementaryData } from 'model/employee-borrower/propose/supplementary-data.model';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes } from './types';

export const updateEmployeeBorrowerProposeSupplementaryDataRequest = (request: ProposeEmployeeSupplementaryData) => action(UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_REQUEST, request);
export const updateEmployeeBorrowerProposeSupplementaryDataSuccess = (data: ProposeEmployeeSimple) => action(UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_SUCCESS, data);
export const updateEmployeeBorrowerProposeSupplementaryDataError = (error: GiroWebappError) => action(UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_ERROR, error);
export const updateEmployeeBorrowerProposeSupplementaryDataResetState = () => action(UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_RESET_STATE);
