import BaseReadOnlyField from 'features/employee-borrower/components/inputs/base-read-only-field';
import React from 'react';

interface CurrencyReadOnlyFieldProps {
    label: string;
    value: number;
    iconLabel?: React.ReactElement;
}

export const CurrencyReadOnlyField = (props: CurrencyReadOnlyFieldProps) => {
    const { value, iconLabel, label } = props;

    const valueDisplay = () => {
        return Number(value).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    };

    return <BaseReadOnlyField label={label} iconLabel={iconLabel} value={valueDisplay()} />;
};

export default CurrencyReadOnlyField;
