import { useEffect } from 'react';
import { userAccountRequest } from 'reducer/account/user-account/actions';
import { useRootDispatch } from 'reducer/hooks';

export const useAccount = () => {
    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(userAccountRequest());
    }, [dispatch]);
};
