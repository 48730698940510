import { EmployeeBorrowerAvailableServices } from 'model/employee-borrower/employee-services.model';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { EmployeeBorrowerAvailableServicesActionTypes } from './types';

export const employeeBorrowerAvailableServicesRequest = () =>
    action(EmployeeBorrowerAvailableServicesActionTypes.EMPLOYEE_BORROWER_AVAILABLE_SERVICES_REQUEST);

export const employeeBorrowerAvailableServicesSuccess = (data: EmployeeBorrowerAvailableServices) =>
    action(EmployeeBorrowerAvailableServicesActionTypes.EMPLOYEE_BORROWER_AVAILABLE_SERVICES_SUCCESS, data);

export const employeeBorrowerAvailableServicesError = (error: GiroWebappError) =>
    action(EmployeeBorrowerAvailableServicesActionTypes.EMPLOYEE_BORROWER_AVAILABLE_SERVICES_ERROR, error);

export const employeeBorrowerAvailableServicesResetState = () =>
    action(EmployeeBorrowerAvailableServicesActionTypes.EMPLOYEE_BORROWER_AVAILABLE_SERVICES_RESET_STATE);
