import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { IEmployeeMonthlyCredit } from '../../model/employee-monthly-credit.model';
import HttpStatus from '../../model/enums/httpStatus';
import employeeMonthlyCreditApi from '../../services/api/employeeMonthlyCreditApi';
import { ErrorUtils } from '../../shared/error/error-utils';
import { markError } from '../application/error/actions';
import {
    employeeMonthlyCreditError,
    employeeMonthlyCreditHistoryError,
    employeeMonthlyCreditHistorySuccess,
    employeeMonthlyCreditSuccess,
} from './actions';
import { EmployeeMonthlyCreditActionTypes } from './types';

function* handleCurrentEmployeeMonthlyCredit() {
    try {
        const result: AxiosResponse<IEmployeeMonthlyCredit> = yield call(employeeMonthlyCreditApi.getCurrentAvailableCredit);
        if (result.status !== HttpStatus.OK && result.status !== HttpStatus.NO_CONTENT) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeMonthlyCreditError(mapped));
            return;
        }
        yield put(employeeMonthlyCreditSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeMonthlyCreditError(mapped));
    }
}

function* watchCurrentEmployeeMonthlyCredit() {
    yield takeEvery(EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_REQUEST, handleCurrentEmployeeMonthlyCredit);
}

function* handleListEmployeeMonthlyCredits() {
    try {
        const result: AxiosResponse<IEmployeeMonthlyCredit[]> = yield call(employeeMonthlyCreditApi.findAll);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeMonthlyCreditHistoryError(mapped));
            return;
        }
        yield put(employeeMonthlyCreditHistorySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeMonthlyCreditHistoryError(mapped));
    }
}

function* watchListEmployeeMonthlyCredits() {
    yield takeEvery(EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_HISTORY_REQUEST, handleListEmployeeMonthlyCredits);
}

function* employeeMonthlyCreditSaga() {
    yield all([fork(watchListEmployeeMonthlyCredits), fork(watchCurrentEmployeeMonthlyCredit)]);
}

export default employeeMonthlyCreditSaga;
