import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import React from 'react';
import BaseMaskInput, { BaseMaskInputProps } from '../../base-mask-input';
import ValidInput, { ValidInputProps } from '../../validation/valid-input';
import '../date-input.scss';
import { MonthMask } from './month-mask';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;
type BaseValidation = Omit<ValidInputProps<string>, 'children'>;
type Base = BaseInput & BaseValidation;

export interface ValidMonthInputProps extends Base {
    onChange: (value: string | undefined) => void;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    endAdornment?: boolean;
}

export const ValidMonthInput = (props: ValidMonthInputProps) => {
    const { validate, showValidation, value: propsValue, onChange, MaskInputComponent, endAdornment, ...otherProps } = props;

    const handleOnChange = (_value: string) => {
        if (onChange && !_value) {
            onChange(undefined);
            return;
        }

        onChange(_value);
    };

    return (
        <ValidInput
            value={propsValue}
            validate={validate}
            showValidation={showValidation}
            className={clsx('date-input base-input month__input', { dirty: propsValue })}
        >
            {({ error }) => (
                <BaseMaskInput
                    {...otherProps}
                    error={error}
                    value={propsValue ? propsValue.toString() : ''}
                    onChange={handleOnChange}
                    MaskInputComponent={MaskInputComponent ?? MonthMask}
                    endAdornment={
                        endAdornment ? (
                            <InputAdornment position="end" style={{ display: 'flex', flexDirection: 'column' }}>
                                <IconButton disabled={props.disabled}>
                                    <Icon>
                                        <div className="date__input--calendar-icon" />
                                    </Icon>
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }
                />
            )}
        </ValidInput>
    );
};

export default ValidMonthInput;
