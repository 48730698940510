import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ProviderByInviteActionTypes, ProviderByInviteState } from './types';

export const initialState: ProviderByInviteState = {
    status: HttpRequestStatus.NOOP,
    person: undefined,
    error: undefined,
};

const reducer: Reducer<ProviderByInviteState> = (state = initialState, action) => {
    switch (action.type) {
        case ProviderByInviteActionTypes.CREATE_PERSON_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, person: undefined, error: undefined };
        }
        case ProviderByInviteActionTypes.CREATE_PERSON_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, person: action.payload, error: undefined };
        }
        case ProviderByInviteActionTypes.CREATE_PERSON_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as providerByInviteReducer };
