import { AxiosResponse } from 'axios';
import { ProposeEmployeeManagerMode } from 'model/employee-borrower/propose/manager.model';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import managerModeApi from 'services/api/employee-borrower/managerModeApi';
import HttpStatus from '../../../model/enums/httpStatus';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { proposeEmployeeManagerModeError, proposeEmployeeManagerModeSuccess } from './actions';
import { ManagerModeActionTypes } from './types';

function* handleManagerMode() {
    try {
        const result: AxiosResponse<ProposeEmployeeManagerMode> = yield call(managerModeApi.getManagerMode);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(proposeEmployeeManagerModeError(mapped));
            return;
        }
        yield put(proposeEmployeeManagerModeSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(proposeEmployeeManagerModeError(mapped));
    }
}

function* watchManagerMode() {
    yield takeEvery(ManagerModeActionTypes.MANAGER_MODE_REQUEST, handleManagerMode);
}

function* managerModeSaga() {
    yield all([fork(watchManagerMode)]);
}

export default managerModeSaga;
