import { EmployeeBorrowerSimulateCalculation, EmployeeBorrowerSimulateCalculationRequest } from 'model/employee-borrower/simulate.model';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { EmployeeBorrowerSimulateCalculationActionTypes } from './types';

export const employeeBorrowerSimulateCalculationRequest = (request: EmployeeBorrowerSimulateCalculationRequest ) => 
action(EmployeeBorrowerSimulateCalculationActionTypes.EMPLOYEE_BORROWER_SIMULATE_CALCULATION_REQUEST, request);

export const employeeBorrowerSimulateCalculationSuccess = (data: EmployeeBorrowerSimulateCalculation) => 
action(EmployeeBorrowerSimulateCalculationActionTypes.EMPLOYEE_BORROWER_SIMULATE_CALCULATION_SUCCESS, data);

export const employeeBorrowerSimulateCalculationError = (error: GiroWebappError) => 
action(EmployeeBorrowerSimulateCalculationActionTypes.EMPLOYEE_BORROWER_SIMULATE_CALCULATION_ERROR, error);

export const employeeBorrowerSimulateCalculationResetState = () => 
action(EmployeeBorrowerSimulateCalculationActionTypes.EMPLOYEE_BORROWER_SIMULATE_CALCULATION_RESET_STATE);
