import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes {
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_REQUEST = '@@updateEmployeeBorrowerProposeSupplementaryData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_REQUEST',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_SUCCESS = '@@updateEmployeeBorrowerProposeSupplementaryData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_SUCCESS',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_ERROR = '@@updateEmployeeBorrowerProposeSupplementaryData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_ERROR',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_RESET_STATE = '@@updateEmployeeBorrowerProposeSupplementaryData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_RESET_STATE',
}

export interface UpdateEmployeeBorrowerProposeSupplementaryDataState extends BaseStoreState {
    readonly proposeSupplementaryData?: ProposeEmployeeSimple;
}
