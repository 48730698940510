import FormControl from '@material-ui/core/FormControl';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import React from 'react';
import PhoneMask from './phone-mask';

type Base = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'type' | 'onChange'>;


export interface SimplePhoneInputProps extends Base {
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    onChange?: (value: string | undefined) => void;
}

export const SimplePhoneInput = (props: SimplePhoneInputProps) => {
    const { MaskInputComponent, onChange, ...otherProps } = props;

    const handleChange = (value: string) => {
        const _value = InputUtils.removeNonDigits(value);
        
        if (onChange && _value.length < 11) {
            onChange(undefined);
            return;
        }
        
        if (onChange) {
            onChange(_value);
        }
    };

    return (
        <FormControl fullWidth className={clsx('base-input', { dirty: props.value })}>
            <BaseMaskInput {...otherProps} onChange={handleChange} MaskInputComponent={MaskInputComponent ?? PhoneMask} type="text" />
        </FormControl>
    );
};

export default SimplePhoneInput;
