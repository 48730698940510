import Loading from 'components/loading/loading';
import { ErrorType } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import HttpStatus from 'model/enums/httpStatus';
import { GiroApiError } from 'model/error';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { hasAcceptedTermsResetState } from 'reducer/account/terms-of-use/actions';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { customizeErrorHandling } from 'reducer/application/error/actions';
import { defaultCompanyEmployeeResetState } from 'reducer/company/default/actions';
import { useRootDispatch, useTermsOfUseState, useUserAccountState } from 'reducer/hooks';
import AuthUtil from 'services/api/authUtil';
import { useAccount } from 'shared/hooks/use-account';
import { useRedirectToDashboard } from 'shared/hooks/use-redirect-to-dashboard';
import { useValidateTerms } from 'shared/hooks/use-validate-terms';
import { useHandleUserNotActivated } from 'shared/hooks/user-not-activated';
import './auto-login.scss';

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(dispatch(customizeErrorHandling({ defaultType: ErrorType.BLOCKING })));
    }, [dispatch]);
};

const useErrorDetection = () => {
    const userAccountState = useUserAccountState();
    const termsOfUseState = useTermsOfUseState();
    const [hasError, setError] = React.useState<boolean>(false);
    const [httpStatus, setHttpStatus] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (userAccountState.status === HttpRequestStatus.ERROR || termsOfUseState.status === HttpRequestStatus.ERROR) {
            setError(true);
            const accountStatus: number | false | undefined =
                userAccountState.status === HttpRequestStatus.ERROR && (userAccountState.error as GiroApiError).httpStatus;
            const termsStatus: number | false | undefined =
                termsOfUseState.status === HttpRequestStatus.ERROR && (termsOfUseState.error as GiroApiError).httpStatus;
            setHttpStatus(accountStatus || termsStatus || undefined);
        } else {
            setError(false);
            setHttpStatus(undefined);
        }
    }, [userAccountState, termsOfUseState]);

    return { hasError, httpStatus };
};

export const AutoLogin = () => {
    const history = useHistory();
    const dispatch = useRootDispatch();

    useErrorConfiguration();
    useHandleUserNotActivated();
    useAccount();
    useValidateTerms();
    useRedirectToDashboard();

    const { hasError, httpStatus } = useErrorDetection();

    React.useEffect(() => {
        if (hasError && [HttpStatus.BAD_REQUEST, HttpStatus.INTERNAL_SERVER_ERROR].find(it => it === httpStatus)) {
            AuthUtil.removeToken();
            dispatch(userAccountResetState());
            dispatch(hasAcceptedTermsResetState());
            dispatch(defaultCompanyEmployeeResetState());
            history.replace('/');
        }
    }, [hasError, httpStatus, history, dispatch]);

    return (
        <div className="auto-login__loading">
            <Loading />
        </div>
    );
};

export default AutoLogin;
