import ButtonAction from 'components/buttons/button-action/button-action';
import FieldBasic from 'components/field-basic/field-basic';
import FieldPassword from 'components/field-password/field-password';
import { GIRO_PHONE_CONTACT } from 'config/constants';
import Carousel from 'features/login/components/carousel/carousel';
import { validateEmail,validatePassword } from 'features/login/validation-constants';
import { Authentication } from 'model/authentication';
import { ErrorConstants,ErrorType,GiroWebappErrorConstants } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { ErrorHandlingCustomization } from 'model/error';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { customizeErrorHandling } from 'reducer/application/error/actions';
import { loginRequest } from 'reducer/authentication/actions';
import { useAuthenticationState,useRootDispatch,useTermsOfUseState,useUserAccountState } from 'reducer/hooks';
import './user-login.scss';

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        const _record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.user.notfound': { message: { key: 'login.invalid' } },
            'error.invalidpassword': { message: { key: 'login.invalid' } },
            'error.user.notactive': {
                type: ErrorType.BLOCKING,
                message: { key: GiroWebappErrorConstants.USER_NOT_ACTIVATED, options: { contact_number: GIRO_PHONE_CONTACT } },
                action: { noAction: true },
            },
        };
        dispatch(dispatch(customizeErrorHandling({ record: _record })));
    }, [dispatch]);
};

const useIsLoading = () => {
    const authenticationState = useAuthenticationState();
    const userAccountState = useUserAccountState();
    const termsOfUseState = useTermsOfUseState();

    const [isLoading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (authenticationState.status === HttpRequestStatus.ONGOING) {
            setLoading(true);
        } else if (authenticationState.status === HttpRequestStatus.ERROR) {
            setLoading(false);
        }
    }, [authenticationState.status]);

    React.useEffect(() => {
        if (userAccountState.status === HttpRequestStatus.ONGOING) {
            setLoading(true);
        } else if (userAccountState.status === HttpRequestStatus.ERROR) {
            setLoading(false);
        }
    }, [userAccountState.status]);

    React.useEffect(() => {
        if (termsOfUseState.status === HttpRequestStatus.ONGOING) {
            setLoading(true);
        } else if (termsOfUseState.status === HttpRequestStatus.ERROR || termsOfUseState.status === HttpRequestStatus.SUCCESS) {
            setLoading(false);
        }
    }, [termsOfUseState.status]);

    return isLoading;
};

export const UserLogin = () => {
    const { t } = useTranslation();

    const [email, setEmail] = React.useState<string>('');
    const [password, setPasswrod] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const dispatch = useRootDispatch();

    useErrorConfiguration();

    const isLoading = useIsLoading();

    const haltProgression = !validateEmail(email).isValid || !validatePassword(password).isValid;

    const handleEnter = () => {
        setValidation(true);
        if (haltProgression) return;

        const _password = password;
        const request: Authentication = { username: email, password: _password };
        dispatch(loginRequest(request));
    };

    return (
        <div className="login--root">
            <Row className="row--container">
                <Col md="6" className="logo--container">
                    <div className="login--logo" />
                    <Carousel />
                </Col>

                <Col className="col--inputs">
                    <Row sm="12">
                        <Col md={{ size: 6, offset: 3 }} sm="12">
                            <form className="login--container">
                                <div className="login--title">
                                    <p> {t('login.title.start').toUpperCase()} </p>
                                    <p> {t('login.title.end').toUpperCase()} </p>
                                </div>
                                <div style={{ marginBottom: '30px' }} />
                                <FieldBasic
                                    label={t('login.email.label')}
                                    value={email}
                                    onChange={setEmail}
                                    validate={validateEmail}
                                    autoComplete="username"
                                    showValidation={showValidation}
                                    onEnter={handleEnter}
                                />
                                <div style={{ marginBottom: '22px' }} />
                                <div className="login--link">
                                    <Link to="/forgot-password">{t('login.password.forgot')} </Link>
                                </div>
                                <div style={{ marginBottom: '10px' }} />
                                <FieldPassword
                                    label={t('login.password.label')}
                                    value={password}
                                    onChange={setPasswrod}
                                    validate={validatePassword}
                                    autoComplete="current-password"
                                    showValidation={showValidation}
                                    onEnter={handleEnter}
                                />
                                <div style={{ marginBottom: '20px' }} />
                                <div className="login--link">
                                    <Link to="/primeiro-acesso">{t('login.password.firstAccess')} </Link>
                                </div>
                                <div style={{ marginBottom: '50px' }} />
                                <div className="login--buttons">
                                    <ButtonAction
                                        label={t('login.actions.enter').toUpperCase()}
                                        onClick={handleEnter}
                                        disabled={isLoading || (showValidation && haltProgression)}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default UserLogin;
