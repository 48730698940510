import { AxiosResponse } from 'axios';
import { ICompanyEmployee } from '../../model/company-employee.model';
import { api } from './api';

const BASE_URL = 'api/webapp/company-employees';

export const companies = () => {
    const getDefault = (): Promise<AxiosResponse<ICompanyEmployee>> => {
        return api.get<ICompanyEmployee>(`${BASE_URL}/default`);
    };

    const changeDefault = (id: string): Promise<AxiosResponse<ICompanyEmployee>> => {
        return api.put<ICompanyEmployee>(`${BASE_URL}/${id}/default`);
    };

    const getMine = (): Promise<AxiosResponse<ICompanyEmployee[]>> => {
        return api.get<ICompanyEmployee[]>(`${BASE_URL}/me`);
    };

    return {
        getDefault,
        changeDefault,
        getMine,
    };
};

export default companies();
