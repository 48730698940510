import { action } from 'typesafe-actions';
import { IEmployeeAnticipation } from '../../model/employee-anticipation.model';
import { GiroWebappError } from '../../model/error';
import { EmployeeAnticipationActionTypes } from './types';

export const employeeAnticipationRequest = (request: IEmployeeAnticipation) =>
    action(EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_REQUEST, request);
export const employeeAnticipationSuccess = (anticipation: IEmployeeAnticipation) =>
    action(EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_SUCCESS, anticipation);
export const employeeAnticipationError = (error: GiroWebappError) =>
    action(EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_ERROR, error);

export const employeeAnticipationHistoryRequest = () => action(EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_HISTORY_REQUEST);
export const employeeAnticipationHistorySuccess = (anticipations: IEmployeeAnticipation[]) =>
    action(EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_HISTORY_SUCCESS, anticipations);
export const employeeAnticipationHistoryError = (error: GiroWebappError) =>
    action(EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_HISTORY_ERROR, error);

export const employeeAnticipationResetState = () => action(EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_RESET_STATE);
