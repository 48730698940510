import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { PersonTermsAcceptance } from '../../../model/person';
import { TermsOfUseActionTypes } from './types';

export const hasAcceptedTermsRequest = () => action(TermsOfUseActionTypes.HAS_ACCEPTED_TERMS_REQUEST);
export const hasAcceptedTermsSuccess = (terms: PersonTermsAcceptance) => action(TermsOfUseActionTypes.HAS_ACCEPTED_TERMS_SUCCESS, terms);
export const hasAcceptedTermsError = (error: GiroWebappError) => action(TermsOfUseActionTypes.HAS_ACCEPTED_TERMS_ERROR, error);

export const hasAcceptedTermsResetState = () => action(TermsOfUseActionTypes.HAS_ACCEPTED_TERMS_RESET_STATE);
