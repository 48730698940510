import { validatePix } from 'features/employee-borrower/components/propose-employee/validation/pix-key-validation';
import { ProposeEmployeeAccountData } from 'model/employee-borrower/propose/account.model';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const ACCOUNT_BANK_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateAccountBank = (value: string | undefined | null) => ValidationUtils.validate(value, ACCOUNT_BANK_VALIDATION);

const ACCOUNT_AGENCY_NUMBER_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty
    },
};

export const validateAccountAgencyNumber = (value: number | undefined | null) => ValidationUtils.validate(value, ACCOUNT_AGENCY_NUMBER_VALIDATION);

const ACCOUNT_AGENCY_DIGIT_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty
    },
};

export const validateAccountAgencyDigit = (value: number | undefined | null) => ValidationUtils.validate(value, ACCOUNT_AGENCY_DIGIT_VALIDATION);

const ACCOUNT_NUMBER_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty
    },
};

export const validateAccountNumber = (value: number | undefined | null) => ValidationUtils.validate(value, ACCOUNT_NUMBER_VALIDATION);

const ACCOUNT_DIGIT_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty
    },
};

export const validateAccountNumberDigit = (value: number | undefined | null) => ValidationUtils.validate(value, ACCOUNT_DIGIT_VALIDATION);

const ACCOUNT_TYPE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateAccountType = (value: string | undefined | null) => ValidationUtils.validate(value, ACCOUNT_TYPE_VALIDATION);

export const validateProposeEmployeeAccount = (value: ProposeEmployeeAccountData | undefined): boolean => {
    if (!value) return true;
    return (
        validateAccountBank(value.accountData?.bank?.name).isValid &&
        validateAccountAgencyNumber(value.accountData?.agencyNumber).isValid &&
        validateAccountNumber(value.accountData?.accountNumber).isValid &&
        validateAccountNumberDigit(value.accountData?.accountDigit).isValid &&
        validateAccountType(value.accountData?.type).isValid &&
        validatePix(value)
    );
};