import { PayrollLoanProposeAnalystStatus } from 'model/enums/analyst-status';
import EmployeeManagerStatus from 'model/enums/manager-info';
import ManagerMode from 'model/enums/manager-mode';

export interface ProposeEmployeeManager {
    proposeId?: number,
    analystStatus?: PayrollLoanProposeAnalystStatus,
    manager?: {
        managerId?: number,
        name?: string,
        email?: string
    }
}

export const defaultProposeEmployeeManager: ProposeEmployeeManager = {
    proposeId: undefined,
    analystStatus: undefined,
    manager: undefined
}

export interface ProposeEmployeeSearchManagers {
    id?: string;
    name?: string;
    email?: string;
}

export interface ProposeEmployeeManagerSummary {
    managerId: number;
    name: string;
    email: string;
}

export interface ProposeEmployeeManagerMode {
    managerMode?: ManagerMode
}

export interface ProposeEmployeeManagerInfo {
    managerId?: number;
    managerStatus?: EmployeeManagerStatus;
    managerName?: string;
    managerEmail?: string;
}

