import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { Invite, InviteCompany } from '../../../model/invite';
import { InviteToAcceptActionTypes } from './types';

export const inviteToAcceptRequest = (token: string) => action(InviteToAcceptActionTypes.INVITE_TO_ACCEPT_REQUEST, token);
export const inviteToAcceptSuccess = (response: Invite) => action(InviteToAcceptActionTypes.INVITE_TO_ACCEPT_SUCCESS, response);
export const inviteToAcceptError = (error: GiroWebappError) => action(InviteToAcceptActionTypes.INVITE_TO_ACCEPT_ERROR, error);
export const inviteToAcceptUpdateCompany = (company: InviteCompany) =>
    action(InviteToAcceptActionTypes.INVITE_TO_ACCEPT_COMPANY_UPDATE, company);
