import i18n from 'config/i18n';
import _ from "lodash";
import FormatUtils from 'shared/util/format-utils';
import { ValidationResult, Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'employee-borrower.simulate.manager.validation';

interface LoanValueValidation {
    loanValue: number | undefined;
    minLoanValue?: number;
    maxLoanValue?: number;
    salary?: number;
    installmentNumber?: number;
    maxInstallmentValue?: number;
}


const MIN_LOAN_VALUE_VALIDATION: Validations<LoanValueValidation> = {
    minLength: {
        predicate: it => it === undefined || it?.loanValue === undefined || it?.minLoanValue === undefined || it === null || it?.loanValue >= it.minLoanValue,
        errorMessage: `${I18N_PREFIX}.loanValue.minLength`,
    },
};
export const minValidateLoanValue = (loanValue: LoanValueValidation) => ValidationUtils.validate(loanValue, MIN_LOAN_VALUE_VALIDATION);

const MAX_LOAN_VALUE_VALIDATION: Validations<LoanValueValidation> = {
    maxLength: {
        predicate: it => it === undefined || it?.loanValue === undefined || it?.maxLoanValue === undefined || it === null || it?.loanValue <= it.maxLoanValue,
        errorMessage: `${I18N_PREFIX}.loanValue.maxLength`,
    },
};
export const maxValidateLoanValue = (loanValue: LoanValueValidation) => ValidationUtils.validate(loanValue, MAX_LOAN_VALUE_VALIDATION);

const MAX_SALARY_VALUE_VALIDATION: Validations<LoanValueValidation> = {
    maxSalary: {
        predicate: it => {
            return it?.loanValue === undefined || it?.salary === undefined || it?.installmentNumber === undefined || it.loanValue === 0 || _.isNaN(it.loanValue) || it.installmentNumber === 0 || _.isNaN(it.installmentNumber) || (it.loanValue / it.installmentNumber) <= (it.salary * 30) / 100
        },
        errorMessage: `${I18N_PREFIX}.loanValue.maxSalary`,
    },
};
export const maxValidateSalaryValue = (loanValue: LoanValueValidation) => ValidationUtils.validate(loanValue, MAX_SALARY_VALUE_VALIDATION);


const LOAN_VALUE_VALIDATION: Validations<LoanValueValidation> = {
    minLength: {
        predicate: it => it === undefined || it?.loanValue === undefined || it?.minLoanValue === undefined || it === null || it?.loanValue >= it.minLoanValue,
        errorMessage: `${I18N_PREFIX}.loanValue.minLength`,
    },
    maxLength: {
        predicate: it => it === undefined || it?.loanValue === undefined || it?.maxLoanValue === undefined || it === null || it?.loanValue <= it.maxLoanValue,
        errorMessage: `${I18N_PREFIX}.loanValue.maxLength`,
    },
    maxSalary: {
        predicate: it => {
            return it?.loanValue === undefined || it?.salary === undefined || it?.installmentNumber === undefined || it.loanValue === 0 || _.isNaN(it.loanValue) || it.installmentNumber === 0 || _.isNaN(it.installmentNumber) || (it.loanValue / it.installmentNumber) <= (it.salary * 30) / 100
        },
        errorMessage: `${I18N_PREFIX}.loanValue.maxSalary`,
    },
};
export const validateLoanValue = (loanValue: LoanValueValidation) => ValidationUtils.validate(loanValue, LOAN_VALUE_VALIDATION);

export const validateMaxInstallmentValue = (loanValue: number | undefined, installmentNumber: number | undefined, maxInstallmentValue: number | undefined): ValidationResult => {
    if (loanValue === undefined || installmentNumber === undefined || maxInstallmentValue === undefined) {
        return {
            isValid: true,
        };
    }

    const currentInstallmentValue = loanValue / installmentNumber;

    const validationMatch = currentInstallmentValue <= maxInstallmentValue;

    return {
        isValid: validationMatch,
        errorMessage: i18n.t(`${I18N_PREFIX}.maxInstallmentValue`, { installmentValue: FormatUtils.formatCurrency(maxInstallmentValue), value: FormatUtils.formatCurrency(currentInstallmentValue) }),
    } as ValidationResult;
}