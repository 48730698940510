import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { SearchCitiesState, SearchCitiesTypes } from './types';

export const initialState: SearchCitiesState = {
    status: HttpRequestStatus.NOOP,
    cities: undefined,
    error: undefined,
};

const reducer: Reducer<SearchCitiesState> = (state = initialState, action): SearchCitiesState => {
    switch (action.type) {
        case SearchCitiesTypes.SEARCH_CITIES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case SearchCitiesTypes.SEARCH_CITIES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, cities: action.payload, error: undefined };
        }
        case SearchCitiesTypes.SEARCH_CITIES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case SearchCitiesTypes.SEARCH_CITIES_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as searchCitiesReducer };
