import FormControl from '@material-ui/core/FormControl';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import React from 'react';
import BaseMaskInput, { BaseMaskInputProps } from '../base-mask-input';
import InputUtils from '../input-utils';
import ZipCodeMask from './zip-code-mask';

type Base = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'type' | 'onChange'>;

export interface SimpleZipCodeInputProps extends Base {
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    onChange?: (value: string | undefined) => void;
}

export const SimpleZipCodeInput = (props: SimpleZipCodeInputProps) => {
    const { MaskInputComponent, onChange, ...otherProps } = props;

    const handleChange = (value: string) => {
        const _value = InputUtils.removeNonDigits(value);

        if (onChange && value.length < 10) {
            onChange(undefined);
            return;
        }

        if (onChange) {
            onChange(_value);
        }
    };

    return (
        <FormControl fullWidth className={clsx('base-input', { dirty: props.value })}>
            <BaseMaskInput {...otherProps} onChange={handleChange} MaskInputComponent={MaskInputComponent ?? ZipCodeMask} />
        </FormControl>
    );
};

export default SimpleZipCodeInput;
