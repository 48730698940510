import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { CompanyEmployeeActionTypes, CompanyEmployeeState } from './types';

export const initialState: CompanyEmployeeState = {
    status: HttpRequestStatus.NOOP,
    companyEmployee: null,
    error: null,
};

const reducer: Reducer<CompanyEmployeeState> = (state = initialState, action): CompanyEmployeeState => {
    switch (action.type) {
        case CompanyEmployeeActionTypes.COMPANY_EMPLOYEE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: null };
        }
        case CompanyEmployeeActionTypes.COMPANY_EMPLOYEE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companyEmployee: action.payload, error: null };
        }
        case CompanyEmployeeActionTypes.COMPANY_EMPLOYEE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case CompanyEmployeeActionTypes.COMPANY_EMPLOYEE_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as companyEmployeeReducer };
