import { AxiosResponse } from 'axios';
import { ProposeEmployeeSearchManagers } from 'model/employee-borrower/propose/manager.model';
import { SearchRequest } from 'model/reducers';
import { api } from 'services/api/api';
import { Page } from 'services/page';

const BASE_URL = '/api/payroll-loan/employee';

export const employeeBorrowerAnalyst = () => {
    const analystSearchManagers = (request: SearchRequest): Promise<AxiosResponse<Page<ProposeEmployeeSearchManagers>>> => {
        return api.get<Page<ProposeEmployeeSearchManagers>>(`${BASE_URL}/analysts/managers/search?search=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable },
        });
    };

    return {
        analystSearchManagers,
    };
};

export default employeeBorrowerAnalyst();
