import clsx from 'clsx';
import ButtonRequest from 'features/dashboard/components/buttons/button-request/button-request';
import { ReactComponent as PayrollloanIcon } from 'images/payrollloan-icon.svg';

import React from 'react';
import { useTranslation } from 'react-i18next';
import './card-payrollloan-service.scss';

interface CardPayrollLoanServiceProps {
    onClick: () => void;
    availablePayrollLoan: boolean;
}

const I18N_PREFIX = 'dashboard.card.payroll-loan';

export const CardPayrollLoanService = (props: CardPayrollLoanServiceProps) => {
    const { onClick, availablePayrollLoan } = props;
    const { t } = useTranslation();

    return (
        <div className="card-payrollloan-service__container">
            <div className={clsx('card-payrollloan-service__container--image-background', { disabled: !availablePayrollLoan })}>
                <div className="card-payrollloan-service__container--image" />
            </div>
            <div className={clsx('card-payrollloan-service__container--info', { disabled: !availablePayrollLoan })}>
                <div className="card-payrollloan-service__container--title">
                    <h3>
                        <PayrollloanIcon
                            className={clsx('card-payrollloan-service__container--title__icon', { disabled: !availablePayrollLoan })}
                        />
                        {t(`${I18N_PREFIX}.title`)}
                    </h3>
                </div>
                <div className="card-payrollloan-service__container--description">
                    <h4> {t(`${I18N_PREFIX}.description`)} </h4>
                </div>
                <div className="card-payrollloan-service__container--footer">
                    <div className="card-payrollloan-service__container--button">
                        <ButtonRequest onClick={onClick} isDisabled={!availablePayrollLoan} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardPayrollLoanService;
