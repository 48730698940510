import AutoLogin from 'features/login/components/auto-login/auto-login';
import UserLogin from 'features/login/components/user-login/user-login';
import React from 'react';
import { useAuthenticationAwareness } from 'shared/hooks/use-authentication-awareness';

export const Login = () => {
    const isAuthenticated = useAuthenticationAwareness();

    if (isAuthenticated) {
        return <AutoLogin />;
    }
    return <UserLogin />;
};

export default Login;
