import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ManagerInfoActionTypes, ManagerInfoState } from './types';

export const initialState: ManagerInfoState = {
    status: HttpRequestStatus.NOOP,
    data: undefined,
    error: undefined,
};

const reducer: Reducer<ManagerInfoState> = (state = initialState, action): ManagerInfoState => {
    switch (action.type) {
        case ManagerInfoActionTypes.MANAGER_INFO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, data: undefined, error: undefined };
        }
        case ManagerInfoActionTypes.MANAGER_INFO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, data: action.payload, error: undefined };
        }
        case ManagerInfoActionTypes.MANAGER_INFO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ManagerInfoActionTypes.MANAGER_INFO_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as managerInfoReducer };
