import { EMAIL_REGEX } from '../../config/constants';
import { Validations, ValidationUtils } from '../../shared/util/validation-utils';

const EMAIL_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'forgot-password.email.validation.required',
    },
    format: {
        predicate: it => !!it && EMAIL_REGEX.test(it),
        errorMessage: 'forgot-password.email.validation.format',
    },
    max_length: {
        predicate: it => !it?.length || it.length <= 100,
        errorMessage: 'forgot-password.email.validation.max-length',
    },
};
export const validateEmail = (value: string) => ValidationUtils.validate(value, EMAIL_VALIDATION);

const INPUT_VALIDATION: Validations<string> = {
  required: {
    predicate: it => !!it,
    errorMessage: 'accept-invite.identification.input.validation.required',
  },
  format: {
    predicate: it => !it?.length || it.length <= 255,
    errorMessage: 'accept-invite.identification.input.validation.max-length',
  },
};
export const validateInput = (value: string) => ValidationUtils.validate(value, INPUT_VALIDATION);
