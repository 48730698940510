import { AxiosResponse } from 'axios';
import { ProposeEmployeeManagerInfo } from 'model/employee-borrower/propose/manager.model';
import { api } from 'services/api/api';

const BASE_URL = '/api/payroll-loan/employee';

export const managerInfoApi = () => {
    const getManagerInfo = (): Promise<AxiosResponse<ProposeEmployeeManagerInfo>> => {
        return api.get<ProposeEmployeeManagerInfo>(`${BASE_URL}/managers/info`);
    };

    return {
        getManagerInfo,
    };
};

export default managerInfoApi();