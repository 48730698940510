import { DEFAULT_PHONE_LENGTH, DEFAULT_PHONE_MASK, DEFAULT_PHONE_REGEX, EXTENDED_PHONE_LENGTH, EXTENDED_PHONE_MASK, EXTENDED_PHONE_REGEX } from 'config/constants';
import { format, isValid, parseISO } from 'date-fns';
import { PersonType } from 'model/enums/person-type';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const EMPTY_STRING = '';
const NON_DIGITS_SEARCH_REGEX = /\D/g;

const isDefined = (value: any) => value !== null && value !== undefined;
const isNotDefined = (value: any) => value === null || value === undefined;

const CURRENCY_FORMATTER = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
});

const PERCENTUAL_FORMATTER = new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

const CPF_TO_FORMAT = /(\d{3})(\d{3})(\d{3})(\d{2})/g;
const CNPJ_TO_FORMAT = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
const RG_TO_FORMAT = /(\d{2})(\d{3})(\d{3})(\d{1})/g;

const formatIdentification = (type?: PersonType, value?: string): string => {
    if (!value) {
        return '';
    } else if (type === PersonType.INDIVIDUAL || value.length === 11) {
        return value?.replace(CPF_TO_FORMAT, '$1.$2.$3-$4');
    } else if (type === PersonType.CORPORATION || value.length === 14) {
        return value?.replace(CNPJ_TO_FORMAT, '$1.$2.$3/$4-$5');
    } else {
        return value;
    }
};

const formatCurrency = (value?: number) => CURRENCY_FORMATTER.format(value ?? 0);
const formatPercent = (value?: number, decimalNumber: boolean = true) => {
    const valueToFormat = !decimalNumber ? value ?? 0 / 100 : value;
    return PERCENTUAL_FORMATTER.format(valueToFormat ?? 0);
}

const formatDate = (date?: Date): string => {
    if (!date) return '';

    return moment(date).format('L');
};

const formatLocalDate = (value: Date, formatToken: string): string | undefined => {
    return isValid(value) && isDefined(formatToken) ? format(value, formatToken) : undefined;
};

const parseDateFromStringISO = (value: string | undefined | null): Date | undefined => {
    if (isNotDefined(value)) return undefined;
    if (!value) return;

    return parseISO(value);
};

const formatStringToZipCode = (value: string | undefined | null) => {
    if (!value) return;
    if (isNotDefined(value) || value.length < 5) return value;
    return `${value.slice(0, 2)}.${value.slice(2, 5)}-${value.slice(5)}`;
};

const formatPhone = (value?: string): string => {
    if (!value) return EMPTY_STRING;
    if (isNotDefined(value)) return EMPTY_STRING;

    const digits = value.replace(NON_DIGITS_SEARCH_REGEX, EMPTY_STRING);
    if (digits.length === DEFAULT_PHONE_LENGTH) return digits.replace(DEFAULT_PHONE_REGEX, DEFAULT_PHONE_MASK);
    if (digits.length === EXTENDED_PHONE_LENGTH) return digits.replace(EXTENDED_PHONE_REGEX, EXTENDED_PHONE_MASK);
    return value;
};

const formatRg = (value?: string): string => {
    if (!value) return EMPTY_STRING;
    if (isNotDefined(value)) return EMPTY_STRING;
    if (value.length !== 9) return EMPTY_STRING;

    return value?.replace(RG_TO_FORMAT, '$1.$2.$3-$4')
}


export const FormatUtils = {
    formatIdentification,
    formatCurrency,
    formatPercent,
    formatLocalDate,
    parseDateFromStringISO,
    formatPhone,
    formatStringToZipCode,
    formatDate,
    formatRg
};

export default FormatUtils;
