import { AxiosResponse } from 'axios';
import { api } from './api';
import { UserAccessToken, UserFirstAccessRequest } from 'model/user';

export const firstAccess = () => {
  const sendPersonInfo = (payload: UserFirstAccessRequest): Promise<AxiosResponse<UserAccessToken>> => {
    return api.get<UserAccessToken>(`api/invites/person-info?name=${payload.name}&identification=${payload.identification}&employeeIdentificationNumber=${payload.employeeIdentificationNumber}`);
  };

  return {
    sendPersonInfo,
  };
};

export default firstAccess();
