import React from 'react';
import { GIRO_PHONE_CONTACT } from '../../config/constants';
import { Authority } from '../../model/enums/authorities';
import { ErrorConstants, ErrorType, GiroWebappErrorConstants } from '../../model/enums/error-constants';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { ErrorHandlingCustomization } from '../../model/error';
import { customizeErrorHandling, markError } from '../../reducer/application/error/actions';
import { useRootDispatch, useUserAccountState } from '../../reducer/hooks';

export const useHandleUserNotActivated = () => {
    const dispatch = useRootDispatch();
    const userAccountState = useUserAccountState();

    React.useEffect(() => {
        const _record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.user.notActivated': {
                type: ErrorType.BLOCKING,
                action: {
                    noAction: true,
                },
                message: { key: GiroWebappErrorConstants.USER_NOT_ACTIVATED, options: { contact_number: GIRO_PHONE_CONTACT } },
            },
        };
        dispatch(dispatch(customizeErrorHandling({ record: _record })));
    }, [dispatch]);

    React.useEffect(() => {
        if (
            userAccountState.status === HttpRequestStatus.SUCCESS &&
            userAccountState.account?.activated === false &&
            userAccountState.account?.authorities?.find(it => it === Authority.ROLE_EMPLOYEE)
        ) {
            dispatch(markError({ error_description: GiroWebappErrorConstants.USER_NOT_ACTIVATED }));
        }
    }, [userAccountState, dispatch]);
};
