import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes, UpdateEmployeeBorrowerProposeSupplementaryDataState } from './types';

export const initialState: UpdateEmployeeBorrowerProposeSupplementaryDataState = {
    status: HttpRequestStatus.NOOP,
    proposeSupplementaryData: undefined,
    error: undefined
};

const reducer: Reducer<UpdateEmployeeBorrowerProposeSupplementaryDataState> = (state = initialState, action): UpdateEmployeeBorrowerProposeSupplementaryDataState => {
    switch (action.type) {
        case UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, proposeSupplementaryData: action.payload, error: undefined };
        }
        case UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as updateEmployeeBorrowerProposeSupplementaryDataReducer };
