import { FeedbackSimple } from 'model/employee-borrower/propose/operator-feedback.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeBorrowerProposeSendRequestedChangesActionTypes {
    EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_REQUEST = '@@employeeBorrowerProposeSendRequestedChanges/EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_REQUEST',
    EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_SUCCESS = '@@employeeBorrowerProposeSendRequestedChanges/EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_SUCCESS',
    EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_ERROR = '@@employeeBorrowerProposeSendRequestedChanges/EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_ERROR',
    EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_RESET_STATE = '@@employeeBorrowerProposeSendRequestedChanges/EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_RESET_STATE',
}

export interface EmployeeBorrowerProposeSendRequestedChangesState extends BaseStoreState {
    readonly feedback?: FeedbackSimple;
}
