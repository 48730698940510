import { MenuItem } from '@material-ui/core';
import SimpleCurrencyInput from 'components/inputs/currency-input/simple-currency-input';
import SimpleNumberInput from 'components/inputs/number-input/simple-number-input';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import SimpleZipCodeInput from 'components/inputs/zip-code-input/simple-zip-code-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import { validateProposeEmployeeAddress } from 'features/employee-borrower/components/propose-employee/validation/propose-employee-address-validation';
import { useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import { ICity } from 'model/city.model';
import { ProposeEmployeeAddressData } from 'model/employee-borrower/propose/address.model';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { ResidenceType } from 'model/enums/residence-type';
import React, { useEffect, useMemo, useState } from 'react';
import Loading from 'components/loading/loading';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { searchCitiesRequest } from 'reducer/city/search-cities/actions';
import { updateEmployeeBorrowerProposeAddressDataResetState } from 'reducer/employeeBorrower/propose/propose-address-data/update-propose-address-data/actions';
import { useRootDispatch, useUpdateEmployeeBorrowerProposeAddressDataState } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import '../propose-employee-steps.scss';

const I18N_PREFIX = 'employee-borrower.propose.addressData';

interface ProposeEmployeeAddressProps {
    handleChange: (value: Partial<ProposeEmployeeAddressData>) => void;
    proposeAddressData: ProposeEmployeeAddressData;
}

export const ProposeEmployeeAddressDataStep = (props: ProposeEmployeeAddressProps) => {
    const { handleChange, proposeAddressData } = props;

    const [shouldLoading, setShouldLoading] = useState<boolean>(false)

    const { addressData } = proposeAddressData;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { toDisabledButtonNext, toNextStep, isEditing, setCurrentStep, setIsEditing } = useEmployeeBorrowerContext();

    const { status: proposeAddresStatus } = useUpdateEmployeeBorrowerProposeAddressDataState();

    const { toastSuccess } = useToast();

    const isDisabledNextStepButton: boolean = useMemo(() => {
        return !validateProposeEmployeeAddress(proposeAddressData);
    }, [proposeAddressData]);

    useEffect(() => {
        toDisabledButtonNext(isDisabledNextStepButton);
    }, [isDisabledNextStepButton, toDisabledButtonNext]);

    useEffect(() => {
        if(proposeAddresStatus === HttpRequestStatus.ONGOING){
            setShouldLoading(true)
        }
        if (proposeAddresStatus === HttpRequestStatus.SUCCESS) {
            dispatch(updateEmployeeBorrowerProposeAddressDataResetState());

            if (!isEditing) {
                toNextStep();
                return;
            }
            setIsEditing(false);
            toastSuccess(t(`${I18N_PREFIX}.edit-success`));
            setCurrentStep('summary');
        }
    }, [proposeAddresStatus, toNextStep, dispatch, isEditing, setCurrentStep, setIsEditing, toastSuccess, t]);

    const handleSelectResidenceType = (residenceType: ResidenceType) => {
        if (residenceType === ResidenceType.RENTED) {
            handleChange({ addressData: { ...addressData, residenceType: ResidenceType[residenceType] } });
            return;
        }

        handleChange({
            addressData: { ...addressData, rentTime: undefined, rentAmount: undefined, residenceType: ResidenceType[residenceType] },
        });
    };

    return (
        <>
            {shouldLoading ? (
                <div className='container__employee-borrower--loading'>
                    <Loading />
                </div>
            ) : (
                <div className="container__propose-employee-step">
                    <div className="header__propose-employee-step">
                        <span className="header__propose-employee-step--title"> {t('employee-borrower.title')} </span>
                        <span className="header__propose-employee-step--subtitle"> {t(`${I18N_PREFIX}.subtitle`)} </span>
                    </div>
                    <div className="form__propose-employee-step">
                        <div className="form__propose-employee-step--input">
                            <SimpleZipCodeInput
                                id="inputText_addressData_zipCode"
                                onChange={zipCode => handleChange({ addressData: { ...addressData, zipCode } })}
                                value={addressData?.zipCode ?? ''}
                                label={`${I18N_PREFIX}.input.zipCode.label`}
                                placeholder={`${I18N_PREFIX}.input.zipCode.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleTextInput
                                id="inputDate_addressData_street"
                                onChange={street => handleChange({ addressData: { ...addressData, street } })}
                                value={addressData?.street ?? ''}
                                label={`${I18N_PREFIX}.input.street.label`}
                                placeholder={`${I18N_PREFIX}.input.street.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleNumberInput
                                id="inputText_addressData_residenceNumber"
                                onChange={residenceNumber => handleChange({ addressData: { ...addressData, number: residenceNumber } })}
                                value={addressData?.number}
                                label={`${I18N_PREFIX}.input.number.label`}
                                placeholder={`${I18N_PREFIX}.input.number.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleTextInput
                                id="inputText_addressData_complement"
                                onChange={complement => handleChange({ addressData: { ...addressData, complement } })}
                                value={addressData?.complement ?? ''}
                                label={`${I18N_PREFIX}.input.complement.label`}
                                placeholder={`${I18N_PREFIX}.input.complement.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <ModalSearch<ICity>
                                id="inputText_addressData_modal_city"
                                itemSize={SEARCH_ITEM_SIZE_LARGE}
                                action={searchCitiesRequest}
                                modalTitle={t(`${I18N_PREFIX}.input.city.modal.title`)}
                                modalLabel={`${I18N_PREFIX}.input.city.modal.label`}
                                modalPlaceholder={`${I18N_PREFIX}.input.city.modal.placeholder`}
                                onSelect={city =>
                                    handleChange({
                                        addressData: {
                                            ...addressData,
                                            city: { id: city.id, name: city.name, stateAcronym: city.stateAcronym },
                                        },
                                    })
                                }
                                renderItem={city => (city.name && city.stateAcronym ? `${city.name} - ${city.stateAcronym}` : city.name)}
                                statusSelector={(state: IRootState) => state.searchCities.status}
                                dataSelector={(state: IRootState) => state.searchCities.cities}
                            >
                                {handleOpen => (
                                    <SimpleSearchInput
                                        id="inputText_addressData_city"
                                        label={`${I18N_PREFIX}.input.city.label`}
                                        placeholder={`${I18N_PREFIX}.input.city.placeholder`}
                                        value={
                                            addressData?.city?.name && addressData?.city?.stateAcronym
                                                ? `${addressData.city.name} - ${addressData.city.stateAcronym}`
                                                : addressData?.city?.name ?? ''
                                        }
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleTextInput
                                id="inputText_addressData_neighborhood"
                                onChange={neighborhood => handleChange({ addressData: { ...addressData, neighborhood } })}
                                value={addressData?.neighborhood ?? ''}
                                label={`${I18N_PREFIX}.input.neighborhood.label`}
                                placeholder={`${I18N_PREFIX}.input.neighborhood.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleSelectInput
                                id="inputSelect_addressData_residenceType"
                                label={`${I18N_PREFIX}.input.residenceType.label`}
                                value={addressData?.residenceType!}
                                onChange={handleSelectResidenceType}
                                mapperFromString={_value => (_value ? ResidenceType[_value] : undefined)}
                                placeholder={`${I18N_PREFIX}.input.residenceType.placeholder`}
                            >
                                <MenuItem value={ResidenceType.OWN}>{t(`${I18N_PREFIX}.input.residenceType.options.OWN`)}</MenuItem>
                                <MenuItem value={ResidenceType.RENTED}>{t(`${I18N_PREFIX}.input.residenceType.options.RENTED`)}</MenuItem>
                                <MenuItem value={ResidenceType.OTHERS}>{t(`${I18N_PREFIX}.input.residenceType.options.OTHERS`)}</MenuItem>
                            </SimpleSelectInput>
                        </div>
                        {addressData?.residenceType === ResidenceType.RENTED && (
                            <>
                                <div className="form__propose-employee-step--input">
                                    <SimpleCurrencyInput
                                        id="inputCheckbox_addressData_rentAmount"
                                        label={`${I18N_PREFIX}.input.rentAmount.label`}
                                        placeholder={`${I18N_PREFIX}.input.rentAmount.placeholder`}
                                        value={addressData?.rentAmount}
                                        onChange={rentAmount => handleChange({ addressData: { ...addressData, rentAmount } })}
                                        externalUpdate
                                    />
                                </div>
                                <div className="form__propose-employee-step--input">
                                    <SimpleTextInput
                                        id="inputCheckbox_addressData_rentTime"
                                        onChange={rentTime => handleChange({ addressData: { ...addressData, rentTime } })}
                                        value={addressData?.rentTime ?? ''}
                                        label={`${I18N_PREFIX}.input.rentTime.label`}
                                        placeholder={`${I18N_PREFIX}.input.rentTime.placeholder`}
                                        externalUpdate
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ProposeEmployeeAddressDataStep;
