import { all, fork, takeEvery } from 'redux-saga/effects';
import { listProgramsRequest } from './actions';
import { ListProgramsActionTypes } from './types';

function* handleListPrograms(action: ReturnType<typeof listProgramsRequest>) {}

function* watchListPrograms() {
    yield takeEvery(ListProgramsActionTypes.LIST_PROGRAMS_REQUEST, handleListPrograms);
}

function* listProgramsSaga() {
    yield all([fork(watchListPrograms)]);
}

export default listProgramsSaga;
