import { EmployeeBorrowerSimulateCalculation } from 'model/employee-borrower/simulate.model';
import { BaseStoreState } from 'reducer/baseStoreState';
export type ApiResponse = Record<string, string>;

export enum EmployeeBorrowerLoanCalculationActionTypes {
    EMPLOYEE_BORROWER_LOAN_CALCULATION_REQUEST = '@@employeeBorrowerLoanCalculation/EMPLOYEE_BORROWER_LOAN_CALCULATION_REQUEST',
    EMPLOYEE_BORROWER_LOAN_CALCULATION_SUCCESS = '@@employeeBorrowerLoanCalculation/EMPLOYEE_BORROWER_LOAN_CALCULATION_SUCCESS',
    EMPLOYEE_BORROWER_LOAN_CALCULATION_ERROR = '@@employeeBorrowerLoanCalculation/EMPLOYEE_BORROWER_LOAN_CALCULATION_ERROR',
    EMPLOYEE_BORROWER_LOAN_CALCULATION_RESET_STATE = '@@employeeBorrowerLoanCalculation/EMPLOYEE_BORROWER_LOAN_CALCULATION_RESET_STATE',
}

export interface EmployeeBorrowerLoanCalculationState extends BaseStoreState {
    readonly loanCalculation?: EmployeeBorrowerSimulateCalculation;
}
