import { Grid } from '@material-ui/core';
import CardProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-propose-employee-summary';
import ReadOnlyFieldProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/read-only-field-propose-employee-summary/read-only-field-propose-employee-summary';
import { ProposeEmployeeBankAccount } from 'model/employee-borrower/propose/account.model';
import React from 'react';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'employee-borrower.propose.account';

interface CardAccountSummaryProps {
    loanBankAccount?: ProposeEmployeeBankAccount;
    onClick: () => void;
    disabled?: boolean;
}

const CardAccountSummary = (props: CardAccountSummaryProps) => {
    const { loanBankAccount, onClick, disabled } = props;

    const { t } = useTranslation();

    return (
        <CardProposeEmployeeSummary disabled={disabled} title={t(`${I18N_PREFIX}.subtitle`)} onClick={onClick}>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary value={loanBankAccount?.bank?.name} label={t(`${I18N_PREFIX}.input.bank.label`)} />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={loanBankAccount?.accountNumber ? `${loanBankAccount.accountNumber}-${loanBankAccount.accountDigit}` : undefined}
                    label={t(`${I18N_PREFIX}.input.accountNumber.label`)}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={t(`${I18N_PREFIX}.input.jointAccount.options.${loanBankAccount?.jointAccount}`)}
                    label={t(`${I18N_PREFIX}.input.jointAccount.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={loanBankAccount?.pixKeyType ? t(`${I18N_PREFIX}.input.pixKeyType.options.${loanBankAccount?.pixKeyType}`) : '-'}
                    label={t(`${I18N_PREFIX}.input.pixKeyType.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={loanBankAccount?.pixKey ?? '-'}
                    label={t(`${I18N_PREFIX}.input.pixKey.label`)}
                />
            </Grid>
        </CardProposeEmployeeSummary>
    );
};

export default CardAccountSummary;
