import { action } from 'typesafe-actions';
import { IEmployeeMonthlyCredit } from '../../model/employee-monthly-credit.model';
import { GiroWebappError } from '../../model/error';
import { EmployeeMonthlyCreditActionTypes } from './types';

export const employeeMonthlyCreditRequest = () => action(EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_REQUEST);
export const employeeMonthlyCreditSuccess = (credit: IEmployeeMonthlyCredit) =>
    action(EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_SUCCESS, credit);
export const employeeMonthlyCreditError = (error: GiroWebappError) =>
    action(EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_ERROR, error);

export const employeeMonthlyCreditHistoryStore = (credit: IEmployeeMonthlyCredit) =>
    action(EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_HISTORY_STORE, credit);
export const employeeMonthlyCreditHistoryRequest = () => action(EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_HISTORY_REQUEST);
export const employeeMonthlyCreditHistorySuccess = (credits: IEmployeeMonthlyCredit[]) =>
    action(EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_HISTORY_SUCCESS, credits);
export const employeeMonthlyCreditHistoryError = (error: GiroWebappError) =>
    action(EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_HISTORY_ERROR, error);

export const employeeMonthlyCreditResetState = () => action(EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_RESET_STATE);
