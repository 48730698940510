// Update supplementary data
import { updateEmployeeBorrowerProposeSupplementaryDataReducer } from 'reducer/employeeBorrower/propose/propose-supplementary-data/update-propose-supplementary-data/reducer';
import updateEmployeeBorrowerProposeSupplementaryDataSagas from 'reducer/employeeBorrower/propose/propose-supplementary-data/update-propose-supplementary-data/sagas';
import { UpdateEmployeeBorrowerProposeSupplementaryDataState } from 'reducer/employeeBorrower/propose/propose-supplementary-data/update-propose-supplementary-data/types';

//
import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

export interface EmployeeBorrowerProposeSupplementaryDataState {
    readonly updateProposeSupplementaryData: UpdateEmployeeBorrowerProposeSupplementaryDataState;
}

export const EmployeeBorrowerProposeSupplementaryDataReducer: ReducersMapObject<EmployeeBorrowerProposeSupplementaryDataState, AnyAction> = {
    updateProposeSupplementaryData: updateEmployeeBorrowerProposeSupplementaryDataReducer
};

export function* employeeBorrowerProposeSupplementaryDataSagas() {
    yield all([fork(updateEmployeeBorrowerProposeSupplementaryDataSagas)]);
}
