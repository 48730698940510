import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import createNumberMask from 'components/inputs/number-mask';
import React from 'react';
import MaskedInput from 'react-text-mask';

const currencyMask = createNumberMask({});

export const CurrencyMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={currencyMask} guide={false} keepCharPositions={false} />;
};

export default CurrencyMask;
