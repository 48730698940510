import { AxiosResponse } from 'axios';
import { FeedbackToEmployeeResult } from 'model/employee-borrower/propose/operator-feedback.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { employeeOperatorFeedbackError, employeeOperatorFeedbackRequest, employeeOperatorFeedbackSuccess } from 'reducer/employeeBorrower/propose/propose-operator-feedback/action';
import { EmployeeOperatorFeedbackTypes } from 'reducer/employeeBorrower/propose/propose-operator-feedback/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleEmployeeOperatorFeedback(action: ReturnType<typeof employeeOperatorFeedbackRequest>) {
    try {
        const result: AxiosResponse<FeedbackToEmployeeResult> = yield call(proposeApi.proposeOperatorFeedbackRequest, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeOperatorFeedbackError(mapped));
            return;
        }
        yield put(employeeOperatorFeedbackSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeOperatorFeedbackError(mapped));
    }
}

function* watchEmployeeOperatorFeedback() {
    yield takeLeading(EmployeeOperatorFeedbackTypes.EMPLOYEE_OPERATOR_FEEDBACK_REQUEST, handleEmployeeOperatorFeedback);
}

function* employeeOperatorFeedbackSaga() {
    yield all([fork(watchEmployeeOperatorFeedback)]);
}

export default employeeOperatorFeedbackSaga;
