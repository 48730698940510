import Footer, { FooterType } from 'features/footer/footer';
import Header, { HeaderType } from 'features/header/header';
import HeaderAwareness from 'features/header/header-awareness/header-awareness';
import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import AuthUtil from 'services/api/authUtil';
import ErrorHandler from 'shared/error/error-handler';
import { usePrivateRouteRedirect } from 'shared/hooks/use-private-route-redirect';

export interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    headerType?: HeaderType;
    footerType?: FooterType;
    DISABLED?: boolean;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props;

    usePrivateRouteRedirect();

    if (props.DISABLED) {
        return <Redirect to="/page-not-found" />;
    }
    if (!AuthUtil.isAuthenticated()) {
        return <Redirect to="/" />;
    }
    const header = props.headerType ?? HeaderType.EMPLOYEE;
    return (
        <Route
            {...rest}
            render={routeProps => (
                <>
                    <ErrorHandler />
                    <Header type={header} />
                    {header === HeaderType.EMPLOYEE ? (
                        <HeaderAwareness>
                            <Component {...routeProps} />
                        </HeaderAwareness>
                    ) : (
                        <Component {...routeProps} />
                    )}
                    <Footer type={props.footerType ?? FooterType.SIMPLE} />
                </>
            )}
        />
    );
};

export default PrivateRoute;
