import { ProposeEmployeeAddressData } from 'model/employee-borrower/propose/address.model';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { UpdateEmployeeBorrowerProposeAddressDataActionTypes } from './types';

export const updateEmployeeBorrowerProposeAddressDataRequest = (request: ProposeEmployeeAddressData) => action(UpdateEmployeeBorrowerProposeAddressDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_REQUEST, request);
export const updateEmployeeBorrowerProposeAddressDataSuccess = (data: ProposeEmployeeSimple) => action(UpdateEmployeeBorrowerProposeAddressDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_SUCCESS, data);
export const updateEmployeeBorrowerProposeAddressDataError = (error: GiroWebappError) => action(UpdateEmployeeBorrowerProposeAddressDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_ERROR, error);
export const updateEmployeeBorrowerProposeAddressDataResetState = () => action(UpdateEmployeeBorrowerProposeAddressDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_RESET_STATE);
