import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { EmployeeBorrowerProposeSendRequestedChangesActionTypes, EmployeeBorrowerProposeSendRequestedChangesState } from './types';

export const initialState: EmployeeBorrowerProposeSendRequestedChangesState = {
    status: HttpRequestStatus.NOOP,
    feedback: undefined,
    error: undefined
};

const reducer: Reducer<EmployeeBorrowerProposeSendRequestedChangesState> = (state = initialState, action): EmployeeBorrowerProposeSendRequestedChangesState => {
    switch (action.type) {
        case EmployeeBorrowerProposeSendRequestedChangesActionTypes.EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeBorrowerProposeSendRequestedChangesActionTypes.EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, feedback: action.payload, error: undefined };
        }
        case EmployeeBorrowerProposeSendRequestedChangesActionTypes.EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeBorrowerProposeSendRequestedChangesActionTypes.EMPLOYEE_BORROWER_PROPOSE_SEND_REQUESTED_CHANGES_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeBorrowerProposeSendRequestedChangesReducer };
