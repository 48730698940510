import { ICity } from 'model/city.model';
import { GiroWebappError } from 'model/error';
import { SearchRequest } from 'model/reducers';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';
import { SearchCitiesTypes } from './types';

export const searchCitiesRequest = (search: SearchRequest) => action(SearchCitiesTypes.SEARCH_CITIES_REQUEST, search);
export const searchCitiesSuccess = (city: Page<ICity>) => action(SearchCitiesTypes.SEARCH_CITIES_SUCCESS, city);
export const searchCitiesError = (error: GiroWebappError) => action(SearchCitiesTypes.SEARCH_CITIES_ERROR, error);
export const searchCitiesResetState = () => action(SearchCitiesTypes.SEARCH_CITIES_RESET_STATE);
