import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { UserLogin } from '../../../model/user';
import userApi from '../../../services/api/userApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { userByResetKeyError, userByResetKeyRequest, userByResetKeySuccess } from './actions';
import { UserByResetKeyActionTypes } from './types';

function* handleFindByResetKey(action: ReturnType<typeof userByResetKeyRequest>) {
    try {
        const result: AxiosResponse<UserLogin> = yield call(userApi.findByResetKey, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(userByResetKeyError(mapped));
            return;
        }
        yield put(userByResetKeySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(userByResetKeyError(mapped));
    }
}

function* watchFindByResetKey() {
    yield takeEvery(UserByResetKeyActionTypes.USER_BY_RESET_KEY_REQUEST, handleFindByResetKey);
}

function* userByResetKeySaga() {
    yield all([fork(watchFindByResetKey)]);
}

export default userByResetKeySaga;
