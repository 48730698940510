import { AxiosResponse } from 'axios';
import { ICompanyEmployee } from '../../model/company-employee.model';
import { api } from './api';

const BASE_URL = 'api/webapp/company-employees';

export const companyEmployees = () => {
    const getCurrent = (): Promise<AxiosResponse<ICompanyEmployee>> => {
        return api.get<ICompanyEmployee>(`${BASE_URL}/current`);
    };

    return {
        getCurrent,
    };
};

export default companyEmployees();
