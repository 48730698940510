import { FeedbackToEmployeeResult } from 'model/employee-borrower/propose/operator-feedback.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeOperatorFeedbackTypes {
    EMPLOYEE_OPERATOR_FEEDBACK_REQUEST = '@@employeeOperatorFeedback/EMPLOYEE_OPERATOR_FEEDBACK_REQUEST',
    EMPLOYEE_OPERATOR_FEEDBACK_SUCCESS = '@@employeeOperatorFeedback/EMPLOYEE_OPERATOR_FEEDBACK_SUCCESS',
    EMPLOYEE_OPERATOR_FEEDBACK_ERROR = '@@employeeOperatorFeedback/EMPLOYEE_OPERATOR_FEEDBACK_ERROR',
    EMPLOYEE_OPERATOR_FEEDBACK_RESET_STATE = '@@employeeOperatorFeedback/EMPLOYEE_OPERATOR_FEEDBACK_RESET_STATE',
}

export interface EmployeeOperatorFeedbackState extends BaseStoreState {
    readonly feedback?: FeedbackToEmployeeResult;
}
