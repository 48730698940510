import React from 'react';
import FormatUtils from 'shared/util/format-utils';

export interface DateProps {
    date: Date | undefined;
}

export const FormattedDate = (props: DateProps) => {
    return <>{FormatUtils.formatDate(props.date)}</>;
};

export default FormattedDate;
