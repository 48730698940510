import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import Currency from 'components/format/currency/Currency';
import FormattedDate from 'components/format/date/formatted-date';
import { PayrollLoanProposeStatus } from 'model/enums/propose-status';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useListProposeHistoryState } from 'reducer/hooks';
import 'styles/constants/_table.scss';

const I18N_PREFIX = 'employee-proposes-history';
export interface HistoryEmployeeProposesProps {
    setSelectedId: React.Dispatch<React.SetStateAction<number | null>>;
    setOpenModalCancel: (value: React.SetStateAction<boolean>) => void;
}

const ListHistoryEmployeeProposes = (props: HistoryEmployeeProposesProps) => {
    const { t } = useTranslation();
    const { setSelectedId, setOpenModalCancel } = props;
    const { proposes } = useListProposeHistoryState();
    const history = useHistory();
    const editIconButton: React.JSX.Element = (
        <>
            {t(`${I18N_PREFIX}.edit`).toUpperCase()}
            <EditIcon style={{ fontSize: '18px', paddingLeft: '2px' }} />
        </>
    );
    const cancelIconButton: React.JSX.Element = (
        <>
            {t(`${I18N_PREFIX}.cancel`).toUpperCase()}
            <CloseIcon style={{ fontSize: '18px', paddingLeft: '2px' }} />
        </>
    );

    const onCancelPress = (id: string) => {
        setOpenModalCancel(true);
        setSelectedId(Number(id));
    };

    return (
        <>
            {proposes?.content?.length !== 0 ? (
                proposes?.content?.map(
                    ({
                        id,
                        requestedDate,
                        dateToFirstPay,
                        requestedValue,
                        installmentNumber,
                        installmentValue,
                        accountNumber,
                        accountDigit,
                        status,
                    }) => (
                        <tr className={'page-container--table-cell'} key={id}>
                            <td className="page-container--table-cell-title">
                                {requestedDate ? <FormattedDate date={requestedDate} /> : '00/00/0000'}
                            </td>
                            <td className="page-container--table-cell-title">
                                <Currency value={requestedValue} />
                            </td>
                            <td className="page-container--table-cell-title">
                                {`${installmentNumber}x `}
                                <Currency value={installmentValue} />
                            </td>
                            <td className="page-container--table-cell-title">
                                {accountNumber && accountDigit ? `${accountNumber}-${accountDigit}` : ''}
                            </td>
                            <td className="page-container--table-cell-title">
                                <span className="page-container--table-status">
                                    <span
                                        className={clsx('page-container--table-status', {
                                            HISTORY: status === PayrollLoanProposeStatus.HISTORY,
                                            UNKNOWN_MANAGER: status === PayrollLoanProposeStatus.UNKNOWN_MANAGER,
                                            MANAGER_REVIEW: status === PayrollLoanProposeStatus.MANAGER_REVIEW,
                                            HUMAN_RESOURCES_REVIEW: status === PayrollLoanProposeStatus.HUMAN_RESOURCES_REVIEW,
                                            OPERATIONAL_REVIEW: status === PayrollLoanProposeStatus.OPERATIONAL_REVIEW,
                                            NEED_CHANGE: status === PayrollLoanProposeStatus.NEED_CHANGE,
                                            INVALID_BANK_ACCOUNT: status === PayrollLoanProposeStatus.INVALID_BANK_ACCOUNT,
                                            APPROVED: status === PayrollLoanProposeStatus.APPROVED,
                                            WAITING_SIGNATURE: status === PayrollLoanProposeStatus.WAITING_SIGNATURE,
                                            SIGNED: status === PayrollLoanProposeStatus.SIGNED,
                                            CANCELLED: status === PayrollLoanProposeStatus.CANCELLED,
                                            RECUSED: status === PayrollLoanProposeStatus.RECUSED,
                                        })}
                                    >
                                        {t(`${I18N_PREFIX}.status-options.${status}`).toUpperCase()}
                                    </span>
                                </span>
                            </td>
                            <td className="page-container--table-cell-title">
                                {dateToFirstPay ? <FormattedDate date={dateToFirstPay} /> : '00/00/0000'}
                            </td>

                            <td className="page-container--table">
                                <span className="material" onClick={() => history.push(`/employee-borrower/payroll-loan/${id}`)}>
                                    {
                                        ({
                                            HISTORY: editIconButton,
                                            MANAGER_REVIEW: editIconButton,
                                            HUMAN_RESOURCES_REVIEW: editIconButton,
                                            UNKNOWN_MANAGER: editIconButton,
                                            NEED_CHANGE: editIconButton,
                                            INVALID_BANK_ACCOUNT: editIconButton,
                                        } as Record<PayrollLoanProposeStatus, React.ReactNode>)[status]
                                    }
                                </span>
                            </td>

                            <td className="page-container--table">
                                <span className="material" onClick={() => onCancelPress(id)}>
                                    {
                                        ({
                                            HISTORY: cancelIconButton,
                                            MANAGER_REVIEW: cancelIconButton,
                                            HUMAN_RESOURCES_REVIEW: cancelIconButton,
                                            OPERATIONAL_REVIEW: cancelIconButton,
                                            UNKNOWN_MANAGER: cancelIconButton,
                                            NEED_CHANGE: cancelIconButton,
                                        } as Record<PayrollLoanProposeStatus, React.ReactNode>)[status]
                                    }
                                </span>
                            </td>
                        </tr>
                    )
                )
            ) : (
                <div className="history-proposes__table-empty-filter">
                    <span className="history-proposes__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                </div>
            )}
        </>
    );
};

export default ListHistoryEmployeeProposes;
