import { EMAIL_REGEX } from 'config/constants';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const MANAGER_ANALYST_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateManagerAnalystName = (value: string | undefined | null) => ValidationUtils.validate(value, MANAGER_ANALYST_NAME_VALIDATION);

const MANAGER_ANALYST_EMAIL_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    format: {
        predicate: it => !!it && EMAIL_REGEX.test(it),
        errorMessage: 'forgot-password.email.validation.format',
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateManagerAnalystEmail = (value: string | undefined | null) => ValidationUtils.validate(value, MANAGER_ANALYST_EMAIL_VALIDATION);
