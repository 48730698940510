import React from 'react';
import { useTranslation } from 'react-i18next';
import './invalid-account-card.scss';

const I18N_PREFIX = 'employee-borrower.propose.invalidAccountCard';

interface ProposeInvalidAccountCardProps {}

const ProposeInvalidAccountCard = (props: ProposeInvalidAccountCardProps) => {
    const { t } = useTranslation();

    return (
        <div className="invalid-account-card">
            <div className="invalid-account-card__header">
                <div className="invalid-account-card__header--icon" />
                <p className="invalid-account-card__header--title"> {t(`${I18N_PREFIX}.title`)} </p>
            </div>
            <p className="invalid-account-card__header--content">{t(`${I18N_PREFIX}.description`)}</p>
        </div>
    );
};

export default ProposeInvalidAccountCard;
