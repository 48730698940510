import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { EmployeeOperatorFeedbackState, EmployeeOperatorFeedbackTypes } from 'reducer/employeeBorrower/propose/propose-operator-feedback/types';
import { Reducer } from 'redux';

export const initialState: EmployeeOperatorFeedbackState = {
    status: HttpRequestStatus.NOOP,
    feedback: undefined,
    error: undefined
};

const reducer: Reducer<EmployeeOperatorFeedbackState> = (state = initialState, action): EmployeeOperatorFeedbackState => {
    switch (action.type) {
        case EmployeeOperatorFeedbackTypes.EMPLOYEE_OPERATOR_FEEDBACK_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeOperatorFeedbackTypes.EMPLOYEE_OPERATOR_FEEDBACK_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, feedback: action.payload, error: undefined };
        }
        case EmployeeOperatorFeedbackTypes.EMPLOYEE_OPERATOR_FEEDBACK_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeOperatorFeedbackTypes.EMPLOYEE_OPERATOR_FEEDBACK_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeOperatorFeedbackReducer };
