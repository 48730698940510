import { HistoryListProposeEmployee } from 'model/employee-history-proposes';
import { HistoryEmployeeProposesTypes } from './types';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { listHistoryEmployeeProposesRequest, listHistoryEmployeeProposesSuccess, listHistoryEmployeeProposesError } from './actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';
import employeeHistoryProposesApi from 'services/api/employeeHistoryProposesApi';

function* handleHistoryListEmployeeProposes(action: ReturnType<typeof listHistoryEmployeeProposesRequest>) {
    try {
        const result: AxiosResponse<Page<HistoryListProposeEmployee>> = yield call(employeeHistoryProposesApi.getAllProposesForHistory, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapLocalError(result);
            yield put(markError(mapped));
            yield put(listHistoryEmployeeProposesError(mapped));
            return;
        }
        yield put(listHistoryEmployeeProposesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listHistoryEmployeeProposesError(mapped));
    }
}

function* watchHistoryListEmployeeProposes() {
    yield takeEvery(HistoryEmployeeProposesTypes.HISTORY_LIST_EMPLOYEE_PROPOSES_REQUEST, handleHistoryListEmployeeProposes);
}

function* listHistoryEmployeeProposesSaga() {
    yield all([fork(watchHistoryListEmployeeProposes)]);
}

export default listHistoryEmployeeProposesSaga;
