import { ProposeEmployeeBankAccount } from 'model/employee-borrower/propose/account.model';
import { BaseStoreState } from 'reducer/baseStoreState';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum EmployeeBorrowerBanksAccountsActionTypes {
    EMPLOYEE_BORROWER_BANKS_ACCOUNTS_REQUEST = '@@employeeBorrowerBanksAccounts/EMPLOYEE_BORROWER_BANKS_ACCOUNTS_REQUEST',
    EMPLOYEE_BORROWER_BANKS_ACCOUNTS_SUCCESS = '@@employeeBorrowerBanksAccounts/EMPLOYEE_BORROWER_BANKS_ACCOUNTS_SUCCESS',
    EMPLOYEE_BORROWER_BANKS_ACCOUNTS_ERROR = '@@employeeBorrowerBanksAccounts/EMPLOYEE_BORROWER_BANKS_ACCOUNTS_ERROR',
    EMPLOYEE_BORROWER_BANKS_ACCOUNTS_RESET_STATE = '@@employeeBorrowerBanksAccounts/EMPLOYEE_BORROWER_BANKS_ACCOUNTS_RESET_STATE',
}

export interface EmployeeBorrowerBanksAccountsState extends BaseStoreState {
    readonly banksAccounts?: Page<ProposeEmployeeBankAccount>;
}
