import { EmployeeDocumentType } from 'model/enums/document-type'


export interface ProposeEmployeeDocumentFile {
    documentId?: number,
    type?: EmployeeDocumentType,
    fileId?: string,
    name?: string,
    data?: number[],
    uniqueKey?: string;
}

export interface ProposeEmployeeDocumentData {
    proposeId?: number;
    documentData?: {
        employeeId?: number;
        cpf?: string;
        rg?: string;
        issueDate?: string;
        issuingAgency?: string
        agencyIssuingAcronym?: string;
    }
}

export const defaultProposeEmployeeDocumentData: Readonly<ProposeEmployeeDocumentData> = {
    proposeId: undefined,
    documentData: undefined
}

export interface ProposeEmployeeDocumentFileUpload {
    proposeId?: number;
    documentFile?: ProposeEmployeeDocumentFile;
}

export const defaultProposeEmployeeDocumentFileUpload: ProposeEmployeeDocumentFileUpload[] = []

export interface ProposeEmployeeDocumentDeleteFile {
    proposeId?: number;
    documentId?: number;
}
export interface ProposeEmployeeDocumentSummary {
    employeeId?: number;
    cpf?: string;
    rg?: string;
    issueDate?: string;
    issuingAgency?: string;
    documents?: ProposeEmployeeDocumentFile[];
    agencyIssuingAcronym?: string;
}


