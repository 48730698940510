import { AxiosResponse } from 'axios';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { cancelEmployeeProposeError, cancelEmployeeProposeRequest, cancelEmployeeProposeSuccess } from './actions';
import { CancelProposeEmployeeActionTypes } from './types';


function* handleCancelPropose(action: ReturnType<typeof cancelEmployeeProposeRequest>) {
    try {
        const result: AxiosResponse<ProposeEmployeeSimple> = yield call(proposeApi.hasCancelledPropose, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(cancelEmployeeProposeError(mapped));
            return;
        }
        yield put(cancelEmployeeProposeSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(cancelEmployeeProposeError(mapped));
    }
}

function* watchCancelPropose() {
    yield takeEvery(CancelProposeEmployeeActionTypes.CANCEL_PROPOSE_EMPLOYEE_REQUEST, handleCancelPropose);
}

function* cancelProposeSaga() {
    yield all([fork(watchCancelPropose)]);
}

export default cancelProposeSaga;
