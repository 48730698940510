import { EmployeeBorrowerSimulate } from 'model/employee-borrower/simulate.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeBorrowerSimulateActionTypes {
    EMPLOYEE_BORROWER_SIMULATE_REQUEST = '@@employeeBorrowerSimulate/EMPLOYEE_BORROWER_SIMULATE_REQUEST',
    EMPLOYEE_BORROWER_SIMULATE_SUCCESS = '@@employeeBorrowerSimulate/EMPLOYEE_BORROWER_SIMULATE_SUCCESS',
    EMPLOYEE_BORROWER_SIMULATE_ERROR = '@@employeeBorrowerSimulate/EMPLOYEE_BORROWER_SIMULATE_ERROR',
    EMPLOYEE_BORROWER_SIMULATE_RESET_STATE = '@@employeeBorrowerSimulate/EMPLOYEE_BORROWER_SIMULATE_RESET_STATE',
}

export interface EmployeeBorrowerSimulateState extends BaseStoreState {
    readonly simulate?: EmployeeBorrowerSimulate;
}
