import { Authority } from 'model/enums/authorities';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTermsOfUseState,useUserAccountState } from 'reducer/hooks';
import { usePrivateRouteRedirect } from 'shared/hooks/use-private-route-redirect';

export const useRedirectToDashboard = () => {
    const history = useHistory();

    const userAccountState = useUserAccountState();
    const termsOfUseState = useTermsOfUseState();

    usePrivateRouteRedirect();

    useEffect(() => {
        if (userAccountState.status !== HttpRequestStatus.SUCCESS) return;
        if (userAccountState.account?.activated !== true) return;
        if (userAccountState.account.authorities?.every(it => it !== Authority.ROLE_EMPLOYEE)) return;
        if (termsOfUseState.status !== HttpRequestStatus.SUCCESS) return;

        history.push('/dashboard');
        history.push(termsOfUseState.terms?.acceptedTerms ? '/dashboard' : '/accept-terms-of-use');
    }, [history, userAccountState, termsOfUseState.status, termsOfUseState.terms]);
};
