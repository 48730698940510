import Checkbox from '@material-ui/core/Checkbox';
import ButtonFinish from 'components/buttons/button-finish/button-finish';
import ButtonPrevious from 'components/buttons/button-previous/button-previous';
import ScrollableTermsOfUse from 'components/terms-of-use/scroollable-terms-of-use';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { useProviderByInviteState } from 'reducer/hooks';
import { useSteps } from '../invite-steps/invite-steps-context';
import './terms-of-use-step.scss';

export interface TermsOfUseProps {
    acceptTerms: boolean;
    onFinish: (accept: boolean) => void;
}

export const TermsOfUseStep = (props: TermsOfUseProps) => {
    const steps = useSteps();
    const { t } = useTranslation();

    const state = useProviderByInviteState();

    const [accept, setAccept] = React.useState<boolean>(props.acceptTerms ?? false);

    const handleAcceptChange = () => {
        setAccept(!accept);
    };

    const haltProgress = !accept;

    const handleFinish = () => {
        if (accept) {
            props.onFinish(accept);
        }
    };

    const handlePrevious = () => {
        steps.toStep(steps.previous);
    };

    return (
        <Row>
            <div className="body__terms-of-use">
                <Row>
                    <Col md="9" style={{ margin: 'auto' }}>
                        <div className="body__terms-of-use--title">
                            <span>{t('terms-of-use.description.start')}</span>
                            {t('terms-of-use.description.middle')}
                            <span>{t('terms-of-use.description.contrast')}</span>
                        </div>
                    </Col>
                </Row>
                <Row style={{ width: '100%' }}>
                    <Col md="9" style={{ margin: 'auto' }}>
                        <ScrollableTermsOfUse />
                    </Col>
                </Row>
                <Row style={{ width: '100%' }}>
                    <Col md="9" style={{ margin: 'auto' }}>
                        <div className="body__full-terms">
                            <Col className="body__full-terms--text">
                                <span>{t('terms-of-use.full-version')} </span>
                                <Link to="/full-terms" target="_blank">
                                    {t('terms-of-use.terms-and-conditions')}
                                </Link>
                                <span> e </span>
                                <Link to="/full-privacy" target="_blank">
                                    {t('terms-of-use.privacy-policy')}
                                </Link>
                            </Col>
                            <Col className="body__full-terms--text" onClick={handleAcceptChange}>
                                <Checkbox checked={accept} />
                                <span>{t('terms-of-use.accept-terms')}</span>
                            </Col>
                        </div>
                    </Col>
                </Row>

                <Row style={{ margin: 'auto' }}>
                    <div className="body__terms-of-use--buttons">
                        <Col>
                            <div className="body__terms-of-use--button-previous">
                                <ButtonPrevious onClick={handlePrevious} />
                            </div>
                        </Col>
                        <Col>
                            <div className="body__terms-of-use--button-next">
                                <ButtonFinish
                                    onClick={handleFinish}
                                    disabled={haltProgress}
                                    isLoading={state.status === HttpRequestStatus.ONGOING}
                                />
                            </div>
                        </Col>
                    </div>
                </Row>
            </div>
        </Row>
    );
};

export default TermsOfUseStep;
