import { NON_DIGIT_SEARCH_REGEX, NON_FLOAT_CHAR_SEARCH_REGEX, PERCENTAGE_FORMATTER } from 'components/inputs/constants';
import {
    CURRENCY_FIXED_DECIMAL,
    DATE_FNS_LOCAL_DATE_FORMAT,
    NUMBER_DECIMAL_SEPARATOR,
    NUMBER_DEFAULT_DECIMAL_SEPARATOR,
    NUMBER_THOUSAND_SEPARATOR,
    UNIT_PRICE_FIXED_DECIMAL,
} from 'config/constants';
import { format, formatISO, isValid, parse, parseISO, toDate } from 'date-fns';

const EMPTY_STRING = '';
const charRegexBuilder = (value: string) => new RegExp(`[${value}]`, 'g');
const isNotDefined = (value: any) => value === null || value === undefined;

// String
const removeNonDigits = (value?: string): string => {
    return value?.replace(NON_DIGIT_SEARCH_REGEX, EMPTY_STRING) ?? EMPTY_STRING;
};

export type StringFormatFunction = (value?: string) => string | undefined;
export type StringPipeFormat = (...formatters: StringFormatFunction[]) => (value?: string) => string | undefined;

const stringPipeFormat: StringPipeFormat = (...formatters: StringFormatFunction[]) => (value?: string) => {
    return formatters.reduce((v, f) => {
        return f(v);
    }, value);
};

const REMOVE_THOUSAND_SEPARATOR: StringFormatFunction = it => it?.replace(charRegexBuilder(NUMBER_THOUSAND_SEPARATOR), EMPTY_STRING);
const REPLACE_DECIMAL_SEPARATOR: StringFormatFunction = it =>
    it?.replace(charRegexBuilder(NUMBER_DECIMAL_SEPARATOR), NUMBER_DEFAULT_DECIMAL_SEPARATOR);
const REMOVE_NON_FLOAT_CHARACTERS: StringFormatFunction = it => it?.replace(NON_FLOAT_CHAR_SEARCH_REGEX, EMPTY_STRING);

const StringUtils = {
    removeNonDigits,
    stringPipeFormat,
    REMOVE_THOUSAND_SEPARATOR,
    REPLACE_DECIMAL_SEPARATOR,
    REMOVE_NON_FLOAT_CHARACTERS,
};

// Number
const formatNumberToPercentage = (value: number, defaultValue?: number): string | undefined => {
    if (isNotDefined(value) && isNotDefined(defaultValue)) return undefined;

    const percentageValue: number = value ?? defaultValue;
    return PERCENTAGE_FORMATTER.format(percentageValue / 100);
};

const normalizeString = (value?: string): string => {
    return (
        value
            ?.replace(charRegexBuilder(NUMBER_THOUSAND_SEPARATOR), EMPTY_STRING)
            .replace(charRegexBuilder(NUMBER_DECIMAL_SEPARATOR), NUMBER_DEFAULT_DECIMAL_SEPARATOR) ?? EMPTY_STRING
    );
};

const numberToStringCurrency = (value?: number): string => {
    return value?.toFixed(CURRENCY_FIXED_DECIMAL).toString() ?? EMPTY_STRING;
};

const currencyToNumber = (value?: string): number => {
    return Number(normalizeString(value));
};

const numberToStringUnitPrice = (value?: number): string => {
    return value?.toFixed(UNIT_PRICE_FIXED_DECIMAL).toString() ?? EMPTY_STRING;
};

const unitPriceToNumber = (value?: string): number => {
    return Number(normalizeString(value));
};

const numericToDigitsString = (value: number | string | undefined): string => {
    if (value === undefined || value === null || value === EMPTY_STRING) return EMPTY_STRING;
    const numeric = Number(value);
    if (isNaN(numeric)) return EMPTY_STRING;
    return Number(numeric).toFixed(0).toString();
};

const stringToInteger = (value: string): number | undefined => {
    const numeric = Number(value?.replace(NON_DIGIT_SEARCH_REGEX, EMPTY_STRING));
    return isNaN(numeric) ? undefined : numeric;
};

const stringToDecimalPointed = (_value: string): number => {
    const numeric = Number(normalizeString(_value));
    return Number(numeric.toFixed(2));
};

const stringToNumber = (value?: string, defaultValue?: number, stringNormalizer?: StringFormatFunction): number | undefined => {
    if (isNotDefined(value)) return defaultValue;
    const normalized = stringNormalizer ? stringNormalizer(value) : value;
    if (isNotDefined(value)) return defaultValue;
    const result = Number(normalized);
    return isNaN(result) ? defaultValue : result;
};

const percentStringToNumber = (value: string): number => stringToDecimalPointed(value.split('%')[0]);

const numberToSimpleString = (_value?: number): string => _value?.toString() ?? '0,00';

const currencyStringToNumber = (value?: string): number => Number(normalizeString(value?.split('R$ ')[1]));

const numberToCurrecyLikeString = (value?: number) =>
    value?.toFixed(CURRENCY_FIXED_DECIMAL).replace(NUMBER_DEFAULT_DECIMAL_SEPARATOR, NUMBER_DECIMAL_SEPARATOR);

const NumberUtils = {
    formatNumberToPercentage,
    numberToStringCurrency,
    currencyToNumber,
    numberToStringUnitPrice,
    unitPriceToNumber,
    numericToDigitsString,
    stringToInteger,
    stringToDecimalPointed,
    numberToSimpleString,
    currencyStringToNumber,
    stringToNumber,
    numberToCurrecyLikeString,
    percentStringToNumber,
};

// Date
const formatToLocalDate = (value?: Date): string =>
    value !== undefined ? (isValid(value) ? format(value, DATE_FNS_LOCAL_DATE_FORMAT) : '') : '';

const formatToLocalDateISO = (date: Date) => formatISO(date, { representation: 'date' });

const isValidDate = (value: Date): boolean => isValid(value);

const isValidDateISO = (value: string): boolean => isValid(parseISO(value));

const parseDate = (value: any): Date => toDate(value);

const parseDateFromISO = (value: string): Date => parseISO(value);

const parseLocalDate = (value: string): Date => parse(value, DATE_FNS_LOCAL_DATE_FORMAT, new Date());

export const DateUtils = {
    formatToLocalDate,
    formatToLocalDateISO,
    isValidDate,
    isValidDateISO,
    parseDate,
    parseDateFromISO,
    parseLocalDate,
};

const InputUtils = {
    ...NumberUtils,
    ...StringUtils,
    ...DateUtils,
};

export default InputUtils;
