import { action } from 'typesafe-actions';
import { CompanyDefault } from '../../../model/company';
import { GiroWebappError } from '../../../model/error';
import { DefaultCompanyActionTypes } from './types';

export const getDefaultCompanyEmployeeRequest = () => action(DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_EMPLOYEE_REQUEST);
export const getDefaultCompanyEmployeeSuccess = (company: CompanyDefault) =>
    action(DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_EMPLOYEE_SUCCESS, company);
export const getDefaultCompanyEmployeeError = (error: GiroWebappError) =>
    action(DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_EMPLOYEE_ERROR, error);

export const changeDefaultCompanyEmployeeRequest = (id: string) =>
    action(DefaultCompanyActionTypes.CHANGE_COMPANY_EMPLOYEE_DEFAULT_REQUEST, id);
export const changeDefaultCompanyEmployeeSuccess = (company: CompanyDefault) =>
    action(DefaultCompanyActionTypes.CHANGE_COMPANY_EMPLOYEE_DEFAULT_SUCCESS, company);
export const changeDefaultCompanyEmployeeError = (error: GiroWebappError) =>
    action(DefaultCompanyActionTypes.CHANGE_COMPANY_EMPLOYEE_DEFAULT_ERROR, error);

export const defaultCompanyEmployeeResetState = () => action(DefaultCompanyActionTypes.DEFAULT_COMPANY_EMPLOYEE_RESET_STATE);
