import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { EmployeeBorrowerProposeDocumentDownloadTypes, ProposeDocumentDownloadState } from 'reducer/employeeBorrower/propose/propose-document-data/download-propose-document/types';
import { Reducer } from 'redux';

const initialState: ProposeDocumentDownloadState = {
    status: HttpRequestStatus.NOOP,
    document: undefined,
    error: undefined,
};

const reducer: Reducer<ProposeDocumentDownloadState> = (state = initialState, action): ProposeDocumentDownloadState => {
    switch (action.type) {
        case EmployeeBorrowerProposeDocumentDownloadTypes.EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeBorrowerProposeDocumentDownloadTypes.EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, document: action.payload, error: undefined };
        }
        case EmployeeBorrowerProposeDocumentDownloadTypes.EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case EmployeeBorrowerProposeDocumentDownloadTypes.EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as proposeDocumentDownloadReducer };
