import { action } from 'typesafe-actions';
import { ICompanyEmployee } from '../../model/company-employee.model';
import { GiroWebappError } from '../../model/error';
import { CompanyEmployeeActionTypes } from './types';

export const companyEmployeeRequest = () => action(CompanyEmployeeActionTypes.COMPANY_EMPLOYEE_REQUEST);

export const companyEmployeeSuccess = (employee: ICompanyEmployee) => action(CompanyEmployeeActionTypes.COMPANY_EMPLOYEE_SUCCESS, employee);

export const companyEmployeeError = (error: GiroWebappError) => action(CompanyEmployeeActionTypes.COMPANY_EMPLOYEE_ERROR, error);

export const companyEmployeeResetState = () => action(CompanyEmployeeActionTypes.COMPANY_EMPLOYEE_RESET_STATE);
