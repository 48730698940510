import { acceptTermsReducer } from 'reducer/account/accept-terms/reducer';
import acceptTermsSaga from 'reducer/account/accept-terms/sagas';
import { AcceptTermsState } from 'reducer/account/accept-terms/types';
import { changePasswordReducer } from 'reducer/account/change-password/reducer';
import changePasswordSaga from 'reducer/account/change-password/sagas';
import { ChangePasswordState } from 'reducer/account/change-password/types';
import { forgotPasswordReducer } from 'reducer/account/forgot-password/reducer';
import forgotPasswordSaga from 'reducer/account/forgot-password/sagas';
import { ForgotPasswordState } from 'reducer/account/forgot-password/types';
import { userPasswordResetReducer } from 'reducer/account/reset-password/reducer';
import userPasswordResetSaga from 'reducer/account/reset-password/sagas';
import { UserPasswordResetState } from 'reducer/account/reset-password/types';
import { termsOfUseReducer } from 'reducer/account/terms-of-use/reducer';
import termsOfUseSaga from 'reducer/account/terms-of-use/sagas';
import { TermsOfUseState } from 'reducer/account/terms-of-use/types';
import { userAccountReducer } from 'reducer/account/user-account/reducer';
import userAccountSaga from 'reducer/account/user-account/sagas';
import { UserAccountState } from 'reducer/account/user-account/types';
import { applicationErrorReducer } from 'reducer/application/error/reducer';
import { ApplicationErrorState } from 'reducer/application/error/types';
import { authenticationReducer } from 'reducer/authentication/reducer';
import authenticationSaga from 'reducer/authentication/sagas';
import { AuthenticationState } from 'reducer/authentication/types';
import { searchBanksReducer } from 'reducer/bank/search-banks/reducer';
import searchBanksSaga from 'reducer/bank/search-banks/sagas';
import { SearchBanksState } from 'reducer/bank/search-banks/types';
import { searchCitiesReducer } from 'reducer/city/search-cities/reducer';
import searchCitiesSaga from 'reducer/city/search-cities/sagas';
import { SearchCitiesState } from 'reducer/city/search-cities/types';
import { defaultCompanyReducer } from 'reducer/company/default/reducer';
import defaultCompanySaga from 'reducer/company/default/sagas';
import { DefaultCompanyEmployeeState } from 'reducer/company/default/types';
import { myCompaniesReducer } from 'reducer/company/myCompanies/reducer';
import myCompaniesSaga from 'reducer/company/myCompanies/sagas';
import { MyCompaniesState } from 'reducer/company/myCompanies/types';
import { companyEmployeeReducer } from 'reducer/companyEmployee/reducer';
import companyEmployeeSaga from 'reducer/companyEmployee/sagas';
import { CompanyEmployeeState } from 'reducer/companyEmployee/types';
import { employeeAnticipationReducer } from 'reducer/employeeAnticipation/reducer';
import employeeAnticipationSaga from 'reducer/employeeAnticipation/sagas';
import { EmployeeAnticipationState } from 'reducer/employeeAnticipation/types';
import { employeeBorrowerAnalystSearchManagersReducer } from 'reducer/employeeBorrower/analyst/search-managers/reducer';
import employeeBorrowerAnalystSearchManagersSaga from 'reducer/employeeBorrower/analyst/search-managers/sagas';
import { EmployeeBorrowerAnalystSearchManagersState } from 'reducer/employeeBorrower/analyst/search-managers/types';
import { employeeBorrowerBanksAccountsReducer } from 'reducer/employeeBorrower/bank-account/reducer';
import employeeBorrowerBanksAccountsSaga from 'reducer/employeeBorrower/bank-account/sagas';
import { EmployeeBorrowerBanksAccountsState } from 'reducer/employeeBorrower/bank-account/types';
import { employeeBorrowerAvailableServicesReducer } from 'reducer/employeeBorrower/employee-service/available-services/reducer';
import employeeBorrowerAvailableServicesSaga from 'reducer/employeeBorrower/employee-service/available-services/sagas';
import { EmployeeBorrowerAvailableServicesState } from 'reducer/employeeBorrower/employee-service/available-services/types';
import { employeeBorrowerLoanCalculationReducer } from 'reducer/employeeBorrower/loan-calculation/reducer';
import employeeBorrowerLoanCalculationSaga from 'reducer/employeeBorrower/loan-calculation/sagas';
import { EmployeeBorrowerLoanCalculationState } from 'reducer/employeeBorrower/loan-calculation/types';
import { EmployeeBorrowerProposeAccountDataReducer, employeeBorrowerProposeAccountDataSagas, EmployeeBorrowerProposeAccountDataState } from 'reducer/employeeBorrower/propose/propose-account-data/propose-account.reducer';
import { EmployeeBorrowerProposeAddressDataReducer, employeeBorrowerProposeAddressDataSagas, EmployeeBorrowerProposeAddressDataState } from 'reducer/employeeBorrower/propose/propose-address-data/propose-address-data.reducer';
import { employeeBorrowerProposeConfirmReducer } from 'reducer/employeeBorrower/propose/propose-confirm/reducer';
import employeeBorrowerProposeConfirmSaga from 'reducer/employeeBorrower/propose/propose-confirm/sagas';
import { EmployeeBorrowerProposeConfirmState } from 'reducer/employeeBorrower/propose/propose-confirm/types';
import { EmployeeBorrowerProposeDocumentReducer, employeeBorrowerProposeDocumentSagas, EmployeeBorrowerProposeDocumentState } from 'reducer/employeeBorrower/propose/propose-document-data/propose-document-data.reducer';
import { EmployeeBorrowerProposeGeneralDataReducer, employeeBorrowerProposeGeneralDataSagas, EmployeeBorrowerProposeGeneralDataState } from 'reducer/employeeBorrower/propose/propose-general-data/propose-general-data.reducer';
import { EmployeeBorrowerProposeManagerReducer, employeeBorrowerProposeManagerSagas, EmployeeBorrowerProposeManagerState } from 'reducer/employeeBorrower/propose/propose-manager/propose-manager.reducer';
import { employeeOperatorFeedbackReducer } from 'reducer/employeeBorrower/propose/propose-operator-feedback/reducer';
import employeeOperatorFeedbackSaga from 'reducer/employeeBorrower/propose/propose-operator-feedback/sagas';
import { EmployeeOperatorFeedbackState } from 'reducer/employeeBorrower/propose/propose-operator-feedback/types';
import { employeeBorrowerProposeRecentReducer } from 'reducer/employeeBorrower/propose/propose-recent/reducer';
import employeeBorrowerProposeRecentSaga from 'reducer/employeeBorrower/propose/propose-recent/sagas';
import { EmployeeBorrowerProposeRecentState } from 'reducer/employeeBorrower/propose/propose-recent/types';
import { employeeBorrowerProposeSendRequestedChangesReducer } from 'reducer/employeeBorrower/propose/propose-send-requested-changes/reducer';
import employeeBorrowerProposeSendRequestedChangesSaga from 'reducer/employeeBorrower/propose/propose-send-requested-changes/sagas';
import { EmployeeBorrowerProposeSendRequestedChangesState } from 'reducer/employeeBorrower/propose/propose-send-requested-changes/types';
import { employeeBorrowerProposeSummaryReducer } from 'reducer/employeeBorrower/propose/propose-summary/reducer';
import employeeBorrowerProposeSummarySaga from 'reducer/employeeBorrower/propose/propose-summary/sagas';
import { EmployeeBorrowerProposeSummaryState } from 'reducer/employeeBorrower/propose/propose-summary/types';
import { EmployeeBorrowerProposeSupplementaryDataReducer, employeeBorrowerProposeSupplementaryDataSagas, EmployeeBorrowerProposeSupplementaryDataState } from 'reducer/employeeBorrower/propose/propose-supplementary-data/propose-supplementary-data.reducer';
import { employeeBorrowerSimulateCalculationReducer } from 'reducer/employeeBorrower/simulate-calculation/reducer';
import employeeBorrowerSimulateCalculationSaga from 'reducer/employeeBorrower/simulate-calculation/sagas';
import { EmployeeBorrowerSimulateCalculationState } from 'reducer/employeeBorrower/simulate-calculation/types';
import { employeeBorrowerSimulateReducer } from 'reducer/employeeBorrower/simulate/reducer';
import employeeBorrowerSimulateSaga from 'reducer/employeeBorrower/simulate/sagas';
import { EmployeeBorrowerSimulateState } from 'reducer/employeeBorrower/simulate/types';
import { cancelProposeEmployeeReducer } from 'reducer/employeeCancelPropose/reducer';
import cancelProposeSaga from 'reducer/employeeCancelPropose/sagas';
import { employeeMonthlyCreditReducer } from 'reducer/employeeMonthlyCredit/reducer';
import employeeMonthlyCreditSaga from 'reducer/employeeMonthlyCredit/sagas';
import { EmployeeMonthlyCreditState } from 'reducer/employeeMonthlyCredit/types';
import { employeeProgramReducer } from 'reducer/employeeProgram/reducer';
import employeeProgramSaga from 'reducer/employeeProgram/sagas';
import { EmployeeProgramState } from 'reducer/employeeProgram/types';
import { employeeTimeEntryReducer } from 'reducer/employeeTimeEntry/reducer';
import employeeTimeEntrySaga from 'reducer/employeeTimeEntry/sagas';
import { EmployeeTimeEntryState } from 'reducer/employeeTimeEntry/types';
import { historyListEmployeeProposesReducer } from 'reducer/history-proposes/reducer';
import listHistoryEmployeeProposesSaga from 'reducer/history-proposes/sagas';
import { HistoryEmployeeProposesState } from 'reducer/history-proposes/types';
import { inviteToAcceptReducer } from 'reducer/invite/toAccept/reducer';
import inviteToAcceptSaga from 'reducer/invite/toAccept/sagas';
import { InviteToAcceptState } from 'reducer/invite/toAccept/types';
import { managerInfoReducer } from 'reducer/manager/managerInfo/reducer';
import managerInfoSaga from 'reducer/manager/managerInfo/sagas';
import { ManagerInfoState } from 'reducer/manager/managerInfo/types';
import { managerModeReducer } from 'reducer/manager/managerMode/reducer';
import managerModeSaga from 'reducer/manager/managerMode/sagas';
import { ManagerModeState } from 'reducer/manager/managerMode/types';
import { personProfileReducer } from 'reducer/person/profile/reducer';
import personProfileSaga from 'reducer/person/profile/sagas';
import { PersonProfileState } from 'reducer/person/profile/types';
import { providerByInviteReducer } from 'reducer/person/provider-invite/reducer';
import createProviderSaga from 'reducer/person/provider-invite/sagas';
import { ProviderByInviteState } from 'reducer/person/provider-invite/types';
import listProgramsSaga from 'reducer/program/list/sagas';
import { userByResetKeyReducer } from 'reducer/user/reset-key/reducer';
import userByResetKeySaga from 'reducer/user/reset-key/sagas';
import { UserByResetKeyState } from 'reducer/user/reset-key/types';
import { validateEmailReducer } from 'reducer/user/validate-email/reducer';
import validateEmailSaga from 'reducer/user/validate-email/sagas';
import { ValidateEmailState } from 'reducer/user/validate-email/types';
import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { CancelProposeEmployeeState } from './employeeCancelPropose/types';
import { FirstAccessState } from 'reducer/account/first-access/types';
import { firstAccessReducer } from 'reducer/account/first-access/reducer';
import firstAccessSaga from 'reducer/account/first-access/sagas';
import updatePersonSaga from 'reducer/account/update-person/sagas';
import { UpdatePersonState } from 'reducer/account/update-person/types';
import { updatePersonReducer } from 'reducer/account/update-person/reducer';


export interface IRootState extends
    EmployeeBorrowerProposeGeneralDataState,
    EmployeeBorrowerProposeSupplementaryDataState,
    EmployeeBorrowerProposeAddressDataState,
    EmployeeBorrowerProposeDocumentState,
    EmployeeBorrowerProposeManagerState,
    EmployeeBorrowerProposeAccountDataState {
    readonly applicationError: ApplicationErrorState;
    readonly authentication: AuthenticationState;
    readonly providerByInvite: ProviderByInviteState;
    readonly userAccout: UserAccountState;
    readonly passwordReset: UserPasswordResetState;
    readonly companyDefault: DefaultCompanyEmployeeState;
    readonly termsOfUse: TermsOfUseState;
    readonly myCompanies: MyCompaniesState;
    readonly companyEmployee: CompanyEmployeeState;
    readonly employeeAnticipation: EmployeeAnticipationState;
    readonly employeeAvailableServices: EmployeeBorrowerAvailableServicesState;
    readonly employeeBorrowerBanksAccounts: EmployeeBorrowerBanksAccountsState;
    readonly employeeBorrowerRecent: EmployeeBorrowerProposeRecentState;
    readonly employeeBorrowerSearchManagers: EmployeeBorrowerAnalystSearchManagersState;
    readonly employeeBorrowerSimulate: EmployeeBorrowerSimulateState;
    readonly employeeBorrowerSimulateCalculation: EmployeeBorrowerSimulateCalculationState;
    readonly employeeBorrowerSummary: EmployeeBorrowerProposeSummaryState;
    readonly employeeBorrowerLoanCalculation: EmployeeBorrowerLoanCalculationState;
    readonly employeeBorrowerConfirmPropose: EmployeeBorrowerProposeConfirmState;
    readonly employeeBorrowerProposeSendRequestedChanges: EmployeeBorrowerProposeSendRequestedChangesState;
    readonly employeeMonthlyCredit: EmployeeMonthlyCreditState;
    readonly employeeProgram: EmployeeProgramState;
    readonly employeeTimeEntry: EmployeeTimeEntryState;
    readonly inviteToAccept: InviteToAcceptState;
    readonly changePassword: ChangePasswordState;
    readonly personProfile: PersonProfileState;
    readonly updatePerson: UpdatePersonState;
    readonly forgotPassword: ForgotPasswordState;
    readonly firstAccess: FirstAccessState;
    readonly acceptTerms: AcceptTermsState;
    readonly userByResetKey: UserByResetKeyState;
    readonly validateEmail: ValidateEmailState;
    readonly searchBanks: SearchBanksState;
    readonly searchCities: SearchCitiesState;
    readonly listHistoryEmployeePropose: HistoryEmployeeProposesState;
    readonly managerMode: ManagerModeState;
    readonly managerInfo: ManagerInfoState;
    readonly cancelProposeEmployee: CancelProposeEmployeeState
    readonly employeeOperatorFeedback: EmployeeOperatorFeedbackState
}

const rootReducer = combineReducers<IRootState>({
    authentication: authenticationReducer,
    applicationError: applicationErrorReducer,
    providerByInvite: providerByInviteReducer,
    userAccout: userAccountReducer,
    passwordReset: userPasswordResetReducer,
    companyDefault: defaultCompanyReducer,
    termsOfUse: termsOfUseReducer,
    myCompanies: myCompaniesReducer,
    companyEmployee: companyEmployeeReducer,
    employeeAnticipation: employeeAnticipationReducer,
    employeeAvailableServices: employeeBorrowerAvailableServicesReducer,
    employeeBorrowerBanksAccounts: employeeBorrowerBanksAccountsReducer,
    employeeBorrowerRecent: employeeBorrowerProposeRecentReducer,
    employeeBorrowerSearchManagers: employeeBorrowerAnalystSearchManagersReducer,
    employeeBorrowerSimulate: employeeBorrowerSimulateReducer,
    employeeBorrowerSimulateCalculation: employeeBorrowerSimulateCalculationReducer,
    employeeBorrowerLoanCalculation: employeeBorrowerLoanCalculationReducer,
    employeeBorrowerSummary: employeeBorrowerProposeSummaryReducer,
    employeeBorrowerConfirmPropose: employeeBorrowerProposeConfirmReducer,
    employeeBorrowerProposeSendRequestedChanges: employeeBorrowerProposeSendRequestedChangesReducer,
    employeeMonthlyCredit: employeeMonthlyCreditReducer,
    employeeProgram: employeeProgramReducer,
    employeeTimeEntry: employeeTimeEntryReducer,
    inviteToAccept: inviteToAcceptReducer,
    changePassword: changePasswordReducer,
    personProfile: personProfileReducer,
    updatePerson: updatePersonReducer,
    forgotPassword: forgotPasswordReducer,
    firstAccess: firstAccessReducer,
    acceptTerms: acceptTermsReducer,
    userByResetKey: userByResetKeyReducer,
    validateEmail: validateEmailReducer,
    searchBanks: searchBanksReducer,
    searchCities: searchCitiesReducer,
    listHistoryEmployeePropose: historyListEmployeeProposesReducer,
    cancelProposeEmployee: cancelProposeEmployeeReducer,
    managerMode: managerModeReducer,
    managerInfo: managerInfoReducer,
    employeeOperatorFeedback: employeeOperatorFeedbackReducer,
    ...EmployeeBorrowerProposeGeneralDataReducer,
    ...EmployeeBorrowerProposeSupplementaryDataReducer,
    ...EmployeeBorrowerProposeAddressDataReducer,
    ...EmployeeBorrowerProposeDocumentReducer,
    ...EmployeeBorrowerProposeManagerReducer,
    ...EmployeeBorrowerProposeAccountDataReducer,
});

export type RootReducer = typeof rootReducer;

export function* rootSaga() {
    yield all([
        fork(authenticationSaga),
        fork(createProviderSaga),
        fork(userAccountSaga),
        fork(userPasswordResetSaga),
        fork(defaultCompanySaga),
        fork(termsOfUseSaga),
        fork(myCompaniesSaga),
        fork(listProgramsSaga),
        fork(companyEmployeeSaga),
        fork(employeeAnticipationSaga),
        fork(employeeBorrowerAvailableServicesSaga),
        fork(employeeBorrowerProposeAccountDataSagas),
        fork(employeeBorrowerBanksAccountsSaga),
        fork(employeeBorrowerProposeGeneralDataSagas),
        fork(employeeBorrowerProposeSupplementaryDataSagas),
        fork(employeeBorrowerProposeAddressDataSagas),
        fork(employeeBorrowerProposeDocumentSagas),
        fork(employeeBorrowerProposeManagerSagas),
        fork(employeeBorrowerProposeRecentSaga),
        fork(employeeBorrowerAnalystSearchManagersSaga),
        fork(employeeBorrowerSimulateSaga),
        fork(employeeBorrowerSimulateCalculationSaga),
        fork(employeeBorrowerLoanCalculationSaga),
        fork(employeeBorrowerProposeSummarySaga),
        fork(employeeBorrowerProposeConfirmSaga),
        fork(employeeBorrowerProposeSendRequestedChangesSaga),
        fork(employeeMonthlyCreditSaga),
        fork(employeeProgramSaga),
        fork(employeeTimeEntrySaga),
        fork(termsOfUseSaga),
        fork(inviteToAcceptSaga),
        fork(changePasswordSaga),
        fork(updatePersonSaga),
        fork(personProfileSaga),
        fork(forgotPasswordSaga),
        fork(firstAccessSaga),
        fork(acceptTermsSaga),
        fork(userByResetKeySaga),
        fork(validateEmailSaga),
        fork(searchBanksSaga),
        fork(searchCitiesSaga),
        fork(listHistoryEmployeeProposesSaga),
        fork(cancelProposeSaga),
        fork(managerModeSaga),
        fork(managerInfoSaga),
        fork(employeeOperatorFeedbackSaga)
    ]);
}

export default rootReducer;
