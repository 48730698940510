import { IEmployeeMonthlyCredit } from '../../model/employee-monthly-credit.model';
import { BaseStoreState } from '../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeMonthlyCreditActionTypes {
    EMPLOYEE_MONTHLY_CREDIT_STORE = '@@employeeMonthlyCredit/EMPLOYEE_MONTHLY_CREDIT_STORE',
    EMPLOYEE_MONTHLY_CREDIT_REQUEST = '@@employeeMonthlyCredit/EMPLOYEE_MONTHLY_CREDIT_REQUEST',
    EMPLOYEE_MONTHLY_CREDIT_SUCCESS = '@@employeeMonthlyCredit/EMPLOYEE_MONTHLY_CREDIT_SUCCESS',
    EMPLOYEE_MONTHLY_CREDIT_ERROR = '@@employeeMonthlyCredit/EMPLOYEE_MONTHLY_CREDIT_ERROR',

    EMPLOYEE_MONTHLY_CREDIT_HISTORY_STORE = '@@employeeMonthlyCredit/EMPLOYEE_MONTHLY_CREDIT_HISTORY_STORE',
    EMPLOYEE_MONTHLY_CREDIT_HISTORY_REQUEST = '@@employeeMonthlyCredit/EMPLOYEE_MONTHLY_CREDIT_HISTORY_REQUEST',
    EMPLOYEE_MONTHLY_CREDIT_HISTORY_SUCCESS = '@@employeeMonthlyCredit/EMPLOYEE_MONTHLY_CREDIT_HISTORY_SUCCESS',
    EMPLOYEE_MONTHLY_CREDIT_HISTORY_ERROR = '@@employeeMonthlyCredit/EMPLOYEE_MONTHLY_CREDIT_HISTORY_ERROR',

    EMPLOYEE_MONTHLY_CREDIT_RESET_STATE = '@@employeeMonthlyCredit/EMPLOYEE_MONTHLY_CREDIT_RESET_STATE',
}

export interface EmployeeMonthlyCreditState extends BaseStoreState {
    readonly employeeMonthlyCredits?: IEmployeeMonthlyCredit[];
    readonly employeeMonthlyCredit?: IEmployeeMonthlyCredit;
}
