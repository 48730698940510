import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootReducer, { IRootState, rootSaga } from './index';

const initialize = (initialState?: IRootState) => {
    const sagaMiddleware = createSagaMiddleware();
    const composeEnhancers = composeWithDevTools({});

    const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, sagaMiddleware)));

    sagaMiddleware.run(rootSaga);

    return store;
};

export default initialize;
