import { AxiosResponse } from 'axios';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { updateEmployeeBorrowerProposeAccountError, updateEmployeeBorrowerProposeAccountRequest, updateEmployeeBorrowerProposeAccountSuccess } from './actions';
import { UpdateEmployeeBorrowerProposeAccountActionTypes } from './types';

function* handleUpdateEmployeeBorrowerProposeAccount(action: ReturnType<typeof updateEmployeeBorrowerProposeAccountRequest>) {
    try {
        const result: AxiosResponse<ProposeEmployeeSimple> = yield call(proposeApi.updateProposeAccountRequest, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(updateEmployeeBorrowerProposeAccountError(mapped));
            return;
        }
        yield put(updateEmployeeBorrowerProposeAccountSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateEmployeeBorrowerProposeAccountError(mapped));
    }
}

function* watchUpdateEmployeeBorrowerProposeAccount() {
    yield takeLeading(UpdateEmployeeBorrowerProposeAccountActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_REQUEST, handleUpdateEmployeeBorrowerProposeAccount);
}

function* updateEmployeeBorrowerProposeAccountSaga() {
    yield all([fork(watchUpdateEmployeeBorrowerProposeAccount)]);
}

export default updateEmployeeBorrowerProposeAccountSaga;
