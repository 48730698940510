export enum KeyCode {
    ENTER = 'ENTER'
}

const keyCodeRecord: Record<number, KeyCode> = {
    13: KeyCode.ENTER,
};

const whichRecord: Record<number, KeyCode> = {
    13: KeyCode.ENTER,
};

const keyRecord: Record<string, KeyCode> = {
    Enter: KeyCode.ENTER,
};

const mapToKey = (event: React.KeyboardEvent): KeyCode | undefined => {
    return keyRecord[event.key];
};

const isKey = (event: React.KeyboardEvent, key: KeyCode): boolean => {
    return key === mapToKey(event);
};

const isAnyKey = (event: React.KeyboardEvent, keys: KeyCode[]): boolean => {
    const key = mapToKey(event);
    return keys.some(it => it === key);
};

const isEnterKey = (event: React.KeyboardEvent): boolean => {
    return isKey(event, KeyCode.ENTER);
};

export const KeyCodeUtils = {
    mapToKey,
    isKey,
    isEnterKey,
    isAnyKey
};
