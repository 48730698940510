import { AxiosResponse } from 'axios';
import { IEmployeeAnticipation } from '../../model/employee-anticipation.model';
import { api } from './api';

const BASE_URL = 'api/webapp/employee-anticipations';

export const employeeAnticipations = () => {
    const findAll = (): Promise<AxiosResponse<IEmployeeAnticipation[]>> => {
        return api.get<IEmployeeAnticipation[]>(`${BASE_URL}`);
    };
    const request = (payload: IEmployeeAnticipation): Promise<AxiosResponse<IEmployeeAnticipation>> => {
        return api.post<IEmployeeAnticipation>(`${BASE_URL}/employee-monthly-credits/${payload.month?.id}/request`, {
            amount: payload.amount,
        });
    };

    return {
        findAll,
        request,
    };
};

export default employeeAnticipations();
