import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { EmployeeBorrowerSimulateCalculationActionTypes, EmployeeBorrowerSimulateCalculationState } from './types';

export const initialState: EmployeeBorrowerSimulateCalculationState = {
    status: HttpRequestStatus.NOOP,
    simulateCalculation: undefined,
    error: undefined
};

const reducer: Reducer<EmployeeBorrowerSimulateCalculationState> = (state = initialState, action): EmployeeBorrowerSimulateCalculationState => {
    switch (action.type) {
        case EmployeeBorrowerSimulateCalculationActionTypes.EMPLOYEE_BORROWER_SIMULATE_CALCULATION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeBorrowerSimulateCalculationActionTypes.EMPLOYEE_BORROWER_SIMULATE_CALCULATION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, simulateCalculation: action.payload, error: undefined };
        }
        case EmployeeBorrowerSimulateCalculationActionTypes.EMPLOYEE_BORROWER_SIMULATE_CALCULATION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeBorrowerSimulateCalculationActionTypes.EMPLOYEE_BORROWER_SIMULATE_CALCULATION_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeBorrowerSimulateCalculationReducer };
