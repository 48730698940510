import { AxiosResponse } from 'axios';
import { EmployeeBorrowerAvailableServices } from 'model/employee-borrower/employee-services.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import employeeServicesApi from 'services/api/employee-borrower/employeeServicesApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { employeeBorrowerAvailableServicesError, employeeBorrowerAvailableServicesSuccess } from './actions';
import { EmployeeBorrowerAvailableServicesActionTypes } from './types';

function* handleEmployeeBorrowerAvailableServices() {
    try {
        const result: AxiosResponse<EmployeeBorrowerAvailableServices> = yield call(employeeServicesApi.availableServices);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerAvailableServicesError(mapped));
            return;
        }
        yield put(employeeBorrowerAvailableServicesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerAvailableServicesError(mapped));
    }
}

function* watchEmployeeBorrowerAvailableServices() {
    yield takeEvery(EmployeeBorrowerAvailableServicesActionTypes.EMPLOYEE_BORROWER_AVAILABLE_SERVICES_REQUEST, handleEmployeeBorrowerAvailableServices);
}

function* employeeBorrowerAvailableServicesSaga() {
    yield all([fork(watchEmployeeBorrowerAvailableServices)]);
}

export default employeeBorrowerAvailableServicesSaga;
