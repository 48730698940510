import React from 'react';
import { useTranslation } from 'react-i18next';
import Currency from '../../../../../components/format/currency/Currency';
import { IEmployeeProgramTaxRange } from '../../../../../model/employee-program-tax-range.model';
import './simulation-example.scss';

export interface SimulationExampleProps {
    onClose: () => void;
    taxes: IEmployeeProgramTaxRange[];
}

export const SimulationExample = (props: SimulationExampleProps) => {
    const { t } = useTranslation();

    const handleClose = () => props.onClose();

    return (
        <div className="simulation-example">
            <div className="simulation-example__container">
                <div className="simulation-example__header">
                    <div className="simulation-example__header--image" />
                    <div className="simulation-example__header--title">{t('anticipation-simulation.simulation-example.title')}</div>
                </div>
                <div className="simulation-example__cost">
                    <div className="simulation-example__fee-table">
                        {props.taxes
                            .filter(it => it.active)
                            .sort((o1, o2) => (o1.lowerAmount ?? 0) - (o2.lowerAmount ?? 0))
                            .map(it => (
                                <div key={it.id}>
                                    <div className="simulation-example__fee-table--header">
                                        <div className="simulation-example__fee-table--header-title">
                                            {t(`anticipation-simulation.simulation-example.cost.discount-fee.mode.${it.mode}`, {
                                                lowerAmount: it.lowerAmount?.toLocaleString('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                }),
                                                upperAmount: it.upperAmount?.toLocaleString('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                }),
                                            })}
                                        </div>
                                        <div className="simulation-example__fee-table--header-value">
                                            <span>
                                                <Currency value={it.taxAmount} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="simulation-example__header">
                    <div className="simulation-example__header--title">
                        {t('anticipation-simulation.simulation-example.bank.info.header')}
                    </div>
                </div>
                <div className="simulation-example__cost">
                    <div className="simulation-example__fee-table">
                        <div>
                            <div className="simulation-example__fee-table--header">
                                <div className="simulation-example__fee-table--header-title">
                                    {t(`anticipation-simulation.simulation-example.bank.info.title`)}
                                </div>
                                <div className="simulation-example__fee-table--header-value">
                                    {t(`anticipation-simulation.simulation-example.bank.info.description`)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="simulation-example__close" onClick={handleClose} />
        </div>
    );
};

export default SimulationExample;
