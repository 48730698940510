import { AxiosResponse } from 'axios';
import { ProposeEmployeeSearchManagers } from 'model/employee-borrower/propose/manager.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import analystApi from 'services/api/employee-borrower/analystApi';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';
import { employeeBorrowerAnalystSearchManagersError, employeeBorrowerAnalystSearchManagersRequest, employeeBorrowerAnalystSearchManagersSuccess } from './actions';
import { EmployeeBorrowerAnalystSearchManagersActionTypes } from './types';

function* handleEmployeeBorrowerAnalystSearchManagers(_action: ReturnType<typeof employeeBorrowerAnalystSearchManagersRequest>) {
    try {
        const result: AxiosResponse<Page<ProposeEmployeeSearchManagers>> = yield call(analystApi.analystSearchManagers, _action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerAnalystSearchManagersError(mapped));
            return;
        }
        yield put(employeeBorrowerAnalystSearchManagersSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerAnalystSearchManagersError(mapped));
    }
}

function* watchEmployeeBorrowerAnalystSearchManagers() {
    yield takeEvery(EmployeeBorrowerAnalystSearchManagersActionTypes.SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_REQUEST , handleEmployeeBorrowerAnalystSearchManagers);
}

function* employeeBorrowerAnalystSearchManagersSaga() {
    yield all([fork(watchEmployeeBorrowerAnalystSearchManagers)]);
}

export default employeeBorrowerAnalystSearchManagersSaga;
