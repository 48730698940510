import { PayrollLoanProposeStatus, RequestedType } from 'model/enums/propose-status';
import { ListHistorySortingType } from 'model/enums/proposeSortingType';
import { Pageable } from 'services/pageable';

export interface HistoryListProposeEmployee {
    id: string,
    requestedDate: Date,
    dateToFirstPay: Date,
    requestedValue: number,
    installmentNumber: number,
    installmentValue: number,
    accountNumber: string,
    accountDigit: string,
    status: PayrollLoanProposeStatus,
    type: RequestedType
}

export interface ProposeEmployeeHistoryFilterRequest {
    sortingType?: ListHistorySortingType;
    month?: string;
    year?: number;
    status?: string;
}

export interface ProposeEmployeeHistoryFilterPageable {
    pageable?: Pageable;
    filter?: ProposeEmployeeHistoryFilterRequest;
}

export const defaultHistoryProposeFilterRequest: Readonly<ProposeEmployeeHistoryFilterRequest> = {};