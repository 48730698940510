import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { useEffect, useState } from 'react';
import { useAuthenticationState, useUserAccountState } from 'reducer/hooks';
import AuthUtil from 'services/api/authUtil';

export const useAuthenticationAwareness = (): boolean => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(AuthUtil.isAuthenticated());
    const authenticationState = useAuthenticationState();
    const accountState = useUserAccountState();

    const update = AuthUtil.isAuthenticated();

    useEffect(() => {
        if (authenticationState.status === HttpRequestStatus.ONGOING) return;
        if (accountState.status === HttpRequestStatus.ONGOING) return;

        setIsAuthenticated(ps => (ps === update ? ps : update));
    }, [update, authenticationState.status, accountState.status]);

    return isAuthenticated;
};
