import { AxiosResponse } from 'axios';
import { EmployeeBorrowerSimulate } from 'model/employee-borrower/simulate.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import simulateApi from 'services/api/employee-borrower/simulateApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { employeeBorrowerSimulateError, employeeBorrowerSimulateSuccess } from './actions';
import { EmployeeBorrowerSimulateActionTypes } from './types';

function* handleGetEmployeeBorrowerSimulate() {
    try {
        const result: AxiosResponse<EmployeeBorrowerSimulate> = yield call(simulateApi.getSimulate);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerSimulateError(mapped));
            return;
        }
        yield put(employeeBorrowerSimulateSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerSimulateError(mapped));
    }
}

function* watchGetEmployeeBorrowerSimulate() {
    yield takeEvery(EmployeeBorrowerSimulateActionTypes.EMPLOYEE_BORROWER_SIMULATE_REQUEST, handleGetEmployeeBorrowerSimulate);
}

function* employeeBorrowerSimulateSaga() {
    yield all([fork(watchGetEmployeeBorrowerSimulate)]);
}

export default employeeBorrowerSimulateSaga;
