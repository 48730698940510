import { AxiosResponse } from 'axios';
import { Invite } from '../../model/invite';
import { api } from './api';

const BASE_URL = 'api/webapp/invites';

export const invites = () => {
    const getInviteByToken = (token: string): Promise<AxiosResponse<Invite>> => {
        return api.get<Invite>(`${BASE_URL}/${token}`);
    };

    return {
        getInviteByToken,
    };
};

export default invites();
