import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { FirstAccessActionTypes, FirstAccessState } from './types';

export const initialState: FirstAccessState = {
    status: HttpRequestStatus.NOOP,
    user: undefined,
    error: undefined,
};

const reducer: Reducer<FirstAccessState> = (state = initialState, action): FirstAccessState => {
    switch (action.type) {
        case FirstAccessActionTypes.FIRST_ACCESS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, user: undefined, error: undefined };
        }
        case FirstAccessActionTypes.FIRST_ACCESS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, user: action.payload, error: undefined };
        }
        case FirstAccessActionTypes.FIRST_ACCESS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case FirstAccessActionTypes.FIRST_ACCESS_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as firstAccessReducer };
