import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { SearchBanksState, SearchBanksTypes } from './types';

export const initialState: SearchBanksState = {
    status: HttpRequestStatus.NOOP,
    banks: undefined,
    error: undefined,
};

const reducer: Reducer<SearchBanksState> = (state = initialState, action): SearchBanksState => {
    switch (action.type) {
        case SearchBanksTypes.SEARCH_BANKS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case SearchBanksTypes.SEARCH_BANKS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, banks: action.payload, error: undefined };
        }
        case SearchBanksTypes.SEARCH_BANKS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case SearchBanksTypes.SEARCH_BANKS_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as searchBanksReducer };
