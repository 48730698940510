import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum EmployeeBorrowerProposeDocumentDownloadTypes {
    EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_REQUEST = '@@document-download-propose/EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_REQUEST',
    EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_SUCCESS = '@@document-download-propose/EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_SUCCESS',
    EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_ERROR = '@@document-download-propose/EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_ERROR',
    EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_RESET_STATE = '@@document-download-propose/EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DOWNLOAD_RESET_STATE',
}

export interface ProposeDocumentDownloadState extends BaseStoreState {
    readonly document?: Uint8Array;
}
