import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorType } from '../../model/enums/error-constants';
import { ErrorHandlingCustomization } from '../../model/error';
import { reset, resolveError } from '../../reducer/application/error/actions';
import { useApplicationErrorState, useRootDispatch } from '../../reducer/hooks';
import ErrorModal from './error-modal';
import ErrorToast from './error-toast';

export const ErrorHandler = () => {
    const applicationErrorState = useApplicationErrorState();
    const dispatch = useRootDispatch();
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch, location]);

    const handleToastClose = (error: string) => {
        dispatch(resolveError(error));
    };

    const handleToastAction = (error: string) => {
        const custom = applicationErrorState.customs[error];
        if (custom?.handler) {
            custom.handler(history);
        }
        dispatch(resolveError(error));
    };

    const handleModalClose = (error: string) => {
        const custom: ErrorHandlingCustomization = applicationErrorState.customs[error];
        if (custom?.action?.noAction) {
            return;
        } else if (custom?.action?.handler) {
            custom.action.handler(history, dispatch);
        } else {
            // redirect to old front remains
            // if necessary, use redux action < customizeErrorHandling > for the specific route/exception as needed
            // history.push('/giro-api');
            console.log('error handler giro-api', error);
        }
    };

    const blockingError: string | undefined =
        applicationErrorState.defaultType === ErrorType.BLOCKING
            ? applicationErrorState.errors.find(it => !!it)
            : applicationErrorState.errors.find(it => applicationErrorState.customs[it]?.type === ErrorType.BLOCKING);

    if (blockingError) {
        const custom: ErrorHandlingCustomization = applicationErrorState.customs[blockingError];
        return (
            <ErrorModal
                open
                message={custom?.message?.key ?? blockingError}
                messageParams={custom?.message?.options}
                actionLabel={custom?.action?.label}
                closable={!custom?.action?.noAction}
                onClose={() => handleModalClose(blockingError)}
            />
        );
    }

    return (
        <>
            {applicationErrorState.errors
                .filter(it => {
                    const record = applicationErrorState.customs[it];
                    return !record || !record.type || record.type === ErrorType.NON_BLOCKING;
                })
                .map((error, index) => {
                    const custom: ErrorHandlingCustomization = applicationErrorState.customs[error];
                    return (
                        <ErrorToast
                            key={index}
                            open
                            message={custom?.message?.key ?? error}
                            messageParams={custom?.message?.options}
                            actionLabel={custom?.action?.label}
                            onClose={() => handleToastClose(error)}
                            onAction={() => handleToastAction(error)}
                        />
                    );
                })}
        </>
    );
};

export default ErrorHandler;
