import React from 'react';
import './read-only-field-propose-employee-summary.scss';

interface ReadOnlyFieldProposeEmployeeSummaryProps {
    label?: string;
    value: string | React.JSX.Element | undefined;
}

const ReadOnlyFieldProposeEmployeeSummary = (props: ReadOnlyFieldProposeEmployeeSummaryProps) => {
    const { label, value } = props;

    return (
        <div className="read-only-field__propose-employee-summary">
            <span className="read-only-field__propose-employee-summary--label"> {label} </span>
            <span className="read-only-field__propose-employee-summary--value"> {value ? value : '-'} </span>
        </div>
    );
};

export default ReadOnlyFieldProposeEmployeeSummary;
