import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum UpdateEmployeeBorrowerProposeDocumentDataActionTypes {
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DATA_REQUEST = '@@updateEmployeeBorrowerProposeDocumentData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DATA_REQUEST',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DATA_SUCCESS = '@@updateEmployeeBorrowerProposeDocumentData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DATA_SUCCESS',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DATA_ERROR = '@@updateEmployeeBorrowerProposeDocumentData/UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DATA_ERROR',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_RESET_STATE = '@@updateEmployeeBorrowerProposeDocumentFileUpload/UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_RESET_STATE',
}

export interface UpdateEmployeeBorrowerProposeDocumentDataState extends BaseStoreState {
    readonly proposeDocumentData?: ProposeEmployeeSimple;
}
