import { IEmployeeProgram } from '../../model/employee-program.model';
import { Page } from '../../services/page';
import { BaseStoreState } from '../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeProgramActionTypes {
    EMPLOYEE_PROGRAM_REQUEST = '@@employeeProgram/EMPLOYEE_PROGRAM_REQUEST',
    EMPLOYEE_PROGRAM_SUCCESS = '@@employeeProgram/EMPLOYEE_PROGRAM_SUCCESS',
    EMPLOYEE_PROGRAM_ERROR = '@@employeeProgram/EMPLOYEE_PROGRAM_ERROR',
    EMPLOYEE_PROGRAM_RESET_STATE = '@@employeeProgram/EMPLOYEE_PROGRAM_RESET_STATE',
}

export interface EmployeeProgramState extends BaseStoreState {
    readonly employeePrograms?: Page<IEmployeeProgram>;
}
