import { Grid } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import IdentificationFormatter from 'components/format/identification/Identification';
import { LocalDateFormatter, LocalDateFormatType } from 'components/format/local-date/local-date-formatter';
import RgFormatter from 'components/format/rg/rg-formatter';
import CardProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-propose-employee-summary';
import ReadOnlyFieldProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/read-only-field-propose-employee-summary/read-only-field-propose-employee-summary';
import { ProposeEmployeeDocumentFile, ProposeEmployeeDocumentSummary } from 'model/employee-borrower/propose/document.model';
import { EmployeeDocumentType } from 'model/enums/document-type';
import { PersonType } from 'model/enums/person-type';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { employeeBorrowerProposeDocumentDownloadRequest } from 'reducer/employeeBorrower/propose/propose-document-data/download-propose-document/action';
import { useRootDispatch } from 'reducer/hooks';

const I18N_PREFIX = 'employee-borrower.propose.documentData';

interface CardDocumentDataSummaryProps {
    documentData?: ProposeEmployeeDocumentSummary;
    onClick: () => void;
    disabled: boolean;
}
interface ReadOnlyDocumentFileProps {
    value: string | undefined;
    onClick: () => void;
}

const ReadOnlyDocumentFile = (props: ReadOnlyDocumentFileProps) => {
    const { value, onClick } = props;
    return (
        <>
            {value ? (
                <div className="card__propose-employee-summary--document__file--selected">
                    <AttachFileIcon fontSize="small" className="card__propose-employee-summary--document__file--selected-file-icon" />
                    <span onClick={onClick} className="card__propose-employee-summary--document__file--selected-value">
                        {value}
                    </span>
                </div>
            ) : (
                '-'
            )}
        </>
    );
};

const CardDocumentDataSummary = (props: CardDocumentDataSummaryProps) => {
    const { documentData, onClick, disabled } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const companyEmployeePhotoDocument: ProposeEmployeeDocumentFile[] | undefined = documentData?.documents?.filter(
        ({ type }) => type === EmployeeDocumentType.COMPANY_EMPLOYEE_PHOTO_DOCUMENT
    );

    const companyEmployeAddressProof: ProposeEmployeeDocumentFile | undefined = documentData?.documents?.find(
        ({ type }) => type === EmployeeDocumentType.COMPANY_EMPLOYEE_ADDRESS_PROOF
    );

    const companyEmployeePaycheck: ProposeEmployeeDocumentFile | undefined = documentData?.documents?.find(
        ({ type }) => type === EmployeeDocumentType.COMPANY_EMPLOYEE_PAYCHECK
    );

    const companyEmployeeWalletRegistration: ProposeEmployeeDocumentFile | undefined = documentData?.documents?.find(
        ({ type }) => type === EmployeeDocumentType.COMPANY_EMPLOYEE_WALLET_REGISTRATION
    );

    const handleDownload = (id: number | undefined) => {
        if (!id) return;
        dispatch(employeeBorrowerProposeDocumentDownloadRequest(id));
    };

    return (
        <CardProposeEmployeeSummary disabled={disabled} title={t(`${I18N_PREFIX}.subtitle`)} onClick={onClick}>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={
                        documentData?.cpf ? <IdentificationFormatter value={documentData?.cpf} type={PersonType.INDIVIDUAL} /> : undefined
                    }
                    label={t(`${I18N_PREFIX}.input.cpf.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={documentData?.rg ? <RgFormatter value={documentData?.rg} /> : undefined}
                    label={t(`${I18N_PREFIX}.input.rg.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={
                        documentData?.issueDate ? (
                            <LocalDateFormatter value={documentData?.issueDate} type={LocalDateFormatType.LONG_FULL_DATE} />
                        ) : undefined
                    }
                    label={t(`${I18N_PREFIX}.input.issueDate.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={documentData?.issuingAgency}
                    label={t(`${I18N_PREFIX}.input.issuingAgency.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={documentData?.agencyIssuingAcronym}
                    label={t(`${I18N_PREFIX}.input.agencyIssuingAcronym.label`)}
                />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={
                        companyEmployeePhotoDocument ? (
                            <React.Fragment>
                                <ReadOnlyDocumentFile
                                    onClick={() => handleDownload(companyEmployeePhotoDocument[0]?.documentId)}
                                    value={companyEmployeePhotoDocument[0]?.name}
                                />
                            </React.Fragment>
                        ) : undefined
                    }
                    label={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_PHOTO_DOCUMENT.title`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={
                        companyEmployeePhotoDocument ? (
                            <React.Fragment>
                                <ReadOnlyDocumentFile
                                    onClick={() => handleDownload(companyEmployeePhotoDocument[1]?.documentId)}
                                    value={companyEmployeePhotoDocument[1]?.name}
                                />
                            </React.Fragment>
                        ) : undefined
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={
                        companyEmployeAddressProof ? (
                            <React.Fragment>
                                <ReadOnlyDocumentFile
                                    onClick={() => handleDownload(companyEmployeAddressProof?.documentId)}
                                    value={companyEmployeAddressProof.name}
                                />
                            </React.Fragment>
                        ) : undefined
                    }
                    label={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_ADDRESS_PROOF.title`)}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={
                        companyEmployeePaycheck ? (
                            <React.Fragment>
                                <ReadOnlyDocumentFile
                                    onClick={() => handleDownload(companyEmployeePaycheck?.documentId)}
                                    value={companyEmployeePaycheck.name}
                                />
                            </React.Fragment>
                        ) : undefined
                    }
                    label={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_PAYCHECK.title`)}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={
                        companyEmployeeWalletRegistration ? (
                            <React.Fragment>
                                <ReadOnlyDocumentFile
                                    onClick={() => handleDownload(companyEmployeeWalletRegistration?.documentId)}
                                    value={companyEmployeeWalletRegistration.name}
                                />
                            </React.Fragment>
                        ) : undefined
                    }
                    label={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_WALLET_REGISTRATION.title`)}
                />
            </Grid>
        </CardProposeEmployeeSummary>
    );
};

export default CardDocumentDataSummary;
