import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { EmployeeMonthlyCreditActionTypes, EmployeeMonthlyCreditState } from './types';

export const initialState: EmployeeMonthlyCreditState = {
    status: HttpRequestStatus.NOOP,
    employeeMonthlyCredit: undefined,
    employeeMonthlyCredits: undefined,
    error: undefined,
};

const reducer: Reducer<EmployeeMonthlyCreditState> = (state = initialState, action): EmployeeMonthlyCreditState => {
    switch (action.type) {
        case EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_STORE: {
            return { ...state, employeeMonthlyCredit: action.payload };
        }
        case EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, employeeMonthlyCredit: action.payload, error: undefined };
        }
        case EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_HISTORY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_HISTORY_STORE: {
            return { ...state, employeeMonthlyCredits: action.payload };
        }
        case EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_HISTORY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, employeeMonthlyCredits: action.payload, error: undefined };
        }
        case EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_HISTORY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeMonthlyCreditActionTypes.EMPLOYEE_MONTHLY_CREDIT_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeMonthlyCreditReducer };
