import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { UserPasswordResetRequest, UserSimple } from '../../../model/user';
import { ForgotPasswordActionTypes } from './types';

export const forgotPasswordRequest = (request: UserPasswordResetRequest) =>
    action(ForgotPasswordActionTypes.FORGOT_PASSWORD_REQUEST, request);
export const forgotPasswordSuccess = (user: UserSimple) => action(ForgotPasswordActionTypes.FORGOT_PASSWORD_SUCCESS, user);
export const forgotPasswordError = (error: GiroWebappError) => action(ForgotPasswordActionTypes.FORGOT_PASSWORD_ERROR, error);
export const forgotPasswordResetState = () => action(ForgotPasswordActionTypes.FORGOT_PASSWORD_RESET_STATE);
