import { ProposeEmployeeManagerMode } from 'model/employee-borrower/propose/manager.model';
import ManagerMode from 'model/enums/manager-mode';
import React from 'react';
import { useManagerModeProposeEmployeeState } from 'reducer/hooks';

export interface EmployeeBorrowerContextProps {
    currentStep: EmployeeBorrowerStep;
    indexedStep: number;
    disabledButton: boolean;
    completedSteps: StepCompletionRecord;
    showSlide: boolean;
    isEditing: boolean;
    toNextStep: () => void;
    toPreviousStep: () => void;
    toDisabledButtonNext: (value: boolean) => void;
    setShowSlide: (value: boolean) => void;
    isOnStep: (step: EmployeeBorrowerStep) => boolean;
    setCurrentStep: (step: EmployeeBorrowerStep) => void;
    toCheckCompletedSteps: (...step: EmployeeBorrowerStep[]) => void;
    setIsEditing: (value: boolean) => void;
    isNextStep: EmployeeBorrowerStep | undefined;
    setIsNextStep: (value: EmployeeBorrowerStep | undefined) => void;
    managerMode: ProposeEmployeeManagerMode | undefined;
    isManualManager: boolean;
    setIsManualManager: (value: boolean) => void;
}

export const StepsContext = React.createContext<EmployeeBorrowerContextProps>({} as EmployeeBorrowerContextProps);

export type EmployeeBorrowerStep =
    | 'loading'
    | 'simulate'
    | 'employeeData'
    | 'generalData'
    | 'supplementaryData'
    | 'addressData'
    | 'documentData'
    | 'account'
    | 'manager'
    | 'summary';

export const steps: EmployeeBorrowerStep[] = [
    'loading',
    'simulate',
    'employeeData',
    'generalData',
    'supplementaryData',
    'addressData',
    'documentData',
    'manager',
    'account',
    'summary',
];

type StepCompletionRecord = Record<EmployeeBorrowerStep, boolean>;

export const defaultCompletedSteps: StepCompletionRecord = {
    loading: false,
    simulate: false,
    employeeData: false,
    generalData: false,
    supplementaryData: false,
    addressData: false,
    documentData: false,
    manager: false,
    account: false,
    summary: false,
};

export const EmployeeBorrowerProvider = props => {
    const delayForLoading = 1250;
    const [indexedStep, setIndexedStep] = React.useState<number>(0);
    const [disabledButton, setDisabledButton] = React.useState<boolean>(false);
    const [completedSteps, setCompletedSteps] = React.useState<StepCompletionRecord>(defaultCompletedSteps);
    const [showSlide, setShowSlide] = React.useState<boolean>(false);
    const [currentStep, setCurrentStep] = React.useState<EmployeeBorrowerStep>(steps[indexedStep]);
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [isNextStep, setIsNextStep] = React.useState<EmployeeBorrowerStep | undefined>(undefined);

    const { managerMode } = useManagerModeProposeEmployeeState();

    const [isManualManager, setIsManualManager] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!managerMode) return;

        setIsManualManager(managerMode.managerMode === ManagerMode.MANUAL);
    }, [managerMode, setIsManualManager]);

    React.useEffect(() => {
        setCurrentStep(steps[indexedStep]);
    }, [indexedStep, setCurrentStep]);

    React.useEffect(() => {
        const stepIndex = steps.findIndex(step => step === currentStep);
        setIndexedStep(stepIndex);
    }, [currentStep, setIndexedStep]);

    const toDisabledButtonNext = React.useCallback(
        (validation: boolean) => {
            if (!validation) return setDisabledButton(false);

            setDisabledButton(true);
        },
        [setDisabledButton]
    );

    const toCompletedStep = () => {
        setCompletedSteps({ ...completedSteps, [currentStep]: true });
    };

    const toPreviousStep = () => {
        setDisabledButton(true);
        const actualStep = indexedStep;

        if ((isOnStep('account') && !isManualManager) || isOnStep('simulate') || isOnStep('generalData')) {
            return setIndexedStep(actualStep - 2);
        }

        setIndexedStep(actualStep - 1);
        return;
    };

    const toNextStep = () => {
        setDisabledButton(true);
        const actualStep = indexedStep;

        toCompletedStep();

        if ((isOnStep('documentData') && !isManualManager) || isOnStep('simulate')) {
            return setIndexedStep(actualStep + 2);
        }

        setIndexedStep(actualStep + 1);
        return;
    };

    const isOnStep = React.useCallback(
        (step: EmployeeBorrowerStep): boolean => {
            return step === steps[indexedStep];
        },
        [indexedStep]
    );

    const toCheckCompletedSteps = React.useCallback(
        (...activeStepArray: EmployeeBorrowerStep[]) => {
            const _completedSteps: StepCompletionRecord = activeStepArray.reduce((obj, step) => {
                obj[step] = true;
                return obj;
            }, defaultCompletedSteps);
            setCompletedSteps(_completedSteps);
        },
        [setCompletedSteps]
    );

    const value: EmployeeBorrowerContextProps = {
        currentStep,
        indexedStep,
        disabledButton,
        completedSteps,
        showSlide,
        toNextStep,
        toPreviousStep,
        toDisabledButtonNext,
        setShowSlide,
        isOnStep,
        setCurrentStep,
        toCheckCompletedSteps,
        isEditing,
        setIsEditing,
        isNextStep,
        setIsNextStep,
        managerMode,
        isManualManager,
        setIsManualManager,
    };

    return <StepsContext.Provider value={value}>{props.children}</StepsContext.Provider>;
};

export const useEmployeeBorrowerContext = () => React.useContext(StepsContext);

export const withEmployeeBorrowerContext = () => <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <EmployeeBorrowerProvider>
            <WrappedComponent {...props} />
        </EmployeeBorrowerProvider>
    );
};
