import { ProposeEmployeeGeneralData } from 'model/employee-borrower/propose/general-data.model';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { UpdateEmployeeBorrowerProposeGeneralDataActionTypes } from './types';

export const updateEmployeeBorrowerProposeGeneralDataRequest = (request: ProposeEmployeeGeneralData) => action(UpdateEmployeeBorrowerProposeGeneralDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_REQUEST, request);
export const updateEmployeeBorrowerProposeGeneralDataSuccess = (data: ProposeEmployeeSimple) => action(UpdateEmployeeBorrowerProposeGeneralDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_SUCCESS, data);
export const updateEmployeeBorrowerProposeGeneralDataError = (error: GiroWebappError) => action(UpdateEmployeeBorrowerProposeGeneralDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_ERROR, error);
export const updateEmployeeBorrowerProposeGeneralDataResetState = () => action(UpdateEmployeeBorrowerProposeGeneralDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_RESET_STATE);
