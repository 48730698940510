import { ProposeEmployeeManagerMode } from 'model/employee-borrower/propose/manager.model';
import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { ManagerModeActionTypes } from './types';


export const proposeEmployeeManagerModeRequest = () => action(ManagerModeActionTypes.MANAGER_MODE_REQUEST);
export const proposeEmployeeManagerModeSuccess = (data: ProposeEmployeeManagerMode) => action(ManagerModeActionTypes.MANAGER_MODE_SUCCESS, data);
export const proposeEmployeeManagerModeError = (error: GiroWebappError) => action(ManagerModeActionTypes.MANAGER_MODE_ERROR, error);
export const proposeEmployeeManagerModeResetState = () => action(ManagerModeActionTypes.MANAGER_MODE_RESET_STATE);
