import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ICompanyEmployee } from '../../model/company-employee.model';
import HttpStatus from '../../model/enums/httpStatus';
import companyEmployeeApi from '../../services/api/companyEmployeeApi';
import { ErrorUtils } from '../../shared/error/error-utils';
import { markError } from '../application/error/actions';
import { companyEmployeeError, companyEmployeeRequest, companyEmployeeSuccess } from './actions';
import { CompanyEmployeeActionTypes } from './types';

function* handleGetCompanyEmployee(_action: ReturnType<typeof companyEmployeeRequest>) {
    try {
        const result: AxiosResponse<ICompanyEmployee> = yield call(companyEmployeeApi.getCurrent);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(companyEmployeeError(mapped));
            return;
        }
        yield put(companyEmployeeSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(companyEmployeeError(mapped));
    }
}

function* watchCompanyEmployee() {
    yield takeEvery(CompanyEmployeeActionTypes.COMPANY_EMPLOYEE_REQUEST, handleGetCompanyEmployee);
}

function* companyEmployeeSaga() {
    yield all([fork(watchCompanyEmployee)]);
}

export default companyEmployeeSaga;
