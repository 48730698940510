import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import { MASK_DIGIT_REGEX } from 'components/inputs/constants';
import React from 'react';
import MaskedInput from 'react-text-mask';

const ZIP_CODE_MASK = [
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '.',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '-',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
];

export const ZipCodeMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={ZIP_CODE_MASK} guide={false} />;
};

export default ZipCodeMask;
