import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { BaseStoreState } from 'reducer/baseStoreState';


export enum CancelProposeEmployeeActionTypes {
    CANCEL_PROPOSE_EMPLOYEE_REQUEST = '@@cancelProposeEmployee/CANCEL_PROPOSE_EMPLOYEE_REQUEST',
    CANCEL_PROPOSE_EMPLOYEE_SUCCESS = '@@cancelProposeEmployee/CANCEL_PROPOSE_EMPLOYEE_SUCCESS_SUCCESS',
    CANCEL_PROPOSE_EMPLOYEE_ERROR = '@@cancelProposeEmployee/CANCEL_PROPOSE_EMPLOYEE_ERROR',
    CANCEL_PROPOSE_EMPLOYEE_STATE = '@@cancelProposeEmployee/CANCEL_PROPOSE_EMPLOYEE_RESET_STATE',
}

export interface CancelProposeEmployeeState extends BaseStoreState {
    readonly proposeId?: ProposeEmployeeSimple;
}
