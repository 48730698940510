import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { IEmployeeAnticipation } from '../../model/employee-anticipation.model';
import HttpStatus from '../../model/enums/httpStatus';
import employeeAnticipationApi from '../../services/api/employeeAnticipationApi';
import { ErrorUtils } from '../../shared/error/error-utils';
import { markError } from '../application/error/actions';
import {
    employeeAnticipationError,
    employeeAnticipationHistoryError,
    employeeAnticipationHistorySuccess,
    employeeAnticipationRequest,
    employeeAnticipationSuccess,
} from './actions';
import { EmployeeAnticipationActionTypes } from './types';

function* handleRequestEmployeeAnticipation(action: ReturnType<typeof employeeAnticipationRequest>) {
    try {
        const result: AxiosResponse<IEmployeeAnticipation> = yield call(employeeAnticipationApi.request, action.payload);
        if (result.status > HttpStatus._2XX) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeAnticipationError(mapped));
            return;
        }
        yield put(employeeAnticipationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(employeeAnticipationError(mapped));
        yield put(markError(mapped));
    }
}

function* watchRequestEmployeeAnticipations() {
    yield takeEvery(EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_REQUEST, handleRequestEmployeeAnticipation);
}

function* handleListEmployeeAnticipation() {
    try {
        const result: AxiosResponse<IEmployeeAnticipation[]> = yield call(employeeAnticipationApi.findAll);
        if (result.status > HttpStatus._2XX) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeAnticipationHistoryError(mapped));
            return;
        }
        yield put(employeeAnticipationHistorySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(employeeAnticipationHistoryError(mapped));
        yield put(markError(mapped));
    }
}

function* watchListEmployeeAnticipations() {
    yield takeEvery(EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_HISTORY_REQUEST, handleListEmployeeAnticipation);
}

function* employeeAnticipationSaga() {
    yield all([fork(watchRequestEmployeeAnticipations), fork(watchListEmployeeAnticipations)]);
}

export default employeeAnticipationSaga;
