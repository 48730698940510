import { ReactText, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';

export const useToast = () => {
    const toastId = useRef<ReactText>(0);

    const toastSuccess = useCallback((value: string) => {
        return toast(value, {
            autoClose: 2000,
            isLoading: false,
            type: 'success',
            icon: false,
        });
    }, []);

    const toastLoading = useCallback((value: string) => {
        return (toastId.current = toast.loading(value, {
            autoClose: false,
            closeButton: false,
        }));
    }, []);

    const closeToast = useCallback(() => {
        return toast.update(toastId.current, {
            autoClose: 400,
            isLoading: false,
        });
    }, []);

    return { toastSuccess, toastLoading, closeToast };
};
