import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { HistoryEmployeeProposesState, HistoryEmployeeProposesTypes } from './types';

export const initialState: HistoryEmployeeProposesState = {
    status: HttpRequestStatus.NOOP,
    proposes: undefined,
    error: null,
};

const reducer: Reducer<HistoryEmployeeProposesState> = (state = initialState, action): HistoryEmployeeProposesState => {
    switch (action.type) {
        case HistoryEmployeeProposesTypes.HISTORY_LIST_EMPLOYEE_PROPOSES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING };
        }
        case HistoryEmployeeProposesTypes.HISTORY_LIST_EMPLOYEE_PROPOSES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, proposes: action.payload, error: null };
        }
        case HistoryEmployeeProposesTypes.HISTORY_LIST_EMPLOYEE_PROPOSES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case HistoryEmployeeProposesTypes.HISTORY_LIST_EMPLOYEE_PROPOSES_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as historyListEmployeeProposesReducer };
