import { ProposeEmployeeAccountData } from 'model/employee-borrower/propose/account.model';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { UpdateEmployeeBorrowerProposeAccountActionTypes } from './types';

export const updateEmployeeBorrowerProposeAccountRequest = (request: ProposeEmployeeAccountData) => action(UpdateEmployeeBorrowerProposeAccountActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_REQUEST, request);
export const updateEmployeeBorrowerProposeAccountSuccess = (data: ProposeEmployeeSimple) => action(UpdateEmployeeBorrowerProposeAccountActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_SUCCESS, data);
export const updateEmployeeBorrowerProposeAccountError = (error: GiroWebappError) => action(UpdateEmployeeBorrowerProposeAccountActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_ERROR, error);
export const updateEmployeeBorrowerProposeAccountResetState = () => action(UpdateEmployeeBorrowerProposeAccountActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_RESET_STATE);
