import Button from '@material-ui/core/Button';
import { InputAdornmentTypeMap } from '@material-ui/core/InputAdornment';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import './button-action.scss';

export interface ButtonActionProps {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    adornment?: OverridableComponent<InputAdornmentTypeMap>;
}

export const ButtonAction = (props: ButtonActionProps): React.JSX.Element => {
    const handleClick = () => {
        if (!(props.isLoading || props.disabled)) {
            props.onClick();
        }
    };

    return (
        <div className="button__style--action">
            <Button variant="contained" onClick={handleClick} disabled={props.disabled}>
                <span> {props.label} </span>
                <span> {props.adornment} </span>
            </Button>
        </div>
    );
};

export default ButtonAction;
