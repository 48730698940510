import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import React from 'react';
import BaseTextInput, { BaseTextInputProps } from './base-text-input';

type Base = Omit<BaseTextInputProps, 'shouldShrinkLabel' | 'inputComponent' | 'changeMapper'>;

export interface BaseMaskInputProps extends Base {
    MaskInputComponent: React.ElementType<InputBaseComponentProps>;
}

export const BaseMaskInput = (props: BaseMaskInputProps) => {
    const { MaskInputComponent, onFocus, onBlur, ...otherProps } = props;

    const [isFocused, setIsFocused] = React.useState<boolean>(false);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsFocused(true);
        if (onFocus) {
            onFocus(event);
        }
    };
    const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(event);
        }
    };

    const handleShrink = (_value: string) => {
        return !!isFocused || !!_value;
    };

    return <BaseTextInput {...otherProps} inputComponent={MaskInputComponent} onFocus={handleFocus} onBlur={handleBlur} shouldShrinkLabel={handleShrink} />;
};

export default BaseMaskInput;
