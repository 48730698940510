import { Grid } from '@material-ui/core';
import { LocalDateFormatter, LocalDateFormatType } from 'components/format/local-date/local-date-formatter';
import PhoneFormatter from 'components/format/phone/phone-formatter';
import CardProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-propose-employee-summary';
import ReadOnlyFieldProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/read-only-field-propose-employee-summary/read-only-field-propose-employee-summary';
import { ProposeEmployeeGeneralDataSummary } from 'model/employee-borrower/propose/general-data.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
const I18N_PREFIX = 'employee-borrower.propose.generalData';

interface CardGeneralDataSummaryProps {
    generalData?: ProposeEmployeeGeneralDataSummary;
    onClick: () => void;
    disabled?: boolean;
}

const CardGeneralDataSummary = (props: CardGeneralDataSummaryProps) => {
    const { generalData, onClick, disabled } = props;

    const { t } = useTranslation();

    return (
        <CardProposeEmployeeSummary disabled={disabled} title={t(`${I18N_PREFIX}.subtitle`)} onClick={onClick}>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary value={generalData?.name} label={t(`${I18N_PREFIX}.input.name.label`)} />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={generalData?.mainPhoneNumber ? <PhoneFormatter value={generalData.mainPhoneNumber.number} /> : undefined}
                    label={t(`${I18N_PREFIX}.input.phoneNumber.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={
                        generalData?.birthDate ? (
                            <LocalDateFormatter value={generalData?.birthDate} type={LocalDateFormatType.LONG_FULL_DATE} />
                        ) : undefined
                    }
                    label={t(`${I18N_PREFIX}.input.birthDate.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={t(`${I18N_PREFIX}.input.gender.options.${generalData?.gender}`)}
                    label={t(`${I18N_PREFIX}.input.gender.label`)}
                />
            </Grid>
        </CardProposeEmployeeSummary>
    );
};

export default CardGeneralDataSummary;
