import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import BaseTextInput, { BaseTextInputProps } from 'components/inputs/base-text-input';
import React from "react";

type SimpleTextInputProps = Omit<BaseTextInputProps, 'error' | 'shouldShrinkLabel' | 'inputComponent' | 'changeMapper'>;

export const SimpleTextInput = (props: SimpleTextInputProps) => {
    const { type, ...otherProps } = props;
    return (
        <FormControl className={clsx('base-input', { dirty: props.value })} fullWidth>
            <BaseTextInput type={type ?? 'text'} {...otherProps} />
        </FormControl>
    );
};

export default SimpleTextInput;
