import { AxiosResponse } from 'axios';
import { ProposeEmployeeHistoryFilterPageable, HistoryListProposeEmployee } from 'model/employee-history-proposes';
import api from 'services/api/api';
import { Page } from 'services/page';

const BASE_URL = '/api/payroll-loan/employee/proposes';

export const employeeProposesHistory = () => {
    const getAllProposesForHistory = (
        request: ProposeEmployeeHistoryFilterPageable
    ): Promise<AxiosResponse<Page<HistoryListProposeEmployee>>> => {
        const { pageable, filter } = request;

        const _requestFilters = [
            filter?.sortingType ? `sortingType=${filter?.sortingType}` : undefined,
            filter?.month ? `month=${filter?.month}` : undefined,
            filter?.year ? `year=${filter?.year}` : undefined,
            filter?.status ? `status=${filter?.status}` : undefined,
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<HistoryListProposeEmployee>>(`${BASE_URL}/history?${_requestFilters}`, { params: { ...pageable } });
    };
    return {
        getAllProposesForHistory
    };
}

export default employeeProposesHistory();