import ButtonAction from 'components/buttons/button-action/button-action';
import ButtonBack from 'components/buttons/button-back/button-back';
import FieldBasic from 'components/field-basic/field-basic';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { UserPasswordResetRequest } from 'model/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { forgotPasswordRequest, forgotPasswordResetState } from 'reducer/account/forgot-password/actions';
import { useForgotPasswordState, useRootDispatch } from 'reducer/hooks';
import ModalForgotPassword from './components/modal-forgot-password';
import './forgot-password.scss';
import { validateEmail } from './validation-constants';

const useForgotPasswordAwareness = () => {
    const state = useForgotPasswordState();

    const isLoading = state.status === HttpRequestStatus.ONGOING;

    return { isLoading };
};

const useShowSuccess = () => {
    const state = useForgotPasswordState();
    const [showModal, setShowModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.SUCCESS) {
            setShowModal(ps => true);
        } else {
            setShowModal(ps => false);
        }
    }, [state.status, setShowModal]);

    return { showModal };
};

const useCleanState = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(forgotPasswordResetState());
        };
    }, [dispatch]);
};

export const ForgotPassword = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    useCleanState();

    const [email, setEmail] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const { isLoading } = useForgotPasswordAwareness();
    const { showModal } = useShowSuccess();

    const haltProgression = !validateEmail(email).isValid;

    const handleClick = async () => {
        setValidation(true);
        if (haltProgression) return;

        const _email = email;
        const payload: UserPasswordResetRequest = { email: _email };
        dispatch(forgotPasswordRequest(payload));
    };

    const handleCloseModal = () => {
        history.goBack();
    };

    return (
        <div style={{ marginTop: '150px' }} className="body__forgot-password">
            <Row>
                <Col style={{ margin: 'auto' }} md={{ size: 4 }}>
                    <div className="body__forgot-password--title">{t('forgot-password.title')}</div>
                    <form className="form__style">
                        <FieldBasic
                            label={t('forgot-password.email.label')}
                            value={email}
                            validate={validateEmail}
                            onChange={setEmail}
                            helpText={t('forgot-password.email.help')}
                            showValidation={showValidation}
                        />
                    </form>
                    <div className="container__button--actions">
                        <ButtonBack label={t('forgot-password.action.back')} onClick={history.goBack} />
                        <ButtonAction
                            label={t('forgot-password.action.send')}
                            onClick={handleClick}
                            disabled={showValidation && haltProgression}
                            isLoading={isLoading}
                        />
                    </div>
                </Col>
            </Row>
            <ModalForgotPassword open={showModal} closeModal={handleCloseModal} />
        </div>
    );
};

export default ForgotPassword;
