import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { EmployeeBorrowerSimulateActionTypes, EmployeeBorrowerSimulateState } from './types';

export const initialState: EmployeeBorrowerSimulateState = {
    status: HttpRequestStatus.NOOP,
    simulate: undefined,
    error: undefined
};

const reducer: Reducer<EmployeeBorrowerSimulateState> = (state = initialState, action): EmployeeBorrowerSimulateState => {
    switch (action.type) {
        case EmployeeBorrowerSimulateActionTypes.EMPLOYEE_BORROWER_SIMULATE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeBorrowerSimulateActionTypes.EMPLOYEE_BORROWER_SIMULATE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, simulate: action.payload, error: undefined };
        }
        case EmployeeBorrowerSimulateActionTypes.EMPLOYEE_BORROWER_SIMULATE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeBorrowerSimulateActionTypes.EMPLOYEE_BORROWER_SIMULATE_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeBorrowerSimulateReducer };
