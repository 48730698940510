import { PersonTermsAcceptance } from '../../../model/person';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum TermsOfUseActionTypes {
    HAS_ACCEPTED_TERMS_REQUEST = '@@terms-of-use/HAS_ACCEPTED_TERMS_REQUEST',
    HAS_ACCEPTED_TERMS_SUCCESS = '@@terms-of-use/HAS_ACCEPTED_TERMS_SUCCESS',
    HAS_ACCEPTED_TERMS_ERROR = '@@terms-of-use/HAS_ACCEPTED_TERMS_ERROR',

    HAS_ACCEPTED_TERMS_RESET_STATE = '@@terms-of-use/HAS_ACCEPTED_TERMS_RESET_STATE',
}

export interface TermsOfUseState extends BaseStoreState {
    readonly terms?: PersonTermsAcceptance;
}
