import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import React from 'react';
import MaskedInput from 'react-text-mask';

const PHONE_MASK = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const EXTENDED_PHONE_MASK = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const NON_DIGIT_SEARCH_REGEX = /\D/g;
const EMPTY_STRING = '';

const PHONE_MASK_FUNCTION = (value?: string) => {
    const digits = value?.replace(NON_DIGIT_SEARCH_REGEX, EMPTY_STRING);
    if (!digits || digits.length < 11) {
        return PHONE_MASK;
    }
    return EXTENDED_PHONE_MASK;
};

export const PhoneMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={PHONE_MASK_FUNCTION} guide={false} />;
};

export default PhoneMask;
