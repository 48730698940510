import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { DefaultCompanyActionTypes, DefaultCompanyEmployeeState } from './types';

export const initialState: DefaultCompanyEmployeeState = {
    status: HttpRequestStatus.NOOP,
    company: undefined,
    error: undefined,
};

const reducer: Reducer<DefaultCompanyEmployeeState> = (state = initialState, action): DefaultCompanyEmployeeState => {
    switch (action.type) {
        case DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_EMPLOYEE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, company: undefined, error: undefined };
        }
        case DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_EMPLOYEE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, company: action.payload, error: undefined };
        }
        case DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_EMPLOYEE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case DefaultCompanyActionTypes.CHANGE_COMPANY_EMPLOYEE_DEFAULT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, company: undefined, error: undefined };
        }
        case DefaultCompanyActionTypes.CHANGE_COMPANY_EMPLOYEE_DEFAULT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, company: action.payload, error: undefined };
        }
        case DefaultCompanyActionTypes.CHANGE_COMPANY_EMPLOYEE_DEFAULT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case DefaultCompanyActionTypes.DEFAULT_COMPANY_EMPLOYEE_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as defaultCompanyReducer };
