import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum EmployeeBorrowerProposeDocumentDeleteTypes {
    PROPOSE_DOCUMENT_DELETE_REQUEST = '@@propose-document-delete/PROPOSE_DOCUMENT_DELETE_REQUEST',
    PROPOSE_DOCUMENT_DELETE_SUCCESS = '@@propose-document-delete/PROPOSE_DOCUMENT_DELETE_SUCCESS',
    PROPOSE_DOCUMENT_DELETE_ERROR = '@@propose-document-delete/PROPOSE_DOCUMENT_DELETE_ERROR',
    PROPOSE_DOCUMENT_DELETE_RESET = '@@propose-document-delete/PROPOSE_DOCUMENT_DELETE_RESET',
}

export interface EmployeeBorrowerProposeDocumentDeleteState extends BaseStoreState {}
