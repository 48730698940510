import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { UpdateEmployeeBorrowerProposeGeneralDataActionTypes, UpdateEmployeeBorrowerProposeGeneralDataState } from './types';

export const initialState: UpdateEmployeeBorrowerProposeGeneralDataState = {
    status: HttpRequestStatus.NOOP,
    proposeGeneralData: undefined,
    error: undefined
};

const reducer: Reducer<UpdateEmployeeBorrowerProposeGeneralDataState> = (state = initialState, action): UpdateEmployeeBorrowerProposeGeneralDataState => {
    switch (action.type) {
        case UpdateEmployeeBorrowerProposeGeneralDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case UpdateEmployeeBorrowerProposeGeneralDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, proposeGeneralData: action.payload, error: undefined };
        }
        case UpdateEmployeeBorrowerProposeGeneralDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case UpdateEmployeeBorrowerProposeGeneralDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as updateEmployeeBorrowerProposeGeneralDataReducer };
