import { ICity } from 'model/city.model';
import { ResidenceType } from 'model/enums/residence-type';

export interface ProposeEmployeeAddressData {
    proposeId?: number;
    addressData?: {
        addressId?: number;
        street?: string;
        number?: number;
        complement?: string;
        zipCode?: string;
        city?: ICity;
        neighborhood?: string;
        rentTime?: string;
        rentAmount?: number;
        residenceType?: ResidenceType;
    }
}

export const defaultProposeEmployeeAddressData: Readonly<ProposeEmployeeAddressData> = {
    proposeId: undefined,
    addressData: undefined
}

export interface ProposeEmployeeAddressSummary {
    id: number;
    street: string;
    number: number;
    complement: string;
    zipCode: string;
    city: ICity;
    neighborhood: string;
    rentTime: string;
    rentAmount: number;
    residenceType: ResidenceType;
}