import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { EmployeeAnticipationActionTypes, EmployeeAnticipationState } from './types';

export const initialState: EmployeeAnticipationState = {
    status: HttpRequestStatus.NOOP,
    employeeAnticipation: undefined,
    employeeAnticipations: undefined,
    error: undefined,
};

const reducer: Reducer<EmployeeAnticipationState> = (state = initialState, action): EmployeeAnticipationState => {
    switch (action.type) {
        case EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, employeeAnticipation: action.payload, error: undefined };
        }
        case EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_HISTORY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_HISTORY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, employeeAnticipations: action.payload, error: undefined };
        }
        case EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_HISTORY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeAnticipationActionTypes.EMPLOYEE_ANTICIPATION_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeAnticipationReducer };
