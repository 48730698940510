import { FilledInputProps } from '@material-ui/core/FilledInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React from 'react';
import { ValidationResult } from '../../shared/util/validation-utils';
import FieldBasic from '../field-basic/field-basic';
import './field-password.scss';

export interface FieldPasswordProps {
    id?: string;
    label: string;
    value: string;
    onChange: (value: string) => void;
    validate?: (value: string) => ValidationResult;
    autoComplete?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    onEnter?: (event: React.KeyboardEvent<HTMLElement>) => void;
    helpText?: string;
    showValidation?: boolean;
    className?: string;
}

export const FieldPassword = (props: FieldPasswordProps) => {
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const { id }= props

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const adornment: Pick<FilledInputProps, 'endAdornment'> | undefined = {
        endAdornment: (
            <InputAdornment className="password__adornment" position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </InputAdornment>
        ),
    };

    const className = ['password__style--field', props.className].filter(it => !!it).join(' ');

    return (
        <FieldBasic
            id={id}
            className={className}
            type={showPassword ? 'text' : 'password'}
            InputProps={{ ...adornment }}
            label={props.label}
            value={props.value}
            inputRef={props.inputRef}
            onChange={props.onChange}
            validate={props.validate}
            onEnter={props.onEnter}
            autoComplete={props.autoComplete}
            helpText={props.helpText}
            showValidation={props.showValidation}
        />
    );
};

export default FieldPassword;
