// Update address data
import { updateEmployeeBorrowerProposeAccountReducer } from 'reducer/employeeBorrower/propose/propose-account-data/update-propose-account-data/reducer';
import updateEmployeeBorrowerProposeAccountSaga from 'reducer/employeeBorrower/propose/propose-account-data/update-propose-account-data/sagas';
import { UpdateEmployeeBorrowerProposeAccountState } from 'reducer/employeeBorrower/propose/propose-account-data/update-propose-account-data/types';
//
import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';


export interface EmployeeBorrowerProposeAccountDataState {
    readonly updateProposeAccountData: UpdateEmployeeBorrowerProposeAccountState;
}

export const EmployeeBorrowerProposeAccountDataReducer: ReducersMapObject<EmployeeBorrowerProposeAccountDataState, AnyAction> = {
    updateProposeAccountData: updateEmployeeBorrowerProposeAccountReducer
};

export function* employeeBorrowerProposeAccountDataSagas() {
    yield all([fork(updateEmployeeBorrowerProposeAccountSaga)]);
}
