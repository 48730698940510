import { ProposeEmployeeAddressData } from 'model/employee-borrower/propose/address.model';
import { ResidenceType } from 'model/enums/residence-type';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const ADDRESS_ZIP_CODE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateAddressZipCode = (value: string | undefined | null) => ValidationUtils.validate(value, ADDRESS_ZIP_CODE_VALIDATION);

const ADDRESS_STREET_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateAddressStreet = (value: string | undefined | null) => ValidationUtils.validate(value, ADDRESS_STREET_VALIDATION);

const ADDRESS_RESIDENCE_NUMBER_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty
    },
    minSize: {
        predicate: ValidationUtils.NUMBER.minSize(0)
    },
};

export const validateAddressResidenceNumber = (value: number | undefined | null) => ValidationUtils.validate(value, ADDRESS_RESIDENCE_NUMBER_VALIDATION);

const ADDRESS_COMPLEMENT_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateAddressComplement = (value: string | undefined | null) => ValidationUtils.validate(value, ADDRESS_COMPLEMENT_VALIDATION);

const ADDRESS_CITY_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateAddressCity = (value: string | undefined | null) => ValidationUtils.validate(value, ADDRESS_CITY_VALIDATION);

const ADDRESS_NEIGHBORHOOD_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateAddressNeighborhood = (value: string | undefined | null) => ValidationUtils.validate(value, ADDRESS_NEIGHBORHOOD_VALIDATION);

const ADDRESS_RENT_AMOUNT_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty
    },
    minSize: {
        predicate: ValidationUtils.NUMBER.minSize(0.1)
    },
};

export const validateAddressRentAmount = (value: number | undefined | null) => ValidationUtils.validate(value, ADDRESS_RENT_AMOUNT_VALIDATION);

const ADDRESS_RENT_TIME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: it => it !== null && it !== undefined && it.replace(/[^0-9]/g, "").length >= 1 && it!.replace(/[^a-z]/g, "").length >= 3
    },
};

export const validateAddressRentTime = (value: string | undefined | null) => ValidationUtils.validate(value, ADDRESS_RENT_TIME_VALIDATION);

const ADDRESS_RESIDENCE_TYPE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    }
};

export const validateAddressResidenceType = (value: string | undefined | null) => ValidationUtils.validate(value, ADDRESS_RESIDENCE_TYPE_VALIDATION);


const validateRentByResidenceType = (value: ProposeEmployeeAddressData): boolean => {
    const { addressData } = value;

    if (addressData?.residenceType !== ResidenceType.RENTED) {
        return true;
    }

    return validateAddressRentAmount(addressData?.rentAmount).isValid && validateAddressRentTime(addressData?.rentTime).isValid;
}

export const validateProposeEmployeeAddress = (value: ProposeEmployeeAddressData | undefined): boolean => {
    if (!value) return false;

    const { addressData } = value;

    return (
        validateAddressZipCode(addressData?.zipCode).isValid &&
        validateAddressStreet(addressData?.street).isValid &&
        validateAddressCity(addressData?.city?.id).isValid &&
        validateAddressResidenceNumber(addressData?.number).isValid &&
        validateAddressNeighborhood(addressData?.neighborhood).isValid &&
        validateAddressResidenceType(addressData?.residenceType).isValid &&
        validateRentByResidenceType(value)
    );
};
