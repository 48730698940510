export enum Authority {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_USER = 'ROLE_USER',
    ROLE_ANONYMOUS = 'ROLE_ANONYMOUS',
    ROLE_BUYER = 'ROLE_BUYER',
    ROLE_FINANCIAL = 'ROLE_FINANCIAL',
    ROLE_PROVIDER = 'ROLE_PROVIDER',
    ROLE_CLIENT = 'ROLE_CLIENT',
    ROLE_PROVIDER_CONSULT = 'ROLE_PROVIDER_CONSULT',
    ROLE_ORGANIZATION_SUPPORT = 'ROLE_ORGANIZATION_SUPPORT',
    ROLE_GIRO_SUPPORT = 'ROLE_GIRO_SUPPORT',
    ROLE_APPROVAL = 'ROLE_APPROVAL',
    ROLE_EMPLOYEE = 'ROLE_EMPLOYEE',
}
