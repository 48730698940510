import { TFunctionResult } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './readonly-text-field.scss';

export interface ReadOnlyTextFieldProps {
    label: string;
    alignCenter?: boolean;
    value: string | number | undefined | React.JSX.Element | TFunctionResult;
}

export const ReadOnlyTextField = (props: ReadOnlyTextFieldProps) => {
    const { label, value, alignCenter } = props;
    const { t } = useTranslation();

    return (
        <dl className="readonly__text-field">
            {alignCenter ? (
                <>
                    <dt className="readonly__text-field-label--center">{t(label)}</dt>
                    <dd className="readonly__text-field-content--center">{value}</dd>
                </>
            ) : (
                <>
                    <dt className="readonly__text-field-label">{t(label)}</dt>
                    <dd className="readonly__text-field-content">{value}</dd>
                </>
            )}
        </dl>
    );
};

export default ReadOnlyTextField;
