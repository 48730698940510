import React from 'react';
import HeaderEmployee from './header-employee/header-employee';
import SimpleHeader from './simple-header';

export enum HeaderType {
    NONE = 'NONE',
    SIMPLE = 'SIMPLE',
    EMPLOYEE = 'EMPLOYEE',
}

export interface HeaderProps {
    type: HeaderType;
}

export const Header = (props: HeaderProps) => {
    switch (props.type) {
        case HeaderType.EMPLOYEE:
            return <HeaderEmployee />;
        case HeaderType.SIMPLE:
            return <SimpleHeader />;
        case HeaderType.NONE:
        default:
            return <></>;
    }
};

export default Header;
