import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { UserAccessToken } from '../../../model/user';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { firstAccessError, firstAccessRequest, firstAccessSuccess } from './actions';
import { FirstAccessActionTypes } from './types';
import firstAccess from 'services/api/firstAccessApi';


function* handleFirstAccess(action: ReturnType<typeof firstAccessRequest>) {
  try {
    const result: AxiosResponse<UserAccessToken> = yield call(firstAccess.sendPersonInfo, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(firstAccessError(mapped));
      return;
    }
    yield put(firstAccessSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(firstAccessError(mapped));
  }
}

function* watchFirstAccess() {
  yield takeEvery(FirstAccessActionTypes.FIRST_ACCESS_REQUEST, handleFirstAccess);
}

function* firstAccessSaga() {
  yield all([fork(watchFirstAccess)]);
}

export default firstAccessSaga;
