import { Grid, MenuItem } from '@material-ui/core';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import ValidMonthInput from 'components/inputs/date-input/month-input/valid-month-input';
import ValidYearInput from 'components/inputs/date-input/year-input/valid-year-input';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import { validateMonth, validateYear } from 'features/history-proposes/components/validation-date/validation-date';
import { ProposeEmployeeHistoryFilterRequest } from 'model/employee-history-proposes';
import { PayrollLoanProposeStatus } from 'model/enums/propose-status';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import './history-proposes-modal-filter.scss';

export interface HistoryProposesModalFilterProps {
    onClose: () => void;
    openModal: boolean;
    title: string;
    filterActive: ProposeEmployeeHistoryFilterRequest;
    setFilterActive: (value: ProposeEmployeeHistoryFilterRequest) => void;
}

const I18N_PREFIX = 'employee-proposes-history.modal.filter';

const HistoryProposesModalFilter = (props: HistoryProposesModalFilterProps) => {
    const { onClose, openModal, title, setFilterActive, filterActive } = props;
    const [filterRequest, setFilterRequest] = useState<ProposeEmployeeHistoryFilterRequest>(filterActive);
    const { t } = useTranslation();
    const monthIsValid = validateMonth(filterRequest.month).isValid;
    const yearIsValid = validateYear(filterRequest.year).isValid;

    const handleConfirmRequest = () => {
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<ProposeEmployeeHistoryFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleClearFilter = () => {
        return setFilterRequest({});
    };

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    return openModal ? (
        <Modal isOpen>
            <div className="history-proposes-modal-filter--container">
                <div className="history-proposes-modal-filter--header">
                    <label className="history-proposes-modal-filter--header-title"> {title} </label>
                    <CloseButton onClick={onClose} />
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ValidYearInput
                            id="inputDate_proposesHistory_year"
                            validate={validateYear}
                            showValidation={!yearIsValid}
                            externalUpdate
                            label={t(`${I18N_PREFIX}.year`)}
                            placeholder={t(`${I18N_PREFIX}.year`)}
                            value={filterRequest.year!}
                            onChange={year => handleChange({ ...filterRequest, year })}
                            endAdornment
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ValidMonthInput
                            id="inputDate_proposesHistory_month"
                            validate={validateMonth}
                            showValidation={!monthIsValid}
                            externalUpdate
                            label={t(`${I18N_PREFIX}.month`)}
                            placeholder={t(`${I18N_PREFIX}.month`)}
                            value={filterRequest.month!}
                            onChange={month => handleChange({ ...filterRequest, month })}
                            endAdornment
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleSelectInput
                            id="inputSelect_proposesHistory_status"
                            label={t(`${I18N_PREFIX}.status`)}
                            value={filterRequest?.status ?? ''}
                            mapperFromString={_value => (_value ? PayrollLoanProposeStatus[_value] : undefined)}
                            placeholder={t(`${I18N_PREFIX}.status`)}
                            onChange={(status: string) => handleChange({ status: PayrollLoanProposeStatus[status] })}
                            externalUpdate
                        >
                            <MenuItem value={PayrollLoanProposeStatus.HISTORY}>{t(`${I18N_PREFIX}.options.history`)}</MenuItem>
                            <MenuItem value={PayrollLoanProposeStatus.MANAGER_REVIEW}>
                                {t(`${I18N_PREFIX}.options.manager-review`)}
                            </MenuItem>
                            <MenuItem value={PayrollLoanProposeStatus.HUMAN_RESOURCES_REVIEW}>
                                {t(`${I18N_PREFIX}.options.hr-review`)}
                            </MenuItem>
                            <MenuItem value={PayrollLoanProposeStatus.OPERATIONAL_REVIEW}>
                                {t(`${I18N_PREFIX}.options.operational-review`)}
                            </MenuItem>
                            <MenuItem value={PayrollLoanProposeStatus.UNKNOWN_MANAGER}>
                                {t(`${I18N_PREFIX}.options.unknown-manager`)}
                            </MenuItem>
                            <MenuItem value={PayrollLoanProposeStatus.NEED_CHANGE}>{t(`${I18N_PREFIX}.options.need-change`)}</MenuItem>
                            <MenuItem value={PayrollLoanProposeStatus.INVALID_BANK_ACCOUNT}>
                                {t(`${I18N_PREFIX}.options.invalid-bank-account`)}
                            </MenuItem>
                            <MenuItem value={PayrollLoanProposeStatus.APPROVED}>{t(`${I18N_PREFIX}.options.approved`)}</MenuItem>
                            <MenuItem value={PayrollLoanProposeStatus.WAITING_SIGNATURE}>
                                {t(`${I18N_PREFIX}.options.waiting-signature`)}
                            </MenuItem>
                            <MenuItem value={PayrollLoanProposeStatus.CANCELLED}>{t(`${I18N_PREFIX}.options.cancelled`)}</MenuItem>
                            <MenuItem value={PayrollLoanProposeStatus.SIGNED}>{t(`${I18N_PREFIX}.options.signed`)}</MenuItem>
                        </SimpleSelectInput>
                    </Grid>
                </Grid>
                <div className="history-proposes-modal-filter--buttons-actions">
                    <ClearButton onClick={handleClearFilter} />
                    <StandardButton label="entity.action.confirm" onClick={handleConfirmRequest} disabled={!yearIsValid || !monthIsValid} />
                </div>
            </div>
        </Modal>
    ) : (
        <> </>
    );
};

export default HistoryProposesModalFilter;
