import { AxiosResponse } from 'axios';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import employeeBorrowerProposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { updateEmployeeBorrowerProposeAddressDataError, updateEmployeeBorrowerProposeAddressDataRequest, updateEmployeeBorrowerProposeAddressDataSuccess } from './actions';
import { UpdateEmployeeBorrowerProposeAddressDataActionTypes } from './types';

function* handleUpdateEmployeeBorrowerProposeAddressData(action: ReturnType<typeof updateEmployeeBorrowerProposeAddressDataRequest>) {
    try {
        const result: AxiosResponse<ProposeEmployeeSimple> = yield call(employeeBorrowerProposeApi.updateProposeAddressData, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(updateEmployeeBorrowerProposeAddressDataError(mapped));
            return;
        }
        yield put(updateEmployeeBorrowerProposeAddressDataSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateEmployeeBorrowerProposeAddressDataError(mapped));
    }
}

function* watchUpdateEmployeeBorrowerProposeAddressData() {
    yield takeLeading(UpdateEmployeeBorrowerProposeAddressDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_ADDRESS_DATA_REQUEST, handleUpdateEmployeeBorrowerProposeAddressData);
}


function* updateEmployeeBorrowerProposeAddressDataSagas() {
    yield all([fork(watchUpdateEmployeeBorrowerProposeAddressData)]);
}

export default updateEmployeeBorrowerProposeAddressDataSagas;
