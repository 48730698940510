import ButtonFinish from 'components/buttons/button-finish/button-finish';
import ButtonPrevious from 'components/buttons/button-previous/button-previous';
import FieldPassword from 'components/field-password/field-password';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { FOCUS_DELAY } from '../../animation-constants';
import { PasswordMatch, validatePasswordMatch } from '../../validation-constants';
import './confirm-only.scss';

export interface ConfirmOnlyProps {
    username: string;
    password: string;
    isLoading: boolean;
    onPrevious: () => void;
    onFinish: (password: string, confirm: string) => void;
}

const mapPasswordMatch = (p: string, m: string): PasswordMatch => ({ password: p, match: m });

export const ConfirmOnly = (props: ConfirmOnlyProps) => {
    const { t } = useTranslation();
    const [confirm, setConfirm] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const INPUT_REF = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        const timer = setTimeout(() => INPUT_REF.current?.focus({ preventScroll: true }), FOCUS_DELAY);
        return () => clearTimeout(timer);
    }, [INPUT_REF]);

    const haltProgression = !validatePasswordMatch(mapPasswordMatch(props.password, confirm)).isValid;

    const handleNext = () => {
        setValidation(true);
        if (haltProgression) return;

        props.onFinish(props.password, confirm);
    };

    return (
        <div className="body__reset-confirm-only">
            <Row style={{ width: '100%' }}>
                <Col md="10" style={{ margin: 'auto' }}>
                    <div style={{ marginBottom: '0' }} className="body__reset-confirm-only--title">
                        {t('password-reset.confirm-title')}
                    </div>
                </Col>
            </Row>
            <div className="body__reset-confirm-only--components">
                <Col md="6" offset="3">
                    <form className="form__style">
                        <input id="confirm-hidden-username" hidden readOnly value={props.username} autoComplete="username" />
                        <FieldPassword
                            className="password__input"
                            value={confirm}
                            label={t('password-reset.confirm.label')}
                            onChange={setConfirm}
                            validate={it => validatePasswordMatch(mapPasswordMatch(props.password, it))}
                            autoComplete="new-password"
                            onEnter={handleNext}
                            inputRef={INPUT_REF}
                            showValidation={showValidation}
                        />
                    </form>
                </Col>
            </div>
            <Row>
                <div className="body__reset-confirm-only--buttons">
                    <Col>
                        <div className="body__reset-confirm-only--button-previous">
                            <ButtonPrevious onClick={props.onPrevious} />
                        </div>
                    </Col>
                    <Col>
                        <div className="body__reset-confirm-only--button-finish">
                            <ButtonFinish onClick={handleNext} disabled={showValidation && haltProgression} isLoading={props.isLoading} />
                        </div>
                    </Col>
                </div>
            </Row>
        </div>
    );
};

export default ConfirmOnly;
