import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { UserSimple } from '../../../model/user';
import accountApi from '../../../services/api/accountApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { changePasswordError, changePasswordRequest, changePasswordSuccess } from './actions';
import { ChangePasswordActionTypes } from './types';

function* handleChangePassword(action: ReturnType<typeof changePasswordRequest>) {
    try {
        const result: AxiosResponse<UserSimple> = yield call(accountApi.changePassword, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(changePasswordError(mapped));
            return;
        }
        yield put(changePasswordSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(changePasswordError(mapped));
    }
}

function* watchChangePassword() {
    yield takeEvery(ChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST, handleChangePassword);
}

function* changePasswordSaga() {
    yield all([fork(watchChangePassword)]);
}

export default changePasswordSaga;
