import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { EmployeeBorrowerAnalystSearchManagersActionTypes, EmployeeBorrowerAnalystSearchManagersState } from './types';

export const initialState: EmployeeBorrowerAnalystSearchManagersState = {
    status: HttpRequestStatus.NOOP,
    managers: undefined,
    error: undefined,
};

const reducer: Reducer<EmployeeBorrowerAnalystSearchManagersState> = (state = initialState, action): EmployeeBorrowerAnalystSearchManagersState => {
    switch (action.type) {
        case EmployeeBorrowerAnalystSearchManagersActionTypes.SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeBorrowerAnalystSearchManagersActionTypes.SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, managers: action.payload, error: undefined };
        }
        case EmployeeBorrowerAnalystSearchManagersActionTypes.SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeBorrowerAnalystSearchManagersActionTypes.SEARCH_EMPLOYEE_BORROWER_ANALYST_MANAGERS_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeBorrowerAnalystSearchManagersReducer };
