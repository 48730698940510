import { IBank } from 'model/bank.model';
import { BankAccountType } from 'model/enums/bank-account-type.model';
import { PixKeyType } from 'model/enums/pix-key-type.model';

export interface ProposeEmployeeAccountData {
    proposeId?: number;
    accountData?: {
        loanAccountId?: number;
        bankAccountId?: string;
        agencyNumber?: number;
        agencyDigit?: number;
        accountNumber?: number;
        accountDigit?: number;
        type?: BankAccountType;
        pixKey?: string;
        pixKeyType?: PixKeyType;
        bank?: IBank;
        jointAccount?: boolean;
    }
}

export const defaultProposeEmployeeAccountData: ProposeEmployeeAccountData = {
    accountData: {
        jointAccount: false,
    }
}

export interface ProposeEmployeeBankAccount {
    bankAccountId?: string;
    agencyNumber?: number;
    agencyDigit?: number;
    accountNumber?: number;
    accountDigit?: number;
    type?: BankAccountType;
    pixKey?: string;
    pixKeyType?: PixKeyType;
    bank?: IBank;
    jointAccount?: boolean;
}


export interface ProposeEmployeeAccountDataSummary {
    loanAccountId?: number;
    agencyNumber?: number;
    agencyDigit?: number;
    accountNumber?: number;
    accountDigit?: number;
    type?: BankAccountType;
    pixKey?: string;
    pixKeyType?: PixKeyType;
    bank?: IBank;
    jointAccount?: boolean;
}

