import MenuItem, { MenuItemPropsList } from 'features/header/components/menu-item/menu-item';
import React from 'react';

const I18N_PREFIX = 'header.header-employee';

export const EntitiesMenu = () => {
    const entities: MenuItemPropsList[] = [
        {
            link: '/dashboard',
            translation: `${I18N_PREFIX}.first-menu`,
        },
        {
          link: '/proposes/history',
          translation: `${I18N_PREFIX}.second-menu`,
      },
    ];

    return <MenuItem list={entities} menu />;
};

export default EntitiesMenu;
