import FormControl from '@material-ui/core/FormControl';
import BaseCheckboxInput, { BaseCheckboxInputProps } from 'components/inputs/checkbox-input/base-checkbox-input';
import React from 'react';

export type SimpleCheckboxInputProps = BaseCheckboxInputProps;

export const SimpleCheckboxInput = (props: SimpleCheckboxInputProps) => {
    const { color, id, ...otherProps } = props;
    return (
        <FormControl fullWidth>
            <BaseCheckboxInput id={id} {...otherProps} color={color ?? 'primary'} />
        </FormControl>
    );
};

export default SimpleCheckboxInput;
