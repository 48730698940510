import { FormControl } from '@material-ui/core';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import NumberMask from 'components/inputs/number-input/number-mask';
import React from 'react';

type Base = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;

export interface SimpleNumberInputProps extends Base {
    value?: number;
    onChange: (value: number | string | undefined) => void;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    hasDecimal?: boolean;
}

export const SimpleNumberInput = ({ hasDecimal = true, ...props }: SimpleNumberInputProps) => {
    const { value, onChange, MaskInputComponent, ...otherProps } = props;

    const handleOnChange = (_value: string) => {
        if (_value === '') {
            onChange(undefined);
            return;
        }

        if (hasDecimal) {
            onChange(InputUtils.stringToDecimalPointed(_value));
        } else {
            onChange(_value);
        }
    };

    return (
        <FormControl fullWidth className={clsx('base-input', { dirty: value })}>
            <BaseMaskInput
                {...otherProps}
                value={value ? InputUtils.numberToSimpleString(value) : undefined}
                onChange={handleOnChange}
                MaskInputComponent={MaskInputComponent ?? NumberMask}
            />
        </FormControl>
    );
};

export default SimpleNumberInput;
