// Update document data
import { proposeDocumentDeleteReducer } from 'reducer/employeeBorrower/propose/propose-document-data/delete-propose-document-data/reducer';
import proposeDocumentDeleteSaga from 'reducer/employeeBorrower/propose/propose-document-data/delete-propose-document-data/sagas';
import { EmployeeBorrowerProposeDocumentDeleteState } from 'reducer/employeeBorrower/propose/propose-document-data/delete-propose-document-data/types';
import { proposeDocumentDownloadReducer } from 'reducer/employeeBorrower/propose/propose-document-data/download-propose-document/reducer';
import employeeBorrowerProposeDocumentDownloadSaga from 'reducer/employeeBorrower/propose/propose-document-data/download-propose-document/sagas';
import { ProposeDocumentDownloadState } from 'reducer/employeeBorrower/propose/propose-document-data/download-propose-document/types';
import { updateEmployeeBorrowerProposeDocumentDataReducer } from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-data/reducer';
import updateEmployeeBorrowerProposeDocumentDataSaga from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-data/sagas';
import { UpdateEmployeeBorrowerProposeDocumentDataState } from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-data/types';

// Update document file upload
import { updateEmployeeBorrowerProposeDocumentFileUploadReducer } from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-file-upload/reducer';
import updateEmployeeBorrowerProposeDocumentFileUploadSaga from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-file-upload/sagas';
import { UpdateEmployeeBorrowerProposeDocumentFileUploadState } from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-file-upload/types';

// Document download


//
import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

export interface EmployeeBorrowerProposeDocumentState {
    readonly updateProposeDocumentData: UpdateEmployeeBorrowerProposeDocumentDataState;
    readonly updateProposeDocumentFileUpload: UpdateEmployeeBorrowerProposeDocumentFileUploadState;
    readonly proposeDocumentDelete: EmployeeBorrowerProposeDocumentDeleteState;
    readonly proposeDocumentDownload: ProposeDocumentDownloadState;
}

export const EmployeeBorrowerProposeDocumentReducer: ReducersMapObject<EmployeeBorrowerProposeDocumentState, AnyAction> = {
    updateProposeDocumentData: updateEmployeeBorrowerProposeDocumentDataReducer,
    updateProposeDocumentFileUpload: updateEmployeeBorrowerProposeDocumentFileUploadReducer,
    proposeDocumentDelete: proposeDocumentDeleteReducer,
    proposeDocumentDownload: proposeDocumentDownloadReducer
};

export function* employeeBorrowerProposeDocumentSagas() {
    yield all([
        fork(updateEmployeeBorrowerProposeDocumentDataSaga), 
        fork(updateEmployeeBorrowerProposeDocumentFileUploadSaga), 
        fork(proposeDocumentDeleteSaga),
        fork(employeeBorrowerProposeDocumentDownloadSaga)
    ]);
}
