import React from 'react';
import { useTranslation } from 'react-i18next';
import './feedback-card.scss';

const I18N_PREFIX = 'employee-borrower.propose.feedbackCard.title';

interface ProposeFeedbackCardProps {
    feedback?: string | null;
}

const ProposeFeedbackCard = (props: ProposeFeedbackCardProps) => {
    const { t } = useTranslation();
    const { feedback } = props;

    return (
        <div className="feedback-card">
            <div className="feedback-card__header">
                <div className="feedback-card__header--icon" />
                <p className="feedback-card__header--title"> {t(`${I18N_PREFIX}`)} </p>
            </div>
            <p className="feedback-card__header--content">{feedback ?? '-'}</p>
        </div>
    );
};

export default ProposeFeedbackCard;
