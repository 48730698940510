import ButtonBack from 'components/buttons/button-back/button-back';
import Loading from 'components/loading/loading';
import ModalMessage from 'components/modals/modal-message/modal-message';
import CardNoActivePropose from 'features/dashboard/components/cards/card-no-active-propose/card-no-active-propose';
import CardPayrollLoanService from 'features/dashboard/components/cards/card-payrollloan-service/card-payrollloan-service';
import CardPayrollLoanRecentPropose from 'features/dashboard/components/cards/card-recent-propose/card-payrollloan-recent-propose';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { PayrollLoanProposeStatus } from 'model/enums/propose-status';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import {
    employeeBorrowerAvailableServicesRequest,
    employeeBorrowerAvailableServicesResetState,
} from 'reducer/employeeBorrower/employee-service/available-services/actions';
import { EmployeeBorrowerAvailableServicesState } from 'reducer/employeeBorrower/employee-service/available-services/types';
import {
    employeeBorrowerProposeRecentRequest,
    employeeBorrowerProposeRecentResetState,
} from 'reducer/employeeBorrower/propose/propose-recent/actions';
import {
    useEmployeeBorrowerAvailableServicesState,
    useEmployeeBorrowerProposeRecentState,
    useRootDispatch,
    useUserAccountState,
} from 'reducer/hooks';
import './dashboard.scss';

const I18N_PREFIX = 'dashboard';
// const I18N_PREFIX = 'employee-proposes-history';

const useAvailableServicesRequest = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(employeeBorrowerAvailableServicesRequest());

        return () => {
            dispatch(employeeBorrowerAvailableServicesResetState());
        };
    }, [dispatch]);
};

const useAvailableServicesAwareness = () => {
    const state: EmployeeBorrowerAvailableServicesState = useEmployeeBorrowerAvailableServicesState();
    const isLoading = state.status !== HttpRequestStatus.SUCCESS;
    const hasError = state.status === HttpRequestStatus.ERROR;
    return { isLoading, hasError };
};

export const Dashboard = () => {
    const { t } = useTranslation();
    const { account } = useUserAccountState();
    const dispatch = useRootDispatch();
    const { availableServices } = useEmployeeBorrowerAvailableServicesState();
    const { proposeRecent } = useEmployeeBorrowerProposeRecentState();
    const history = useHistory();

    const [shouldOpenRejectModal, setShouldOpenRejectModal] = useState<boolean>(false);

    useAvailableServicesRequest();

    const { isLoading, hasError } = useAvailableServicesAwareness();

    const handleTryAgain = () => {
        dispatch(employeeBorrowerAvailableServicesRequest());
    };

    React.useEffect(() => {
        dispatch(employeeBorrowerProposeRecentRequest());

        return () => {
            dispatch(employeeBorrowerProposeRecentResetState());
        };
    }, [dispatch]);

    if (hasError) {
        return (
            <div className="dashboard__container--error">
                <div className="dashboard__error-message">{t('dashboard.error-message.available-services')}</div>
                <ButtonBack label={t('global.try-again').toUpperCase()} onClick={handleTryAgain} />
            </div>
        );
    }

    const isFineshedPropose = (statusPropose: any) => {
        return (
            statusPropose === PayrollLoanProposeStatus.CANCELLED ||
            statusPropose === PayrollLoanProposeStatus.RECUSED ||
            statusPropose === PayrollLoanProposeStatus.SIGNED ||
            !statusPropose
        );
    };

    const handleLoanPermission = () => {
        if (proposeRecent) {
            if (isFineshedPropose(proposeRecent.status)) {
                return history.push('/employee-borrower/payroll-loan');
            }
            setShouldOpenRejectModal(true);
            return;
        }
        return history.push('/employee-borrower/payroll-loan');
    };

    return (
        <div className="container__dashboard">
            {shouldOpenRejectModal && (
                <ModalMessage
                    title={t(`${I18N_PREFIX}.modal.title`)}
                    message={t(`${I18N_PREFIX}.modal.description`)}
                    onClose={() => setShouldOpenRejectModal(false)}
                    onCancel={() => setShouldOpenRejectModal(false)}
                />
            )}
            <Container>
                <div className="dashboard--title">
                    <span>{t('dashboard.greetings', { name: account?.firstName })}</span>
                    <p> {t('dashboard.title')} </p>
                </div>
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <Row>
                            <Col xs={{ size: 12 }} md={{ size: 8 }} lg={{ size: 6 }}>
                                <CardPayrollLoanService
                                    onClick={() => handleLoanPermission()}
                                    availablePayrollLoan={availableServices?.availablePayrollLoan ?? false}
                                />
                            </Col>
                        </Row>
                        <div className="dashboard--subtitle">
                            <span> {t(`dashboard.most-recent`)} </span>
                        </div>
                        <Row>
                            <Col xs={{ size: 12 }} md={{ size: 6 }} lg={{ size: 6 }}>
                                {proposeRecent?.id ? (
                                    <CardPayrollLoanRecentPropose proposeRecent={proposeRecent} />
                                ) : (
                                    <CardNoActivePropose />
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
            {availableServices?.availableAnticipation}
        </div>
    );
};

export default Dashboard;
