import { IEmployeeProgramTaxRange, TaxRangeStrategy } from '../../model/employee-program-tax-range.model';

const isInRange = (tax: IEmployeeProgramTaxRange, value: number): boolean => {
    switch (tax.mode) {
        case TaxRangeStrategy.LOWER_INCLUSIVE_UPPER_INCLUSIVE:
            return value >= (tax.lowerAmount ?? 0) && value <= (tax.upperAmount ?? 0);
        case TaxRangeStrategy.LOWER_EXCLUSIVE_UPPER_INCLUSIVE:
            return value > (tax.lowerAmount ?? 0) && value <= (tax.upperAmount ?? 0);
        case TaxRangeStrategy.LOWER_INCLUSIVE_UPPER_EXCLUSIVE:
            return value >= (tax.lowerAmount ?? 0) && value < (tax.upperAmount ?? 0);
        case TaxRangeStrategy.LOWER_EXCLUSIVE_UPPER_EXCLUSIVE:
            return value > (tax.lowerAmount ?? 0) && value < (tax.upperAmount ?? 0);
        default:
            return false;
    }
};

const CurrencyUtils = {
    isInRange,
};

export default CurrencyUtils;
