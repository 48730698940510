import clsx from 'clsx';
import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import Currency from 'components/format/currency/Currency';
import FormattedDate from 'components/format/date/formatted-date';
import { ProposeEmployeeSummaryData } from 'model/employee-borrower/propose/summary.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'shared/responsive/media-query';
import './card-simulate-summary.scss';

interface CardSimulateSummaryProps {
    summary: ProposeEmployeeSummaryData | undefined;
    onClick: () => void;
    disabled?: boolean;
}

const I18N_PREFIX = 'employee-borrower.simulate.detail.input';

const CardSimulateSummary = (props: CardSimulateSummaryProps) => {
    const { summary, onClick, disabled } = props;
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <div className={clsx('card-simulate-summary', { disabled })} onClick={disabled ? undefined : onClick}>
                <div className="card-simulate-summary--first__container">
                    <div className="card-simulate-summary--item">
                        <span className="card-simulate-summary--item__label"> {t(`${I18N_PREFIX}.loanValue.label`)}</span>
                        <span className="card-simulate-summary--item__value" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Currency value={summary?.requestedValue} />
                        </span>
                    </div>
                    {!disabled && <EditIconButton />}
                </div>
                <div className="card-simulate-summary--item">
                    <span className="card-simulate-summary--item__label"> {t(`${I18N_PREFIX}.installmentNumber.label`)} </span>
                    <span className="card-simulate-summary--item__value">
                        <strong> {`${summary?.installmentNumber}x`} </strong> <Currency value={summary?.installmentValue} />
                    </span>
                </div>
                <div className="card-simulate-summary--item">
                    <span className="card-simulate-summary--item__label"> {t(`${I18N_PREFIX}.availableCredit.label`)} </span>
                    <span className="card-simulate-summary--item__value">
                        <Currency value={summary?.availableAmount} />
                    </span>
                </div>
                <div className="card-simulate-summary--item">
                    <span className="card-simulate-summary--item__label"> {t(`${I18N_PREFIX}.dateToFirstPay.label`)} </span>
                    <span className="card-simulate-summary--item__value">
                        <FormattedDate date={summary?.dateToFirstPay} />
                    </span>
                </div>
                <div className="card-simulate-summary--item">
                    <span className="card-simulate-summary--item__label"> {t(`${I18N_PREFIX}.loanTotalValue.label`)} </span>
                    <span className="card-simulate-summary--item__value">
                        <h2>
                            <strong>
                                <Currency value={summary?.loanTotalValue} />
                            </strong>
                        </h2>
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className={clsx('card-simulate-summary', { disabled })} onClick={disabled ? undefined : onClick}>
            <div className="card-simulate-summary--item">
                <span className="card-simulate-summary--item__label"> {t(`${I18N_PREFIX}.loanValue.label`)}</span>
                <span className="card-simulate-summary--item__value" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Currency value={summary?.requestedValue} /> {!disabled && <EditIconButton />}
                </span>
            </div>
            <div className="card-simulate-summary--item">
                <span className="card-simulate-summary--item__label"> {t(`${I18N_PREFIX}.installmentNumber.label`)} </span>
                <span className="card-simulate-summary--item__value">
                    <strong> {`${summary?.installmentNumber}x`} </strong> <Currency value={summary?.installmentValue} />
                </span>
            </div>
            <div className="card-simulate-summary--item">
                <span className="card-simulate-summary--item__label"> {t(`${I18N_PREFIX}.availableCredit.label`)} </span>
                <span className="card-simulate-summary--item__value">
                    <Currency value={summary?.availableAmount} />
                </span>
            </div>
            <div className="card-simulate-summary--item">
                <span className="card-simulate-summary--item__label"> {t(`${I18N_PREFIX}.dateToFirstPay.label`)} </span>
                <span className="card-simulate-summary--item__value">
                    <FormattedDate date={summary?.dateToFirstPay} />
                </span>
            </div>
            <div className="card-simulate-summary--item">
                <span className="card-simulate-summary--item__label"> {t(`${I18N_PREFIX}.loanTotalValue.label`)} </span>
                <span className="card-simulate-summary--item__value">
                    <h2>
                        <strong>
                            <Currency value={summary?.loanTotalValue} />
                        </strong>
                    </h2>
                </span>
            </div>
        </div>
    );
};

export default CardSimulateSummary;
