import { ProposeEmployeeDocumentFileUpload } from 'model/employee-borrower/propose/document.model';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes } from './types';

export const updateEmployeeBorrowerProposeDocumentFileUploadRequest = (request: ProposeEmployeeDocumentFileUpload[]) => action(UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_REQUEST, request);
export const updateEmployeeBorrowerProposeDocumentFileUploadSuccess = (data: ProposeEmployeeSimple) => action(UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_SUCCESS, data);
export const updateEmployeeBorrowerProposeDocumentFileUploadError = (error: GiroWebappError) => action(UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_ERROR, error);
export const updateEmployeeBorrowerProposeDocumentFileUploadResetState = () => action(UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_RESET_STATE);

