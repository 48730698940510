import { EducationType } from 'model/enums/education-type';
import { MaritalStatus } from 'model/enums/marital-status';

export interface ProposeEmployeeSupplementaryData {
    proposeId?: number,
    supplementaryData?: {
        employeeId?: number;
        education?: EducationType;
        nationality?: string;
        mothersName?: string;
        fathersName?: string;
        maritalStatus?: MaritalStatus;
        spouseName?: string;
        spouseBirthDate?: string;
        spouseIdentification?: string;
        dependentsNumber?: number;
        birthCity?: string;
        birthState?: string;
    }
}

export const defaultProposeEmployeeSupplementaryData: Readonly<ProposeEmployeeSupplementaryData> = {
    proposeId: undefined,
    supplementaryData: undefined
}


export interface ProposeEmployeeSupplementaryDataSummary {
    id: number;
    education: EducationType;
    nationality: string;
    mothersName: string;
    fathersName: string;
    maritalStatus: MaritalStatus;
    spouseName?: string;
    spouseBirthDate?: string;
    spouseIdentification?: string;
    dependentsNumber?: number;
    birthCity?: string;
    birthState?: string;
}