import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './button-next-step.scss';

export interface ButtonNextStepProps {
    onClick: () => void;
    disabled?: boolean;
    isLoading?: boolean;
}

export const ButtonNextStep = (props: ButtonNextStepProps): React.JSX.Element => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (!(props.isLoading || props.disabled)) {
            props.onClick();
        }
    };

    return (
        <div className="button__style--fourth">
            <Button variant="contained" onClick={handleClick} disabled={props.disabled}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span> {t('accept-invite.buttons.next-step').toUpperCase()} </span>
                    <InputAdornment position="end">
                        <div className="button--arrow" />
                    </InputAdornment>{' '}
                </div>
            </Button>
        </div>
    );
};

export default ButtonNextStep;
