import ButtonNextStep from 'components/buttons/button-next-step/button-next-step';
import { ButtonPrevious } from 'components/buttons/button-previous/button-previous';
import FieldBasic from 'components/field-basic/field-basic';
import { InviteToCreateProvider } from 'model/invite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { FOCUS_DELAY } from '../animation/animation-constants';
import { useSteps } from '../invite-steps/invite-steps-context';
import { validatePersonName } from '../validation/validation-constants';
import './identification-person.scss';
import './identification-step.scss';

export interface IdentificationPersonProps {
    personName: string;
    onChange: (change: Partial<InviteToCreateProvider>) => void;
}

export const IdentificationPerson = (props: IdentificationPersonProps): React.JSX.Element => {
    const steps = useSteps();
    const { t } = useTranslation();
    const [person, setPerson] = React.useState<string>(props.personName ?? '');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const INPUT_REF = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (steps.isMobile) {
            const timer = setTimeout(() => INPUT_REF.current?.focus({ preventScroll: true }), FOCUS_DELAY);
            return () => clearTimeout(timer);
        }
    }, [INPUT_REF, steps.isMobile]);

    const haltProgression = !validatePersonName(person).isValid;

    const handleNext = async () => {
        setValidation(true);
        if (haltProgression) return;

        props.onChange({ personName: person });
        steps.toNext();
    };

    const handleEnter = () => {
        if (steps.isMobile) handleNext();
    };

    return (
        <Row>
            <div className="body__identification">
                <Row>
                    <Col md="10" style={{ margin: 'auto' }}>
                        <div style={{ fontSize: '1.375rem' }} className="body__identification--title">
                            {t('accept-invite.identification.person-name.label')}
                        </div>
                    </Col>
                </Row>
                <div className="body__identification--components">
                    <Col md="6" offset="3">
                        <form className="form__style" noValidate autoComplete="off">
                            <FieldBasic
                                label={t('accept-invite.identification.person-name.label')}
                                value={props.personName}
                                onChange={setPerson}
                                validate={validatePersonName}
                                onEnter={handleEnter}
                                inputRef={INPUT_REF}
                                showValidation={showValidation}
                            />
                        </form>
                    </Col>
                </div>
                <Row>
                    <div className="body__password--buttons">
                        <Col>
                            <div className="body__password--button-cancel">
                                <ButtonPrevious onClick={steps.toPrevious} />
                            </div>
                        </Col>
                        <Col>
                            <div className="body__password--button-save">
                                <ButtonNextStep onClick={handleNext} disabled={showValidation && haltProgression} />
                            </div>
                        </Col>
                    </div>
                </Row>
            </div>
        </Row>
    );
};

export default IdentificationPerson;
