import ProfileEdit from 'features/profile/components/profile-editing/profile-editing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';
import ProfileDisplay from './components/profile-display/profile-display';
import ProfileLogout from './components/profile-logout/profile-logout';
import ProfilePasswordEditing from './components/profile-password-editing/profile-password-editing';
import { ProfileProvider, useProfileContext, withProfileContext } from './profile-context';
import './profile.scss';

export const Profile = () => {
    const { t } = useTranslation();

    const parentRef = React.useRef<HTMLDivElement>(null);
    const {
        isEditing,
        setIsEditing,
        handleSave,
        disabled,
        setMessageVisibility,
        setShowValidation,
        activeId,
        setActiveId,
    } = useProfileContext();

    React.useEffect(() => {
        activeId == 'data' ? setIsEditing(false) : setIsEditing(true);
        !activeId && setActiveId('data');
    }, [activeId, setIsEditing]);

    React.useEffect(() => {
        setMessageVisibility(false);
        setShowValidation(false);
    }, [isEditing, setMessageVisibility, setShowValidation]);

    const handleCancel = () => {
        setActiveId('data');
        setIsEditing(false);
    };

    const handleEdit = () => {
        setActiveId('editData');
        setIsEditing(true);
    };

    const saveEdition = (evt: React.MouseEvent) => {
        disabled ? evt.preventDefault() : handleSave();
    };

    const isDataPage = activeId === 'data';

    return (
        <div id="profile" style={{ background: 'white' }} className="user-profile__items">
            <div className="personal-data__items">
                {isEditing && (
                    <div className="personal-data__items--back">
                        <Container>
                            <Row>
                                <>
                                    <Col style={{ paddingRight: '0' }} md={{ size: '2', offset: '8' }}>
                                        <Link to="#data" className="license-name__items--cancel" onClick={handleCancel}>
                                            {t('profile.cancel')}
                                        </Link>
                                    </Col>
                                    <Col style={{ paddingLeft: '0' }} md="2">
                                        <ProfileProvider>
                                            <div className={`license-name__items--save ${disabled}`} onClick={e => saveEdition(e)}>
                                                {t('profile.save')}
                                            </div>
                                        </ProfileProvider>
                                    </Col>
                                </>
                            </Row>
                        </Container>
                    </div>
                )}
                {isDataPage && (
                    <div className="personal-data__items--back">
                        <Container>
                            <Row>
                                <>
                                    <Col style={{ paddingRight: '0' }} md={{ size: '2', offset: '8' }}>
                                        <Link to="#edit" className="license-name__items--edit" onClick={handleEdit}>
                                            {t('profile.edit')}
                                        </Link>
                                    </Col>
                                </>
                            </Row>
                        </Container>
                    </div>
                )}
                <div className="container">
                    <Row>
                        <Col md="2">
                            <div className="personal-data__items--sidebar-one">
                                <div className="personal-data__items--sidebar-title">{t('profile.user.title')}</div>
                                <a href="#data" onClick={() => setActiveId('data')}>
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId !== 'password' ? 'active' : ''}`}>
                                        {t('profile.user.data.title')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </a>
                                <a href="#password" onClick={() => setActiveId('password')}>
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'password' ? 'active' : ''}`}>
                                        {t('profile.user.password.title')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </a>
                                <ProfileLogout />
                            </div>
                        </Col>
                        <Col md="9">
                            <div ref={parentRef}>
                                {(() => {
                                    switch (activeId) {
                                        case 'editData':
                                            return <ProfileEdit />;
                                        case 'password':
                                            return <ProfilePasswordEditing />;
                                        default:
                                            return <ProfileDisplay />;
                                    }
                                })()}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default compose(withProfileContext())(Profile);
