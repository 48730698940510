import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum UpdateEmployeeBorrowerProposeAccountActionTypes {
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_REQUEST = '@@updateEmployeeBorrowerProposeAccount/UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_REQUEST',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_SUCCESS = '@@updateEmployeeBorrowerProposeAccount/UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_SUCCESS',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_ERROR = '@@updateEmployeeBorrowerProposeAccount/UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_ERROR',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_RESET_STATE = '@@updateEmployeeBorrowerProposeAccount/UPDATE_EMPLOYEE_BORROWER_PROPOSE_ACCOUNT_RESET_STATE',
}

export interface UpdateEmployeeBorrowerProposeAccountState extends BaseStoreState {
    readonly proposeAccount?: ProposeEmployeeSimple;
}
