export enum ListHistorySortingType {
    RECENT = 'RECENT',
    OLDER = 'OLDER',
    REQUESTED_VALUE_ASC = 'REQUESTED_VALUE_ASC',
    REQUESTED_VALUE_DESC = 'REQUESTED_VALUE_DESC',
    INSTALLMENT_ASC = 'INSTALLMENT_ASC',
    INSTALLMENT_DESC = 'INSTALLMENT_DESC',
    ACCOUNT_ASC = 'ACCOUNT_ASC',
    ACCOUNT_DESC = 'ACCOUNT_DESC',
    STATUS_ASC = 'STATUS_ASC',
    STATUS_DESC = 'STATUS_DESC'
}