import { AxiosResponse } from 'axios';
import { EmployeeBorrowerSimulateCalculation } from 'model/employee-borrower/simulate.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import simulateApi from 'services/api/employee-borrower/simulateApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { employeeBorrowerSimulateCalculationError, employeeBorrowerSimulateCalculationRequest, employeeBorrowerSimulateCalculationSuccess } from './actions';
import { EmployeeBorrowerSimulateCalculationActionTypes } from './types';

function* handleGetEmployeeBorrowerSimulateCalculation(action: ReturnType<typeof employeeBorrowerSimulateCalculationRequest>) {
    try {
        const result: AxiosResponse<EmployeeBorrowerSimulateCalculation> = yield call(simulateApi.getSimulateCalculation, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerSimulateCalculationError(mapped));
            return;
        }
        yield put(employeeBorrowerSimulateCalculationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerSimulateCalculationError(mapped));
    }
}

function* watchGetEmployeeBorrowerSimulateCalculation() {
    yield takeEvery(EmployeeBorrowerSimulateCalculationActionTypes.EMPLOYEE_BORROWER_SIMULATE_CALCULATION_REQUEST, handleGetEmployeeBorrowerSimulateCalculation);
}

function* employeeBorrowerSimulateCalculationSaga() {
    yield all([fork(watchGetEmployeeBorrowerSimulateCalculation)]);
}

export default employeeBorrowerSimulateCalculationSaga;
