import { AxiosResponse } from 'axios';
import { EmailValidationRequest, UserEmailValidation, UserLogin } from '../../model/user';
import { api } from './api';

const BASE_URL = 'api/webapp/users';

export const users = () => {
    const validateEmail = (request: EmailValidationRequest): Promise<AxiosResponse<UserEmailValidation>> => {
        return api.post<UserEmailValidation>(`${BASE_URL}/email/validate`, { email: request.email, token: request.token });
    };

    const updateEmail = (request: EmailValidationRequest): Promise<AxiosResponse<UserEmailValidation>> => {
      return api.post<UserEmailValidation>(`${BASE_URL}/email/change`, { email: request.email, token: request.token });
    };

    const findByResetKey = (key: string): Promise<AxiosResponse<UserLogin>> => {
        return api.get<UserLogin>(`${BASE_URL}/reset-key/${key}`);
    };

    return {
        validateEmail,
        findByResetKey,
        updateEmail
    };
};

export default users();
