import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Authentication, Token } from '../../model/authentication';
import { api } from './api';

export const authenticate = () => {
    const login = (authentication: Authentication): Promise<AxiosResponse<Token>> => {
        const config: AxiosRequestConfig = {};
        return api.post<Token>('api/authenticate', authentication, config);
    };

    const logout = () => {
        return api.post<void>('api/logout');
    };

    return {
        login,
        logout,
    };
};

export default authenticate();
