import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './button-start.scss';

export interface WelcomeProps {
    onClick: () => void;
    disabled: boolean;
    isLoading?: boolean;
}

export const ButtonStart = (props: WelcomeProps): React.JSX.Element => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (!props.isLoading) {
            props.onClick();
        }
    };

    return (
        <div className="button__style">
            <Button variant="contained" onClick={handleClick} disabled={props.disabled}>
                <span> {t('accept-invite.buttons.start').toUpperCase()} </span>
            </Button>
        </div>
    );
};

export default ButtonStart;
