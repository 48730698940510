import BackDrop from 'components/backdrop/backdrop';
import ValidCpfInput from 'components/inputs/cpf-input/valid-cpf-input';
import SimpleLocalDateInput from 'components/inputs/date-input/simple-local-date-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import DocumentFileInput from 'features/employee-borrower/components/inputs/document-file-input/document-file-input';
import {
    validateDocumenAgencyIssuingAcronym,
    validateDocumentCpf,
    validateProposeEmployeeDocument,
} from 'features/employee-borrower/components/propose-employee/validation/propose-employee-document-validation';
import { useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import { ProposeEmployeeDocumentData, ProposeEmployeeDocumentFileUpload } from 'model/employee-borrower/propose/document.model';
import { EmployeeDocumentType } from 'model/enums/document-type';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    employeeBorrowerProposeDocumentDeleteRequest,
    employeeBorrowerProposeDocumentDeleteResetState,
} from 'reducer/employeeBorrower/propose/propose-document-data/delete-propose-document-data/actions';
import { employeeBorrowerProposeDocumentDownloadRequest } from 'reducer/employeeBorrower/propose/propose-document-data/download-propose-document/action';
import { updateEmployeeBorrowerProposeDocumentDataResetState } from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-data/actions';
import { updateEmployeeBorrowerProposeDocumentFileUploadResetState } from 'reducer/employeeBorrower/propose/propose-document-data/update-propose-document-file-upload/actions';
import { employeeBorrowerProposeSummaryRequest } from 'reducer/employeeBorrower/propose/propose-summary/actions';
import {
    useEmployeeBorrowerProposeDocumentDeleteState,
    useRootDispatch,
    useUpdateEmployeeBorrowerProposeDocumentDataState,
    useUpdateEmployeeBorrowerProposeDocumentFileUploadState,
} from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import '../propose-employee-steps.scss';

const I18N_PREFIX = 'employee-borrower.propose.documentData';

const filterFileUploadData = (data: ProposeEmployeeDocumentFileUpload[], fileType: EmployeeDocumentType) => {
    return data.filter(({ documentFile }) => documentFile?.type === fileType);
};
interface ProposeEmployeeDocumentStepProps {
    proposeDocumentData: ProposeEmployeeDocumentData;
    proposeDocumentFileUpload: ProposeEmployeeDocumentFileUpload[];
    setProposeDocumentFileUpload: (value: ProposeEmployeeDocumentFileUpload[]) => void;
    handleChangeDocumentData: (value: Partial<ProposeEmployeeDocumentData>) => void;
    handleChangeDocumentFileUpload: (value: Partial<ProposeEmployeeDocumentFileUpload>) => void;
    proposeId: number | undefined;
    setIsReloadPageRequestSummary: (value: boolean) => void;
}

export interface ProposeEmployeeDeleteDocument {
    uniqueKey?: string;
    documentId?: number;
}

export const ProposeEmployeeDocumentStep = (props: ProposeEmployeeDocumentStepProps) => {
    const {
        proposeDocumentData,
        proposeDocumentFileUpload,
        setProposeDocumentFileUpload,
        handleChangeDocumentData,
        handleChangeDocumentFileUpload,
        setIsReloadPageRequestSummary,
        proposeId,
    } = props;

    const { documentData } = proposeDocumentData;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const [removedDocumentId, setRemovedDocumentId] = useState<number | undefined>(undefined);

    const {
        toDisabledButtonNext,
        toNextStep,
        isEditing,
        setCurrentStep,
        toCheckCompletedSteps,
        setIsEditing,
    } = useEmployeeBorrowerContext();

    const { status: proposeDocumentDataStatus } = useUpdateEmployeeBorrowerProposeDocumentDataState();
    const { status: proposeDocumentFileUploadStatus } = useUpdateEmployeeBorrowerProposeDocumentFileUploadState();
    const { status: proposeDocumentDeleteStatus } = useEmployeeBorrowerProposeDocumentDeleteState();

    const cpfIsValid = validateDocumentCpf(documentData?.cpf).isValid;

    const { toastSuccess } = useToast();

    const isDisabledNextStepButton: boolean = useMemo(() => {
        return (
            !validateProposeEmployeeDocument(proposeDocumentData) ||
            filterFileUploadData(proposeDocumentFileUpload, EmployeeDocumentType.COMPANY_EMPLOYEE_PHOTO_DOCUMENT).length === 0 ||
            filterFileUploadData(proposeDocumentFileUpload, EmployeeDocumentType.COMPANY_EMPLOYEE_ADDRESS_PROOF).length === 0 ||
            filterFileUploadData(proposeDocumentFileUpload, EmployeeDocumentType.COMPANY_EMPLOYEE_PAYCHECK).length === 0 ||
            filterFileUploadData(proposeDocumentFileUpload, EmployeeDocumentType.COMPANY_EMPLOYEE_WALLET_REGISTRATION).length === 0
        );
    }, [proposeDocumentData, proposeDocumentFileUpload]);

    useEffect(() => {
        toDisabledButtonNext(isDisabledNextStepButton);
    }, [isDisabledNextStepButton, toDisabledButtonNext]);

    useEffect(() => {
        if (
            (proposeDocumentDataStatus === HttpRequestStatus.SUCCESS && proposeDocumentFileUploadStatus === HttpRequestStatus.SUCCESS) ||
            (proposeDocumentDataStatus === HttpRequestStatus.SUCCESS && proposeDocumentFileUploadStatus === HttpRequestStatus.NOOP)
        ) {
            dispatch(updateEmployeeBorrowerProposeDocumentDataResetState());
            dispatch(updateEmployeeBorrowerProposeDocumentFileUploadResetState());

            if (!isEditing) {
                toNextStep();
                toCheckCompletedSteps('employeeData');
                return;
            }
            setIsEditing(false);
            toastSuccess(t(`${I18N_PREFIX}.edit-success`));
            setCurrentStep('summary');
        }
    }, [
        proposeDocumentDataStatus,
        proposeDocumentFileUploadStatus,
        toNextStep,
        dispatch,
        isEditing,
        setCurrentStep,
        toCheckCompletedSteps,
        toastSuccess,
        t,
        setIsEditing,
    ]);

    useEffect(() => {
        if (proposeDocumentDeleteStatus === HttpRequestStatus.SUCCESS) {
            dispatch(employeeBorrowerProposeDocumentDeleteResetState());
            const _filteredData = proposeDocumentFileUpload.filter(({ documentFile }) => documentFile?.documentId !== removedDocumentId);
            setProposeDocumentFileUpload(_filteredData);
        }
    }, [proposeDocumentDeleteStatus, removedDocumentId, proposeDocumentFileUpload, setProposeDocumentFileUpload, dispatch]);

    useEffect(() => {
        if (proposeDocumentFileUploadStatus === HttpRequestStatus.SUCCESS && proposeId) {
            setIsReloadPageRequestSummary(false);
            dispatch(employeeBorrowerProposeSummaryRequest(proposeId));
        }
    }, [dispatch, proposeId, proposeDocumentFileUploadStatus, setIsReloadPageRequestSummary]);

    const handleDelete = (params: ProposeEmployeeDeleteDocument) => {
        const { documentId, uniqueKey } = params;

        if (documentId) {
            setRemovedDocumentId(documentId);
            dispatch(employeeBorrowerProposeDocumentDeleteRequest({ documentId, proposeId }));
            return;
        }

        const _filteredData = proposeDocumentFileUpload.filter(({ documentFile }) => documentFile?.uniqueKey !== uniqueKey);
        setProposeDocumentFileUpload(_filteredData);
    };

    const handleDownload = (id: number | undefined) => {
        if (!id) return;
        dispatch(employeeBorrowerProposeDocumentDownloadRequest(id));
    };

    const isLoading = proposeDocumentFileUploadStatus === HttpRequestStatus.ONGOING;

    return (
        <>
            {isLoading && <BackDrop open loadingMessage={t(`${I18N_PREFIX}.loadingMessage`)} />}
            <div className="container__propose-employee-step">
                <div className="header__propose-employee-step">
                    <span className="header__propose-employee-step--title"> {t('employee-borrower.title')} </span>
                    <span className="header__propose-employee-step--subtitle"> {t(`${I18N_PREFIX}.subtitle`)} </span>
                </div>
                <div className="form__propose-employee-step">
                    <div className="form__propose-employee-step--input">
                        <ValidCpfInput
                            id="inputText_documentData_cpf"
                            validate={validateDocumentCpf}
                            showValidation={!cpfIsValid}
                            onChange={cpf => handleChangeDocumentData({ documentData: { ...documentData, cpf } })}
                            value={documentData?.cpf ?? ''}
                            label={`${I18N_PREFIX}.input.cpf.label`}
                            placeholder={`${I18N_PREFIX}.input.cpf.placeholder`}
                            externalUpdate
                        />
                    </div>
                    <div className="form__propose-employee-step--input">
                        <SimpleTextInput
                            id="inputText_documentData_rg"
                            onChange={rg => handleChangeDocumentData({ documentData: { ...documentData, rg } })}
                            value={documentData?.rg ?? ''}
                            label={`${I18N_PREFIX}.input.rg.label`}
                            placeholder={`${I18N_PREFIX}.input.rg.placeholder`}
                            externalUpdate
                        />
                    </div>
                    <div className="form__propose-employee-step--input">
                        <SimpleLocalDateInput
                            id="inputDate_documentData_issueDate"
                            onChange={issueDate =>
                                handleChangeDocumentData({
                                    documentData: { ...documentData, issueDate },
                                })
                            }
                            value={documentData?.issueDate ?? ''}
                            label={`${I18N_PREFIX}.input.issueDate.label`}
                            placeholder={`${I18N_PREFIX}.input.issueDate.placeholder`}
                            externalUpdate
                        />
                    </div>
                    <div className="form__propose-employee-step--input">
                        <SimpleTextInput
                            id="inputText_documentData_issuingAgency"
                            onChange={issuingAgency => handleChangeDocumentData({ documentData: { ...documentData, issuingAgency } })}
                            value={documentData?.issuingAgency ?? ''}
                            label={`${I18N_PREFIX}.input.issuingAgency.label`}
                            placeholder={`${I18N_PREFIX}.input.issuingAgency.placeholder`}
                            externalUpdate
                        />
                    </div>
                    <div className="form__propose-employee-step--input">
                        <ValidTextInput
                            id="inputText_documentData_agencyIssuingAcronym"
                            onChange={agencyIssuingAcronym =>
                                handleChangeDocumentData({ documentData: { ...documentData, agencyIssuingAcronym } })
                            }
                            value={documentData?.agencyIssuingAcronym ?? ''}
                            label={`${I18N_PREFIX}.input.agencyIssuingAcronym.label`}
                            placeholder={`${I18N_PREFIX}.input.agencyIssuingAcronym.placeholder`}
                            validate={validateDocumenAgencyIssuingAcronym}
                            showValidation
                            externalUpdate
                        />
                    </div>
                    <span className="header__propose-employee-step--subtitle" style={{ marginTop: '35px' }}>
                        {t(`${I18N_PREFIX}.attachments.title`)}
                    </span>
                    <div className="form__propose-employee-step--box-info">{t(`${I18N_PREFIX}.attachments.description`)}</div>
                    <DocumentFileInput
                        id="inputFile_documentData_employeePhotoDocument"
                        title={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_PHOTO_DOCUMENT.title`)}
                        description={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_PHOTO_DOCUMENT.description`)}
                        limit={2}
                        fileData={proposeDocumentFileUpload}
                        fileType={EmployeeDocumentType.COMPANY_EMPLOYEE_PHOTO_DOCUMENT}
                        onChange={handleChangeDocumentFileUpload}
                        handleDelete={handleDelete}
                        proposeId={proposeId}
                        handleDownload={handleDownload}
                    />
                    <DocumentFileInput
                        id="inputFile_documentData_employeeAddressProof"
                        title={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_ADDRESS_PROOF.title`)}
                        description={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_ADDRESS_PROOF.description`)}
                        limit={1}
                        fileData={proposeDocumentFileUpload}
                        fileType={EmployeeDocumentType.COMPANY_EMPLOYEE_ADDRESS_PROOF}
                        onChange={handleChangeDocumentFileUpload}
                        handleDelete={handleDelete}
                        proposeId={proposeId}
                        handleDownload={handleDownload}
                    />
                    <DocumentFileInput
                        id="inputFile_documentData_employeePaycheck"
                        title={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_PAYCHECK.title`)}
                        description={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_PAYCHECK.description`)}
                        limit={1}
                        fileData={proposeDocumentFileUpload}
                        fileType={EmployeeDocumentType.COMPANY_EMPLOYEE_PAYCHECK}
                        onChange={handleChangeDocumentFileUpload}
                        handleDelete={handleDelete}
                        proposeId={proposeId}
                        handleDownload={handleDownload}
                    />
                    <DocumentFileInput
                        id="inputFile_documentData_employeeWalletRegistration"
                        title={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_WALLET_REGISTRATION.title`)}
                        description={t(`${I18N_PREFIX}.input.files.COMPANY_EMPLOYEE_WALLET_REGISTRATION.description`)}
                        limit={1}
                        fileData={proposeDocumentFileUpload}
                        fileType={EmployeeDocumentType.COMPANY_EMPLOYEE_WALLET_REGISTRATION}
                        onChange={handleChangeDocumentFileUpload}
                        handleDelete={handleDelete}
                        proposeId={proposeId}
                        handleDownload={handleDownload}
                    />
                </div>
            </div>
            )
        </>
    );
};

export default ProposeEmployeeDocumentStep;
