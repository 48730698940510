// Update managr
import { updateEmployeeBorrowerProposeManagerReducer } from 'reducer/employeeBorrower/propose/propose-manager/update-propose-manager/reducer';
import updateEmployeeBorrowerProposeManagerSaga from 'reducer/employeeBorrower/propose/propose-manager/update-propose-manager/sagas';
import { UpdateEmployeeBorrowerProposeManagerState } from 'reducer/employeeBorrower/propose/propose-manager/update-propose-manager/types';

//
import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

export interface EmployeeBorrowerProposeManagerState {
    readonly updateProposeManager: UpdateEmployeeBorrowerProposeManagerState;
}

export const EmployeeBorrowerProposeManagerReducer: ReducersMapObject<EmployeeBorrowerProposeManagerState, AnyAction> = {
    updateProposeManager: updateEmployeeBorrowerProposeManagerReducer
};

export function* employeeBorrowerProposeManagerSagas() {
    yield all([fork(updateEmployeeBorrowerProposeManagerSaga)]);
}
