import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { HistoryListProposeEmployee } from 'model/employee-history-proposes';
import { ListHistorySortingType } from 'model/enums/proposeSortingType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';
import 'styles/constants/_table.scss';

const I18N_PREFIX = 'employee-proposes-history';

interface ListHistoryProposesHeaderProps {
    proposes: HistoryListProposeEmployee[];
    handleSort: (property: ListHistorySortingType) => void;
    sortedProperty: ListHistorySortingType | undefined;
    sortOrder: SortOrder;
}

export const ListHistoryProposesHeader = (props: ListHistoryProposesHeaderProps) => {
    const { t } = useTranslation();
    const { proposes, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: ListHistorySortingType, active: boolean) => {
        return {
            active,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: proposes && proposes.length <= 1 ? true : false,
            onSort: () => handleSort(_property),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn
                label={t(`${I18N_PREFIX}.requestedDate`).toUpperCase()}
                sortable={mapSortable(
                    sortOrder === 'desc' ? ListHistorySortingType.OLDER : ListHistorySortingType.RECENT,
                    sortedProperty === ListHistorySortingType.RECENT || sortedProperty === ListHistorySortingType.OLDER
                )}
            />
            <SortableColumn
                label={t(`${I18N_PREFIX}.requestedValue`).toUpperCase()}
                sortable={mapSortable(
                    sortOrder === 'desc' ? ListHistorySortingType.REQUESTED_VALUE_ASC : ListHistorySortingType.REQUESTED_VALUE_DESC,
                    sortedProperty === ListHistorySortingType.REQUESTED_VALUE_ASC ||
                        sortedProperty === ListHistorySortingType.REQUESTED_VALUE_DESC
                )}
            />
            <SortableColumn
                label={t(`${I18N_PREFIX}.installments`).toUpperCase()}
                sortable={mapSortable(
                    sortOrder === 'desc' ? ListHistorySortingType.INSTALLMENT_ASC : ListHistorySortingType.INSTALLMENT_DESC,
                    sortedProperty === ListHistorySortingType.INSTALLMENT_ASC || sortedProperty === ListHistorySortingType.INSTALLMENT_DESC
                )}
            />
            <SortableColumn
                label={t(`${I18N_PREFIX}.account`).toUpperCase()}
                sortable={mapSortable(
                    sortOrder === 'desc' ? ListHistorySortingType.ACCOUNT_ASC : ListHistorySortingType.ACCOUNT_DESC,
                    sortedProperty === ListHistorySortingType.ACCOUNT_ASC || sortedProperty === ListHistorySortingType.ACCOUNT_DESC
                )}
            />
            <SortableColumn
                label={t(`${I18N_PREFIX}.status`).toUpperCase()}
                sortable={mapSortable(
                  sortOrder === 'desc' ? ListHistorySortingType.STATUS_ASC : ListHistorySortingType.STATUS_DESC,
                  sortedProperty === ListHistorySortingType.STATUS_ASC || sortedProperty === ListHistorySortingType.STATUS_DESC
                  )}
            />
            <SortableColumn label={t(`${I18N_PREFIX}.dueDate`).toUpperCase()} />
        </tr>
    );
};

export default ListHistoryProposesHeader;
