import { AxiosResponse } from 'axios';
import { IBank } from 'model/bank.model';
import { SearchRequest } from 'model/reducers';
import { api } from 'services/api/api';
import { Page } from 'services/page';

const BASE_URL = '/api/payroll-loan/user';

export const bank = () => {
    const searchBanks = (request: SearchRequest): Promise<AxiosResponse<Page<IBank>>> => {
        return api.get<Page<IBank>>(`${BASE_URL}/banks/search?search=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable },
        });
    };

    return {
        searchBanks,
    };
};

export default bank();
