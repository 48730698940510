import Slide from '@material-ui/core/Slide';
import ButtonAction from 'components/buttons/button-action/button-action';
import Currency from 'components/format/currency/Currency';
import { IEmployeeAnticipation } from 'model/employee-anticipation.model';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { employeeAnticipationRequest } from 'reducer/employeeAnticipation/actions';
import { useEmployeeAnticipationState, useEmployeeMonthlyCreditState, useRootDispatch } from 'reducer/hooks';
import './anticipation-request-confirmation.scss';

export interface AnticipationRequestConfirmationProps {
    amount: number;
    tax: number;
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const useBatchRequestStateAwareness = () => {
    const state = useEmployeeAnticipationState();
    const dispatch = useRootDispatch();

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const hasError = state.status === HttpRequestStatus.ERROR;

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.ONGOING) {
            setLoading(true);
        } else if (state.status === HttpRequestStatus.SUCCESS || state.status === HttpRequestStatus.ERROR) {
            setLoading(false);
        }
    }, [state.status, dispatch]);

    return { isLoading, hasError };
};

export const AnticipationRequestConfirmation = (props: AnticipationRequestConfirmationProps) => {
    const { open, onCancel, onConfirm } = props;
    const { t } = useTranslation();
    const employeeMonthlyCreditState = useEmployeeMonthlyCreditState();
    const anticipationState = useEmployeeAnticipationState();

    const dispatch = useRootDispatch();
    const { isLoading } = useBatchRequestStateAwareness();

    const handleAnticipationRequestConfirmation = () => {
        const request: IEmployeeAnticipation = {
            amount: props.amount,
            employee: employeeMonthlyCreditState.employeeMonthlyCredit?.employee,
            month: employeeMonthlyCreditState.employeeMonthlyCredit,
        };
        dispatch(employeeAnticipationRequest(request));
    };

    React.useEffect(() => {
        if (anticipationState.status === HttpRequestStatus.SUCCESS) {
            onConfirm();
        }
    }, [anticipationState.status, onConfirm, dispatch]);

    const bankAccount = employeeMonthlyCreditState.employeeMonthlyCredit?.employee?.bankAccount;

    return (
        <Slide in={open} direction="up" timeout={500} unmountOnExit>
            <div className="anticipation-request-confimation">
                <div className="body__confirmation--itens">
                    <div className="body__confirmation--content">
                        <div className="body__confirmation--image" />
                    </div>

                    <div className="body__confirmation--title-start">
                        <p>
                            <span>{t('anticipation-simulation.anticipation-request-confirmation.review')}</span>
                        </p>
                        <small>{t('anticipation-simulation.anticipation-request-confirmation.end-message')}</small>
                    </div>
                    <div className="body__confirmation">
                        {t('anticipation-simulation.anticipation-request-confirmation.amount')}
                        <span>
                            <Currency value={props?.amount ?? 0} />
                        </span>
                    </div>

                    <div className="body__confirmation">
                        {t('anticipation-simulation.anticipation-request-confirmation.tax')}
                        <span>
                            <Currency value={props?.tax ?? 0} />
                        </span>
                    </div>

                    <div className="body__confirmation">
                        {t('anticipation-simulation.anticipation-request-confirmation.net-amount')}
                        <span>
                            <Currency value={(props?.amount ?? 0) + (props?.tax ?? 0)} />
                        </span>
                    </div>

                    {bankAccount && (
                        <div className="anticipation-bank-account">
                            <div className="anticipation-bank-account__title">
                                {t('anticipation-simulation.anticipation-request-confirmation.bank-account.title')}
                            </div>
                            <div className="anticipation-bank-account__item">
                                <span>{t('anticipation-simulation.anticipation-request-confirmation.bank-account.bank')}</span>
                                {`${bankAccount.bank?.number} - ${bankAccount.bank?.name}`}
                            </div>
                            <div className="anticipation-bank-account__item">
                                <span>{t('anticipation-simulation.anticipation-request-confirmation.bank-account.agency')}</span>
                                {[bankAccount.agencyNumber ?? '', bankAccount.agencyDigit ?? ''].filter(it => !!it).join('-')}
                            </div>
                            <div className="anticipation-bank-account__item">
                                <span>{t('anticipation-simulation.anticipation-request-confirmation.bank-account.account')}</span>
                                {[bankAccount.accountNumber ?? '', bankAccount.accountDigit ?? ''].filter(it => !!it).join('-')}
                            </div>

                            <div className="anticipation-bank-account__message">
                                {t('anticipation-simulation.anticipation-request-confirmation.bank-account.message')}
                            </div>
                        </div>
                    )}

                    <div className="body__confirmation--title-start">
                        {t('anticipation-simulation.anticipation-request-confirmation.start')}
                        <span>
                            {t('anticipation-simulation.anticipation-request-confirmation.days-to-pay', {
                                count: Math.max(
                                    1,
                                    Math.round(
                                        (employeeMonthlyCreditState.employeeMonthlyCredit?.employee?.program?.estimatedPaymentDateInHours ??
                                            0) / 24
                                    )
                                ),
                            })}
                        </span>
                        {t('anticipation-simulation.anticipation-request-confirmation.end')}
                    </div>
                    <div className="body__confirmation--content">
                        <div className="body__confirmation--button">
                            <button className="body__confirmation--button-cancel" onClick={onCancel}>
                                {t('anticipation-simulation.anticipation-request-confirmation.button.cancel').toUpperCase()}
                            </button>
                            <ButtonAction
                                label={t('anticipation-simulation.anticipation-request-confirmation.button.confirm').toUpperCase()}
                                onClick={handleAnticipationRequestConfirmation}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Slide>
    );
};

export default AnticipationRequestConfirmation;
