import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './button-request.scss';

export interface ButtonRequestProps {
    onClick: () => void;
    isDisabled?: boolean;
}

export const ButtonRequest = (props: ButtonRequestProps): React.JSX.Element => {
    const { isDisabled, onClick } = props;
    const { t } = useTranslation();

    return (
        <div className="button__request">
            <Button variant="contained" disabled={isDisabled} onClick={!isDisabled ? onClick : undefined}>
                <span> {t('accept-invite.buttons.request').toUpperCase()} </span>
            </Button>
        </div>
    );
};

export default ButtonRequest;
