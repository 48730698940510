import Modal from '@material-ui/core/Modal';
import ButtonBack from 'components/buttons/button-back/button-back';
import Loading from 'components/loading/loading';
import { ErrorConstants, ErrorType } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { ErrorHandlingCustomization } from 'model/error';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { customizeErrorHandling } from 'reducer/application/error/actions';
import { employeeAnticipationResetState } from 'reducer/employeeAnticipation/actions';
import { employeeMonthlyCreditRequest, employeeMonthlyCreditResetState } from 'reducer/employeeMonthlyCredit/actions';
import { useEmployeeAnticipationState, useEmployeeMonthlyCreditState, useRootDispatch } from 'reducer/hooks';
import ContextRibbon from '../../context-ribbon/context-ribbon';
import './anticipation-review.scss';
import AnticipationRequestConfirmation from './components/anticipation-request-confirmation/anticipation-request-confirmation';
import AnticipationSuccess from './components/anticipation-success/anticipation-success';
import AnticipationCreditInfo from './components/monthly-credit-info/anticipation-credit-info';
import SimulationExample from './components/simulation-example/simulation-example';

const useMonthlyCreditRequest = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(employeeMonthlyCreditRequest());
    }, [dispatch]);
};

const useAnticipationStateAwareness = () => {
    const state = useEmployeeAnticipationState();
    const stateCredit = useEmployeeMonthlyCreditState();

    const isLoading = state.status === HttpRequestStatus.ONGOING || stateCredit.status === HttpRequestStatus.ONGOING;
    const hasError = state.status === HttpRequestStatus.ERROR || stateCredit.status === HttpRequestStatus.ERROR;

    return { isLoading, hasError, credit: stateCredit.employeeMonthlyCredit };
};

const useCleanState = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(employeeMonthlyCreditResetState());
            dispatch(employeeAnticipationResetState());
        };
    }, [dispatch]);
};

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        const record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.credit.notfound': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'anticipation-simulation.no-credits.action',
                    handler: history => history.push('/dashboard'),
                },
                message: {
                    key: 'anticipation-simulation.no-credits.message',
                },
            },
        };
        dispatch(dispatch(customizeErrorHandling({ record })));
    }, [dispatch]);
};

export const AnticipationReview = () => {
    const dispatch = useRootDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [amount, setAmount] = React.useState<number>(0);
    const [tax, setTax] = React.useState<number>(0);
    const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false);
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [showExample, setShowExample] = React.useState<boolean>(false);

    const { isLoading, hasError, credit } = useAnticipationStateAwareness();

    useCleanState();
    useErrorConfiguration();
    useMonthlyCreditRequest();

    const handleTryAgain = () => {
        dispatch(employeeMonthlyCreditRequest());
    };

    const handleAnticipationRequest = (_amount: number, _tax: number) => {
        setAmount(_amount);
        setTax(_tax);
        setShowConfirmation(true);
    };
    const handleCancelAnticipation = () => setShowConfirmation(false);

    const handleConfirmation = () => {
        setShowConfirmation(false);
        setShowSuccess(true);
    };

    const openTaxes = () => setShowExample(true);

    const handleCloseExample = () => setShowExample(false);

    const handleSuccessClose = () => {
        setShowSuccess(false);
        history.push('/dashboard');
    };

    if (isLoading || credit == null) {
        return (
            <div className="anticipation-simulation__container--loading">
                <ContextRibbon />
                <Loading />
            </div>
        );
    }

    if (hasError) {
        return (
            <div className="anticipation-simulation__container--error">
                <ContextRibbon />
                <div className="anticipation-simulation__error-message">{t('anticipation-simulation.error-message')}</div>
                <ButtonBack label={t('global.try-again').toUpperCase()} onClick={handleTryAgain} />
            </div>
        );
    }

    return (
        <div className="container__simulation">
            <div className="container__simulation--content">
                <ContextRibbon />
                {!showConfirmation && !showSuccess && credit && (
                    <AnticipationCreditInfo credit={credit} onRequest={handleAnticipationRequest} openTaxes={openTaxes} />
                )}
            </div>
            <AnticipationRequestConfirmation
                amount={amount}
                tax={tax}
                open={showConfirmation}
                onCancel={handleCancelAnticipation}
                onConfirm={handleConfirmation}
            />
            <AnticipationSuccess open={showSuccess} onClose={handleSuccessClose} />
            <Modal open={showExample} disableAutoFocus disableBackdropClick>
                <div>
                    <SimulationExample onClose={handleCloseExample} taxes={credit.employee?.program?.taxes ?? []} />
                </div>
            </Modal>
        </div>
    );
};

export default AnticipationReview;
