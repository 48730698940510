import { PersonSimple } from '../../../model/person';
import { BaseStoreState } from '../../baseStoreState';

export enum AcceptTermsActionTypes {
    ACCEPT_TERMS_REQUEST = '@@acceptTerms/ACCEPT_TERMS_REQUEST',
    ACCEPT_TERMS_SUCCESS = '@@acceptTerms/ACCEPT_TERMS_SUCCESS',
    ACCEPT_TERMS_ERROR = '@@acceptTerms/ACCEPT_TERMS_ERROR',
    ACCEPT_TERMS_RESET_STATE = '@@acceptTerms/ACCEPT_TERMS_RESET_STATE',
}

export interface AcceptTermsState extends BaseStoreState {
    readonly person?: PersonSimple;
}
