import React from 'react';
import { compose } from 'redux';
import { IEmployeeMonthlyCredit } from '../../../../../model/employee-monthly-credit.model';
import AnticipationCreditHeader from '../monthly-credit-header/monthly-credit-header';
import { withMonthlyCreditInfoContext } from './monthly-credit-info-context';

export interface AnticipationCreditInfoProps {
    credit: IEmployeeMonthlyCredit;
    openTaxes: () => void;
    onRequest: (amount: number, tax: number) => void;
}

export const AnticipationCreditInfo = (props: AnticipationCreditInfoProps) => {
    return (
        <>
            <AnticipationCreditHeader onRequest={props.onRequest} openTaxes={props.openTaxes} />
            {/* <Container>
				<Row>
					<Col>
						<div className="simulation--text">
							<div>{_.upperFirst(moment(props.credit?.month, 'YYYY-MM')?.format('MMMM YYYY'))}</div>
						</div>
					</Col>
				</Row>
				<Row>
					<TableEntries data={props.credit.entries} />
				</Row>
			</Container> */}
        </>
    );
};

export default compose(withMonthlyCreditInfoContext())(AnticipationCreditInfo);
