import { ProposeEmployeeBankAccount } from 'model/employee-borrower/propose/account.model';
import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';
import { EmployeeBorrowerBanksAccountsActionTypes } from './types';

export const employeeBorrowerBanksAccountsRequest = () => action(EmployeeBorrowerBanksAccountsActionTypes.EMPLOYEE_BORROWER_BANKS_ACCOUNTS_REQUEST);
export const employeeBorrowerBanksAccountsSuccess = (data: Page<ProposeEmployeeBankAccount>) => action(EmployeeBorrowerBanksAccountsActionTypes.EMPLOYEE_BORROWER_BANKS_ACCOUNTS_SUCCESS, data);
export const employeeBorrowerBanksAccountsError = (error: GiroWebappError) => action(EmployeeBorrowerBanksAccountsActionTypes.EMPLOYEE_BORROWER_BANKS_ACCOUNTS_ERROR, error);
export const employeeBorrowerBanksAccountsResetState = () => action(EmployeeBorrowerBanksAccountsActionTypes.EMPLOYEE_BORROWER_BANKS_ACCOUNTS_RESET_STATE);
