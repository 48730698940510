import { AxiosResponse } from 'axios';
import { ICity } from 'model/city.model';
import { SearchRequest } from 'model/reducers';
import { Page } from 'services/page';
import { api } from './api';

const BASE_URL = 'api/payroll-loan/user';

export const cities = () => {
    const searchCities = (request: SearchRequest): Promise<AxiosResponse<Page<ICity>>> => {
        return api.get<Page<ICity>>(`${BASE_URL}/cities/search?search=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable },
        });
    };

    return {
        searchCities,
    };
};

export default cities();
