import clsx from 'clsx';
import { ProposeEmployeeAccountData, ProposeEmployeeBankAccount } from 'model/employee-borrower/propose/account.model';
import React from 'react';
import './select-propose-employee-account.scss';

interface SelectProposeEmployeeAccountProps {
    handleChange: (value: Partial<ProposeEmployeeAccountData>) => void;
    banksAccounts: ProposeEmployeeBankAccount[] | undefined;
    proposeAccountData: ProposeEmployeeAccountData | undefined;
}

export const SelectProposeEmployeeAccount = (props: SelectProposeEmployeeAccountProps) => {
    const { handleChange, banksAccounts, proposeAccountData } = props;

    const handleSelectAccount = (account: ProposeEmployeeBankAccount) => {
        handleChange({
            accountData: {
                ...account,
                jointAccount: proposeAccountData?.accountData?.jointAccount,
            },
        });
    };

    return (
        <>
            {banksAccounts?.map(value => {
                return (
                    <div
                        className={clsx('select-propose-employee-account__card', {
                            active: proposeAccountData?.accountData?.accountNumber === value.accountNumber,
                        })}
                        key={value.bankAccountId}
                        onClick={() => handleSelectAccount(value)}
                    >
                        <div className="select-propose-employee-account__card-item">
                            <span className="select-propose-employee-account__card-checkbox">
                                {proposeAccountData?.accountData?.accountNumber === value?.accountNumber && (
                                    <span className="select-propose-employee-account__card-checkbox-active" />
                                )}
                            </span>
                        </div>
                        <div className="select-propose-employee-account__card-item">
                            <span className="select-propose-employee-account__card-bank"> {value.bank?.name} </span>
                            <span className="select-propose-employee-account__card-account">
                                {`${value.accountNumber}-${value.accountDigit}`}
                            </span>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default SelectProposeEmployeeAccount;
