import { ProviderCreatedByInvite } from '../../../model/invite';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum ProviderByInviteActionTypes {
    CREATE_PERSON_REQUEST = '@@createPersonProviderByInvite/CREATE_PERSON_REQUEST',
    CREATE_PERSON_SUCCESS = '@@createPersonProviderByInvite/CREATE_PERSON_SUCCESS',
    CREATE_PERSON_ERROR = '@@createPersonProviderByInvite/CREATE_PERSON_ERROR',
}

export interface ProviderByInviteState extends BaseStoreState {
    readonly person?: ProviderCreatedByInvite;
}
