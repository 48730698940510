import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { IEmployeeProgram } from '../../model/employee-program.model';
import HttpStatus from '../../model/enums/httpStatus';
import employeeProgramApi from '../../services/api/employeeProgram';
import { Page } from '../../services/page';
import { ErrorUtils } from '../../shared/error/error-utils';
import { markError } from '../application/error/actions';
import { employeeProgramError, employeeProgramRequest, employeeProgramSuccess } from './actions';
import { EmployeeProgramActionTypes } from './types';

function* handleListEmployeeProgram(action: ReturnType<typeof employeeProgramRequest>) {
    try {
        const result: AxiosResponse<Page<IEmployeeProgram>> = yield call(employeeProgramApi.findAll);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeProgramError(mapped));
            return;
        }
        yield put(employeeProgramSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeProgramError(mapped));
    }
}

function* watchListEmployeePrograms() {
    yield takeEvery(EmployeeProgramActionTypes.EMPLOYEE_PROGRAM_REQUEST, handleListEmployeeProgram);
}

function* employeeProgramSaga() {
    yield all([fork(watchListEmployeePrograms)]);
}

export default employeeProgramSaga;
