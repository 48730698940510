import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import './menu-item.scss';

export type LinkAction = () => void;

export interface MenuItemPropsList {
    link: string;
    translation: string;
}

export interface MenuItemProps {
    icon?: IconProp;
    list: MenuItemPropsList[];
    menu?: boolean;
}

export const MenuItem = (props: MenuItemProps) => {
    const local = useLocation().pathname;
    const [itemActive, setItemActive] = useState<number | null>(null);
    const menuRef = useRef(null);
    const history = useHistory();

    const { t } = useTranslation();
    const { icon, list, menu } = props;

    const handleActiveItem = (index: number) => {
        setItemActive(index);
    };

    const handleActive = useCallback(
        (path?: string) => {
            const activeIndex = props.list.findIndex(it => it.link.split('/')[1] === path);
            setItemActive(ps => (ps !== activeIndex ? activeIndex : ps));
        },
        [props.list]
    );

    useEffect(() => {
        handleActive(local.split('/')[1]);
    }, [local, itemActive, handleActive]);

    useEffect(() => {
        return () => {
            setItemActive(null);
        };
    }, [setItemActive]);

    return (
        <ul className="page-header--menu-list" ref={menu ? menuRef : null}>
            {list.map((item, index) => {
                return (
                    <li className="page-header--menu-list-item" key={index} ref={!menu ? menuRef : null}>
                        <div
                            className={clsx('page-header--menu-list-item-button', { active: itemActive === index })}
                            onClick={() => {
                                handleActiveItem(index);
                                history.push(item.link);
                            }}
                        >
                            {icon && <FontAwesomeIcon icon={icon} />}
                            <span> {t(item.translation)}</span>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default MenuItem;
