import ButtonStart from 'components/buttons/button-start/button-start';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { useSteps } from '../invite-steps/invite-steps-context';
import './welcome.scss';

export interface WelcomeProps {
    companyName: string;
    organizationName: string;
    loading: boolean;
}

export const Welcome = (props: WelcomeProps): React.JSX.Element => {
    const { t } = useTranslation();
    const steps = useSteps();

    return (
        <div className="body__welcome">
            <Row>
                <Col md="8" style={{ margin: 'auto' }}>
                    <div className="body__welcome--title">
                        <span>{t('accept-invite.welcome.title')}</span>
                    </div>
                    <div className="body__welcome--text">
                        {props.companyName ? (
                            <>
                                {t('accept-invite.welcome.invite.start')}
                                <span>{t('accept-invite.welcome.invite.program')}</span>
                                {t('accept-invite.welcome.invite.end')}
                                <span>{props.companyName}.</span>
                            </>
                        ) : (
                            <>
                                {t('accept-invite.welcome.fallback.invite.start')}
                                <span>{t('accept-invite.welcome.fallback.invite.program')}</span>
                                {t('accept-invite.welcome.fallback.invite.end')}
                            </>
                        )}
                    </div>
                    <div className="body__welcome--text">
                        {t('accept-invite.welcome.register.start')}
                        <span>{t('accept-invite.welcome.register.process')}</span>
                        {t('accept-invite.welcome.register.end')}
                    </div>
                    <div className="container__button">
                        <ButtonStart onClick={steps.toNext} disabled={props.loading} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Welcome;
