import { ProposeEmployeeManagerMode } from 'model/employee-borrower/propose/manager.model';
import { BaseStoreState } from '../../baseStoreState';

export enum ManagerModeActionTypes {
    MANAGER_MODE_REQUEST = '@@managerMode/MANAGER_MODE_REQUEST',
    MANAGER_MODE_SUCCESS = '@@managerMode/MANAGER_MODE_SUCCESS',
    MANAGER_MODE_ERROR = '@@managerMode/MANAGER_MODE_ERROR',
    MANAGER_MODE_RESET_STATE = '@@managerMode/MANAGER_MODE_RESET_STATE',
}

export interface ManagerModeState extends BaseStoreState {
    readonly managerMode?: ProposeEmployeeManagerMode;
}
