import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { Invite } from '../../../model/invite';
import inviteApi from '../../../services/api/inviteApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { inviteToAcceptError, inviteToAcceptRequest, inviteToAcceptSuccess } from './actions';
import { InviteToAcceptActionTypes } from './types';

function* handleInviteToAcceptRequest(action: ReturnType<typeof inviteToAcceptRequest>) {
    try {
        const result: AxiosResponse<Invite> = yield call(inviteApi.getInviteByToken, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(inviteToAcceptError(mapped));
            return;
        }
        yield put(inviteToAcceptSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(inviteToAcceptError(mapped));
    }
}

function* watchHandleinviteToAcceptRequest() {
    yield takeEvery(InviteToAcceptActionTypes.INVITE_TO_ACCEPT_REQUEST, handleInviteToAcceptRequest);
}

function* inviteToAcceptSaga() {
    yield all([fork(watchHandleinviteToAcceptRequest)]);
}

export default inviteToAcceptSaga;
