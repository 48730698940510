import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import ButtonAction from 'components/buttons/button-action/button-action';
import ButtonBack from 'components/buttons/button-back/button-back';
import Card from 'components/card/card';
import CurrencyField from 'components/field-basic/currency-field';
import Loading from 'components/loading/loading';
import Slider from 'components/slider/slider';
import CurrencyReadOnlyField from 'features/employee-borrower/components/inputs/currency-read-only-field/currency-read-only-field';
import DateReadOnlyField from 'features/employee-borrower/components/inputs/date-read-only-field/date-read-only-field';
import NumberReadOnlyField from 'features/employee-borrower/components/inputs/number-read-only-field/number-read-only-field';
import {
    validateLoanValue,
    validateMaxInstallmentValue,
} from 'features/employee-borrower/components/simulate/validation/simulation-validation';
import { useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import { EmployeeBorrowerSimulateCalculationRequest } from 'model/employee-borrower/simulate.model';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { employeeBorrowerUpdateLoanCalculationResetState } from 'reducer/employeeBorrower/loan-calculation/actions';
import { employeeBorrowerSimulateRequest, employeeBorrowerSimulateResetState } from 'reducer/employeeBorrower/simulate/actions';
import {
    useEmployeeBorrowerProposeSummaryState,
    useEmployeeBorrowerSimulateState,
    useEmployeeCompanyDefaultState,
    useRootDispatch,
    useUpdateEmployeeBorrowerLoanCalculationState,
} from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import './simulate-card-manager.scss';

const I18N_PREFIX = 'employee-borrower.simulate.manager';

interface SimulateCardManagerProps {
    onSave: () => void;
    simulateCalculationRequest: EmployeeBorrowerSimulateCalculationRequest;
    setSimulateCalculationRequest: (value: EmployeeBorrowerSimulateCalculationRequest) => void;
    handleLoanValidation: () => boolean;
}

export const SimulateCardManager = (props: SimulateCardManagerProps) => {
    const { onSave, setSimulateCalculationRequest, simulateCalculationRequest, handleLoanValidation } = props;

    const { t } = useTranslation();

    const { isEditing, setIsEditing, setCurrentStep } = useEmployeeBorrowerContext();
    const { simulate, status: simulateStatus } = useEmployeeBorrowerSimulateState();
    const { status: updateLoanCalculationStatus } = useUpdateEmployeeBorrowerLoanCalculationState();
    const { proposeSummary } = useEmployeeBorrowerProposeSummaryState();
    const { toastSuccess } = useToast();
    const { company } = useEmployeeCompanyDefaultState();

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(employeeBorrowerSimulateRequest());

        return () => {
            dispatch(employeeBorrowerSimulateResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!isEditing) return;
        if (updateLoanCalculationStatus !== HttpRequestStatus.SUCCESS) return;
        setIsEditing(false);
        toastSuccess(t(`${I18N_PREFIX}.edit-success`));
        setCurrentStep('summary');

        dispatch(employeeBorrowerUpdateLoanCalculationResetState());
    }, [dispatch, updateLoanCalculationStatus, isEditing, setIsEditing, setCurrentStep, toastSuccess, t]);

    useEffect(() => {
        if (simulateStatus === HttpRequestStatus.SUCCESS && !simulateCalculationRequest.installmentNumber) {
            setSimulateCalculationRequest({ installmentNumber: simulate?.minInstallmentNumber });
        }
    }, [simulateStatus, simulate, simulateCalculationRequest, setSimulateCalculationRequest]);

    const handleChange = (value: Partial<EmployeeBorrowerSimulateCalculationRequest>) => {
        setSimulateCalculationRequest({ ...simulateCalculationRequest, ...value });
    };

    const handleSave = () => {
        const isValidLoanValue = handleLoanValidation();
        if (!isValidLoanValue) return;

        onSave();
    };

    const hasError = simulateStatus === HttpRequestStatus.ERROR;

    const handleTryAgain = () => {
        dispatch(employeeBorrowerSimulateRequest());
    };

    if (hasError) {
        return (
            <div className="error__employee-borrower">
                <div className="error-message">{t('global.employee-borrower.simulate.error-message')}</div>
                <ButtonBack label={t('global.try-again')} onClick={handleTryAgain} />
            </div>
        );
    }

    const isLoading = simulateStatus !== HttpRequestStatus.SUCCESS;

    return (
        <>
            {isLoading ? (
                <div className="container__employee-borrower--loading">
                    <Loading />
                </div>
            ) : (
                <>
                    <div className="header__simulate-card-manager">
                        <span className="header__simulate-card-manager--title"> {t('employee-borrower.title')} </span>
                        <span className="header__simulate-card-manager--subtitle"> {t(`${I18N_PREFIX}.subtitle`)} </span>
                        <span className="header__simulate-card-manager--description"> {t(`${I18N_PREFIX}.description`)} </span>
                    </div>
                    <div className="card__simulate-card-manager">
                        <Card>
                            <CurrencyField
                                label={t(`${I18N_PREFIX}.input.loanValue.label`)}
                                value={simulateCalculationRequest?.loanValue}
                                onChange={loanValue => handleChange({ loanValue: loanValue / 100 })}
                                showValidation={simulateCalculationRequest?.loanValue ? true : false}
                                validate={value =>
                                    validateLoanValue({
                                        loanValue: value,
                                        minLoanValue: simulate?.minLoanValue,
                                        maxLoanValue: simulate?.maxLoanValue,
                                        salary: company?.salary,
                                        installmentNumber: simulateCalculationRequest?.installmentNumber,
                                    })
                                }
                                externalUpdate
                            />
                            <Slider
                                placeholder={t(`${I18N_PREFIX}.input.installmentNumber.placeholder`)}
                                label={t(`${I18N_PREFIX}.input.installmentNumber.label`)}
                                onChange={installmentNumber => handleChange({ installmentNumber })}
                                maxValue={simulate?.maxInstallmentNumber}
                                minValue={simulate?.minInstallmentNumber}
                                defaultValue={proposeSummary?.installmentNumber ?? simulate?.minInstallmentNumber}
                                externalUpdate
                                validate={value =>
                                    validateMaxInstallmentValue(
                                        simulateCalculationRequest?.loanValue,
                                        value as number,
                                        simulate?.maxInstallmentValue
                                    )
                                }
                                showValidation={simulateCalculationRequest?.installmentNumber ? true : false}
                            />
                            {!isEditing && <ButtonAction label={t(`${I18N_PREFIX}.button`)} onClick={handleSave} />}
                            <CurrencyReadOnlyField
                                label={t(`${I18N_PREFIX}.input.availableCredit.label`)}
                                value={simulate?.maxLoanValue ?? 0}
                                iconLabel={<MonetizationOnOutlinedIcon />}
                            />
                            <NumberReadOnlyField
                                label={t(`${I18N_PREFIX}.input.receiptDeadline.label`)}
                                prefix={t(`${I18N_PREFIX}.input.receiptDeadline.prefix`)}
                                value={simulate?.deadlineForReceipt ?? 0}
                                iconLabel={<TodayOutlinedIcon />}
                            />
                            <DateReadOnlyField
                                label={t(`${I18N_PREFIX}.input.dateToFirstPay.label`)}
                                value={simulate?.dateToFirstPay}
                                iconLabel={<EventOutlinedIcon />}
                            />
                        </Card>
                    </div>
                </>
            )}
        </>
    );
};

export default SimulateCardManager;
