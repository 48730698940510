import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import './close-button.scss';

export interface CloseButtonProps {
    onClick?: () => void;
    disabled?: boolean;
}

export const CloseButton = (props: CloseButtonProps): React.JSX.Element => {
    return (
        <div className="button__style--close">
            <IconButton onClick={props.onClick} disabled={props.disabled}>
                <Icon>
                    <div className="button__style--close-icon" />
                </Icon>
            </IconButton>
        </div>
    );
};

export default CloseButton;
