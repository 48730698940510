import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import Loading from '../../components/loading/loading';
import { Authentication } from '../../model/authentication';
import { ErrorConstants, ErrorType, GiroWebappErrorConstants } from '../../model/enums/error-constants';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { InviteStatus } from '../../model/enums/invite-status';
import { WhatsAppStatus } from '../../model/enums/whatsapp-status';
import { ErrorHandlingCustomization } from '../../model/error';
import { InviteCompany, InviteToCreateProvider, InviteToCreateProviderRequest } from '../../model/invite';
import { hasAcceptedTermsResetState } from '../../reducer/account/terms-of-use/actions';
import { userAccountResetState } from '../../reducer/account/user-account/actions';
import { customizeErrorHandling, markError } from '../../reducer/application/error/actions';
import { loginRequest } from '../../reducer/authentication/actions';
import { defaultCompanyEmployeeResetState } from '../../reducer/company/default/actions';
import { useAuthenticationState, useInviteToAcceptState, useProviderByInviteState, useRootDispatch } from '../../reducer/hooks';
import { inviteToAcceptRequest, inviteToAcceptUpdateCompany } from '../../reducer/invite/toAccept/actions';
import { createProviderRequest } from '../../reducer/person/provider-invite/actions';
import authUtil from '../../services/api/authUtil';
import './accept-invite.scss';
import Contact from './pages/contact/contact';
import ContactInformative from './pages/contact/contact-informative';
import ContactPhone from './pages/contact/contact-phone';
import EditEmail from './pages/edit-email/edit-email';
import IdentificationPerson from './pages/identification-step/identification-person';
import IdentificationStep from './pages/identification-step/identification-step';
import IdentificationUser from './pages/identification-step/identification-user';
import InviteSteps from './pages/invite-steps/invite-steps';
import { StepsEnum } from './pages/invite-steps/invite-steps-context';
import InviteSuccess from './pages/invite-success/invite-success';
import ConfirmOnly from './pages/Password/confirm-only';
import PasswordAndConfirm from './pages/Password/password-and-confirm';
import PasswordOnly from './pages/Password/password-only';
import TermsOfUseStep from './pages/terms-of-use-step/terms-of-use-step';
import Welcome from './pages/welcome/welcome';

enum AcceptInviteQueryParams {
    COMPANY_NAME = 'companyName',
    COMPANY_IDENTIFICATION = 'identification',
    ORGANIZATION_NAME = 'organizationName',
}

interface AcceptInviteParams {
    token: string;
}

const useInviteToAcceptRequest = (initialToken: string) => {
    const [token] = React.useState<string>(initialToken);
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(inviteToAcceptRequest(token));
    }, [token, dispatch]);
};

const useInviteToAcceptAwareness = () => {
    const state = useInviteToAcceptState();
    const isLoading = state.status !== HttpRequestStatus.ERROR && state.status !== HttpRequestStatus.SUCCESS;
    return { isLoading };
};

const useInviteStatusValidation = () => {
    const state = useInviteToAcceptState();
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.SUCCESS && state.invite?.status && state.invite.status !== InviteStatus.OPEN) {
            type WrongInviteStatus = InviteStatus.CLOSED | InviteStatus.CONFIRMED | InviteStatus.WAITING_CONFIRMATION;
            const inviteStatusRecord: Record<WrongInviteStatus, GiroWebappErrorConstants> = {
                CLOSED: GiroWebappErrorConstants.INVITE_STATUS_CLOSED,
                CONFIRMED: GiroWebappErrorConstants.INVITE_STATUS_CONFIRMED,
                WAITING_CONFIRMATION: GiroWebappErrorConstants.INVITE_STATUS_WAITING_CONFIRMATION,
            };
            dispatch(markError({ error_description: inviteStatusRecord[state.invite.status] }));
        }
    }, [state.status, state.invite, dispatch]);
};

interface InviteParams {
    companyName: string;
    companyIdentification: string;
    organizationName: string;
}

const useInviteParams = (query: URLSearchParams): [InviteParams] => {
    const dispatch = useRootDispatch();

    const [companyName, setCompanyName] = React.useState<string>('');
    const [organizationName, setOrganizationName] = React.useState<string>('');
    const [companyIdentification, setCompanyIdentification] = React.useState<string>('');

    React.useEffect(() => {
        setCompanyName(query.get(AcceptInviteQueryParams.COMPANY_NAME) ?? '');
        setOrganizationName(query.get(AcceptInviteQueryParams.ORGANIZATION_NAME) ?? '');
        setCompanyIdentification(query.get(AcceptInviteQueryParams.COMPANY_IDENTIFICATION) ?? '');
    }, [query]);

    React.useEffect(() => {
        const company: InviteCompany = { name: companyName, identification: companyIdentification };
        dispatch(inviteToAcceptUpdateCompany(company));
    }, [companyName, companyIdentification, dispatch]);

    return [{ companyName, companyIdentification, organizationName }];
};

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        const _record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.invite.not.found': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'accept-invite.error.understood',
                },
            },
            'error.invite.closed': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'accept-invite.error.understood',
                },
            },
            'error.invite.confirmed': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'accept-invite.error.understood',
                },
            },
            'error.invite.waitingConfirmation': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'accept-invite.error.understood',
                },
            },
        };
        dispatch(dispatch(customizeErrorHandling({ record: _record })));
    }, [dispatch]);
};

export const AcceptInvite = (): React.JSX.Element => {
    const { token } = useParams<AcceptInviteParams>();
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const dispatch = useRootDispatch();
    const [{ companyName, organizationName }] = useInviteParams(query);

    const { invite } = useInviteToAcceptState();
    const providerByInviteState = useProviderByInviteState();
    const authenticationState = useAuthenticationState();

    useInviteToAcceptRequest(token);
    const { isLoading } = useInviteToAcceptAwareness();
    useErrorConfiguration();
    useInviteStatusValidation();

    const [inviteConfirmation, setInviteConfirmation] = React.useState<InviteToCreateProvider>({} as InviteToCreateProvider);
    const [isCompleted, setCompleted] = React.useState<boolean>(false);

    const handleChange = (value: Partial<InviteToCreateProvider>) => {
        setInviteConfirmation({ ...inviteConfirmation, ...value });
    };

    React.useEffect(() => {
        authUtil.clear();
        dispatch(userAccountResetState());
        dispatch(hasAcceptedTermsResetState());
        dispatch(defaultCompanyEmployeeResetState());
    }, [dispatch]);

    React.useEffect(() => {
        const { status, person } = providerByInviteState;
        if (status === HttpRequestStatus.SUCCESS && person?.inviteStatus === InviteStatus.CONFIRMED) {
            setCompleted(true);
        }
    }, [providerByInviteState]);

    React.useEffect(() => {
        const { status } = authenticationState;
        if (status === HttpRequestStatus.SUCCESS || status === HttpRequestStatus.ERROR) {
            history.push('/invite-redirect');
        }
    }, [authenticationState, history]);

    const handleFinish = (accept: boolean) => {
        if (accept) {
            const _confirm = { ...inviteConfirmation, acceptedTerms: accept, whatsAppStatus: WhatsAppStatus.NOT_RECEIVE };
            setInviteConfirmation(_confirm);
            if (isCompleted) {
                setCompleted(false);
            }
            const _token = token;
            const request: InviteToCreateProviderRequest = { token: _token, invite: _confirm };
            dispatch(createProviderRequest(request));
        }
    };

    const handleOnStartUse = () => {
        const _username = inviteConfirmation.email;
        const _password = inviteConfirmation.password;
        const authentication: Authentication = { username: _username, password: _password };
        dispatch(loginRequest(authentication));
    };

    const stepsRecord: Record<StepsEnum, React.ReactNode> = {
        WELCOME: <Welcome companyName={companyName} organizationName={organizationName} loading={isLoading} />,
        IDENTIFICATION: (
            <IdentificationStep
                companyName={companyName}
                personName={inviteConfirmation.personName ?? invite?.personName ?? ''}
                email={inviteConfirmation.email ?? invite?.email ?? ''}
                onChange={handleChange}
            />
        ),
        IDENTIFICATION_USER: (
            <IdentificationUser companyName={companyName} email={inviteConfirmation.email ?? invite?.email ?? ''} onChange={handleChange} />
        ),
        IDENTIFICATION_PERSON: (
            <IdentificationPerson personName={inviteConfirmation.personName ?? invite?.personName ?? ''} onChange={handleChange} />
        ),
        EDIT_EMAIL: <EditEmail onChange={handleChange} />,
        PASSWORD_AND_CONFIRM: (
            <PasswordAndConfirm
                username={inviteConfirmation.email ?? ''}
                password={inviteConfirmation.password ?? ''}
                confirm={inviteConfirmation.confirm ?? ''}
                onChange={handleChange}
            />
        ),
        PASSWORD_ONLY: (
            <PasswordOnly username={inviteConfirmation.email ?? ''} password={inviteConfirmation.password ?? ''} onChange={handleChange} />
        ),
        CONFIRM_ONLY: (
            <ConfirmOnly
                username={inviteConfirmation.email ?? ''}
                password={inviteConfirmation.password ?? ''}
                confirm={inviteConfirmation.confirm ?? ''}
                onChange={handleChange}
            />
        ),
        CONTACT: (
            <Contact
                phoneNumber={inviteConfirmation.phoneNumber ?? ''}
                whatsappStatus={inviteConfirmation.whatsAppStatus ?? WhatsAppStatus.NOT_RECEIVE}
                onChange={handleChange}
            />
        ),
        CONTACT_INFORMATIVE: (
            <ContactInformative whatsappStatus={inviteConfirmation.whatsAppStatus ?? WhatsAppStatus.NOT_RECEIVE} onChange={handleChange} />
        ),
        CONTACT_PHONE: (
            <ContactPhone
                phoneNumber={inviteConfirmation.phoneNumber ?? ''}
                whatsappStatus={inviteConfirmation.whatsAppStatus ?? WhatsAppStatus.NOT_RECEIVE}
                onChange={handleChange}
            />
        ),
        TERMS_OF_USE: <TermsOfUseStep acceptTerms={inviteConfirmation.acceptedTerms ?? false} onFinish={handleFinish} />,
    };

    return (
        <div className="page">
            <Container>{isLoading ? <Loading /> : <InviteSteps stepsRecord={stepsRecord} token={token} />}</Container>
            <InviteSuccess open={isCompleted} onStartUse={handleOnStartUse} />
        </div>
    );
};

export default AcceptInvite;
