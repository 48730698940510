import { AxiosResponse } from 'axios';
import { ProposeEmployeeSummaryData } from 'model/employee-borrower/propose/summary.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { employeeBorrowerProposeSummaryError, employeeBorrowerProposeSummaryRequest, employeeBorrowerProposeSummarySuccess, } from './actions';
import { EmployeeBorrowerProposeSummaryActionTypes } from './types';

function* handleGetEmployeeBorrowerProposeSummary(action: ReturnType<typeof employeeBorrowerProposeSummaryRequest>) {
    try {
        const result: AxiosResponse<ProposeEmployeeSummaryData> = yield call(proposeApi.proposeSummaryRequest, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerProposeSummaryError(mapped));
            return;
        }
        yield put(employeeBorrowerProposeSummarySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerProposeSummaryError(mapped));
    }
}

function* watchGetEmployeeBorrowerProposeSummary() {
    yield takeLeading(EmployeeBorrowerProposeSummaryActionTypes.EMPLOYEE_BORROWER_PROPOSE_SUMMARY_REQUEST, handleGetEmployeeBorrowerProposeSummary);
}

function* employeeBorrowerProposeSummarySaga() {
    yield all([fork(watchGetEmployeeBorrowerProposeSummary)]);
}

export default employeeBorrowerProposeSummarySaga;
