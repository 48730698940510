import { AxiosResponse } from 'axios';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import employeeBorrowerProposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { updateEmployeeBorrowerProposeGeneralDataError, updateEmployeeBorrowerProposeGeneralDataRequest, updateEmployeeBorrowerProposeGeneralDataSuccess } from './actions';
import { UpdateEmployeeBorrowerProposeGeneralDataActionTypes } from './types';

function* handleUpdateEmployeeBorrowerProposeGeneralData(action: ReturnType<typeof updateEmployeeBorrowerProposeGeneralDataRequest>) {
    try {
        const result: AxiosResponse<ProposeEmployeeSimple> = yield call(employeeBorrowerProposeApi.updateProposeGeneralData, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(updateEmployeeBorrowerProposeGeneralDataError(mapped));
            return;
        }
        yield put(updateEmployeeBorrowerProposeGeneralDataSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateEmployeeBorrowerProposeGeneralDataError(mapped));
    }
}

function* watchUpdateEmployeeBorrowerProposeGeneralData() {
    yield takeLeading(UpdateEmployeeBorrowerProposeGeneralDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_GENERAL_DATA_REQUEST, handleUpdateEmployeeBorrowerProposeGeneralData);
}


function* updateEmployeeBorrowerProposeGeneralDataSagas() {
    yield all([fork(watchUpdateEmployeeBorrowerProposeGeneralData)]);
}

export default updateEmployeeBorrowerProposeGeneralDataSagas;
