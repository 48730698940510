import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeBorrowerProposeRecentActionTypes {
    EMPLOYEE_BORROWER_PROPOSE_RECENT_REQUEST = '@@employeeBorrowerProposeRecent/EMPLOYEE_BORROWER_PROPOSE_RECENT_REQUEST',
    EMPLOYEE_BORROWER_PROPOSE_RECENT_SUCCESS = '@@employeeBorrowerProposeRecent/EMPLOYEE_BORROWER_PROPOSE_RECENT_SUCCESS',
    EMPLOYEE_BORROWER_PROPOSE_RECENT_ERROR = '@@employeeBorrowerProposeRecent/EMPLOYEE_BORROWER_PROPOSE_RECENT_ERROR',
    EMPLOYEE_BORROWER_PROPOSE_RECENT_RESET_STATE = '@@employeeBorrowerProposeRecent/EMPLOYEE_BORROWER_PROPOSE_RECENT_RESET_STATE',
}

export interface EmployeeBorrowerProposeRecentState extends BaseStoreState {
    readonly proposeRecent?: ProposeEmployeeSimple;
}
