import { UserAccessToken } from '../../../model/user';
import { BaseStoreState } from '../../baseStoreState';

export enum FirstAccessActionTypes {
    FIRST_ACCESS_REQUEST = '@@firstAccess/FIRST_ACCESS_REQUEST',
    FIRST_ACCESS_SUCCESS = '@@firstAccess/FIRST_ACCESS_SUCCESS',
    FIRST_ACCESS_ERROR = '@@firstAccess/FIRST_ACCESS_ERROR',
    FIRST_ACCESS_RESET_STATE = '@@firstAccess/FIRST_ACCESS_RESET_STATE',
}

export interface FirstAccessState extends BaseStoreState {
  readonly user?: UserAccessToken;
}
