import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ButtonAction from 'components/buttons/button-action/button-action';
import Card from 'components/card/card';
import FieldPassword from 'components/field-password/field-password';
import PasswordStrength from 'components/password-strength/password-strength';
import { PasswordMatch, validatePassword, validatePasswordMatch } from '../../validation-constants';
import './password.scss';

export interface PasswordProps {
    username: string;
    isLoading: boolean;
    onFinish: (password: string, confirm: string) => void;
}

const mapPasswordMatch = (p: string, m: string): PasswordMatch => ({ password: p, match: m });

export const Password = (props: PasswordProps) => {
    const { t } = useTranslation();

    const [password, setPassword] = React.useState<string>('');
    const [confirm, setConfirm] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const haltProgression = !validatePassword(password).isValid || !validatePasswordMatch(mapPasswordMatch(password, confirm)).isValid;

    const handleClick = () => {
        setValidation(true);
        if (haltProgression) return;

        props.onFinish(password, confirm);
    };

    return (
        <div className="body__password-reset--desktop">
            <Row style={{ width: '100%' }}>
                <Col md="10" style={{ margin: 'auto' }}>
                    <div className="body__password--title">{t('password-reset.title')}</div>
                </Col>
            </Row>
            <div className="body__password--components">
                <Col md="6" offset="3">
                    <form className="form__style">
                        <input id="password-hidden-username" hidden readOnly value={props.username} autoComplete="username" />
                        <FieldPassword
                            className="password__input"
                            value={password}
                            label={t('password-reset.password.label')}
                            onChange={setPassword}
                            validate={validatePassword}
                            autoComplete="new-password"
                            helpText={t('password-reset.password.description')}
                            showValidation={showValidation}
                        />
                        <FieldPassword
                            className="password__input"
                            value={confirm}
                            label={t('password-reset.confirm.label')}
                            onChange={setConfirm}
                            validate={it => validatePasswordMatch(mapPasswordMatch(password, it))}
                            autoComplete="new-password"
                            showValidation={showValidation}
                        />
                    </form>
                </Col>
                <Col md="3" className="password__strength--desktop">
                    <Card>
                        <div className="body__password-strength">{t('password-reset.strength').toUpperCase()}</div>
                        <PasswordStrength password={password} />
                    </Card>
                </Col>
            </div>
            <Row>
                <div className="body__password--buttons">
                    <Col>
                        <div className="body__password--button-finish">
                            <ButtonAction
                                label={t('password-reset.finish')}
                                onClick={handleClick}
                                disabled={showValidation && haltProgression}
                                isLoading={props.isLoading}
                            />
                        </div>
                    </Col>
                </div>
            </Row>
        </div>
    );
};

export default Password;
