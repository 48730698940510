import ButtonNextStep from 'components/buttons/button-next-step/button-next-step';
import ButtonPrevious from 'components/buttons/button-previous/button-previous';
import PhoneInput from 'components/phone-input/phone-input';
import { WhatsAppStatus } from 'model/enums/whatsapp-status';
import { InviteToCreateProvider } from 'model/invite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { useSteps } from '../invite-steps/invite-steps-context';
import { validatePhoneNumber, validateWhatsappNumber } from '../validation/validation-constants';
import './contact.scss';

export interface ContactPhoneProps {
    phoneNumber: string;
    whatsappStatus: WhatsAppStatus;
    onChange: (change: Partial<InviteToCreateProvider>) => void;
}

export const ContactPhone = (props: ContactPhoneProps) => {
    const { t } = useTranslation();
    const steps = useSteps();
    const [phone, setPhone] = React.useState<string>(props.phoneNumber ?? '');
    const [showValidation, setValidtion] = React.useState<boolean>(false);

    const validation = WhatsAppStatus.RECEIVE === props.whatsappStatus ? validateWhatsappNumber : validatePhoneNumber;

    const haltProgression = !validation(phone).isValid;

    const handleNext = () => {
        setValidtion(true);
        if (haltProgression) return;

        props.onChange({ phoneNumber: phone });
        steps.toNext();
    };

    const handleEnter = () => {
        if (steps.isMobile) handleNext();
    };

    return (
        <div className="body__contact">
            <Row>
                <Col md="12" lg={{ size: 6, offset: 3 }}>
                    <Row>
                        <Col>
                            <div className="body__contact--title">{t('accept-invite.contact.phone.label')}</div>
                        </Col>
                    </Row>
                    <div className="field--background">
                        <Row style={{ justifyContent: 'center' }}>
                            <Col>
                                <PhoneInput
                                    value={props.phoneNumber}
                                    label={t(
                                        `accept-invite.contact.${
                                            WhatsAppStatus.RECEIVE === props.whatsappStatus ? 'whatsapp' : 'phone'
                                        }.label`
                                    )}
                                    onChange={setPhone}
                                    validate={validation}
                                    autoFocus
                                    onEnter={handleEnter}
                                    showValidation={showValidation}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <div className="body__contact--buttons">
                            <Col>
                                <div className="body__contact--button-previous">
                                    <ButtonPrevious onClick={steps.toPrevious} />
                                </div>
                            </Col>
                            <Col>
                                <div className="body__contact--button-finish">
                                    <ButtonNextStep onClick={handleNext} disabled={showValidation && haltProgression} />
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ContactPhone;
