import { IEmployeeAnticipation } from '../../model/employee-anticipation.model';
import { BaseStoreState } from '../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeAnticipationActionTypes {
    EMPLOYEE_ANTICIPATION_REQUEST = '@@employeeAnticipation/EMPLOYEE_ANTICIPATION_REQUEST',
    EMPLOYEE_ANTICIPATION_SUCCESS = '@@employeeAnticipation/EMPLOYEE_ANTICIPATION_SUCCESS',
    EMPLOYEE_ANTICIPATION_ERROR = '@@employeeAnticipation/EMPLOYEE_ANTICIPATION_ERROR',

    EMPLOYEE_ANTICIPATION_HISTORY_REQUEST = '@@employeeAnticipation/EMPLOYEE_ANTICIPATION_HISTORY_REQUEST',
    EMPLOYEE_ANTICIPATION_HISTORY_SUCCESS = '@@employeeAnticipation/EMPLOYEE_ANTICIPATION_HISTORY_SUCCESS',
    EMPLOYEE_ANTICIPATION_HISTORY_ERROR = '@@employeeAnticipation/EMPLOYEE_ANTICIPATION_HISTORY_ERROR',

    EMPLOYEE_ANTICIPATION_RESET_STATE = '@@employeeAnticipation/EMPLOYEE_ANTICIPATION_RESET_STATE',
}

export interface EmployeeAnticipationState extends BaseStoreState {
    readonly employeeAnticipation?: IEmployeeAnticipation;
    readonly employeeAnticipations?: IEmployeeAnticipation[];
}
