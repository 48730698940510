import Collapse from '@material-ui/core/Collapse';
import ButtonNext from 'components/buttons/button-next/button-next';
import ButtonPrevious from 'components/buttons/button-previous/button-previous';
import PhoneInput from 'components/phone-input/phone-input';
import { WhatsAppStatus } from 'model/enums/whatsapp-status';
import { InviteToCreateProvider } from 'model/invite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { COLLAPSE_TIMEOUT } from '../animation/animation-constants';
import { useSteps } from '../invite-steps/invite-steps-context';
import { validatePhoneNumber, validateWhatsappNumber } from '../validation/validation-constants';
import './contact.scss';

export interface ContactProps {
    phoneNumber: string;
    whatsappStatus: WhatsAppStatus;
    onChange: (change: Partial<InviteToCreateProvider>) => void;
}

export const Contact = (props: ContactProps) => {
    const { t } = useTranslation();
    const steps = useSteps();
    const [status] = React.useState<WhatsAppStatus>(props.whatsappStatus ?? WhatsAppStatus.NOT_RECEIVE);
    const [phone, setPhone] = React.useState<string>(props.phoneNumber ?? '');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const haltProgression = !validateWhatsappNumber(phone).isValid;

    const handleNext = () => {
        setValidation(true);
        if (haltProgression) {
            return;
        }
        if (status !== props.whatsappStatus || phone !== props.phoneNumber) {
            props.onChange({ whatsAppStatus: status, phoneNumber: phone });
        }
        steps.toNext();
    };

    return (
        <div className="body__contact">
            <Row>
                <Col md="12" lg={{ size: 6, offset: 3 }}>
                    <Row>
                        <Col>
                            <div className="body__contact--title">
                                {t('accept-invite.contact.start')}
                                {t('accept-invite.contact.end')}
                            </div>
                        </Col>
                    </Row>
                    <Collapse
                        in={status === WhatsAppStatus.RECEIVE || status === WhatsAppStatus.NOT_RECEIVE}
                        timeout={COLLAPSE_TIMEOUT}
                        unmountOnExit
                    >
                        <div className="field--background">
                            <Row style={{ justifyContent: 'center' }}>
                                <Col>
                                    <PhoneInput
                                        value={props.phoneNumber}
                                        label={t(`accept-invite.contact.${WhatsAppStatus.RECEIVE === status ? 'whatsapp' : 'phone'}.label`)}
                                        onChange={setPhone}
                                        validate={value =>
                                            WhatsAppStatus.RECEIVE === status ? validateWhatsappNumber(value) : validatePhoneNumber(value)
                                        }
                                        autoFocus
                                        showValidation={showValidation}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                    <Row>
                        <div className="body__contact--buttons">
                            <Col>
                                <div className="body__contact--button-previous">
                                    <ButtonPrevious onClick={steps.toPrevious} />
                                </div>
                            </Col>
                            <Col>
                                <div className="body__contact--button-next">
                                    <ButtonNext
                                        onClick={handleNext}
                                        disabled={status === WhatsAppStatus.NOT_KNOWN || (showValidation && haltProgression)}
                                    />
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Contact;
