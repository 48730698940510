import FormControl from '@material-ui/core/FormControl';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import React from 'react';
import BaseMaskInput, { BaseMaskInputProps } from '../base-mask-input';
import InputUtils from '../input-utils';
import LocalDateInputMask from './local-date-mask-input';

type Base = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'value' | 'onChange'>;

export interface SimpleLocalDateInputProps extends Base {
    value: string;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    defaultDate?: Date;
    placeholder?: string;
    onChange?: (value: string | undefined) => void;
}

export const SimpleLocalDateInput = (props: SimpleLocalDateInputProps) => {
    const { value: propsValue, defaultDate, placeholder, MaskInputComponent, onChange, ...otherProps } = props;

    const [refDate, setRefDate] = React.useState<Date | undefined>(
        InputUtils.isValidDateISO(propsValue) ? InputUtils.parseDateFromISO(propsValue) : InputUtils.parseDate(defaultDate)
    );

    const handleChange = (value: string) => {
        const _date = InputUtils.parseLocalDate(value);

        if (onChange && value.length < 10) {
            onChange(undefined);
            return;
        }

        setRefDate(_date);

        if (onChange && InputUtils.isValidDate(_date)) {
            onChange(InputUtils.formatToLocalDateISO(_date));
        }
    };

    React.useEffect(() => {
        if (!propsValue && !defaultDate) {
            setRefDate(undefined);
        }
    }, [propsValue, defaultDate]);

    return (
        <FormControl className={clsx('base-input', { dirty: refDate })}>
            <BaseMaskInput
                {...otherProps}
                value={InputUtils.formatToLocalDate(refDate)}
                onChange={handleChange}
                MaskInputComponent={MaskInputComponent ?? LocalDateInputMask}
                placeholder={placeholder}
            />
        </FormControl>
    );
};

export default SimpleLocalDateInput;
