import { AxiosResponse } from 'axios';
import { IEmployeeMonthlyCredit } from '../../model/employee-monthly-credit.model';
import { api } from './api';

const BASE_URL = 'api/webapp/employee-monthly-credits';

export const employeeMonthlyCredits = () => {
    const getCurrentAvailableCredit = (): Promise<AxiosResponse<IEmployeeMonthlyCredit>> => {
        return api.get<IEmployeeMonthlyCredit>(`${BASE_URL}/current?timestamp=${new Date().getTime()}`);
    };

    const findAll = (): Promise<AxiosResponse<IEmployeeMonthlyCredit>> => {
        return api.get<IEmployeeMonthlyCredit>(`${BASE_URL}`);
    };

    return {
        getCurrentAvailableCredit,
        findAll,
    };
};

export default employeeMonthlyCredits();
