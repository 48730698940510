export enum ErrorType {
    NON_BLOCKING = 'NON_BLOCKING',
    BLOCKING = 'BLOCKING',
    IGNORED = 'IGNORED',
}

export enum GiroApiErrorConstants {
    // ERR_CONCURRENCY_FAILURE = 'error.concurrencyFailure',
    ERR_VALIDATION = 'error.validation',
    ERR_ACCESS_DENIED = 'error.accessDenied',
    // ERR_INTERNAL_SERVER_ERROR = 'error.internalServerError',
    ERROR_INVALID_IMPORT_STATE = 'error.import.state',
    ERROR_INVALID_CREDIT_STATE = 'error.credit.state',
    ERROR_INVALID_CREDIT = 'error.credit.invalid',
    ENTITY_NOT_FOUND = 'error.entity.notfound',
    ORGANIZATION_NOT_FOUND = 'error.organization.notfound',
    WRONG_ORGANIZATION = 'error.wrong.organization',
    PROVIDER_ALREADY_IN_PROGRAM = 'error.provider.already.in.program',
    OPERATION_CLOSED = 'error.operation.closed',
    COMPANY_ALREADY_EXISTS = 'error.company.exists',
    // COMPANY_ID_IS_NULL = 'error.company.is.not.null',
    COMPANY_NOT_EXISTS = 'error.company.notexists',
    COMPANY_DOES_NOT_HAVE_ADDRESS = 'error.company.does.not.have.address',
    CREDIT_NOT_FOUND = 'error.credit.notfound',
    CREDIT_WRONG_STATUS = 'error.credit.wrongstatus',
    // CREDIT_ACCEPTED_DATE_NULL = 'error.credit.accepted.date.null',
    BATCH_EMPTY = 'error.batch.empty',
    PROVIDER_NOT_ACTIVATE = 'error.provider.notactive',
    PROVIDER_HAS_CREDIT = 'error.provider.hascredit',
    PROVIDER_EMPTY = 'error.provider.empty',
    REQUEST_BATCH_DISABLED = 'error.request.batch.disabled',
    REQUEST_WRONG_PROVIDER = 'error.request.wrong.provider',
    REQUEST_MULTIPLE_PROGRAMS = 'error.request.multiple.programs',
    REQUEST_PROVIDER_CANT_ANTICIPATE = 'error.REQUEST.provider.cant.anticipate',
    REQUEST_PROVIDER_CANT_CANCEL = 'error.REQUEST.provider.cant.cancel',
    BATCH_NOT_FOUND = 'error.batch.notfound',
    BATCH_WRONG_STATUS = 'error.batch.wrong.status',
    // BATCH_EMPTY_CREDITS = 'error.batch.credit.empty',
    BATCH_CREDIT_RECONCILIATION_FAILED = 'error.batch.creditreconciliationfailed',
    BATCH_ALL_CREDIT_RECONCILIATION_FAILED = 'error.batch.allcreditreconciliationfailed',
    BANK_ACCOUNT_NOT_EMPTY = 'error.bankaccount.not.empty',
    BANK_ACCOUNT_NOT_FOUND = 'error.bankaccount.not.found',
    EMAIL_ALREADY_IN_USE = 'email.already.inuse',
    ERROR_RESET_PASSWORD = 'error.reset.password',
    BUYER_EMPTY = 'error.buyer.empty',
    PROGRAM_EMPTY = 'error.program.empty',
    PROGRAM_NOT_FOUND = 'error.program.notfound',
    PROGRAM_NOT_EMPTY = 'error.program.notempty',
    IDENTIFIER_KEY_NOT_FOUND = 'error.identifierkey.notfound',
    FINANCIAL_EMPTY = 'error.financial.empty',
    PERSON_NOT_FOUND = 'error.person.notfound',
    PERSON_EMPTY = 'error.person.empty',
    CANNOT_CREATE_AN_EXISTING_PERSON = 'error.person.exists',
    USER_NOT_CHANGE_BATCH = 'error.batch.usernotchange',
    // USER_EMPTY = 'error.user.empty',
    USER_NOT_ACTIVATE = 'error.user.notactive',
    INVALID_CNPJ = 'error.invalid.cnpj',
    CNPJ_DUPLICATE = 'error.progm.cnpjduplicate',
    COMPANY_NAME_EMPTY = 'error.companyName.empty',
    EMAIL_ALREADY_BLOCKED = 'error.blocked.email.already',
    INVALID_PASSWORD = 'error.invalidpassword',
    USER_NOT_FOUND = 'error.user.notfound',
    USER_LOGIN_EMPTY = 'error.user.loginempty',
    USER_INVALID_PARAMS = 'error.user.invalidparams',
    USER_INVALID_CLIENTID = 'error.user.invalidclientid',
    // DATE_LOWER_OR_EQUAL = 'error.date.lowerorequal',
    USER_NOT_TEAMMEMBER = 'error.user.not.teammember',
    USER_NOT_HAVE_COMPANY = 'error.user.not.have.company',
    WRONG_INVITE_STATUS = 'error.wrong.invite.status',
    INVITE_NOT_FOUND = 'error.invite.not.found',

    INVITE_PASSWORD_DOES_NOT_MATCH = 'error.invite.passwordDoesNotMatch',
    INVITE_PHONE_NUMBER_ABSENT = 'error.invaite.phoneNumberAbsent',
    USER_PASSWORD_DOES_NOT_MATCH = 'error.user.passwordDoesNotMatch',
    USER_RESET_KEY_DOES_NOT_EXISTS = 'error.user.resetKeyDoesNotExists',

    INVOICE_OR_PROVIDER_TITLE_EXISTS = 'error.company.companyProvider.link.exists',
    // ORGANIZATION_KEY_ALREADY_EXISTS = 'error.organization.key.already.exists',
    UNAUTHORIZED_ORGANIZATION_ACCESS = 'error.unauthorized.organization.access',
    // CREDIT_REQUEST_WRONG_ORGANIZATION = 'error.credit.request.wrong.organization',
    BANK_NOT_FOUND = 'error.bank.not.found',
    USER_HAS_ACTIVE_PIN = 'error.user.has.active.pin',
    USER_HAS_REGISTERED_PIN = 'error.user.has.registered.pin',
    USER_DOES_NOT_HAVE_PIN = 'error.user.does.not.have.pin',
    PIN_NOT_MATCH = 'error.pin.not.match',
    SESSION_TERM_NOT_FOUND = 'error.sessionTerm.not.found',
    CITY_NOT_FOUND = 'error.city.not.found',
    FILE_NOT_FOUND = 'error.file.not.found',
    FILE_IO_EXCEPTION = 'error.file.io.exception',
    INVALID_ACCESS_TOKEN = 'invalid.access.token',
    ERROR_REFRESH_TOKEN = 'error.refresh.token',

    EMPLOYEE_GENERAL_DATA_INVALID = "error.invalid.employeeGeneralData",
    EMPLOYEE_SUPPLEMENTARY_DATA_INVALID = "error.invalid.employeeSupplementaryData",

    PERSON_NOT_TEAM_MEMBER = 'error.person.notTeamMember',
}

export enum GiroWebappErrorConstants {
    // http
    HTTP_UNAUTHORIZED = 'error.http.unauthorized',
    HTTP_FORBIDDEN = 'error.http.forbidden',

    // invite
    INVITE_STATUS_CONFIRMED = 'error.invite.confirmed',
    INVITE_STATUS_WAITING_CONFIRMATION = 'error.invite.waitingConfirmation',
    INVITE_STATUS_CLOSED = 'error.invite.closed',

    // user
    USER_NOT_ACTIVATED = 'error.user.notActivated',
}

export type ErrorConstants = GiroApiErrorConstants | GiroWebappErrorConstants;
