import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { MyCompaniesActionTypes, MyCompaniesState } from './types';

export const initialState: MyCompaniesState = {
    status: HttpRequestStatus.NOOP,
    companies: [],
    error: undefined,
};

const reducer: Reducer<MyCompaniesState> = (state = initialState, action): MyCompaniesState => {
    switch (action.type) {
        case MyCompaniesActionTypes.GET_MY_COMPANIES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, companies: [], error: undefined };
        }
        case MyCompaniesActionTypes.GET_MY_COMPANIES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companies: action.payload, error: undefined };
        }
        case MyCompaniesActionTypes.GET_MY_COMPANIES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as myCompaniesReducer };
