import clsx from 'clsx';
import BaseTextInput, { BaseTextInputProps } from 'components/inputs/base-text-input';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import React from "react";

type BaseInput = Omit<BaseTextInputProps, 'shouldShrinkLabel' | 'inputComponent' | 'changeMapper'>;
type BaseValidation = Omit<ValidInputProps<string>, 'children'>;

export type ValidTextInputProps = BaseInput & BaseValidation;

export const ValidTextInput = (props: ValidTextInputProps) => {
    const { validate, showValidation, type, ...otherProps } = props;

    return (
        <ValidInput value={props.value} validate={validate} showValidation={showValidation} className={clsx('base-input', { dirty: props.value })}>
            {({ error }) => <BaseTextInput {...otherProps} error={error} type={type ?? 'text'} />}
        </ValidInput>
    );
};

export default ValidTextInput;
