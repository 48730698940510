import { validateEmail, validatePassword } from 'features/login/validation-constants';
import { validatePasswordMatch } from 'features/password-reset/validation-constants';
import { PersonToProfileUpdate } from 'model/person';
import React from 'react';
import { changePasswordRequest } from 'reducer/account/change-password/actions';
import { updatePersonRequest } from 'reducer/account/update-person/actions';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { UserToChangePassword } from '../../model/user';
import { useChangePasswordState, usePersonUpdateProfileState, useRootDispatch } from '../../reducer/hooks';

export interface ProfileContextProps {
    isEditing: boolean;
    showValidation: boolean;
    changePassword: UserToChangePassword;
    isUpdatingPassword: boolean;
    updatePasswordSuccess: boolean;
    isUpdatingPerson: boolean;
    updatePersonSuccess: boolean;
    handlePasswordChange: (update: Partial<UserToChangePassword>) => void;
    setIsEditing: (value: boolean) => void;
    handleSave: () => void;
    messageVisibility: boolean;
    setMessageVisibility: (value: boolean) => void;
    disabled: string;
    setShowValidation: (value: boolean) => void;
    activeId: ActiveType;
    setActiveId: (value: ActiveType) => void;
    handleUpdatePerson: (value: Partial<PersonToProfileUpdate>) => void;
    setPersonUpdate: (value: PersonToProfileUpdate) => void;
    personUpdate: PersonToProfileUpdate;
}

export type ActiveType = 'data' | 'editData' | 'password';

export const ProfileContext = React.createContext<ProfileContextProps>({} as ProfileContextProps);

export const ProfileProvider: React.FunctionComponent = props => {
    const dispatch = useRootDispatch();
    const changePasswordState = useChangePasswordState();
    const updatePersonState = usePersonUpdateProfileState();

    const getInitialActiveId = (): ActiveType => {
        const hashValue = window.location.hash.slice(1);
        if (hashValue === 'data' || hashValue === 'editData' || hashValue === 'password') {
            return hashValue;
        }
        return 'data';
    };

    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [activeId, setActiveId] = React.useState<ActiveType>(getInitialActiveId());
    const [personUpdate, setPersonUpdate] = React.useState<PersonToProfileUpdate>({} as PersonToProfileUpdate);
    const [changePassword, setChangePassword] = React.useState<UserToChangePassword>({} as UserToChangePassword);
    const [showValidation, setShowValidation] = React.useState<boolean>(false);
    const [messageVisibility, setMessageVisibility] = React.useState<boolean>(false);

    const isUpdatingPassword = changePasswordState.status === HttpRequestStatus.ONGOING;
    const updatePasswordSuccess = changePasswordState.status === HttpRequestStatus.SUCCESS;

    const isUpdatingPerson = updatePersonState.status === HttpRequestStatus.ONGOING;
    const updatePersonSuccess = updatePersonState.status === HttpRequestStatus.SUCCESS;

    const disabled = isUpdatingPassword ? 'disabled' : '';

    const handleSave = () => {
        setShowValidation(true);
        console.log('activeId', activeId);
        handleEditRequest();
        handlePasswordRequest();
    };

    const handleEditRequest = (): void => {
        if (activeId === 'editData') {
            if (!validateEmail(personUpdate.email).isValid) return;

            dispatch(updatePersonRequest(personUpdate));
        }
    };

    const handlePasswordRequest = (): void => {
        if (activeId === 'password') {
            if (!validatePassword(changePassword.currentPassword).isValid) return;
            if (!validatePasswordMatch({ password: changePassword.newPassword, match: changePassword.newPasswordConfirm }).isValid) return;

            dispatch(changePasswordRequest(changePassword));
        }
    };

    const handlePasswordChange = (update: Partial<UserToChangePassword>) => setChangePassword(ps => ({ ...ps, ...update }));

    const handleUpdatePerson = (value: Partial<PersonToProfileUpdate>) => setPersonUpdate(ps => ({ ...ps, ...value }));

    const value: ProfileContextProps = {
        isEditing,
        showValidation,
        changePassword,
        isUpdatingPassword,
        updatePasswordSuccess,
        isUpdatingPerson,
        updatePersonSuccess,
        setIsEditing,
        handleSave,
        handlePasswordChange,
        messageVisibility,
        setMessageVisibility,
        disabled,
        setShowValidation,
        activeId,
        setActiveId,
        handleUpdatePerson,
        setPersonUpdate,
        personUpdate,
    };
    return <ProfileContext.Provider value={value}>{props.children}</ProfileContext.Provider>;
};

export const useProfileContext = () => React.useContext(ProfileContext);

export const withProfileContext = () => <P extends object>(WrapperComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <ProfileProvider>
            <WrapperComponent {...props} />
        </ProfileProvider>
    );
};
