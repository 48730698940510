import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { UpdatePersonActionTypes, UpdatePersonState } from './types';

export const initialState: UpdatePersonState = {
    status: HttpRequestStatus.NOOP,
    person: undefined,
    error: undefined,
};

const reducer: Reducer<UpdatePersonState> = (state = initialState, action): UpdatePersonState => {

    switch (action.type) {
        case UpdatePersonActionTypes.UPDATE_PERSON_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, person: undefined, error: undefined };
        }
        case UpdatePersonActionTypes.UPDATE_PERSON_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, person: action.payload, error: undefined };
        }
        case UpdatePersonActionTypes.UPDATE_PERSON_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdatePersonActionTypes.UPDATE_PERSON_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updatePersonReducer };
