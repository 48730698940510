import ButtonBack from 'components/buttons/button-back/button-back';
import FilterButton from 'components/buttons/filter-button/filter-button';
import Loading from 'components/loading/loading';
import ModalMessage from 'components/modals/modal-message/modal-message';
import ListHistoryProposesHeader from 'features/history-proposes/components/history-employee-proposes-header/history-employee-header';
import HistoryProposesModalFilter from 'features/history-proposes/components/history-proposes-modal-filter/history-proposes-modal-filter';
import ListHistoryEmployeeProposes from 'features/history-proposes/components/list-history-employee-proposes/list-history-employee-proposes';
import { defaultHistoryProposeFilterRequest,ProposeEmployeeHistoryFilterPageable,ProposeEmployeeHistoryFilterRequest } from 'model/employee-history-proposes';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { ListHistorySortingType } from 'model/enums/proposeSortingType';
import React,{ useCallback,useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cancelEmployeeProposeRequest,cancelEmployeeProposeResetState } from 'reducer/employeeCancelPropose/actions';
import { listHistoryEmployeeProposesRequest,listHistoryEmployeeProposesResetState } from 'reducer/history-proposes/actions';
import { useCancelProposeEmployeeState,useListProposeHistoryState,useRootDispatch } from 'reducer/hooks';
import { LIST_PAGE_SIZE,Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import { useToast } from 'shared/hooks/use-toast';
import Pagination from 'shared/pagination/pagination';
import './history-proposes.scss';

const I18N_PREFIX = 'employee-proposes-history';

const EmployeeProposesHistory = () => {
    const { t } = useTranslation();
    const { proposes, status: listStatus } = useListProposeHistoryState();
    const dispatch = useRootDispatch();
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<ListHistorySortingType | undefined>(ListHistorySortingType.RECENT);
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const { status: cancelProposeStatus } = useCancelProposeEmployeeState();
    const { toastSuccess } = useToast();

    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);

    const [openModalFilter, setOpenModalFilter] = useState<boolean>(false);
    const [filterActive, setFilterActive] = useState<ProposeEmployeeHistoryFilterRequest>(defaultHistoryProposeFilterRequest);

    const filterPageable = useCallback((): ProposeEmployeeHistoryFilterPageable => {
        const _pageable: Pageable = {
            page,
            size: LIST_PAGE_SIZE,
        };

        const _filter: ProposeEmployeeHistoryFilterRequest = { ...filterActive, sortingType: sortedProperty } ?? {};

        return {
            pageable: _pageable,
            filter: _filter,
        };
    }, [sortedProperty, page, filterActive]);

    useEffect(() => {
        dispatch(listHistoryEmployeeProposesRequest(filterPageable()));
    }, [dispatch, filterPageable]);

    useEffect(() => {
        return () => {
            dispatch(listHistoryEmployeeProposesResetState());
        };
    }, [dispatch, filterPageable]);

    useEffect(() => {
        if (cancelProposeStatus === HttpRequestStatus.SUCCESS) {
            setOpenModalCancel(false);
            toastSuccess(t(`${I18N_PREFIX}.toast.cancel-propose.success`));
            dispatch(listHistoryEmployeeProposesRequest(filterPageable()));
            dispatch(cancelEmployeeProposeResetState());
        }
    }, [cancelProposeStatus, dispatch, filterPageable, t, toastSuccess]);

    const handleTryAgain = () => {
        dispatch(listHistoryEmployeeProposesRequest(filterPageable()));
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('employee-proposes-history.error-message')}</div>
                <ButtonBack label={t('global.try-again')} onClick={handleTryAgain} />
            </div>
        );
    }

    const isLoading = listStatus !== HttpRequestStatus.SUCCESS;

    const handleSort = (property: ListHistorySortingType) => {
        if (sortOrder === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
            return;
        }
        setSortedProperty(property);
        setSortOrder('asc');
    };

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    return (
        <>
            <main className="history-proposes">
                <section className="history-proposes__container">
                    <header className="history-proposes__header">
                        <h1 className="history-proposes__header--title">{t(`${I18N_PREFIX}.title`)}</h1>
                        <div className="history-proposes__header--buttons-filter-button">
                            <FilterButton
                                onClick={() => {
                                    setOpenModalFilter(true);
                                }}
                                label={t(`${I18N_PREFIX}.modal.filter.label`)}
                            />
                        </div>
                    </header>

                    <div className="history-proposes__table">
                        <table className="page-container--table">
                            <thead>
                                <ListHistoryProposesHeader
                                    proposes={proposes?.content ?? []}
                                    handleSort={handleSort}
                                    sortedProperty={sortedProperty}
                                    sortOrder={sortOrder}
                                />
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <>
                                        <Loading isTable />
                                    </>
                                ) : (
                                    <>
                                        <ListHistoryEmployeeProposes
                                            setSelectedId={setSelectedId}
                                            setOpenModalCancel={setOpenModalCancel}
                                        />
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {!isLoading && <Pagination page={page} totalPages={proposes?.totalPages ?? 0} onChange={handlePageChange} />}
                    <HistoryProposesModalFilter
                        openModal={openModalFilter}
                        onClose={() => setOpenModalFilter(false)}
                        title={t(`${I18N_PREFIX}.modal.filter.label`)}
                        setFilterActive={setFilterActive}
                        filterActive={filterActive}
                    />
                    {openModalCancel && (
                        <ModalMessage
                            title={t(`${I18N_PREFIX}.modal.cancel-propose-request.title`)}
                            message={t(`${I18N_PREFIX}.modal.cancel-propose-request.subtitle`)}
                            onAction={() => {
                                dispatch(cancelEmployeeProposeRequest(selectedId as number));
                            }}
                            onClose={() => setOpenModalCancel(false)}
                            onCancel={() => setOpenModalCancel(false)}
                            disabled={cancelProposeStatus === HttpRequestStatus.ONGOING}
                        />
                    )}
                </section>
            </main>
        </>
    );
};

export default EmployeeProposesHistory;
