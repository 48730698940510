import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CompanyDefault } from '../../../model/company';
import HttpStatus from '../../../model/enums/httpStatus';
import companyApi from '../../../services/api/companyApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { changeDefaultCompanyEmployeeRequest, getDefaultCompanyEmployeeError, getDefaultCompanyEmployeeSuccess } from './actions';
import { DefaultCompanyActionTypes } from './types';

function* handleGetCompanyDefault() {
    try {
        const result: AxiosResponse<CompanyDefault> = yield call(companyApi.getDefault);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(getDefaultCompanyEmployeeError(mapped));
            return;
        }
        yield put(getDefaultCompanyEmployeeSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getDefaultCompanyEmployeeError(mapped));
    }
}

function* watchGetCompanyDefault() {
    yield takeEvery(DefaultCompanyActionTypes.GET_DEFAULT_COMPANY_EMPLOYEE_REQUEST, handleGetCompanyDefault);
}

function* handleChangeDefaultCompany(action: ReturnType<typeof changeDefaultCompanyEmployeeRequest>) {
    try {
        const result: AxiosResponse<CompanyDefault> = yield call(companyApi.changeDefault, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(getDefaultCompanyEmployeeError(mapped));
            return;
        }
        yield put(getDefaultCompanyEmployeeSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getDefaultCompanyEmployeeError(mapped));
    }
}

function* watchChangeDefaultCompany() {
    yield takeEvery(DefaultCompanyActionTypes.CHANGE_COMPANY_EMPLOYEE_DEFAULT_REQUEST, handleChangeDefaultCompany);
}

function* defaultCompanySaga() {
    yield all([fork(watchGetCompanyDefault), fork(watchChangeDefaultCompany)]);
}

export default defaultCompanySaga;
