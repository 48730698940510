import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './button-previous.scss';

export interface ButtonPreviousProps {
    onClick: () => void;
    style?: any;
    disabled?: boolean;
}

export const ButtonPrevious = (props: ButtonPreviousProps): React.JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="button__previous" {...props}>
            <Button variant="contained" onClick={props.onClick} disabled={props.disabled}>
                <InputAdornment position="start">
                    <div className="button--arrow" />
                </InputAdornment>
                {t('accept-invite.buttons.previous').toUpperCase()}
            </Button>
        </div>
    );
};

export default ButtonPrevious;
