import { AxiosResponse } from 'axios';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import employeeBorrowerProposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { updateEmployeeBorrowerProposeSupplementaryDataError, updateEmployeeBorrowerProposeSupplementaryDataRequest, updateEmployeeBorrowerProposeSupplementaryDataSuccess } from './actions';
import { UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes } from './types';

function* handleUpdateEmployeeBorrowerProposeSupplementaryData(action: ReturnType<typeof updateEmployeeBorrowerProposeSupplementaryDataRequest>) {
    try {
        const result: AxiosResponse<ProposeEmployeeSimple> = yield call(employeeBorrowerProposeApi.updateProposeSupplementaryData, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(updateEmployeeBorrowerProposeSupplementaryDataError(mapped));
            return;
        }
        yield put(updateEmployeeBorrowerProposeSupplementaryDataSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateEmployeeBorrowerProposeSupplementaryDataError(mapped));
    }
}

function* watchUpdateEmployeeBorrowerProposeSupplementaryData() {
    yield takeLeading(UpdateEmployeeBorrowerProposeSupplementaryDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_SUPPLEMENTARY_DATA_REQUEST, handleUpdateEmployeeBorrowerProposeSupplementaryData);
}


function* updateEmployeeBorrowerProposeSupplementaryDataSagas() {
    yield all([fork(watchUpdateEmployeeBorrowerProposeSupplementaryData)]);
}

export default updateEmployeeBorrowerProposeSupplementaryDataSagas;
