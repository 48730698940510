import { EmployeeBorrowerAvailableServices } from 'model/employee-borrower/employee-services.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeBorrowerAvailableServicesActionTypes {
    EMPLOYEE_BORROWER_AVAILABLE_SERVICES_REQUEST = '@@employeeBorrowerAvailableServices/EMPLOYEE_BORROWER_AVAILABLE_SERVICES_REQUEST',
    EMPLOYEE_BORROWER_AVAILABLE_SERVICES_SUCCESS = '@@employeeBorrowerAvailableServices/EMPLOYEE_BORROWER_AVAILABLE_SERVICES_SUCCESS',
    EMPLOYEE_BORROWER_AVAILABLE_SERVICES_ERROR = '@@employeeBorrowerAvailableServices/EMPLOYEE_BORROWER_AVAILABLE_SERVICES_ERROR',
    EMPLOYEE_BORROWER_AVAILABLE_SERVICES_RESET_STATE = '@@employeeBorrowerAvailableServices/EMPLOYEE_BORROWER_AVAILABLE_SERVICES_RESET_STATE',
}

export interface EmployeeBorrowerAvailableServicesState extends BaseStoreState {
    readonly availableServices?: EmployeeBorrowerAvailableServices;
}
