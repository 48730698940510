import ButtonAction from 'components/buttons/button-action/button-action';
import ButtonBack from 'components/buttons/button-back/button-back';
import FieldBasic from 'components/field-basic/field-basic';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { UserFirstAccessRequest } from 'model/user';
import React,{ useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { firstAccessRequest } from 'reducer/account/first-access/actions';
import { useFirstAccessState,useRootDispatch } from 'reducer/hooks';
import './first-access.scss';
import { validateInput } from './validation-constants';

const useFirstAccessAwareness = () => {
    const state = useFirstAccessState();

    const isLoading = state.status === HttpRequestStatus.ONGOING;

    return { isLoading };
};

export const FirstAccess = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const state = useFirstAccessState();

    const [name, setName] = React.useState<string>('');
    const [cpf, setCpf] = React.useState<string>('');
    const [register, setRegister] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);
    const [returnedErro, setReturnedErro] = React.useState<boolean>(false);

    const { isLoading } = useFirstAccessAwareness();

    const haltProgression = !validateInput(name).isValid || !validateInput(cpf).isValid || !validateInput(register).isValid;

    const handleClick = async () => {
        setValidation(true);
        if (haltProgression) return;
        const payload: UserFirstAccessRequest = {
            name: name.trim(),
            identification: cpf.trim(),
            employeeIdentificationNumber: register.trim(),
        };

        dispatch(firstAccessRequest(payload));
    };

    const handleCpfChange = (value: string) => {
        setCpf(value.replace(/\D/g, ''));
    };

    useEffect(() => {
        if (state.user?.token) {
            history.push(`/accept-invite/${state.user.token}`);
        }
        if (state.status === HttpRequestStatus.ERROR) {
            setReturnedErro(true);
        } else {
            setReturnedErro(false);
        }
    }, [state]);

    return (
        <div style={{ marginTop: '150px' }} className="body__first-access">
            <Row>
                <Col style={{ margin: 'auto' }} md={{ size: 4 }}>
                    <div className="body__first-access--title">{t('first-access.title')}</div>
                    <form className="form__style">
                        <FieldBasic
                            label={t('first-access.label.name')}
                            value={name}
                            onChange={setName}
                            showValidation={showValidation}
                            validate={validateInput}
                            className={returnedErro ? 'red_border' : ''}
                        />
                        <FieldBasic
                            label={t('first-access.label.cpf')}
                            value={cpf}
                            validate={validateInput}
                            onChange={handleCpfChange}
                            showValidation={showValidation}
                            className={returnedErro ? 'red_border' : ''}
                        />
                        <FieldBasic
                            label={t('first-access.label.register')}
                            value={register}
                            validate={validateInput}
                            onChange={setRegister}
                            showValidation={showValidation}
                            className={returnedErro ? 'red_border' : ''}
                        />
                    </form>
                    {returnedErro && <div className="styled_error">{t('first-access.error')}</div>}
                    <div className="container__button--actions">
                        <ButtonAction
                            label={t('first-access.advance')}
                            onClick={handleClick}
                            disabled={showValidation && haltProgression}
                            isLoading={isLoading}
                        />
                        <ButtonBack label={t('first-access.cancel')} onClick={() => history.push('/dashboard')} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default FirstAccess;
