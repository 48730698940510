import { ProposeEmployeeSummaryData } from 'model/employee-borrower/propose/summary.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeBorrowerProposeSummaryActionTypes {
    EMPLOYEE_BORROWER_PROPOSE_SUMMARY_REQUEST = '@@employeeBorrowerProposeSummary/EMPLOYEE_BORROWER_PROPOSE_SUMMARY_REQUEST',
    EMPLOYEE_BORROWER_PROPOSE_SUMMARY_SUCCESS = '@@employeeBorrowerProposeSummary/EMPLOYEE_BORROWER_PROPOSE_SUMMARY_SUCCESS',
    EMPLOYEE_BORROWER_PROPOSE_SUMMARY_ERROR = '@@employeeBorrowerProposeSummary/EMPLOYEE_BORROWER_PROPOSE_SUMMARY_ERROR',
    EMPLOYEE_BORROWER_PROPOSE_SUMMARY_RESET_STATE = '@@employeeBorrowerProposeSummary/EMPLOYEE_BORROWER_PROPOSE_SUMMARY_RESET_STATE',
}

export interface EmployeeBorrowerProposeSummaryState extends BaseStoreState {
    readonly proposeSummary?: ProposeEmployeeSummaryData;
}
