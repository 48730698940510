import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const MONTH_VALIDATION: Validations<string> = {
    required: {
        predicate: it => it === null || it === undefined || (it.length === 2),
        errorMessage: `employee-proposes-history.modal.filter.error-message.month`
    }
}

export const validateMonth = (value: string | undefined | null) => ValidationUtils.validate(value, MONTH_VALIDATION);

const YEAR_VALIDATION: Validations<number> = {
    required: {
        predicate: it => it === null || it === undefined || (it.toString().length === 4) ,
        errorMessage: `employee-proposes-history.modal.filter.error-message.year`,
    },
    pastYear: {
        predicate: it => it === null || it === undefined || it <= new Date().getFullYear(),
        errorMessage: `employee-proposes-history.modal.filter.error-message.past-year`
    }

}

export const validateYear = (value: number | undefined | null) => ValidationUtils.validate(value, YEAR_VALIDATION);
