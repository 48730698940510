import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { EmployeeBorrowerProposeConfirmActionTypes, EmployeeBorrowerProposeConfirmState } from './types';

export const initialState: EmployeeBorrowerProposeConfirmState = {
    status: HttpRequestStatus.NOOP,
    proposeConfirm: undefined,
    error: undefined
};

const reducer: Reducer<EmployeeBorrowerProposeConfirmState> = (state = initialState, action): EmployeeBorrowerProposeConfirmState => {
    switch (action.type) {
        case EmployeeBorrowerProposeConfirmActionTypes.EMPLOYEE_BORROWER_PROPOSE_CONFIRM_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeBorrowerProposeConfirmActionTypes.EMPLOYEE_BORROWER_PROPOSE_CONFIRM_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, proposeConfirm: action.payload, error: undefined };
        }
        case EmployeeBorrowerProposeConfirmActionTypes.EMPLOYEE_BORROWER_PROPOSE_CONFIRM_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeBorrowerProposeConfirmActionTypes.EMPLOYEE_BORROWER_PROPOSE_CONFIRM_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeBorrowerProposeConfirmReducer };
