import { AxiosResponse } from 'axios';
import { PersonSimple, PersonTermsAcceptance } from '../../model/person';
import { UserAccount, UserNewPassword, UserPasswordResetRequest, UserSimple, UserToChangePassword } from '../../model/user';
import { api } from './api';

const BASE_URL = 'api';

export const accounts = () => {
    const hasAcceptedTerms = (): Promise<AxiosResponse<PersonTermsAcceptance>> => {
        return api.get<PersonTermsAcceptance>(`${BASE_URL}/accepted-terms`);
    };

    const acceptTerms = (): Promise<AxiosResponse<PersonSimple>> => {
        return api.put<PersonSimple>(`${BASE_URL}/accept-terms`);
    };

    const recoverUserAccount = (): Promise<AxiosResponse<UserAccount>> => {
        return api.get<UserAccount>(`${BASE_URL}/account`);
    };

    const requestPasswordReset = (payload: UserPasswordResetRequest): Promise<AxiosResponse<UserSimple>> => {
        return api.post<UserSimple>(`${BASE_URL}/account/reset-password/init`, payload.email);
    };

    const resetPassword = (payload: UserNewPassword): Promise<AxiosResponse<UserSimple>> => {
        return api.post<UserSimple>(`${BASE_URL}/account/reset-password/finish`, payload);
    };

    const changePassword = (payload: UserToChangePassword): Promise<AxiosResponse<UserSimple>> => {
        return api.post<UserSimple>(`${BASE_URL}/account/change-password`, payload);
    };

    return {
        hasAcceptedTerms,
        acceptTerms,
        recoverUserAccount,
        requestPasswordReset,
        resetPassword,
        changePassword,
    };
};

export default accounts();
