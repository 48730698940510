import { AxiosResponse } from 'axios';
import { PersonToProfile } from 'model/person';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import authUtil from 'services/api/authUtil';
import personApi from 'services/api/personApi';
import HttpStatus from '../../../model/enums/httpStatus';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { updatePersonError, updatePersonRequest, updatePersonSuccess } from './actions';
import { UpdatePersonActionTypes } from './types';

function* handleUpdatePerson(action: ReturnType<typeof updatePersonRequest>) {
    try {
        const result: AxiosResponse<PersonToProfile> = yield call(personApi.updateProfile, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(updatePersonError(mapped));
            return;
        }

        const newToken = result.headers['x-new-token'];
        authUtil.setToken({ id_token: newToken });

        yield put(updatePersonSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updatePersonError(mapped));
    }
}

function* watchUpdatePerson() {
    yield takeEvery(UpdatePersonActionTypes.UPDATE_PERSON_REQUEST, handleUpdatePerson);
}

function* updatePersonSaga() {
    yield all([fork(watchUpdatePerson)]);
}

export default updatePersonSaga;
