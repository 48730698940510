import { Grid } from '@material-ui/core';
import Currency from 'components/format/currency/Currency';
import ZipCodeFormatter from 'components/format/zip-code/zip-code-formatter';
import CardProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/card-propose-employee-summary/card-propose-employee-summary';
import ReadOnlyFieldProposeEmployeeSummary from 'features/employee-borrower/components/propose-employee/propose-employee-steps/propose-employee-summary/read-only-field-propose-employee-summary/read-only-field-propose-employee-summary';
import { ProposeEmployeeAddressSummary } from 'model/employee-borrower/propose/address.model';
import React from 'react';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'employee-borrower.propose.addressData';

interface CardAddressDataSummaryProps {
    addressData?: ProposeEmployeeAddressSummary;
    onClick: () => void;
    disabled: boolean;
}

const CardAddressDataSummary = (props: CardAddressDataSummaryProps) => {
    const { addressData, onClick, disabled } = props;

    const { t } = useTranslation();

    return (
        <CardProposeEmployeeSummary title={t(`${I18N_PREFIX}.subtitle`)} onClick={onClick} disabled={disabled}>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary value={addressData?.street} label={t(`${I18N_PREFIX}.input.street.label`)} />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={addressData?.number ? String(addressData.number) : undefined}
                    label={t(`${I18N_PREFIX}.input.number.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary value={addressData?.complement} label={t(`${I18N_PREFIX}.input.complement.label`)} />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary value={addressData?.zipCode ? <ZipCodeFormatter value={addressData?.zipCode} /> : undefined} label={t(`${I18N_PREFIX}.input.zipCode.label`)} />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary value={addressData?.city?.name} label={t(`${I18N_PREFIX}.input.city.label`)} />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary value={addressData?.neighborhood} label={t(`${I18N_PREFIX}.input.neighborhood.label`)} />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={t(`${I18N_PREFIX}.input.residenceType.options.${addressData?.residenceType}`)}
                    label={t(`${I18N_PREFIX}.input.residenceType.label`)}
                />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary value={addressData?.rentTime} label={t(`${I18N_PREFIX}.input.rentTime.label`)} />
            </Grid>
            <Grid item xs={6}>
                <ReadOnlyFieldProposeEmployeeSummary
                    value={addressData?.rentAmount ? <Currency value={addressData.rentAmount} /> : undefined}
                    label={t(`${I18N_PREFIX}.input.rentAmount.label`)}
                />
            </Grid>
        </CardProposeEmployeeSummary>
    );
};

export default CardAddressDataSummary;
