export enum PayrollLoanProposeStatus {
    HISTORY = 'HISTORY',
    UNKNOWN_MANAGER = 'UNKNOWN_MANAGER',
    MANAGER_REVIEW = 'MANAGER_REVIEW',
    HUMAN_RESOURCES_REVIEW = 'HUMAN_RESOURCES_REVIEW',
    OPERATIONAL_REVIEW = 'OPERATIONAL_REVIEW',
    NEED_CHANGE = 'NEED_CHANGE',
    INVALID_BANK_ACCOUNT = 'INVALID_BANK_ACCOUNT',
    APPROVED = 'APPROVED',
    WAITING_SIGNATURE = 'WAITING_SIGNATURE',
    SIGNED = 'SIGNED',
    CANCELLED = 'CANCELLED',
    RECUSED = 'RECUSED'
}

export enum RequestedType {
    PAYROLL_LOAN = 'PAYROLL_LOAN'
}