import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import BaseSearchInput, { BaseSearchInputProps } from 'components/inputs/search-input/base-search-input';
import React from 'react';
import './simple-search-input.scss';

interface SimpleSearchInputProps extends BaseSearchInputProps {
    id?: string;
    onChange?: (value: string) => void;
    onClick?: () => void;
    onFocus?: () => void;
    placeholder: string;
    label: string;
    value: string | undefined;
}

export const SimpleSearchInput = (props: SimpleSearchInputProps) => {
    const { onChange, placeholder, label, value, readOnly, onClick, onFocus, disabled , id} = props;

    return (
        <FormControl className={clsx('search-input', { dirty: value, readonly: readOnly, disabled })} fullWidth>
            <BaseSearchInput
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                onClick={onClick}
                onFocus={onFocus}
                label={label}
                value={value}
                readOnly={readOnly}
                disabled={disabled}
            />
        </FormControl>
    );
};

export default SimpleSearchInput;
