import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { EmployeeProgramActionTypes, EmployeeProgramState } from './types';

export const initialState: EmployeeProgramState = {
    status: HttpRequestStatus.NOOP,
    employeePrograms: undefined,
    error: undefined,
};

const reducer: Reducer<EmployeeProgramState> = (state = initialState, action): EmployeeProgramState => {
    switch (action.type) {
        case EmployeeProgramActionTypes.EMPLOYEE_PROGRAM_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case EmployeeProgramActionTypes.EMPLOYEE_PROGRAM_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, employeePrograms: action.payload, error: undefined };
        }
        case EmployeeProgramActionTypes.EMPLOYEE_PROGRAM_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case EmployeeProgramActionTypes.EMPLOYEE_PROGRAM_RESET_STATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as employeeProgramReducer };
