import { EmployeeBorrowerSimulateCalculation } from 'model/employee-borrower/simulate.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeBorrowerSimulateCalculationActionTypes {
    EMPLOYEE_BORROWER_SIMULATE_CALCULATION_REQUEST = '@@employeeBorrowerSimulateCalculation/EMPLOYEE_BORROWER_SIMULATE_CALCULATION_REQUEST',
    EMPLOYEE_BORROWER_SIMULATE_CALCULATION_SUCCESS = '@@employeeBorrowerSimulateCalculation/EMPLOYEE_BORROWER_SIMULATE_CALCULATION_SUCCESS',
    EMPLOYEE_BORROWER_SIMULATE_CALCULATION_ERROR = '@@employeeBorrowerSimulateCalculation/EMPLOYEE_BORROWER_SIMULATE_CALCULATION_ERROR',
    EMPLOYEE_BORROWER_SIMULATE_CALCULATION_RESET_STATE = '@@employeeBorrowerSimulateCalculation/EMPLOYEE_BORROWER_SIMULATE_CALCULATION_RESET_STATE',
}

export interface EmployeeBorrowerSimulateCalculationState extends BaseStoreState {
    readonly simulateCalculation?: EmployeeBorrowerSimulateCalculation;
}
