import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum EmployeeBorrowerProposeConfirmActionTypes {
    EMPLOYEE_BORROWER_PROPOSE_CONFIRM_REQUEST = '@@employeeBorrowerProposeConfirm/EMPLOYEE_BORROWER_PROPOSE_CONFIRM_REQUEST',
    EMPLOYEE_BORROWER_PROPOSE_CONFIRM_SUCCESS = '@@employeeBorrowerProposeConfirm/EMPLOYEE_BORROWER_PROPOSE_CONFIRM_SUCCESS',
    EMPLOYEE_BORROWER_PROPOSE_CONFIRM_ERROR = '@@employeeBorrowerProposeConfirm/EMPLOYEE_BORROWER_PROPOSE_CONFIRM_ERROR',
    EMPLOYEE_BORROWER_PROPOSE_CONFIRM_RESET_STATE = '@@employeeBorrowerProposeConfirm/EMPLOYEE_BORROWER_PROPOSE_CONFIRM_RESET_STATE',
}

export interface EmployeeBorrowerProposeConfirmState extends BaseStoreState {
    readonly proposeConfirm?: ProposeEmployeeSimple;
}
