import { AxiosResponse } from 'axios';
import { ProposeEmployeeAccountData } from 'model/employee-borrower/propose/account.model';
import { ProposeEmployeeAddressData } from 'model/employee-borrower/propose/address.model';
import { ProposeEmployeeDocumentData, ProposeEmployeeDocumentDeleteFile, ProposeEmployeeDocumentFileUpload } from 'model/employee-borrower/propose/document.model';
import { ProposeEmployeeGeneralData } from 'model/employee-borrower/propose/general-data.model';
import { ProposeEmployeeManager } from 'model/employee-borrower/propose/manager.model';
import { FeedbackSimple, FeedbackToEmployeeResult } from 'model/employee-borrower/propose/operator-feedback.model';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { ProposeEmployeeSummaryData } from 'model/employee-borrower/propose/summary.model';
import { ProposeEmployeeSupplementaryData } from 'model/employee-borrower/propose/supplementary-data.model';
import { api } from 'services/api/api';

const BASE_URL = '/api/payroll-loan/employee';

export const employeeBorrowerPropose = () => {
    const proposeRecentRequest = (): Promise<AxiosResponse<ProposeEmployeeSimple>> => {
        return api.get<ProposeEmployeeSimple>(`${BASE_URL}/proposes/recent`)
    };

    const updateProposeAddressData = (data: ProposeEmployeeAddressData): Promise<AxiosResponse<ProposeEmployeeSimple>> => {
        return api.put<ProposeEmployeeSimple>(`${BASE_URL}/proposes/address-data`, data)
    };

    const updateProposeGeneralData = (data: ProposeEmployeeGeneralData): Promise<AxiosResponse<ProposeEmployeeSimple>> => {
        return api.put<ProposeEmployeeSimple>(`${BASE_URL}/proposes/general-data`, data)
    };

    const updateProposeSupplementaryData = (data: ProposeEmployeeSupplementaryData): Promise<AxiosResponse<ProposeEmployeeSimple>> => {
        return api.put<ProposeEmployeeSimple>(`${BASE_URL}/proposes/supplementary-data`, data)
    };

    const updateProposeDocumentDataRequest = (data: ProposeEmployeeDocumentData): Promise<AxiosResponse<ProposeEmployeeSimple>> => {
        return api.put<ProposeEmployeeSimple>(`${BASE_URL}/proposes/document-data`, data);
    };

    const updateProposeDocumentFileUploadRequest = (data: ProposeEmployeeDocumentFileUpload): Promise<AxiosResponse<ProposeEmployeeSimple>> => {
        return api.put<ProposeEmployeeSimple>(`${BASE_URL}/employee-documents/propose/upload/${data.proposeId}`, data.documentFile);
    };

    const getDocumentDownload = (documentId: number): Promise<AxiosResponse<Uint8Array>> => {
        return api.get<Uint8Array>(`${BASE_URL}/employee-documents/download/${documentId}`, { responseType: 'arraybuffer' })
    }

    const proposeDocumentFileDeleteRequest = (request: ProposeEmployeeDocumentDeleteFile): Promise<AxiosResponse<void>> => {
        return api.delete<void>(`${BASE_URL}/employee-documents/${request.documentId}/propose/${request.proposeId}`);
    };

    const updateProposeManagerRequest = (data: ProposeEmployeeManager): Promise<AxiosResponse<ProposeEmployeeSimple>> => {
        return api.put<ProposeEmployeeSimple>(`${BASE_URL}/proposes/manager-data`, data);
    };

    const updateProposeAccountRequest = (data: ProposeEmployeeAccountData): Promise<AxiosResponse<ProposeEmployeeSimple>> => {
        return api.put<ProposeEmployeeSimple>(`${BASE_URL}/proposes/account-data`, data);
    };

    const proposeSummaryRequest = (proposeId: number): Promise<AxiosResponse<ProposeEmployeeSummaryData>> => {
        return api.get<ProposeEmployeeSummaryData>(`${BASE_URL}/proposes/summary/${proposeId}`);
    };

    const confirmProposeRequest = (proposeId: number): Promise<AxiosResponse<ProposeEmployeeSimple>> => {
        return api.put<ProposeEmployeeSimple>(`${BASE_URL}/proposes/confirm/${proposeId}`);
    };
    const hasCancelledPropose = (proposeId: number): Promise<AxiosResponse<ProposeEmployeeSimple>> => {
        return api.put<ProposeEmployeeSimple>(`${BASE_URL}/proposes/cancel/${proposeId}`);
    };

    const proposeOperatorFeedbackRequest = (proposeId: number): Promise<AxiosResponse<FeedbackToEmployeeResult>> => {
        return api.get<FeedbackToEmployeeResult>(`${BASE_URL}/feedbacks/need-change/${proposeId}`);
    };

    const sendProposeRequestedChangesToOperator = (proposeId: number): Promise<AxiosResponse<FeedbackSimple>> => {
        return api.patch<FeedbackSimple>(`${BASE_URL}/feedbacks/requested-changes/${proposeId}`);
    };

    return {
        updateProposeDocumentDataRequest,
        updateProposeDocumentFileUploadRequest,
        updateProposeManagerRequest,
        updateProposeAccountRequest,
        proposeSummaryRequest,
        proposeRecentRequest,
        updateProposeAddressData,
        updateProposeGeneralData,
        updateProposeSupplementaryData,
        confirmProposeRequest,
        hasCancelledPropose,
        proposeDocumentFileDeleteRequest,
        proposeOperatorFeedbackRequest,
        sendProposeRequestedChangesToOperator,
        getDocumentDownload
    };
};

export default employeeBorrowerPropose();
