import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum UpdateEmployeeBorrowerProposeDocumentFileUploadActionTypes {
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_REQUEST = '@@updateEmployeeBorrowerProposeDocumentFileUpload/UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_REQUEST',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_SUCCESS = '@@updateEmployeeBorrowerProposeDocumentFileUpload/UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_SUCCESS',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_ERROR = '@@updateEmployeeBorrowerProposeDocumentFileUpload/UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_ERROR',
    UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_FILE_UPLOAD_RESET_STATE = '@@updateEmployeeBorrowerProposeDocumentFileUpload/UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_RESET_STATE',
}

export interface UpdateEmployeeBorrowerProposeDocumentFileUploadState extends BaseStoreState {
    readonly proposeDocumentFileUpload?: ProposeEmployeeSimple;
}
