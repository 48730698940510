import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { MASK_DIGIT_REGEX } from '../constants';

const CPF_MASK = [
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '.',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '.',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '-',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
];

export const CpfMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={CPF_MASK} guide={false} />;
};

export default CpfMask;
