import SimpleCheckboxInput from 'components/inputs/checkbox-input/simple-checkbox-input';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import { validateManagerAnalystName } from 'features/employee-borrower/components/propose-employee/validation/propose-employee-manager-validation';
import { useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import { ProposeEmployeeManager, ProposeEmployeeSearchManagers } from 'model/employee-borrower/propose/manager.model';
import { PayrollLoanProposeAnalystStatus } from 'model/enums/analyst-status';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { employeeBorrowerAnalystSearchManagersRequest } from 'reducer/employeeBorrower/analyst/search-managers/actions';
import { updateEmployeeBorrowerProposeManagerResetState } from 'reducer/employeeBorrower/propose/propose-manager/update-propose-manager/actions';
import { useRootDispatch, useUpdateEmployeeBorrowerProposeManagerState } from 'reducer/hooks';
import Loading from 'components/loading/loading';
import { useToast } from 'shared/hooks/use-toast';
import '../propose-employee-steps.scss';

const I18N_PREFIX = 'employee-borrower.propose.manager';

interface ProposeEmployeeManagerStepProps {
    handleChange: (value: Partial<ProposeEmployeeManager>) => void;
    proposeManager: ProposeEmployeeManager;
}

export const ProposeEmployeeManagerStep = (props: ProposeEmployeeManagerStepProps) => {
    const { handleChange, proposeManager } = props;

    const [shouldLoading, setShouldLoading] = useState<boolean>(false)

    const { manager, analystStatus } = proposeManager;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { toDisabledButtonNext, toNextStep, isEditing, setCurrentStep, setIsEditing } = useEmployeeBorrowerContext();

    const { status: proposeManagerStatus } = useUpdateEmployeeBorrowerProposeManagerState();

    const [disabledManagerInputSearch, setDisabledManagerInputSearch] = useState<boolean>(
        analystStatus === PayrollLoanProposeAnalystStatus.MANAGER_UNKNOWN
    );

    const { toastSuccess } = useToast();

    const isDisabledNextStepButton: boolean = useMemo(() => {
        return !analystStatus && !validateManagerAnalystName(manager?.name).isValid && !validateManagerAnalystName(manager?.name).isValid;
    }, [manager, analystStatus]);

    useEffect(() => {
        toDisabledButtonNext(isDisabledNextStepButton);
    }, [isDisabledNextStepButton, toDisabledButtonNext]);

    const handleCheckBox = () => {
        setDisabledManagerInputSearch(!disabledManagerInputSearch);

        if (analystStatus === PayrollLoanProposeAnalystStatus.MANAGER_UNKNOWN) {
            handleChange({ analystStatus: undefined, manager: { name: undefined, email: undefined } });
            return;
        }

        handleChange({ analystStatus: PayrollLoanProposeAnalystStatus.MANAGER_UNKNOWN, manager: { name: undefined, email: undefined } });
    };

    useEffect(() => {
        if(proposeManagerStatus === HttpRequestStatus.ONGOING){
            setShouldLoading(true)
        }
        if (proposeManagerStatus === HttpRequestStatus.SUCCESS) {
            dispatch(updateEmployeeBorrowerProposeManagerResetState());

            if (!isEditing) {
                toNextStep();
                return;
            }
            setIsEditing(false);
            toastSuccess(t(`${I18N_PREFIX}.edit-success`));
            setCurrentStep('summary');
        }
    }, [proposeManagerStatus, toNextStep, dispatch, setCurrentStep, isEditing, setIsEditing, toastSuccess, t]);

    return (
        <>
            {shouldLoading ? (
                <div className="container__employee-borrower--loading">
                    <Loading />
                </div>
            ) : (
                <div className="container__propose-employee-step">
                    <div className="header__propose-employee-step">
                        <span className="header__propose-employee-step--title"> {t('employee-borrower.title')} </span>
                        <span className="header__propose-employee-step--subtitle"> {t(`${I18N_PREFIX}.subtitle`)} </span>
                        <span className="header__propose-employee-step--description"> {t(`${I18N_PREFIX}.description`)} </span>
                    </div>
                    <div className="form__propose-employee-step">
                        <div className="form__propose-employee-step--input">
                            <ModalSearch<ProposeEmployeeSearchManagers>
                                id="inputText_managerData_modal_name_email"
                                itemSize={SEARCH_ITEM_SIZE}
                                action={employeeBorrowerAnalystSearchManagersRequest}
                                modalTitle={t(`${I18N_PREFIX}.input.manager.modal.title`)}
                                modalLabel={`${I18N_PREFIX}.input.manager.modal.title`}
                                modalPlaceholder={`${I18N_PREFIX}.input.manager.modal.title`}
                                onSelect={managerData =>
                                    handleChange({
                                        manager: { managerId: Number(managerData.id), name: managerData.name, email: managerData.email },
                                    })
                                }
                                renderItem={managerData => managerData.name}
                                statusSelector={(state: IRootState) => state.employeeBorrowerSearchManagers.status}
                                dataSelector={(state: IRootState) => state.employeeBorrowerSearchManagers.managers}
                            >
                                {handleOpen => (
                                    <SimpleSearchInput
                                        id="inputText_managerData_name_email"
                                        label={`${I18N_PREFIX}.input.manager.label`}
                                        placeholder={`${I18N_PREFIX}.input.manager.placeholder`}
                                        value={analystStatus !== PayrollLoanProposeAnalystStatus.MANAGER_UNKNOWN ? manager?.name : ''}
                                        onClick={disabledManagerInputSearch ? undefined : handleOpen}
                                        onFocus={disabledManagerInputSearch ? undefined : handleOpen}
                                        readOnly
                                        externalUpdate
                                        disabled={disabledManagerInputSearch}
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleCheckboxInput
                                id="inputText_managerData_managerUnknown"
                                value={analystStatus === PayrollLoanProposeAnalystStatus.MANAGER_UNKNOWN ? true : false}
                                label={`${I18N_PREFIX}.input.analystStatus.label`}
                                onChange={handleCheckBox}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ProposeEmployeeManagerStep;
