const config = {
    VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.REACT_APP_API_URL;
export const GIRO_API_URL: string = process.env.REACT_APP_OLD_FRONTEND ?? 'https://app.consignado.staging.giro.tech/';
// REACT_APP_INVITE_REDIRECT=http://localhost:3000
export const INVITE_REDICT_URL: string = process.env.REACT_APP_INVITE_REDIRECT ?? GIRO_API_URL;
export const ORGANIZATION_NAME: string | undefined = process.env.REACT_APP_ORGANIZATION;

export const CREDIT_FLOW_URL: string = 'https://www.creditflow.com.br';
export const GIRO_APP_LINK: string = 'https://britain.gtfolha.giro.tech/';
export const GIRO_PIPEFY_EMPLOYEE_FORM_LINK: string = 'https://app.pipefy.com/public/form/40JNOeZH';
export const GIRO_PAYROLL_LOAN_ADMIN: string = 'https://britain.gtfolha.giro.tech/admin/';

export const GIRO_API_TERMS_WEBVIEW: string = `${GIRO_API_URL}#/accept-terms-webview`;

export const GIRO_PHONE_CONTACT = '(41) 98875-4600';

export const PRIVACY_POLICY_FULL_TERM_URL = "https://esparta.s3.amazonaws.com/Politica+de+Privacidade-+Plataforma+Consignado+-+CreditFlow+-+Final.pdf";
export const TERMS_AND_CONDITIONS_FULL_TERM_URL = "https://esparta.s3.amazonaws.com/Termos+e+Condi%C3%A7%C3%B5es+-+Tomador+Plataforma+Consignado+-+Atual.pdf";


export const messages = {
    DATA_ERROR_ALERT: 'Internal Error',
};

export const EMAIL_REGEX: RegExp = /^[\w-]+(?:\.[\w-]+)*@[a-z0-9-]+(?:\.[a-z0-9]+)*(?:\.[a-z]{2,})$/i;
export const PHONE_REGEX: RegExp = /^[1-9]{2}\d?\d{8}$/;
export const PHONE_PATTERN_REGEX = /^\(?[1-9]{2}\)?\s?\d?\d{4}-?\d{4}$/;
export const PHONE_PIX_REGEX: RegExp = /^(\+55\d{11}|\d{11})$/;

export const INTERCOM_WEBCHAT_URL = 'https://widget.intercom.io/widget/';
export const JIVO_WEBCHAT_URL = '//code.jivosite.com/widget/';
export const DIGISAC_WEBCHAT_URL = 'https://webchat.digisac.app/embedded.js';
export const DIGISAC_KEY = process.env.REACT_APP_DIGISAC_KEY;

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_IMAGE_SIZE_LIMIT = 15555555;

export const CURRENCY_FIXED_DECIMAL = 2;
export const UNIT_PRICE_FIXED_DECIMAL = 8;
export const PERCENTAGE_FIXED_DECIMAL = 2;

export const NUMBER_DEFAULT_DECIMAL_SEPARATOR = '.'; // dot

export const NUMBER_DECIMAL_SEPARATOR = ','; // comma
export const NUMBER_THOUSAND_SEPARATOR = '.'; // dot

export const INTL_LOCALE = 'pt-BR';
export const INTL_CURRENCY = 'BRL';

export const DATE_FNS_LOCAL_DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_FNS_SHORT_FULL_DATE_FORMAT = 'dd/MM/yy';
export const DATE_FNS_LONG_FULL_DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_FNS_DAY_ONLY_DATE_FORMAT = 'dd';

export const DEFAULT_PHONE_LENGTH = 10;
export const DEFAULT_PHONE_REGEX = /(\d{2})(\d{4})(\d{4})/g;
export const DEFAULT_PHONE_MASK = '($1) $2-$3';

export const EXTENDED_PHONE_LENGTH = 11;
export const EXTENDED_PHONE_REGEX = /(\d{2})(\d{5})(\d{4})/g;
export const EXTENDED_PHONE_MASK = '($1) $2-$3';

export const MOVIDESK_WEBCHAT_URL = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
export const MOVIDESK_WEBCHAT_KEY = 'F7D20CA3D632467098CDB106A7CA42E2';