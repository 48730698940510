import { ICompanyEmployee } from '../../model/company-employee.model';
import { BaseStoreState } from '../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum CompanyEmployeeActionTypes {
    COMPANY_EMPLOYEE_REQUEST = '@@companyEmployee/COMPANY_EMPLOYEE_REQUEST',
    COMPANY_EMPLOYEE_SUCCESS = '@@companyEmployee/COMPANY_EMPLOYEE_SUCCESS',
    COMPANY_EMPLOYEE_ERROR = '@@companyEmployee/COMPANY_EMPLOYEE_ERROR',
    COMPANY_EMPLOYEE_RESET_STATE = '@@companyEmployee/COMPANY_EMPLOYEE_RESET_STATEE',
}

export interface CompanyEmployeeState extends BaseStoreState {
    readonly companyEmployee?: ICompanyEmployee | null;
}
