import { ProposeEmployeeGeneralData } from 'model/employee-borrower/propose/general-data.model';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const GENERAL_DATA_PERSON_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateGeneralDataPersonName = (value: string | undefined | null) => ValidationUtils.validate(value, GENERAL_DATA_PERSON_NAME_VALIDATION);

const GENERAL_DATA_INFO_PHONE_NUMBER_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateGeneralDataPhoneNumber = (value: string | undefined | null) => ValidationUtils.validate(value, GENERAL_DATA_INFO_PHONE_NUMBER_VALIDATION);

const GENERAL_DATA_INFO_GENDER_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateGeneralDataGender = (value: string | undefined | null) => ValidationUtils.validate(value, GENERAL_DATA_INFO_GENDER_VALIDATION);

const GENERAL_DATA_INFO_BIRTHDATE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank
    },
    pastYear: {
        predicate: it => it !== null && it !== undefined && new Date(it) <= new Date()
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0)
    },
};

export const validateGeneralDataInfoBirthDate = (value: string | undefined | null) => ValidationUtils.validate(value, GENERAL_DATA_INFO_BIRTHDATE_VALIDATION);

export const validateProposeEmployeeGeneralData = (value: ProposeEmployeeGeneralData | undefined): boolean => {
    if (!value) return true;
    return (
        validateGeneralDataPersonName(value?.generalData?.name).isValid &&
        validateGeneralDataPhoneNumber(value?.generalData?.mainPhoneNumber?.number).isValid &&
        validateGeneralDataGender(value?.generalData?.gender).isValid &&
        validateGeneralDataInfoBirthDate(value?.generalData?.birthDate).isValid
    );
};