import { AxiosResponse } from 'axios';
import { EmployeeBorrowerSimulateCalculation } from 'model/employee-borrower/simulate.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import simulateApi from 'services/api/employee-borrower/simulateApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { employeeBorrowerUpdateLoanCalculationError, employeeBorrowerUpdateLoanCalculationRequest, employeeBorrowerUpdateLoanCalculationSuccess } from './actions';
import { EmployeeBorrowerLoanCalculationActionTypes } from './types';

function* handleUpdateEmployeeBorrowerLoanCalculation(action: ReturnType<typeof employeeBorrowerUpdateLoanCalculationRequest>) {
    try {
        const result: AxiosResponse<EmployeeBorrowerSimulateCalculation> = yield call(simulateApi.updateLoanCalculation, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerUpdateLoanCalculationError(mapped));
            return;
        }
        yield put(employeeBorrowerUpdateLoanCalculationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerUpdateLoanCalculationError(mapped));
    }
}

function* watchUpdateEmployeeBorrowerLoanCalculation() {
    yield takeLeading(EmployeeBorrowerLoanCalculationActionTypes.EMPLOYEE_BORROWER_LOAN_CALCULATION_REQUEST, handleUpdateEmployeeBorrowerLoanCalculation);
}

function* employeeBorrowerLoanCalculationSaga() {
    yield all([fork(watchUpdateEmployeeBorrowerLoanCalculation)]);
}

export default employeeBorrowerLoanCalculationSaga;
