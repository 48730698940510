import { AxiosResponse } from 'axios';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { updateEmployeeBorrowerProposeDocumentDataError, updateEmployeeBorrowerProposeDocumentDataRequest, updateEmployeeBorrowerProposeDocumentDataSuccess } from './actions';
import { UpdateEmployeeBorrowerProposeDocumentDataActionTypes } from './types';

function* handleUpdateEmployeeBorrowerProposeDocumentData(action: ReturnType<typeof updateEmployeeBorrowerProposeDocumentDataRequest>) {
    try {
        const result: AxiosResponse<ProposeEmployeeSimple> = yield call(proposeApi.updateProposeDocumentDataRequest, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(updateEmployeeBorrowerProposeDocumentDataError(mapped));
            return;
        }
        yield put(updateEmployeeBorrowerProposeDocumentDataSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateEmployeeBorrowerProposeDocumentDataError(mapped));
    }
}

function* watchUpdateEmployeeBorrowerProposeDocumentData() {
    yield takeLeading(UpdateEmployeeBorrowerProposeDocumentDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DATA_REQUEST, handleUpdateEmployeeBorrowerProposeDocumentData);
}

function* updateEmployeeBorrowerProposeDocumentDataSaga() {
    yield all([fork(watchUpdateEmployeeBorrowerProposeDocumentData)]);
}

export default updateEmployeeBorrowerProposeDocumentDataSaga;
