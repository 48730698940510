import InputUtils from 'components/inputs/input-utils';
import IdentificationUtils from 'shared/util/identification-utils';
import { isObjectDefined, valuesOfObject } from 'shared/util/object-utils';

export type Predicate<T> = (value: T | null | undefined) => boolean;

export interface Validation<T> {
    predicate: Predicate<T>;
    errorMessage?: string;
}

export interface Validations<T> {
    [key: string]: Validation<T>;
}

export interface ValidResult {
    isValid: true;
    errorMessage?: undefined;
}

export interface InvalidReulst {
    isValid: false;
    errorMessage: string;
}

export type ValidationResult = ValidResult | InvalidReulst;

const validatePredicate = <T>(value: T | null | undefined, validation: Validation<T>): ValidationResult => {
    return validation.predicate(value) ? { isValid: true } : { isValid: false, errorMessage: validation.errorMessage ? validation.errorMessage : '' };
};

const validate = <T>(value: T | null | undefined, validation: Validations<T>): ValidationResult => {
    return (
        valuesOfObject(validation)
            .filter(isObjectDefined)
            .map(it => validatePredicate(value, it))
            .find(it => !it.isValid) ?? { isValid: true }
    );
};

// String
type StringPredicate = Predicate<string | null | undefined>;

const isNotBlank: StringPredicate = it => it !== null && it !== undefined && it.trim() !== '';
const minLength = (targetLength: number): StringPredicate => it => it === null || it === undefined || it.length >= targetLength;
const maxLength = (targetLenght: number): StringPredicate => it => it === null || it === undefined || it.length <= targetLenght;
const pattern = (patternRegExp: RegExp): StringPredicate => it => it === null || it === undefined || patternRegExp.test(it);
const ValidCPF: StringPredicate = it => it !== null && it !== undefined && isValidCPF(it);

// Number
type NumberPredicate = Predicate<number | null | undefined>;

const isNotZero: NumberPredicate = it => it !== 0;
const isNotEmpty: NumberPredicate = it => it !== null && it !== undefined;
const minSize = (targetSize: number): NumberPredicate => it => it === null || it === undefined || it >= targetSize;
const maxSize = (targetSize: number): NumberPredicate => it => it === null || it === undefined || it <= targetSize;

// Date
type DatePredicate = Predicate<Date | null | undefined>;

const isValidDate: DatePredicate = it => it !== null && it !== undefined && InputUtils.isValidDate(it);

// Identification
const isValidCNPJ: StringPredicate = it => it === null || it === undefined || IdentificationUtils.isValidCNPJ(it);
const isValidCPF: StringPredicate = it => it === null || it === undefined || IdentificationUtils.isValidCPF(it);

export const ValidationUtils = {
    validate,
    STRING: {
        isNotBlank,
        minLength,
        maxLength,
        pattern,
        ValidCPF,
        isValidCNPJ,
        isValidCPF,
    },
    NUMBER: {
        isNotEmpty,
        isNotZero,
        minSize,
        maxSize,
    },
    DATE: {
        isValidDate
    }
};
