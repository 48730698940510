import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { employeeBorrowerProposeDocumentDeleteError, employeeBorrowerProposeDocumentDeleteRequest, employeeBorrowerProposeDocumentDeleteSuccess } from 'reducer/employeeBorrower/propose/propose-document-data/delete-propose-document-data/actions';
import { EmployeeBorrowerProposeDocumentDeleteTypes } from 'reducer/employeeBorrower/propose/propose-document-data/delete-propose-document-data/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import proposeApi from 'services/api/employee-borrower/proposeApi';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleProposeDocumentDelete(action: ReturnType<typeof employeeBorrowerProposeDocumentDeleteRequest>) {
    try {
        const result: AxiosResponse<void> = yield call(proposeApi.proposeDocumentFileDeleteRequest, action.payload);
        if (result.status !== HttpStatus.NO_CONTENT) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(employeeBorrowerProposeDocumentDeleteError(mapped));
            return;
        }
        yield put(employeeBorrowerProposeDocumentDeleteSuccess());
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(employeeBorrowerProposeDocumentDeleteError(mapped));
    }
}

function* watchProposeDocumentDelete() {
    yield takeLeading(EmployeeBorrowerProposeDocumentDeleteTypes.PROPOSE_DOCUMENT_DELETE_REQUEST, handleProposeDocumentDelete);
}

function* proposeDocumentDeleteSaga() {
    yield all([fork(watchProposeDocumentDelete)]);
}

export default proposeDocumentDeleteSaga;
