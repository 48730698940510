import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './button-cancel.scss';

export interface ButtonCancelProps {
    onClick: () => void;
}

export const ButtonCancel = (props: ButtonCancelProps): React.JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="button__cancel">
            <Button variant="contained" onClick={props.onClick}>
                <span> {t('accept-invite.buttons.cancel').toUpperCase()} </span>
            </Button>
        </div>
    );
};

export default ButtonCancel;
