import { action } from 'typesafe-actions';
import { IEmployeeTimeEntry } from '../../model/employee-time-entry.model';
import { GiroWebappError } from '../../model/error';
import { EmployeeTimeEntryActionTypes } from './types';

export const employeeTimeEntryRequest = () => action(EmployeeTimeEntryActionTypes.EMPLOYEE_TIME_ENTRY_REQUEST);
export const employeeTimeEntrySuccess = (entries: IEmployeeTimeEntry[]) =>
    action(EmployeeTimeEntryActionTypes.EMPLOYEE_TIME_ENTRY_SUCCESS, entries);
export const employeeTimeEntryError = (error: GiroWebappError) => action(EmployeeTimeEntryActionTypes.EMPLOYEE_TIME_ENTRY_ERROR, error);

export const employeeTimeEntryResetState = () => action(EmployeeTimeEntryActionTypes.EMPLOYEE_TIME_ENTRY_RESET_STATE);
