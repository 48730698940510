import { ErrorConstants, ErrorType } from './enums/error-constants';

export const UnknownError = {
    error_description: 'error.unknown',
};

export type UnknownErrorType = typeof UnknownError;

export const UnauthorizedError = {
    httpStatus: 401,
    error_description: 'error.http.unauthorized',
};

export const ForbiddenError = {
    httpStatus: 403,
    error_description: 'error.http.forbidden',
};

export const StagingEnvironmentAlert = {
    httpStatus: 200,
    error_description: 'global.alert.environment.staging',
};

export const BadRequestError = {
    httpStatus: 400,
    error_description: 'error.http.badRequest',
}

export type HttpError = typeof UnauthorizedError | typeof ForbiddenError  | typeof BadRequestError;

export interface GiroApiError {
    message?: string;
    httpStatus?: number;
    code?: number;
    fieldErrors?: string[];
    error_description?: string;
}

export type GiroWebappError = GiroApiError | HttpError | UnknownErrorType;

export interface ErrorMessage {
    key: string;
    options?: { [key: string]: string };
}

export interface ErrorAction {
    label?: string;
    handler?: (history, dispatch) => void;
    noAction?: boolean;
}

export interface ErrorHandlingCustomization {
    type?: ErrorType;
    message?: ErrorMessage;
    action?: ErrorAction;
}

export interface ErrorCustominization {
    defaultType?: ErrorType;
    record?: Partial<Record<ErrorConstants, ErrorHandlingCustomization>>;
}
