import React from 'react';
import FormatUtils from 'shared/util/format-utils';

export interface RgFormatterProps {
    value?: string;
}

export const RgFormatter = (props: RgFormatterProps) => {
    return <>{FormatUtils.formatRg(props.value)}</>;
};

export default RgFormatter;
