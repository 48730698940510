import { MenuItem } from '@material-ui/core';
import SimpleLocalDateInput from 'components/inputs/date-input/simple-local-date-input';
import SimplePhoneInput from 'components/inputs/phone-input/simple-phone-input';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import { validateProposeEmployeeGeneralData } from 'features/employee-borrower/components/propose-employee/validation/propose-employee-general-data-validation';
import { useEmployeeBorrowerContext } from 'features/employee-borrower/employee-borrower-context';
import { ProposeEmployeeGeneralData } from 'model/employee-borrower/propose/general-data.model';
import { GenderType } from 'model/enums/gender-type';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateEmployeeBorrowerProposeGeneralDataResetState } from 'reducer/employeeBorrower/propose/propose-general-data/update-propose-general-data/actions';
import { useRootDispatch, useUpdateEmployeeBorrowerProposeGeneralDataState } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import Loading from 'components/loading/loading';
import '../propose-employee-steps.scss';

const I18N_PREFIX = 'employee-borrower.propose.generalData';

interface ProposeEmployeeGeneralDataStepProps {
    handleChange: (value: Partial<ProposeEmployeeGeneralData>) => void;
    proposeGeneralData: ProposeEmployeeGeneralData;
}

export const ProposeEmployeeGeneralDataStep = (props: ProposeEmployeeGeneralDataStepProps) => {
    const { proposeGeneralData, handleChange } = props;

    const [shouldLoading, setShouldLoading] = useState<boolean>(false)

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { generalData } = proposeGeneralData;

    const { toDisabledButtonNext, toNextStep, isEditing, setCurrentStep, setIsEditing } = useEmployeeBorrowerContext();

    const { status: proposeGeneralDataStatus } = useUpdateEmployeeBorrowerProposeGeneralDataState();

    const { toastSuccess } = useToast();

    const isDisabledNextStepButton: boolean = useMemo(() => {
        return !validateProposeEmployeeGeneralData(proposeGeneralData);
    }, [proposeGeneralData]);

    useEffect(() => {
        toDisabledButtonNext(isDisabledNextStepButton);
    }, [isDisabledNextStepButton, toDisabledButtonNext]);

    useEffect(() => {
        if(proposeGeneralDataStatus === HttpRequestStatus.ONGOING){
            setShouldLoading(true)
        }
        if (proposeGeneralDataStatus === HttpRequestStatus.SUCCESS) {
            dispatch(updateEmployeeBorrowerProposeGeneralDataResetState());

            if (!isEditing) {
                toNextStep();
                return;
            }

            setIsEditing(false);
            toastSuccess(t(`${I18N_PREFIX}.edit-success`));
            setCurrentStep('summary');
        }
    }, [proposeGeneralDataStatus, toNextStep, dispatch, isEditing, setCurrentStep, setIsEditing, toastSuccess, t]);

    return (
        <>
            {shouldLoading ? (
                <div className='container__employee-borrower--loading'>
                    <Loading />
                </div>
            ) : (
                <div className="container__propose-employee-step">
                    <div className="header__propose-employee-step">
                        <span className="header__propose-employee-step--title"> {t('employee-borrower.title')} </span>
                        <span className="header__propose-employee-step--subtitle"> {t(`${I18N_PREFIX}.subtitle`)} </span>
                    </div>
                    <div className="form__propose-employee-step">
                        <div className="form__propose-employee-step--input">
                            <SimpleTextInput
                                id="inputText_generalData_name"
                                onChange={name => handleChange({ generalData: { ...generalData, name } })}
                                value={generalData?.name ?? ''}
                                label={`${I18N_PREFIX}.input.name.label`}
                                placeholder={`${I18N_PREFIX}.input.name.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimplePhoneInput
                                id="inputText_generalData_mainPhoneNumber"
                                onChange={phoneNumber =>
                                    handleChange({ generalData: { ...generalData, mainPhoneNumber: { number: phoneNumber } } })
                                }
                                value={generalData?.mainPhoneNumber?.number ?? ''}
                                label={`${I18N_PREFIX}.input.phoneNumber.label`}
                                placeholder={`${I18N_PREFIX}.input.phoneNumber.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleLocalDateInput
                                id="inputDate_generalData_birthDate"
                                onChange={birthDate =>
                                    handleChange({
                                        generalData: {
                                            ...generalData,
                                            birthDate,
                                        },
                                    })
                                }
                                value={generalData?.birthDate ?? ''}
                                label={`${I18N_PREFIX}.input.birthDate.label`}
                                placeholder={`${I18N_PREFIX}.input.birthDate.placeholder`}
                            />
                        </div>
                        <div className="form__propose-employee-step--input">
                            <SimpleSelectInput
                                id="inputSelect_generalData_genderSelection"
                                label={`${I18N_PREFIX}.input.gender.label`}
                                value={generalData?.gender ?? ''}
                                onChange={gender => handleChange({ generalData: { ...generalData, gender: GenderType[gender] } })}
                                mapperFromString={_value => (_value ? GenderType[_value] : undefined)}
                                placeholder={`${I18N_PREFIX}.input.gender.placeholder`}
                            >
                                <MenuItem value={GenderType.MALE}>{t(`${I18N_PREFIX}.input.gender.options.MALE`)}</MenuItem>
                                <MenuItem value={GenderType.FEMALE}>{t(`${I18N_PREFIX}.input.gender.options.FEMALE`)}</MenuItem>
                            </SimpleSelectInput>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ProposeEmployeeGeneralDataStep;
