import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ManagerModeActionTypes, ManagerModeState } from './types';

export const initialState: ManagerModeState = {
    status: HttpRequestStatus.NOOP,
    managerMode: undefined,
    error: undefined,
};

const reducer: Reducer<ManagerModeState> = (state = initialState, action): ManagerModeState => {
    switch (action.type) {
        case ManagerModeActionTypes.MANAGER_MODE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, managerMode: undefined, error: undefined };
        }
        case ManagerModeActionTypes.MANAGER_MODE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, managerMode: action.payload, error: undefined };
        }
        case ManagerModeActionTypes.MANAGER_MODE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ManagerModeActionTypes.MANAGER_MODE_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as managerModeReducer };
