import { AxiosResponse } from 'axios';
import { IBank } from 'model/bank.model';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import bankApi from 'services/api/bankApi';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';
import { searchBanksError, searchBanksRequest, searchBanksSuccess } from './actions';
import { SearchBanksTypes } from './types';

function* handleSearchBanks(_action: ReturnType<typeof searchBanksRequest>) {
    try {
        const result: AxiosResponse<Page<IBank>> = yield call(bankApi.searchBanks, _action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroApiError(result);
            yield put(markError(mapped));
            yield put(searchBanksError(mapped));
            return;
        }
        yield put(searchBanksSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchBanksError(mapped));
    }
}

function* watchSearchBanks() {
    yield takeEvery(SearchBanksTypes.SEARCH_BANKS_REQUEST, handleSearchBanks);
}

function* searchBanksSaga() {
    yield all([fork(watchSearchBanks)]);
}

export default searchBanksSaga;
