import { ICompanyEmployee } from '../../../model/company-employee.model';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum DefaultCompanyActionTypes {
    GET_DEFAULT_COMPANY_EMPLOYEE_REQUEST = '@@defaultCompany/GET_COMPANY_EMPLOYEE_DEFAULT_REQUEST',
    GET_DEFAULT_COMPANY_EMPLOYEE_SUCCESS = '@@defaultCompany/GET_DEFAULT_COMPANY_EMPLOYEE_SUCCESS',
    GET_DEFAULT_COMPANY_EMPLOYEE_ERROR = '@@defaultCompany/GET_DEFAULT_COMPANY_EMPLOYEE_ERROR',
    CHANGE_COMPANY_EMPLOYEE_DEFAULT_REQUEST = '@@defaultCompany/CHANGE_COMPANY_EMPLOYEE_DEFAULT_REQUEST',
    CHANGE_COMPANY_EMPLOYEE_DEFAULT_SUCCESS = '@@defaultCompany/CHANGE_COMPANY_EMPLOYEE_DEFAULT_SUCCESS',
    CHANGE_COMPANY_EMPLOYEE_DEFAULT_ERROR = '@@defaultCompany/CHANGE_COMPANY_EMPLOYEE_DEFAULT_ERROR',

    DEFAULT_COMPANY_EMPLOYEE_RESET_STATE = '@@defaultCompany/DEFAULT_COMPANY_EMPLOYEE_RESET_STATE',
}

export interface DefaultCompanyEmployeeState extends BaseStoreState {
    readonly company?: ICompanyEmployee;
}
