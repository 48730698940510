import { ProposeEmployeeDocumentData } from 'model/employee-borrower/propose/document.model';
import { ProposeEmployeeSimple } from 'model/employee-borrower/propose/propose.model';
import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { UpdateEmployeeBorrowerProposeDocumentDataActionTypes } from './types';

export const updateEmployeeBorrowerProposeDocumentDataRequest = (request: ProposeEmployeeDocumentData) => action(UpdateEmployeeBorrowerProposeDocumentDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DATA_REQUEST, request);
export const updateEmployeeBorrowerProposeDocumentDataSuccess = (data: ProposeEmployeeSimple) => action(UpdateEmployeeBorrowerProposeDocumentDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DATA_SUCCESS, data);
export const updateEmployeeBorrowerProposeDocumentDataError = (error: GiroWebappError) => action(UpdateEmployeeBorrowerProposeDocumentDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_DATA_ERROR, error);
export const updateEmployeeBorrowerProposeDocumentDataResetState = () => action(UpdateEmployeeBorrowerProposeDocumentDataActionTypes.UPDATE_EMPLOYEE_BORROWER_PROPOSE_DOCUMENT_RESET_STATE);

