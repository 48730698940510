import { FilledInputProps, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FieldBasic from '../../../../components/field-basic/field-basic';
import { useCompanyFilterContext } from './company-filter-context';
import './company-filter.scss';

export const CompanyFilter = () => {
    const { t } = useTranslation();
    const { filter, changeFilter } = useCompanyFilterContext();

    const handleFilterChange = (value: string) => {
        if (filter !== value && changeFilter) {
            changeFilter(value);
        }
    };

    const adornment: Pick<FilledInputProps, 'startAdornment'> | undefined = {
        startAdornment: (
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
        ),
    };

    return (
        <div className="container__filter">
            <FieldBasic
                value={filter}
                label={t('modal-company.search')}
                InputProps={{ type: 'search', placeholder: t('modal-company.search'), ...adornment }}
                onChange={handleFilterChange}
            />
        </div>
    );
};

export default CompanyFilter;
