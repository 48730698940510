import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Token } from '../../model/authentication';
import authenticationApi from '../../services/api/authenticationApi';
import AuthUtil from '../../services/api/authUtil';
import { ErrorUtils } from '../../shared/error/error-utils';
import { markError } from '../application/error/actions';
import { loginError, loginRequest, loginSuccess } from './actions';
import { AuthenticationActionTypes } from './types';

function* handleLogin(action: ReturnType<typeof loginRequest>) {
  try {
    const result: AxiosResponse<Token> = yield call(authenticationApi.login, action.payload);
    if (result.status !== 200 || !result.data) {
      const mapped = ErrorUtils.mapGiroApiError(result);
      yield put(markError(mapped));
      yield put(loginError(mapped));
      return;
    }

    const token: Token = result.data;
    AuthUtil.setToken(token);

    yield put(loginSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(loginError(mapped));
  }
}

function* watchLoginRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}

function* handleLogout() {
  try {
    yield call(authenticationApi.logout);
  } catch (error) {
    // nothing to do
  }
}
function* handleClear() {
  try {
    yield localStorage.clear();
  } catch (error) {
    // nothing to do
  }
}

function* watchLogoutRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGOUT_REQUEST, handleLogout);
}
function* watchClearRequest() {
  yield takeEvery(AuthenticationActionTypes.CLEAR, handleClear);
}

function* authenticationSaga() {
  yield all([fork(watchLoginRequest), fork(watchLogoutRequest), fork(watchClearRequest)]);
}

export default authenticationSaga;
